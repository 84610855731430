export const CSVHeaderTable = ({ headers }) => (
  <div className="overflow-x-auto max-w-full mb-4 c-scrollbar">
    <table className="border-collapse border-1/2 border-solid border-grey text-black text-xs">
      <thead>
        <tr>
          {headers.map((header) => (
            <th
              key={header}
              className="border-1/2 border-solid border-grey font-normal p-1"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
    </table>
  </div>
);

export const Preview = () => {
  const headers = ["Header 1", "Header 2", "Header 3", "Header 4"];

  return <CSVHeaderTable headers={headers} />;
};
