import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Select from "components/forms/select/Select";
import Modal from "components/modals/Modal";
import addNotification from "components/notifications/Notifications";

const DelayedConfirmationModal = ({
  id,
  title,
  confirmationText,
  onConfirmation,
  undoMessage,
  closeModal,
  openModal,
  content = "",
  selectOptions = [],
}) => {
  const { t } = useTranslation();

  const undoText = t("global.undo");
  const closeModalText = t("referrals.delayedConfirmationModal.closeModalText");
  const selectRewardCategoryPlaceholder = t(
    "referrals.delayedConfirmationModal.selectRewardCategory"
  );
  const undoCounterText = t("referrals.delayedConfirmationModal.undoCounterText");

  const [disableConfirmButton, setDisableConfirmButton] = useState();
  const [loadingConfirmButton, setLoadingConfirmButton] = useState();
  const [confirmTimeout, setConfirmTimeout] = useState();

  const [showConfirmButton, setShowConfirmButton] = useState();

  const [disableUndoButton, setDisableUndoButton] = useState();
  const [undoCounter, setUndoCounter] = useState();
  const [undoInterval, setUndoInterval] = useState();
  const [undoTimeout, setUndoTimeout] = useState();

  const [showCloseButton, setShowCloseButton] = useState(true);
  const [disableCloseButton, setDisableCloseButton] = useState();

  const [selectedOption, setSelectedOption] = useState(null);
  const hasSelectOptions = selectOptions.length > 0;

  const handleSelectorChange = (item) => {
    setSelectedOption(item.value);
    setDisableConfirmButton(false);
  };

  const undoAction = () => {
    clearTimeout(undoTimeout);
    clearInterval(undoInterval);
    closeModal();
    addNotification.success(undoText, undoMessage);
  };

  const undoTimerComplete = () => {
    setDisableUndoButton(true);
    onConfirmation(selectedOption);
  };

  const startUndoTimer = () => {
    setShowCloseButton(false);
    setDisableCloseButton(true);
    setShowConfirmButton(false);
    setUndoCounter(3);
    setUndoTimeout(setTimeout(() => undoTimerComplete(), 3000));
  };

  useEffect(() => {
    if (undoCounter === 0) {
      setUndoCounter(undoCounterText);
      return;
    }

    const ref =
      undoCounter > 0 &&
      setInterval(() => {
        setUndoCounter(undoCounter - 1);
      }, 1000);

    setUndoInterval(ref);

    return () => {
      clearInterval(ref);
    };
  }, [undoCounter, undoCounterText]);

  useEffect(() => {
    setShowConfirmButton(true);
    setDisableUndoButton(false);
    setShowCloseButton(true);
    setDisableCloseButton(false);
    if (!hasSelectOptions) {
      setDisableConfirmButton(true);
      setLoadingConfirmButton(true);
      setConfirmTimeout(
        setTimeout(() => {
          setDisableConfirmButton(false);
          setLoadingConfirmButton(false);
        }, 3000)
      );
    } else {
      setDisableConfirmButton(true);
      setLoadingConfirmButton(false);
      setSelectedOption(null);
    }
    return () => {
      clearTimeout(confirmTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Modal
      closeModal={closeModal}
      closeModalText={closeModalText}
      openModal={openModal}
      title={title}
      id={id}
      buttonClassName="button--negative"
      showCloseButton={showCloseButton}
      disableCloseButton={disableCloseButton}
    >
      {content && <p>{content}</p>}
      {hasSelectOptions && (
        <React.Fragment>
          <Select
            options={selectOptions}
            handleOnChange={handleSelectorChange}
            selectedValue={selectedOption}
            selectedPlaceholder={selectRewardCategoryPlaceholder}
            fullWidth
          />
          <br />
        </React.Fragment>
      )}
      {showConfirmButton && (
        <Button
          buttonElement="button"
          appearance="primary"
          className="button button--cta u-stack-2"
          fullWidth
          onClick={startUndoTimer}
          loading={loadingConfirmButton}
          disabled={disableConfirmButton}
        >
          {confirmationText}
        </Button>
      )}
      {!showConfirmButton && (
        <Button
          buttonElement="button"
          appearance="primary"
          className="button button--negative u-stack-2"
          fullWidth
          onClick={undoAction}
          disabled={disableUndoButton}
        >
          {`${undoText}... `}
          {undoCounter}
        </Button>
      )}
    </Modal>
  );
};

DelayedConfirmationModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  undoMessage: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default DelayedConfirmationModal;
