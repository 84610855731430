import React from "react";

import { Info } from "assets/images/CustomIcons";
import PropTypes from "prop-types";

import Tooltip from "components/tooltip/Tooltip";

import "./tooltip_th.scss";

const TooltipTh = ({ headerCopy, tooltipMessage }) => (
  <th className="tooltip-th">
    <span className="tooltip-th__container">
      <Tooltip
        testId="tooltip-th-tooltip"
        className="tooltip-th__container__tooltip"
        tipContentClassName="c-dropdown__info-plain"
        content={tooltipMessage}
        direction="up"
        useHover
      >
        <Info />
      </Tooltip>

      <span className="tooltip-th__container__header">{headerCopy}</span>
    </span>
  </th>
);

TooltipTh.propTypes = {
  headerCopy: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
};

export default TooltipTh;
