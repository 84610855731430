import ReferralFriend from "models/ReferralFriend";
import Status from "models/Status";

export default class CisReferral {
  constructor(data) {
    this.id = data.attributes.id;
    this.createdAt = data.attributes.claimed_at;
    this.rewards = [];
    this.friend = new ReferralFriend(data.attributes.friend.data.attributes);
    this.status = new Status({
      value: data.attributes.status,
      updated_at: data.attributes.final_state_at || 0,
      failure_reason: data.attributes.failure_reason,
    });
  }
}
