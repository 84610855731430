import { useHistory } from "react-router-dom";

export const ReactRouter5Adapter = ({ children }) => {
  const history = useHistory();

  const adapter = {
    replace(location) {
      history.replace(location.search || "?", location.state);
    },
    push(location) {
      history.push(location.search || "?", location.state);
    },
    get location() {
      return history.location;
    },
  };

  return children(adapter);
};
