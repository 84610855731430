import BaseUser from "models/BaseUser";

import Order from "./Order";
import Voucher from "./Voucher";

export default class Friend extends BaseUser {
  constructor(market, data) {
    super(market, data);

    this.mobile = data.mobile;
    this.firstSeen = data.first_seen;
    this.lastSeen = data.last_seen;
    this.type = data.user_type;

    this.vouchers = data.vouchers.map((voucherData) => new Voucher(voucherData));
    this.orders = data.orders.map((orderData) => new Order(orderData));

    this.totalReferrals = data.total_referrals;
    this.totalCompletedReferrals = data.total_completed_referrals;
    this.totalPendingReferrals = data.total_pending_referrals;
    this.totalCancelledReferrals = data.total_cancelled_referrals;
  }

  displayType() {
    return "friend";
  }

  isA(className) {
    return className === "ExternalUser";
  }

  isCustomer() {
    return false;
  }

  isExternalUser() {
    return true;
  }
}
