import React, { useEffect, useState } from "react";

import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import CheckboxDropdown from "components/forms/checkbox_dropdown/CheckboxDropdown";
import Form from "components/forms/form/Form";

const RewardForm = ({ market, campaignId, partnerId, partnerRewards, onCloseForm, onSubmit }) => {
  const { t } = useTranslation();
  const [availableRewards, setAvailableRewards] = useState({});
  const [form, setForm] = useState(partnerRewards);
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    const getPartnerAvailableRewards = async () => {
      setForm((f) => ({
        ...f,
        loading: true,
      }));
      setFormErrors([]);

      try {
        const res = await market.partner.getPartnerAvailableRewards(partnerId, campaignId);

        setAvailableRewards(res);
      } catch (errors) {
        setFormErrors([{ type: "getAvailableRewardsError" }]);
      } finally {
        setForm((f) => ({
          ...f,
          loading: false,
        }));
      }
    };

    getPartnerAvailableRewards();
  }, [campaignId, market?.partner, partnerId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setForm({
      ...form,
      loading: true,
    });
    setFormErrors([]);

    try {
      await market.partner.setPartnerRewards(
        partnerId,
        campaignId,
        form.partner_rewards &&
          form.partner_rewards.filter((reward) =>
            availableRewards.partner_rewards.includes(reward)
          ),
        form.audience_rewards &&
          form.audience_rewards.filter((reward) =>
            availableRewards.audience_rewards.includes(reward)
          )
      );

      setForm({
        ...form,
        loading: false,
      });
      onSubmit();
      onCloseForm();
    } catch (errors) {
      setFormErrors([{ type: "setRewardsError" }]);
    }
  };

  const handleDropdownSelect = (name, selected) => {
    const values = selected.map((option) => option.value);

    setForm({
      ...form,
      [name]: values,
    });
  };

  const formatDropdownData = (values) => {
    if (!values) {
      return [];
    }

    return values.map((v) => ({
      label: v,
      value: v,
    }));
  };

  const avilablePartnerRewards = () =>
    availableRewards && formatDropdownData(availableRewards.partner_rewards);
  const avilableAudienceRewards = () =>
    availableRewards && formatDropdownData(availableRewards.audience_rewards);

  const existingSupportedPartnerRewards = () =>
    availableRewards &&
    availableRewards.partner_rewards &&
    form.partner_rewards &&
    form.partner_rewards.filter((reward) => availableRewards.partner_rewards.includes(reward));

  const existingSupportedAudienceRewards = () =>
    availableRewards &&
    availableRewards.audience_rewards &&
    form.audience_rewards &&
    form.audience_rewards.filter((reward) => availableRewards.audience_rewards.includes(reward));

  return (
    <Form onSubmit={handleSubmit} errorKey="partnerRewards" errors={formErrors}>
      <h2 className="">{t("partnerProfile.rewards.setRewards")}</h2>

      <div className="partner-rewards-form-fields">
        <CheckboxDropdown
          name="partner_rewards"
          className="partner-rewards-form-dropdown"
          labelText={t("partnerProfile.rewards.form.partnerRewardLabel")}
          placeholder={t("partnerProfile.rewards.form.rewardPlaceholder")}
          options={avilablePartnerRewards() || []}
          value={formatDropdownData(existingSupportedPartnerRewards())}
          handleOnChange={handleDropdownSelect}
        />

        <CheckboxDropdown
          name="audience_rewards"
          className="partner-rewards-form-dropdown"
          labelText={t("partnerProfile.rewards.form.audienceRewardLabel")}
          placeholder={t("partnerProfile.rewards.form.rewardPlaceholder")}
          options={avilableAudienceRewards() || []}
          value={formatDropdownData(existingSupportedAudienceRewards())}
          handleOnChange={handleDropdownSelect}
        />
      </div>

      <div className="partner-form__modal-footer">
        <Button buttonElement="submit" size="large" appearance="primary" loading={form.loading}>
          {t("global.save")}
        </Button>
        <Button
          buttonElement="button"
          size="large"
          appearance="secondary"
          outline
          onClick={onCloseForm}
        >
          {t("global.cancel")}
        </Button>
      </div>
    </Form>
  );
};

RewardForm.propTypes = {
  market: PropTypes.instanceOf(Market).isRequired,
  campaignId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  partnerRewards: PropTypes.shape({
    partner_rewards: PropTypes.arrayOf(PropTypes.string),
    audience_rewards: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onCloseForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RewardForm;
