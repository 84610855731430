import React, { useState } from "react";

import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import { Button, FulfilmentActions, StatusColours } from "bp-ui";
import { Modal } from "bp-ui";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { getFulfilmentStatusMessage } from "components/customer_lookup/new_user/helpers";
import UserFulfilmentExpire from "components/customer_lookup/user/user_fulfilments/user_fulfilment_expire/UserFulfilmentExpire";
import UserFulfilmentExpiryChange from "components/customer_lookup/user/user_fulfilments/user_fulfilment_expiry_change/UserFulfilmentExpiryChange";
import UserFulfilmentUnexpire from "components/customer_lookup/user/user_fulfilments/user_fulfilment_unexpire/UserFulfilmentUnexpire";
import FormattedDate from "components/formatted_date/FormattedDate";
import Select from "components/forms/select/Select";
import addNotification from "components/notifications/Notifications";

export const TimelineFulfilmentDetailAndActions = ({
  fulfilment,
  timezoneOffset,
  selectedMarket,
  reloadData,
  userId,
  addToTimeline,
}) => {
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [showChangeExpiryModal, setShowChangeExpiryModal] = useState(false);
  const [showUnexpireModal, setShowUnexpireModal] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentAdmin } = useAuth();
  const fulfilmentStatusMessage = getFulfilmentStatusMessage(fulfilment);
  const { manageExpiryOperations, resendFulfilmentEmail } = Permissions;

  const handleOnChange = (option) => {
    setSelectedAction(option.value);
  };

  const handleResendRewardEmail = async () => {
    try {
      setLoading(true);
      await selectedMarket.users.resendFulfillment(userId, fulfilment.id);
      processSuccessfulRewardAction(
        t("global.success"),
        t("fulfilments.actions.successRewardEmailResentMessage"),
        () => null
      );

      if (addToTimeline) {
        await new Promise((resolve) => {
          setTimeout(() => {
            addToTimeline({
              emailType: "generic",
              emailEvent: "processed",
              timestamp: DateTime.now().toISO(),
              fulfilmentId: fulfilment.id,
            });

            resolve();
          }, 1000);
        });
      }

      setLoading(false);
    } catch (e) {
      addNotification.warning(
        t("global.error"),
        t("fulfilments.actions.errorRewardEmailResenMessage")
      );
    } finally {
      setLoading(false);
    }
  };

  const processRewardAction = () => {
    switch (selectedAction) {
      case "resentOption":
        setLoading(true);
        handleResendRewardEmail();
        break;
      case "changeExpiry":
        setShowChangeExpiryModal(true);
        break;
      case "expire":
        setShowExpireModal(true);
        break;
      case "unexpire":
        setShowUnexpireModal(true);
        break;
      default:
        break;
    }
  };

  const processSuccessfulRewardAction = (title, message, modalFunction) => {
    modalFunction(false);
    addNotification.success(title, message);
    setSelectedAction(null);
    reloadData && reloadData();
  };

  const onSuccessfulChangeExpiry = (fn) =>
    processSuccessfulRewardAction(
      t("global.success"),
      t("fulfilments.notifications.successfulExpiryDateUpdate"),
      () => setShowChangeExpiryModal(false)
    );

  const onSuccessfulUnexpire = (fn) =>
    processSuccessfulRewardAction(
      t("global.success"),
      t("fulfilments.notifications.successfulExpiryDateUpdate"),
      () => setShowUnexpireModal(false)
    );

  const onSuccessfulExpiry = (fn) =>
    processSuccessfulRewardAction(
      t("global.success"),
      t("fulfilments.notifications.successfulExpiry"),
      () => setShowExpireModal(false)
    );

  const options = [];
  if (
    currentAdmin.can(resendFulfilmentEmail) &&
    fulfilment.availableActions.indexOf(FulfilmentActions.RESEND) > -1
  ) {
    options.push({
      name: t("fulfilments.actions.resentOption"),
      value: "resentOption",
    });
  }

  if (currentAdmin.can(manageExpiryOperations)) {
    if (fulfilment.availableActions.indexOf(FulfilmentActions.EXPIRE) > -1) {
      options.push({
        name: t("fulfilments.actions.changeExpiry"),
        value: "changeExpiry",
      });
      options.push({
        name: t("fulfilments.actions.expire"),
        value: "expire",
      });
    }
    if (fulfilment.availableActions.indexOf(FulfilmentActions.UNEXPIRE) > -1) {
      options.push({
        name: t("fulfilments.actions.unexpire"),
        value: "unexpire",
      });
    }
  }

  const statusColour = StatusColours[fulfilment.status.value];

  return (
    <div>
      {(fulfilmentStatusMessage || fulfilment.providerTransactionId) && (
        <div
          className={`border-0 border-l-2 pl-4 border-solid border-${statusColour || "red"} flex flex-col`}
        >
          {fulfilment.providerTransactionId && (
            <div className="text-black text-xs my-0.5">
              <span className="font-bold mr-1">
                {t("referrerProfile.referralTimeline.transactionId")}
              </span>
              {fulfilment.providerTransactionId}
            </div>
          )}
          {fulfilmentStatusMessage && (
            <>
              <div className="text-black text-xs my-0.5">
                <span className="font-bold mr-1">
                  {t("referrerProfile.referralTimeline.timeProcessed")}
                </span>
                <FormattedDate
                  timestamp={fulfilment.status.updatedAt}
                  timezoneOffset={timezoneOffset}
                  type="numeral_short_with_time"
                />
              </div>
              <div className="text-black text-xs my-0.5">
                <span className="font-bold mr-1">
                  {t("referrerProfile.referralTimeline.error")}
                </span>
                {fulfilmentStatusMessage}
              </div>
            </>
          )}
        </div>
      )}

      {options.length > 0 ? (
        <>
          <h4 className="mt-8 mb-0">{t("referrerProfile.referralTimeline.actionDescriptor")}</h4>
          <Select
            options={options}
            handleOnChange={handleOnChange}
            selectedValue={selectedAction}
            buttonAppearance="secondary"
            selectedPlaceholder={t("referrerProfile.referralTimeline.selectPlaceholder")}
          />
          <div>
            <Button
              className="mt-4"
              disabled={!selectedAction}
              onClick={processRewardAction}
              loading={loading}
            >
              {t("referrerProfile.referralTimeline.confirm")}
            </Button>
          </div>

          <Modal
            onClose={() => setShowChangeExpiryModal(false)}
            open={showChangeExpiryModal}
            title={t("fulfilments.changeExpiryDateText")}
            showCloseButton={false}
            className="!max-w-1/3"
          >
            <UserFulfilmentExpiryChange
              fulfilmentId={fulfilment.id}
              selectedMarket={selectedMarket}
              onSuccessfulUpdate={onSuccessfulChangeExpiry}
              onCancel={() => setShowChangeExpiryModal(false)}
              cancelText={t("global.cancel")}
            />
          </Modal>
          <Modal
            onClose={() => setShowUnexpireModal(false)}
            open={showUnexpireModal}
            title={t("fulfilments.unexpireModalText")}
            showCloseButton={false}
            className="!max-w-1/3"
          >
            <UserFulfilmentUnexpire
              fulfilmentId={fulfilment.id}
              selectedMarket={selectedMarket}
              onSuccessfulUpdate={onSuccessfulUnexpire}
              onCancel={() => setShowUnexpireModal(false)}
              cancelText={t("global.cancel")}
            />
          </Modal>
          <Modal
            onClose={() => setShowExpireModal(false)}
            open={showExpireModal}
            title={t("fulfilments.expireModalText")}
            showCloseButton={false}
            subTitle={t("fulfilments.expireModalSubtitle")}
            header
            headerType="danger"
            className="!max-w-1/3"
          >
            <UserFulfilmentExpire
              fulfilmentId={fulfilment.id}
              selectedMarket={selectedMarket}
              onSuccessfulUpdate={onSuccessfulExpiry}
              onCancel={() => setShowExpireModal(false)}
              cancelText={t("global.cancel")}
              errorNotificationTitle={t("global.error")}
              errorMessage={t("fulfilments.notifications.expiryErrorMessage")}
            />
          </Modal>
        </>
      ) : null}
    </div>
  );
};
