import React from "react";

import { Trans, useTranslation } from "react-i18next";

const ContactModalContent = () => {
  const { t } = useTranslation();
  const emailSubject = t("reporting.dashboard.overview.contactBar.modal.emailSubject");
  const firstParagraph = t("reporting.dashboard.overview.contactBar.modal.firstParagraph");
  const reachOutAt = `${t("reporting.dashboard.overview.contactBar.modal.reachOutAt")}:`;

  const emailUrl = `mailto:success@buyapowa.com?subject=${emailSubject}`;

  return (
    <div className="c-contact-bar__modal">
      <p>{firstParagraph}</p>
      <p>
        <Trans t={t} i18nKey="reporting.dashboard.overview.contactBar.modal.secondParagraph" />
      </p>
      <p>
        {reachOutAt}
        <br />
        <a className="c-link--email" href={emailUrl}>
          success@buyapowa.com
        </a>
      </p>
    </div>
  );
};

export default ContactModalContent;
