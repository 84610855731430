import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Modal from "components/modals/Modal";
import "components/partner_management/partner_profile/assets/assets.scss";
import AssetsForm from "components/partner_management/partner_profile/assets/form/AssetsForm";

import Assets from "./Assets";
import NoAssets from "./NoAssets";

const AssetsSection = ({ market, partner, reloadPartner }) => {
  const { t } = useTranslation();
  const partnerId = partner.id;
  const [addAssetModalDisplayState, setAddAssetModalDispayState] = useState(false);
  const toggleAddAssetModal = () => setAddAssetModalDispayState((a) => !a);

  const defaultLocale = partner.partnerLocales.find(
    (partnerLocale) => partnerLocale.locale === market.defaultLocale
  );

  const hasAssets = !!defaultLocale;

  return (
    <div className="c-partner-profile__profile">
      <div className="c-partner-profile__profile-button-header">
        <h2>{t("partnerProfile.assets")}</h2>

        <Button size="small" appearance="primary" onClick={toggleAddAssetModal}>
          {hasAssets
            ? t("partnerProfile.addAssets.editPrompt")
            : t("partnerProfile.addAssets.prompt")}
        </Button>

        <Modal
          openModal={addAssetModalDisplayState}
          closeModal={toggleAddAssetModal}
          showCloseButton={false}
          showCrossButton
          className="c-assets-modal"
        >
          <AssetsForm
            market={market}
            partnerId={partnerId}
            onCancel={toggleAddAssetModal}
            reloadPartner={reloadPartner}
            defaultLocale={defaultLocale}
            partner={partner}
          />
        </Modal>
      </div>

      <p className="c-partner-profile__description">{t("partnerProfile.contentInfo")}</p>

      {hasAssets && <Assets defaultLocale={defaultLocale} />}
      {!hasAssets && <NoAssets />}
    </div>
  );
};

export default AssetsSection;
