import React from "react";

import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useMatch, useParams } from "react-router-dom-v5-compat";

import BulkActions from "components/bulk_actions/BulkActions";
import UserSearch from "components/customer_lookup/search/user_search/UserSearch";
import { User } from "components/customer_lookup/user/User";
import Navigation from "components/navigation_new/Navigation";
import Partners from "components/partner_management/Partners";
import PartnerProfile from "components/partner_management/partner_profile/PartnerProfile";
import SubNavigation from "components/sub_navigation_new/SubNavigation";

import AddCustomers from "../bulk_actions/add_customers/AddCustomers.jsx";
import ForgetUsers from "../bulk_actions/forget_users/ForgetUsers.jsx";
import ReferralManagement from "../bulk_actions/referral_management/ReferralManagement.jsx";
import Signups from "../bulk_actions/signups/Signups.jsx";
import TransactionTracking from "../bulk_actions/transaction_tracking/TransactionTracking.jsx";
import "./users.scss";

const {
  bulkActionTransactions,
  bulkActionReferrals,
  bulkActionSignups,
  bulkActionCustomers,
  bulkActionForgetUsers,
  managePartners,
} = Permissions;

const Users = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const { currentAdmin } = useAuth();

  let path = useMatch({ path: ":market/users", end: false })?.pathname;

  const timezoneOffset = selectedMarket.timezone_offset;

  const bulkActionsPermissions = [
    bulkActionTransactions,
    bulkActionReferrals,
    bulkActionSignups,
    bulkActionCustomers,
    bulkActionForgetUsers,
  ];

  const availableLinks = [
    {
      text: t("users.navigation.partnerManagement"),
      path: "/users/partners",
      permissions: [managePartners],
    },
    {
      text: t("users.navigation.customerManagement"),
      path: "/users/search",
      permissions: [],
    },
    {
      text: t("users.navigation.bulkActions"),
      path: "/users/bulk-actions",
      permissions: bulkActionsPermissions,
    },
  ];

  const availableBulkActionLinks = [
    {
      text: t("bulkActions.navigation.transactionTracking"),
      path: "transaction-tracking",
      permissions: [bulkActionTransactions],
    },
    {
      text: t("bulkActions.navigation.referralManagement"),
      path: "referral-management",
      permissions: [bulkActionReferrals],
    },
    {
      text: t("bulkActions.navigation.signups"),
      path: "signups",
      permissions: [bulkActionSignups],
    },
    {
      text: t("bulkActions.navigation.addCustomers"),
      path: "add-customers",
      permissions: [bulkActionCustomers],
    },
    {
      text: t("bulkActions.navigation.forgetUsers"),
      path: "forget-users",
      permissions: [bulkActionForgetUsers],
    },
  ];

  const firstAuthorizedBulkActionsLink = () => {
    const authorizedLinks = currentAdmin.super
      ? availableBulkActionLinks
      : availableBulkActionLinks.filter((link) => currentAdmin.canPerformAnyOf(link.permissions));

    if (authorizedLinks.length > 0) {
      return authorizedLinks[0].path;
    }

    return ".";
  };

  if (path === "/") path = "";

  return (
    <div className="c-users">
      <Navigation selectedMarket={selectedMarket} />

      <SubNavigation subdomain={selectedMarket.subdomain} availableLinks={availableLinks} />
      <Routes>
        <Route path={`${path}`} element={<Navigate to={`${path}/search`} />} />
        <Route path={`${path}/partners`} element={<Partners selectedMarket={selectedMarket} />} />

        <Route
          path={`${path}/partners/:partnerId`}
          element={
            <PartnerProfile selectedMarket={selectedMarket} timezoneOffset={timezoneOffset} />
          }
        />

        <Route path={`${path}/search`} element={<UserSearch selectedMarket={selectedMarket} />} />

        <Route path={`${path}/search/:id`} element={<User selectedMarket={selectedMarket} />} />

        <Route
          path={`${path}/bulk-actions/*`}
          element={<BulkActions selectedMarket={selectedMarket} />}
        >
          <Route
            path="transaction-tracking"
            element={<TransactionTracking selectedMarket={selectedMarket} />}
          />
          <Route
            path="referral-management"
            element={<ReferralManagement selectedMarket={selectedMarket} />}
          />
          <Route path="signups" element={<Signups selectedMarket={selectedMarket} />} />
          <Route path="add-customers" element={<AddCustomers selectedMarket={selectedMarket} />} />
          <Route path="forget-users" element={<ForgetUsers selectedMarket={selectedMarket} />} />
        </Route>
        <Route
          path={`${path}/bulk-actions`}
          element={<Navigate to={`${path}/bulk-actions/${firstAuthorizedBulkActionsLink()}`} />}
        />
      </Routes>
    </div>
  );
};

Users.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default Users;
