import React from "react";

import { fullName } from "helpers/Helpers";
import CisRewardFulfillment from "models/CisRewardFulfillment";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import NoResults from "components/customer_lookup/no_results/NoResults";
import Status from "components/customer_lookup/row_atoms/status/Status";
import FormattedDate from "components/formatted_date/FormattedDate";
import FormattedRewardIdentifier from "components/formatted_reward_identifier/FormattedRewardIdentifier";

import RewardActions from "./reward_actions/RewardActions";
import "./rewards.scss";

const Rewards = ({ rewardFulfillments, refreshData, selectedMarket, userId }) => {
  const anyWithActions = rewardFulfillments.some((rf) => rf.status.isActionable());

  const { t } = useTranslation();

  const noneText = t("global.none");
  const headerText = t("rewards.header");
  const tableHeaderAddressText = t("rewards.tableHeaders.address");
  const tableHeaderDateEarnedText = t("rewards.tableHeaders.dateEarned");
  const tableHeaderItemText = t("rewards.tableHeaders.item");
  const tableHeaderSourceText = t("rewards.tableHeaders.source");
  const tableHeaderStatusText = t("rewards.tableHeaders.status");

  return rewardFulfillments.length > 0 ? (
    <div className="card">
      <h2>{headerText}</h2>
      <div className="c-cis-rewards">
        <div className="table-container">
          <table className="portal-table">
            <thead>
              <tr>
                <th>{tableHeaderItemText}</th>
                <th>{tableHeaderDateEarnedText}</th>
                <th>{tableHeaderSourceText}</th>
                <th>{tableHeaderAddressText}</th>
                <th>{tableHeaderStatusText}</th>
                {anyWithActions && <th />}
              </tr>
            </thead>
            <tbody>
              {rewardFulfillments.map((rf) => (
                <tr key={rf.id}>
                  <td>
                    <FormattedRewardIdentifier value={rf.rewardIdentifier} />
                  </td>
                  <td>
                    <FormattedDate
                      timestamp={rf.createdAt}
                      timezoneOffset={selectedMarket.timezone_offset}
                      type="long"
                    />
                  </td>
                  <td>
                    {rf.source ? fullName(rf.source.firstName, rf.source.lastName) : noneText}
                  </td>
                  <td>
                    {rf.address
                      ? `${rf.address.street} ${rf.address.city} ${rf.address.state} ${rf.address.country} ${rf.address.zip}`
                      : noneText}
                  </td>
                  <td>
                    <Status status={rf.status} timezoneOffset={selectedMarket.timezone_offset} />
                  </td>
                  {anyWithActions && (
                    <td>
                      <RewardActions
                        selectedMarket={selectedMarket}
                        rewardFulfillment={rf}
                        refreshData={refreshData}
                        userId={userId}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <NoResults table={headerText} />
  );
};

Rewards.propTypes = {
  rewardFulfillments: PropTypes.arrayOf(PropTypes.instanceOf(CisRewardFulfillment)).isRequired,
  userId: PropTypes.string.isRequired,
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default Rewards;
