import React, { useEffect } from "react";

import { useAuth } from "@/AuthWrapper";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import Users from "components/customer_lookup/Users";
import SearchWrapper from "components/customer_lookup/search/SearchWrapper";
import NotFound from "components/not_found/NotFound";
import ParagonConnect from "components/paragon/ParagonConnect";
import BiCampaignReports from "components/reports/BiCampaignReports";

const Root = () => {
  const { market: subdomain } = useParams();
  const { currentAdmin } = useAuth();
  const ldClient = useLDClient();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (ldClient) {
      const ldContext = ldClient.getContext();
      ldContext.market_subdomain = subdomain;
      ldClient.identify(ldContext);
    }
  }, [ldClient, subdomain]);

  const selectedMarket = currentAdmin.marketBySubdomain(subdomain);

  if (selectedMarket === null) {
    return <NotFound />;
  }

  return (
    <SearchWrapper>
      <Switch>
        <Route path={`${path}/users`}>
          <Users selectedMarket={selectedMarket} />
        </Route>

        <Route path={`${path}/reports`}>
          <BiCampaignReports selectedMarket={selectedMarket} />
        </Route>

        <Route path={`${path}/integrations`}>
          <ParagonConnect selectedMarket={selectedMarket} />
        </Route>

        <Route
          exact
          path={path}
          render={() => <Redirect to={`/${selectedMarket.subdomain}/users/search`} />}
        />

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </SearchWrapper>
  );
};

export default Root;
