export default class Status {
  constructor(data) {
    this.value = data.value;
    this.updatedAt = data.updated_at;
    this.failureReason = data.failure_reason || null;
    this.paymentProviderStatus = data.payment_provider_status || null;
    this.paymentProviderError = data.payment_provider_error || null;
  }

  isPending() {
    return this.value === "pending";
  }

  isCancelled() {
    return this.value === "cancelled";
  }

  isFulfilled() {
    return this.value === "complete" || this.value === "fulfilled" || this.value === "redeemed";
  }

  isWaiting() {
    return this.value === "waiting";
  }

  isActionable() {
    return this.isWaiting() || this.isFulfilled();
  }

  isExpired() {
    return this.value === "expired";
  }

  isFailed() {
    return this.value === "failed";
  }
}
