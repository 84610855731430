import { Permissions } from "bp-ui";
import Market from "models/Market";

export default class Admin {
  constructor(data, axios) {
    this.email = data.email;
    this.markets = data.markets.map((m) => new Market(m, axios));
    this.permissions = data.permissions;
    this.super = data.super;
  }

  can(action) {
    return Permissions.can(this, action);
  }

  canPerformAnyOf(actions) {
    return Permissions.canPerformAnyOf(this, actions);
  }

  marketBySubdomain(subdomain) {
    return this.markets.find((m) => m.subdomain === subdomain) || null;
  }

  isMultiMarket() {
    return this.markets.length > 1;
  }
}
