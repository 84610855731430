import { useEffect } from "react";

// using a default export is hard to test so had to do this
export const useGetPartnerProgramPerformance = (market, dispatch, filters, deps) => {
  useEffect(() => {
    const getProgramPerformance = async () => {
      dispatch({ type: "loading" });
      try {
        const performance = await market.partner.getProgramPerformance(filters);
        dispatch({
          type: "loadedSuccessfully",
          programPerformance: performance,
        });
      } catch (errors) {
        dispatch({
          type: "errorsOccurred",
          errors,
        });
      }
    };
    getProgramPerformance();
  }, [dispatch, filters, market.partner, ...(deps || [])]);
};
