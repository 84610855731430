import React from "react";

import { number, string } from "prop-types";

import FormattedDate from "components/formatted_date/FormattedDate";

const ReferredByDate = ({ date, timezoneOffset, label }) => (
  <div className="cis-account">
    <span className="cis-account__header">{label}</span>
    <span className="cis-account__detail">
      <FormattedDate timestamp={date} timezoneOffset={timezoneOffset} type="long" />
    </span>
  </div>
);

ReferredByDate.propTypes = {
  date: number.isRequired,
  label: string.isRequired,
  timezoneOffset: string.isRequired,
};

export default ReferredByDate;
