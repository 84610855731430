import React from "react";

import { DateTime } from "luxon";

const formatPattern = {
  long: DateTime.DATE_FULL, // DD MMMM YYYY
  day_only: {
    day: "numeric",
  },
  day_month_only: {
    day: "numeric",
    month: "short",
  },
  long_with_time: {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  }, // DD MMMM YYYY HH:mm
  short: DateTime.DATE_MED, // D MMM YYYY
  numeral_short: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }, // DD/MM/YYY or DD.MM.YYYY
  numeral_short_with_time: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  }, // DD/MM/YYYY HH:mm or DD.MM.YYYY HH:mm
  short_without_day: {
    month: "short",
    year: "numeric",
  },
  month_only: {
    month: "short",
  },
};

export function formatDate(date, type) {
  return (
    DateTime.isDateTime(date) ? date : DateTime.fromISO(date)
  ).toLocaleString(formatPattern[type]);
}

export const FormattedDate = ({ timestamp, type, timezoneOffset }) => {
  if (timestamp === 0 || !timestamp) {
    return "Unknown";
  }

  let date;

  try {
    date = DateTime.fromSeconds(timestamp, { zone: "UTC" });
  } catch {
    date = DateTime.fromISO(timestamp, { zone: "UTC" });
  }

  if (timezoneOffset) {
    date = date.setZone("UTC" + timezoneOffset);
  }

  return <span className="c-formatted-date">{formatDate(date, type)}</span>;
};
