import React from "react";

import i18next from "i18next";
import { arrayOf, func, node, shape, string } from "prop-types";

import "./form.scss";

const Form = ({ children, onSubmit, errorKey, errors = [] }) => (
  <form className="c-form" onSubmit={onSubmit}>
    {children}
    {errors.map((error) => (
      <div className="c-form__error-message" key={error}>
        {i18next.t(`forms.errors.${errorKey}.${error.type}`)}
      </div>
    ))}
  </form>
);

Form.propTypes = {
  children: node.isRequired,
  onSubmit: func.isRequired,
  errorKey: string.isRequired,
  errors: arrayOf(
    shape({
      type: string,
    })
  ),
};

export default Form;
