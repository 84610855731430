import React, { useEffect, useReducer, useState } from "react";

import { Download } from "assets/images/CustomIcons";
import { useGetPartnerProgramPerformance } from "hooks/useGetPartnerProgramPerformance";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import PartnerPerformanceTable from "components/partner_management/program_performance_table/ProgramPerformanceTable";
import PartnerFilter from "components/reports/partner-reporting-dashboard/PartnerFilter";
import "components/reports/partner-reporting-dashboard/partner_reporting_dashboard.scss";
import Spinner from "components/spinner/Spinner";

const initialProgramPerformanceResponseState = {
  programPerformance: {
    monthly: [],
    totals: {
      visits: 0,
      created: 0,
      cancelled: 0,
      confirmed: 0,
    },
  },
  isLoading: false,
};

const programPerformanceResponseReducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        isProgramPerformanceLoading: true,
      };
    case "loadedSuccessfully":
      return {
        ...state,
        programPerformance: action.programPerformance,
        isProgramPerformanceLoading: false,
      };
    case "errorsOccurred":
      throw action.errors;
    default:
      throw new Error("Invalid Action Type for partnerResponseReducer");
  }
};

const ReportingPartnerDashboard = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const type = "partner_performance_report";
  const [isLoading, setIsLoading] = useState(false);
  const [cancelPoller, setCancelPoller] = useState(null);
  const downloadCsvButtonLoading = t("partners.programPerformance.downloadCsvButton.loading");
  const downloadCsvButtonText = t("partners.programPerformance.downloadCsvButton.text");
  const [partnerIdsFilter, setPartnerIdsFilter] = useState([]);

  const [{ programPerformance, isProgramPerformanceLoading }, programPerformanceDispatch] =
    useReducer(programPerformanceResponseReducer, initialProgramPerformanceResponseState);
  useGetPartnerProgramPerformance(selectedMarket, programPerformanceDispatch, partnerIdsFilter, [
    partnerIdsFilter,
  ]);

  const reloadPartnerProgramPerformanceData = (partnerIds) => {
    setPartnerIdsFilter(partnerIds);
  };

  const setPoller = async (reportId) => {
    const [reportPoller, cancel] =
      await selectedMarket.platformReports.generateMarketReportPoller(reportId);

    setCancelPoller(() => cancel);

    const downloadReport = await reportPoller;

    setCancelPoller(null);

    return downloadReport;
  };

  const handleGenerateDownload = async () => {
    setIsLoading(true);
    let report;

    try {
      const newReportId = await selectedMarket.platformReports.buildForMarket(
        type,
        selectedMarket.id
      );
      report = await setPoller(newReportId);

      window.location.href = report.url;
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(
    () => () => {
      if (cancelPoller != null) {
        cancelPoller();
      }
    },
    [cancelPoller]
  );

  return (
    <div className="partner-dashboard">
      <div className="card">
        <div className="card__toolbar">
          <h2>{t("partners.programPerformance.header")}</h2>

          <PartnerFilter
            selectedMarket={selectedMarket}
            onApply={reloadPartnerProgramPerformanceData}
          />

          <Button
            appearance="primary"
            className="partner-dashboard__reporting-button"
            icon={<Download />}
            loading={isLoading}
            onClick={handleGenerateDownload}
          >
            {" "}
            {isLoading ? downloadCsvButtonLoading : downloadCsvButtonText}
          </Button>
        </div>

        <p className="c-partner-profile__description">{t("partners.programPerformance.info")}</p>

        {isProgramPerformanceLoading ? (
          <Spinner showSpinner loadingText="" fullHeight={false} />
        ) : (
          <PartnerPerformanceTable partnerPerformance={programPerformance} />
        )}
      </div>
    </div>
  );
};

ReportingPartnerDashboard.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default ReportingPartnerDashboard;
