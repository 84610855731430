import React from "react";

import ModelStatus from "models/Status";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";

import ReferralStatusIcon from "components/customer_lookup/icons/referral_status/ReferralStatus";
import FormattedDate from "components/formatted_date/FormattedDate";
import Tooltip from "components/tooltip/Tooltip";

import "./status.scss";

const Status = ({ status, timezoneOffset }) => {
  const { t } = useTranslation();

  const statuses = {
    cancelled: t("statuses.cancelled"),
    complete: t("statuses.complete"),
    failed: t("statuses.failed"),
    fulfilled: t("statuses.fulfilled"),
    pending: t("statuses.pending"),
    processing: t("statuses.processing"),
    redeemed: t("statuses.redeemed"),
    waiting: t("statuses.waiting"),
    expired: t("statuses.expired"),
    paused: t("statuses.paused"),
  };

  const paymentProviderStatusExists = status.paymentProviderStatus != null;
  const paymentProviderErrorExists = status.paymentProviderError != null;
  const statusMessage = paymentProviderStatusExists
    ? t(`fulfilments.paymentProviderStatuses.${status.paymentProviderStatus}`)
    : status.failureReason;
  const paymentProviderErrors = () => {
    if (status.paymentProviderError && status.paymentProviderError.indexOf(" ") >= 0) {
      return status.paymentProviderError;
    }

    return t(`fulfilments.paymentProviderStatuses.${status.paymentProviderError}`);
  };
  const tooltipMessage = paymentProviderErrorExists
    ? `${statusMessage}\n\n${paymentProviderErrors()}`
    : statusMessage;

  const content = (
    <div className={`status-detail status-${status.value}`}>
      <span className="icon-wrapper">
        <ReferralStatusIcon status={status} />
      </span>
      <span className="detail">
        <span className="detail__status">
          {statuses[status.value]}
          {statusMessage && <FiInfo />}
        </span>
        <br />
        <span className="detail__date">
          <FormattedDate timestamp={status.updatedAt} timezoneOffset={timezoneOffset} type="long" />
        </span>
      </span>
    </div>
  );

  return tooltipMessage ? (
    <Tooltip direction="up" content={tooltipMessage} testId="statusTooltip">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

Status.propTypes = {
  status: PropTypes.instanceOf(ModelStatus).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default Status;
