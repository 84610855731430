import PropTypes from "prop-types";

const Placeholder = ({ value = undefined, placeholder = "-" }) => {
  if (value !== null && value !== "" && typeof value !== "undefined") {
    return value;
  }

  return placeholder;
};

Placeholder.propTypes = {
  value: PropTypes.node,
  placeholder: PropTypes.string,
};

export default Placeholder;
