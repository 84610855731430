import React from "react";

import BaseUser from "models/BaseUser";
import Campaign from "models/Campaign";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import IdentifierField from "components/customer_lookup/user/user_campaigns/user_campaign_fields/IdentifierField";
import ReferredByDate from "components/customer_lookup/user/user_campaigns/user_campaign_fields/ReferredByDate";
import ReferredByField from "components/customer_lookup/user/user_campaigns/user_campaign_fields/ReferredByField";
import FormattedDate from "components/formatted_date/FormattedDate";
import Button from "components/forms/button/Button";

// TODO sc-33290: this button is a react router Link and needs to be tested for react-router-dom weirdness during and after upgrade
import "./user_campaigns.scss";

const linkToReferredBy = (selectedMarket, campaignReferral) =>
  generatePath("/:subdomain/users/:entity(partners|search)/:id", {
    subdomain: selectedMarket.subdomain,
    entity: campaignReferral.referringType === "partner" ? "partners" : "search",
    id: campaignReferral.referringId,
  });

const UserCampaign = ({ user, campaign, joinedAt, selectedMarket, handleIdentifierEdit }) => {
  const { t } = useTranslation();
  const campaignReferral = user.campaignReferrals.find((cr) => cr.campaignId === campaign.id);

  const headerText = `${t("campaign.header")}: ${campaign.name}`;
  const referredByDateLabel = t("campaign.referredByDateLabel");
  const referredByFieldLabel = t("campaign.referredByFieldLabel");
  const subheaderText = t("campaign.subheader");

  const showIdentifierField = campaign.customFieldsFor(user).length > 0;
  const showReferredBy = !!campaignReferral && user.isExternalUser();

  return (
    <div className="card">
      <h2 className="card__title">{headerText}</h2>
      <div className="cis-account-container">
        <div className="cis-account">
          <span className="cis-account__header">{subheaderText}</span>
          <span className="cis-account__detail">
            <FormattedDate
              timestamp={joinedAt}
              timezoneOffset={selectedMarket.timezone_offset}
              type="long"
            />
          </span>
        </div>

        {showReferredBy && (
          <ReferredByField label={referredByFieldLabel}>
            <Button
              appearance="link"
              buttonElement="link"
              href={linkToReferredBy(selectedMarket, campaignReferral)}
            >
              {campaignReferral.referringFullName}
            </Button>
          </ReferredByField>
        )}

        {showIdentifierField &&
          campaign
            .customFieldsFor(user)
            .map((customField) => (
              <IdentifierField
                key={customField.label}
                campaign={campaign}
                label={customField.label}
                existingValue={user.customFieldFor(campaign, customField.label)?.value}
                handleIdentifierEdit={handleIdentifierEdit}
              />
            ))}

        {!!campaignReferral && user.isExternalUser() && campaign.postPurchaseTracking && (
          <ReferredByDate
            date={campaignReferral.completedAt}
            label={referredByDateLabel}
            timezoneOffset={selectedMarket.timezone_offset}
          />
        )}
      </div>
    </div>
  );
};

UserCampaign.propTypes = {
  user: PropTypes.instanceOf(BaseUser).isRequired,
  campaign: PropTypes.instanceOf(Campaign).isRequired,
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  joinedAt: PropTypes.number.isRequired,
  handleIdentifierEdit: PropTypes.func.isRequired,
};

export default UserCampaign;
