import * as Cookies from "es-cookie";

import { BpAuth0Provider } from "./BpAuth0Provider.jsx";
import { BpAuthProvider } from "./BpAuthProvider.jsx";

export const AuthProvider = ({ onAuth, children, onRedirectCallback }) => {
  // We have been redirected here from SSO
  const buyapowaJwt = Cookies.get("buyapowa_jwt");

  if (buyapowaJwt) {
    return (
      <BpAuthProvider jwt={buyapowaJwt} onAuth={onAuth}>
        {children}
      </BpAuthProvider>
    );
  }

  // else
  return (
    <BpAuth0Provider
      onAuth={onAuth}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      client_id={import.meta.env.VITE_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      audience={import.meta.env.VITE_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </BpAuth0Provider>
  );
};
