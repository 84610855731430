import React from "react";

import { node, string } from "prop-types";
import TooltipLite from "react-tooltip-lite";

import "./tooltip.scss";

const Tooltip = ({ children, testId = null, className = null, ...tooltipProps }) => {
  const { direction = "down" } = tooltipProps;

  return (
    <div data-testid={testId} role="tooltip" className={className}>
      <TooltipLite direction={direction} {...tooltipProps} className="c-tooltip">
        {children}
      </TooltipLite>
    </div>
  );
};

Tooltip.propTypes = {
  children: node.isRequired,
  content: string.isRequired,
  direction: string,
  testId: string,
  className: string,
};

export default Tooltip;
