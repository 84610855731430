import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PresetLink from "components/reports/date_picker/date_range_selector/presets/PresetLink";
import "components/reports/date_picker/date_range_selector/presets/presets_selector.scss";

const PresetsSelector = ({
  customStartDate,
  customEndDate,
  timezoneOffset,
  setPresetRangeStartDate,
  closeDateRangeSelector = null,
  action = null,
}) => {
  const { t } = useTranslation();

  const currentTime = DateTime.now().setZone("UTC" + timezoneOffset);

  const yesterday = currentTime.minus({ days: 1 });
  const lastMonth = currentTime.minus({ months: 1 });
  const lastYear = currentTime.minus({ years: 1 });

  const lastMonthText = t("reporting.dashboard.topbar.dateRangeSelector.presetsSelector.lastMonth");
  const lastSevenDaysText = t(
    "reporting.dashboard.topbar.dateRangeSelector.presetsSelector.lastSevenDays"
  );
  const lastThirtyDaysText = t(
    "reporting.dashboard.topbar.dateRangeSelector.presetsSelector.lastThirtyDays"
  );
  const lastYearText = t("reporting.dashboard.topbar.dateRangeSelector.presetsSelector.lastYear");
  const thisMonthText = t("reporting.dashboard.topbar.dateRangeSelector.presetsSelector.thisMonth");
  const thisYearText = t("reporting.dashboard.topbar.dateRangeSelector.presetsSelector.thisYear");
  const todayText = t("reporting.dashboard.topbar.dateRangeSelector.presetsSelector.today");
  const yesterdayText = t("reporting.dashboard.topbar.dateRangeSelector.presetsSelector.yesterday");

  const presetLinks = [
    {
      text: todayText,
      startDate: currentTime.startOf("day"),
      endDate: currentTime,
    },
    {
      text: yesterdayText,
      startDate: yesterday.startOf("day"),
      endDate: yesterday.endOf("day"),
    },
    {
      text: lastSevenDaysText,
      startDate: currentTime.minus({ days: 7 }),
      endDate: currentTime,
    },
    {
      text: lastThirtyDaysText,
      startDate: currentTime.minus({ days: 30 }),
      endDate: currentTime,
    },
    {
      text: thisMonthText,
      startDate: currentTime.startOf("month"),
      endDate: currentTime,
    },
    {
      text: lastMonthText,
      startDate: lastMonth.startOf("month"),
      endDate: lastMonth.endOf("month"),
    },
    {
      text: thisYearText,
      startDate: currentTime.startOf("year"),
      endDate: currentTime,
    },
    {
      text: lastYearText,
      startDate: lastYear.startOf("year"),
      endDate: lastYear.endOf("year"),
    },
  ];

  return (
    <div className="presets-selector">
      {presetLinks.map((presetLink) => (
        <PresetLink
          text={presetLink.text}
          presetStartDate={presetLink.startDate}
          presetEndDate={presetLink.endDate}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setPresetRangeStartDate={setPresetRangeStartDate}
          closeDateRangeSelector={closeDateRangeSelector}
          action={action}
          key={presetLink.text}
        />
      ))}
    </div>
  );
};

PresetsSelector.propTypes = {
  customStartDate: PropTypes.instanceOf(DateTime).isRequired,
  customEndDate: PropTypes.instanceOf(DateTime).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
  setPresetRangeStartDate: PropTypes.func.isRequired,
  closeDateRangeSelector: PropTypes.func,
  action: PropTypes.func,
};

export default PresetsSelector;
