import React, { Component } from "react";

import BaseUser from "models/BaseUser";
import Market from "models/Market";
import PropTypes from "prop-types";
import { Translation } from "react-i18next";

import Button from "components/forms/button/Button";
import TextInput from "components/forms/text_input/TextInput";

import "./user_edit.scss";

class UserEdit extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(BaseUser).isRequired,
    selectedMarket: PropTypes.instanceOf(Market).isRequired,
    onSuccessfulEdit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      customFields: user.customFields,
      loading: false,
      formErrors: {},
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loading: true, formErrors: {} });

    const { selectedMarket } = this.props;
    const { user, onSuccessfulEdit } = this.props;
    const { firstName, lastName, email, customFields } = this.state;

    try {
      await selectedMarket.users.updateDetails(user.id, firstName, lastName, email, customFields);

      onSuccessfulEdit();
    } catch (e) {
      if (e && e.errors) {
        this.setState({ formErrors: e.errors });
      }
      this.setState({ loading: false });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { firstName, lastName, email, loading, formErrors } = this.state;

    return (
      <Translation>
        {(t) => (
          <form className="c-form" onSubmit={this.handleSubmit}>
            <div className="name">
              <TextInput
                labelText={t("forms.labels.firstName")}
                id="firstName"
                value={firstName}
                handleChange={this.handleChange}
                required
                focus
                errors={formErrors.first_name}
              />
              <TextInput
                labelText={t("forms.labels.lastName")}
                id="lastName"
                value={lastName}
                handleChange={this.handleChange}
                errors={formErrors.last_name}
              />
            </div>
            <TextInput
              labelText={t("forms.labels.email")}
              id="email"
              value={email}
              required
              handleChange={this.handleChange}
              errors={formErrors.email}
            />
            <Button buttonElement="submit" appearance="tertiary" fullWidth loading={loading}>
              {t("global.save")}
            </Button>
          </form>
        )}
      </Translation>
    );
  }
}

export default UserEdit;
