import React from "react";

import NotFoundLogo from "assets/images/page-not-found.png";

import Button from "components/forms/button/Button";

import "./not_found.scss";

const NotFound = () => (
  <div className="c-app-error">
    <div className="c-app-error__image">
      <img src={NotFoundLogo} alt="Page Not Found" />
    </div>
    <div className="c-app-error__copy">
      <h1 className="u-stack-1">We&apos;re sorry</h1>
      <p>
        Looks like the page you were looking for went missing. Let&apos;s get you back on track.
      </p>
      <Button buttonElement="link" href="/" appearance="secondary" size="tiny">
        Back to portal
      </Button>
    </div>
    <div className="c-app-error__help-text">
      <p>
        If you can&apos;t find what you need, have a chat with us at{" "}
        <a className="c-button c-button--link" href="mailto:help@buyapowa.com">
          help@buyapowa.com
        </a>{" "}
        and we&apos;ll sort it out.
      </p>
    </div>
  </div>
);

export default NotFound;
