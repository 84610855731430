import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import TextInput from "components/forms/text_input/TextInput";

import "../user_fulfilments.scss";

const UserFulfilmentExpiryChange = (props) => {
  const { t } = useTranslation();

  const [expiryDate, setExpiryDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { cancelText, onCancel, fulfilmentId, selectedMarket, onSuccessfulUpdate } = props;

  const handleChange = (e) => {
    setExpiryDate(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setFormErrors({});

    try {
      await selectedMarket.users.updateExpiryDate(fulfilmentId, expiryDate);
      onSuccessfulUpdate();
    } catch (e) {
      if (e && e.errors) {
        setFormErrors(e.errors);
      }
      setLoading(false);
    }
  };

  return (
    <form>
      <TextInput
        labelText={t("forms.labels.expiryDate")}
        id="expiryDate"
        value={expiryDate}
        handleChange={handleChange}
        required
        focus
        errors={formErrors.expiry_date}
        hint={t("forms.hints.expiryDate")}
      />
      <div className="expiry-container-actions">
        <Button onClick={onCancel} appearance="secondary" outline fullWidth loading={loading}>
          {cancelText}
        </Button>
        <Button appearance="tertiary" fullWidth loading={loading} onClick={handleSubmit}>
          {t("global.save")}
        </Button>
      </div>
    </form>
  );
};

export default UserFulfilmentExpiryChange;
