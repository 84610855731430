import CisAccount from "models/CisAccount";
import CisReferral from "models/CisReferral";
import CisRewardFulfillment from "models/CisRewardFulfillment";
import CisSubscription from "models/CisSubscription";

export default class CisUser {
  constructor(data) {
    const userAttributes = data.data.attributes.user.data.attributes;
    const accountAttributes = userAttributes.account.data;
    const rewardFulfillments = data.data.attributes.reward_fulfillments;
    const { referrals } = data.data.attributes;

    this.id = userAttributes.id;
    this.cisId = userAttributes.cis_id;
    this.identifier = userAttributes.identifier;
    this.status = userAttributes.status;
    this.createdAt = userAttributes.creation_date;
    this.eligibleForRewards = userAttributes.eligible_for_rewards;

    this.account = null;
    this.subscriptions = [];
    this.rewardFulfillments = [];
    this.referrals = [];

    if (accountAttributes) {
      this.account = new CisAccount(userAttributes.account);

      if (accountAttributes.attributes.subscriptions) {
        this.subscriptions = accountAttributes.attributes.subscriptions.data.map(
          (subscription) => new CisSubscription(subscription)
        );
      }
    }

    if (rewardFulfillments) {
      this.rewardFulfillments = rewardFulfillments.data.map(
        (rewardFulfillment) => new CisRewardFulfillment(rewardFulfillment)
      );
    }

    if (referrals) {
      this.referrals = referrals.data.map((referral) => new CisReferral(referral));
    }
  }
}
