const externalFetch = async (url, options = {}) => {
  const response = await fetch(url, options);

  if (!response.ok) {
    const error = new Error("Request failed");
    error.response = response;
    throw error;
  }

  return {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
  };
};

const axiosFetchClone = {
  put: (url, data = {}, options = {}) =>
    externalFetch(url, { ...options, method: "PUT", body: data }),
};

export default axiosFetchClone;
