import React, { useState } from "react";
import { useEffect } from "react";

import { useAuth } from "@/AuthWrapper";
import { LargeImage, SmallImage } from "assets/images/CustomIcons";
import { InfoPanel, Statuses, buttonBase, buttonDisabled, buttonPrimary } from "bp-ui";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { FaRegTrashCan } from "react-icons/fa6";

const ImageDirectUploader = ({
  id,
  title,
  name,
  infoText,
  market,
  onUploadSucceeded,
  onDeleteImage,
  currentFile,
  setValidationErrors,
  withLargeIcon = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [uploadedFileSrc, setUploadedFileSrc] = useState(null);
  const [uploadedBlob, setUploadedBlob] = useState(null);
  const errorKey = "fileImport";
  const fileExists = !!currentFile?.url;

  const { t } = useTranslation();
  const { getAuthHeaders } = useAuth();

  useEffect(() => {
    if (uploadedBlob !== null && uploadedFileSrc !== null) {
      const newFileData = {
        url: uploadedFileSrc,
        id: uploadedBlob.signed_id,
        filename: uploadedBlob.filename,
      };
      onUploadSucceeded(newFileData);
    }
  }, [uploadedBlob, uploadedFileSrc]);

  const fileUploadedCallback = (res) => {
    setUploadedFileSrc(res.secure_url);
  };

  const setInvalid = () => {
    setValidationErrors((prevState) => {
      if (!prevState.includes(id)) {
        return [...prevState, id];
      }
      return prevState;
    });
  };

  const setIsValid = () =>
    setValidationErrors((prevState) => prevState.filter((item) => item !== id));

  const handleFileSelectChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setInvalid();
      handleDeleteImage();
      setLoading(true);

      const upload = await market.imports.directUpload(file, getAuthHeaders, fileUploadedCallback);

      upload.create((error, blob) => {
        if (error) {
          setFormErrors([{ type: "upload_error" }]);
        } else {
          setUploadedBlob(blob);
          setIsValid();
        }
      });
    } catch (error) {
      setFormErrors([{ type: "upload_error" }]);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = () => {
    setUploadedBlob(null);
    setUploadedFileSrc(null);
    setFormErrors([]);
    onDeleteImage();
  };

  const parsedErrorMessages = (errorObject) => {
    if (errorObject.messages) {
      return `\n${errorObject.messages.join("\n")}`;
    }

    return [];
  };

  const outerContainerClass =
    "h-[300px] p-0.5 flex justify-center items-center bg-light-grey border border-dashed box-content rounded-xl";
  const successBorderClass = "border-blue";
  const pendingBorderClass = "border-grey";

  return (
    <div className="w-[300px]">
      {fileExists ? (
        <div className={classnames(outerContainerClass, successBorderClass)}>
          <img
            className="max-w-full max-h-full object-contain rounded-xl"
            src={currentFile.url}
            alt={currentFile.filename}
          />
        </div>
      ) : (
        <>
          <div className={classnames(outerContainerClass, pendingBorderClass, "mb-4")}>
            <div className="flex flex-col justify-center items-center">
              <span className="text-grey">{withLargeIcon ? <LargeImage /> : <SmallImage />}</span>
              <span className="text-dark-grey text-sm my-2">{title}</span>
              <span className="text-mid-grey text-xs mb-2">{infoText}</span>

              <label
                htmlFor={id}
                role="button"
                aria-label={`${t("partnerProfile.addAssets.importerButtonText")} ${name}`}
                className={`${buttonBase} ${loading ? buttonDisabled : buttonPrimary}`}
                tabIndex={0}
              >
                {t("partnerProfile.addAssets.importerButtonText")}
              </label>

              <input
                id={id}
                type="file"
                name={name}
                className="hidden"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileSelectChange}
                disabled={loading}
              />
            </div>
          </div>
        </>
      )}

      {currentFile.filename && (
        <div
          className="h-6 my-4 flex justify-between cursor-pointer"
          tabIndex={0}
          onClick={handleDeleteImage}
          aria-label={`Delete ${name}, filename: ${currentFile.filename}`}
          role="button"
        >
          <span className="text-black text-xs">{currentFile.filename}</span>
          <span className="text-mid-blue text-normal">
            <FaRegTrashCan />
          </span>
        </div>
      )}

      {formErrors.map((error) => (
        <InfoPanel key={error.type} statusType={Statuses.ERROR}>
          {t(`forms.errors.${errorKey || id}.${error.type}`)}
          {parsedErrorMessages(error)}
        </InfoPanel>
      ))}
    </div>
  );
};

export default ImageDirectUploader;
