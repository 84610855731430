import React from "react";

import { Permissions } from "bp-ui";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation } from "react-router-dom-v5-compat";

import Navigation from "components/navigation_new/Navigation";
import SubNavigation from "components/sub_navigation_new/SubNavigation";

import ReportsManagement from "../campaign_management/reports_management/ReportsManagement";
import "./bi_campaign_reports.scss";
import ReportingPartnerDashboard from "./partner-reporting-dashboard/PartnerReportingDashboard";
import ReportingDashboard from "./reporting-dashboard/ReportingDashboard";
import ReportingNavigation from "./reporting-navigation/ReportingNavigation";

const BiCampaignReports = ({ selectedMarket }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { viewReports } = Permissions;

  const title = t("reporting.navigation.noMobileWarningTitle");
  const subtitle = t("reporting.navigation.noMobileWarningSubtitle");

  const availableLinks = [
    {
      text: t("reporting.navigation.referrerReports"),
      path: "/reports/referrer/dashboard",
      cisOnly: false,
      permissions: [viewReports],
      visible: !!selectedMarket
        .visibleCampaigns()
        .find((f) => f.programType === "referrer" || f.programType === null),
    },
    {
      text: t("reporting.navigation.rewardReports"),
      path: "/reports/reward/operational",
      cisOnly: false,
      permissions: [viewReports],
      visible: !!selectedMarket.visibleCampaigns().find((f) => f.programType === "reward"),
    },
    {
      text: t("reporting.navigation.partnerReports"),
      path: "/reports/partner/dashboard",
      cisOnly: false,
      permissions: [viewReports],
      visible: !!selectedMarket.visibleCampaigns().find((f) => f.programType === "partner"),
    },
  ];

  if (location.pathname.endsWith("/reports")) {
    const defaultReport = availableLinks.find((f) => f.visible);

    if (defaultReport) {
      return <Navigate to={`/${selectedMarket.subdomain}${defaultReport.path}`} />;
    }
  }

  return (
    <div className="bi-campaign-reports">
      <Navigation selectedMarket={selectedMarket} />
      <div className="large-screen-only">
        <SubNavigation
          subdomain={selectedMarket.subdomain}
          cisMarket={selectedMarket.cis}
          availableLinks={availableLinks}
        />

        <Routes>
          <Route
            path={`:market/reports/partner`}
            element={<ReportingNavigation selectedMarket={selectedMarket} type="partner" />}
          >
            <Route
              path="dashboard"
              element={
                <ReportingPartnerDashboard selectedMarket={selectedMarket} location={location} />
              }
            />
            <Route
              path="operational"
              element={<ReportsManagement selectedMarket={selectedMarket} type="partner" />}
            />
          </Route>

          <Route
            path={`:market/reports/reward`}
            element={<ReportingNavigation selectedMarket={selectedMarket} type="reward" />}
          >
            <Route
              path="operational"
              element={<ReportsManagement selectedMarket={selectedMarket} type="reward" />}
            />
          </Route>

          <Route
            path={`:market/reports/referrer`}
            element={<ReportingNavigation selectedMarket={selectedMarket} type="referrer" />}
          >
            <Route
              path="dashboard"
              element={
                <ReportingDashboard
                  selectedMarket={selectedMarket}
                  location={location}
                  type="referrer"
                />
              }
            />
            <Route
              path="operational"
              element={<ReportsManagement selectedMarket={selectedMarket} type="referrer" />}
            />
          </Route>
        </Routes>
      </div>

      <div className="small-screen-only">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  );
};

export default BiCampaignReports;
