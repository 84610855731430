import React, { useCallback, useEffect, useMemo, useState } from "react";

import { areRequestsCancelled, cancelRequests } from "@/axiosInterceptor";
import i18next from "i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import ErrorLoader from "components/error_loader/ErrorLoader";
import Modal from "components/modals/Modal";
import ContactBar from "components/reports/contact_bar/ContactBar";
import ContactModalContent from "components/reports/contact_bar/ContactModalContent";
import Overview from "components/reports/overview/Overview";
import PerformanceTiles from "components/reports/performance_tiles/PerformanceTiles";
import TimelineChart from "components/reports/timeline_chart/TimelineChart";
import TopBar from "components/reports/top_bar/TopBar";
import Spinner from "components/spinner/Spinner";

const BiCampaignReport = ({
  selectedCampaigns,
  selectedStartDate,
  selectedEndDate,
  selectedMarket,
  programType,
}) => {
  const [statsMain, setStatsMain] = useState(null);
  const [statsTimeline, setStatsTimeline] = useState(null);
  const [statsMainLoadingError, setStatsMainLoadingError] = useState(false);
  const [statsTimelineLoadingError, setStatsTimelineLoadingError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchStatsMain(selectedCampaigns, selectedStartDate, selectedEndDate);
    fetchStatsTimeline(selectedCampaigns, selectedStartDate, selectedEndDate);

    return () => cancelRequests();
  }, [selectedCampaigns, selectedStartDate, selectedEndDate]);

  const fetchStatsMain = useCallback(
    async (campaigns, startDate, endDate) => {
      try {
        const result = await selectedMarket.stats.main(
          campaigns.map((c) => c.id),
          startDate,
          endDate
        );

        setStatsMain(result);
      } catch (e) {
        if (!areRequestsCancelled(e)) {
          setStatsMainLoadingError(true);
        }
      }
    },
    [selectedMarket]
  );

  const fetchStatsTimeline = useCallback(
    async (campaigns, startDate, endDate) => {
      try {
        const result = await selectedMarket.stats.timeline(
          campaigns.map((c) => c.id),
          startDate,
          endDate
        );

        setStatsTimeline(result);
      } catch (e) {
        if (!areRequestsCancelled(e)) {
          setStatsTimelineLoadingError(true);
        }
      }
    },
    [selectedMarket]
  );

  const statsStartingDate = useMemo(
    () => selectedMarket.minStatsStartDate(selectedCampaigns),
    [selectedCampaigns]
  );

  return (
    <>
      <TopBar
        selectedMarket={selectedMarket}
        selectedCampaigns={selectedCampaigns}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        campaignFilter={programType && ((c) => c.programType === programType)}
      />
      {statsMain && (
        <>
          <PerformanceTiles stats={statsMain.performance} />
          <div className="overview card">
            <Tabs className="c-tabs" selectedTabClassName="c-tabs-list__tab--selected">
              <TabList className="c-tabs-list">
                <Tab className="c-tabs-list__tab u-inline-3">
                  {i18next.t("reporting.dashboard.tablist.overview")}
                </Tab>
                <Tab className="c-tabs-list__tab">
                  {i18next.t("reporting.dashboard.tablist.timeline")}
                </Tab>
              </TabList>
              <TabPanel>
                <Overview
                  timezoneOffset={selectedMarket.timezone_offset}
                  statsStartingDate={statsStartingDate}
                  stats={statsMain.metrics}
                  totalConfirmedReferrals={statsMain.totalConfirmedReferrals}
                  totalRegisteredCustomers={statsMain.totalRegisteredCustomers}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                />
              </TabPanel>
              <TabPanel>
                {statsTimeline && <TimelineChart statsTimeline={statsTimeline} />}
                <ErrorLoader
                  errorText={i18next.t("reporting.dashboard.errorTimeLineData")}
                  showError={statsTimelineLoadingError}
                />
              </TabPanel>
            </Tabs>

            <ContactBar handleClick={() => setShowModal(!showModal)} />
            <Modal
              closeModal={() => setShowModal(false)}
              closeModalText={i18next.t("global.close")}
              className="modal--informative"
              openModal={showModal}
              title={i18next.t("reporting.dashboard.modal.title")}
              id="overview"
            >
              <ContactModalContent />
            </Modal>
          </div>
        </>
      )}
      <ErrorLoader
        errorText={i18next.t("reporting.dashboard.errorLoadingData")}
        showError={statsMainLoadingError}
        hasCardWrapper
      />
      <Spinner
        loadingText={i18next.t("reporting.dashboard.loadingText")}
        showSpinner={!statsMain && !statsMainLoadingError}
      />
    </>
  );
};

export default BiCampaignReport;
