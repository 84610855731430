import React, { useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

import "components/partner_management/partner_profile/assets/locale_switcher/locale_switcher.scss";
import {
  customStyles,
  customTheme,
} from "components/partner_management/partner_profile/assets/locale_switcher/select_config";

const LocaleSwitcher = ({
  marketLocales,
  selectedLocale,
  populatedLocales,
  availableLocales,
  toggleLocaleModal,
  updateLocale,
  setForm,
}) => {
  const { t } = useTranslation();
  const initialLanguage = () => marketLocales.find((locale) => locale.value === selectedLocale);
  const [language, setLanguage] = useState(initialLanguage());

  const handleLanguageChange = (newLocale) => {
    setLanguage(newLocale);
    handleLocaleChange(newLocale.value);
  };

  const handleLocaleChange = (newLocale) => {
    const existingContent = availableLocales.find(
      (partnerLocale) => partnerLocale.locale === newLocale
    );

    if (existingContent) {
      // fill in the whole form with the existing content for editing.
      setForm(existingContent);
    } else {
      // set the selected locale on the blank form for creating a new one.
      updateLocale(newLocale);

      // If we have content to copy from then show the prompt to use existing templates.
      if (populatedLocales.length > 0) {
        toggleLocaleModal();
      }
    }
  };

  const Option = ({ value, ...otherProps }) => {
    const hasContent = (locale) =>
      populatedLocales.find((populatedLocale) => populatedLocale === locale);

    return (
      <components.Option {...otherProps}>
        <span className={classnames(hasContent(value) ? "dot-filled" : "dot")} />
        <span>{t(`languages.${value}`)}</span>
      </components.Option>
    );
  };

  return (
    <div className="field-group">
      <Select
        options={marketLocales}
        value={language}
        onChange={handleLanguageChange}
        theme={customTheme}
        styles={customStyles}
        components={{ Option }}
        getOptionLabel={(e) => <span>{t(`languages.${e.value}`)}</span>}
      />
    </div>
  );
};

export default LocaleSwitcher;
