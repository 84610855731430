import React, { useEffect, useReducer } from "react";

import { capitalize } from "helpers/Helpers.js";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Checkbox from "components/forms/checkbox/Checkbox";
import Form from "components/forms/form/Form";
import Select from "components/forms/select/Select";
import TextInput from "components/forms/text_input/TextInput";

import {
  campaignConfigReducer,
  defaultCampaignConfigData,
  getRewardCategoryList,
  noRewardCategoryString,
} from "../user_utils";

const CreateReferralForm = ({
  selectedMarket,
  campaignParticipations,
  formState: form,
  dispatchFormAction,
  formErrors,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [{ displayOptIns, rewardTypes, customFields }, dispatchCampaignAction] = useReducer(
    campaignConfigReducer,
    defaultCampaignConfigData
  );

  const liveInteractedCampaigns = selectedMarket.visibleCampaigns().filter((c) => {
    const interactedCampaigns = campaignParticipations.find((cp) => cp.campaign.id === c.id);

    return c.live && !!interactedCampaigns;
  });

  const campaignOptions = liveInteractedCampaigns.map((campaign) => ({
    value: campaign.id,
    name: campaign.name,
  }));

  const saveButtonText = t("global.save");
  const firstName = t("forms.labels.firstName");
  const lastName = t("forms.labels.lastName");
  const email = t("forms.labels.email");
  const orderNumber = t("forms.labels.orderNumber");
  const rewardCategory = t("forms.labels.rewardCategory");
  const selectRewardCategoryPlaceholder = t("forms.labels.selectRewardCategory");
  const noRewardCategoryOption = {
    name: t("referrals.optionsLabels.noRewardCategory"),
    value: noRewardCategoryString,
  };

  const formSelectCampaignText = t("userSearch.addReferrer.modal.form.selectCampaignText");
  const formMarketingOptInLabelText = t(
    "userSearch.addReferrer.modal.form.marketingOptInLabelText"
  );
  const formTermAndConditionsLabelText = t(
    "userSearch.addReferrer.modal.form.termAndConditionsLabelText"
  );

  useEffect(() => {
    if (!form.campaignId) {
      dispatchCampaignAction({ type: "resetCampaign" });
      return;
    }

    const selectedCampaign = selectedMarket.campaignById(form.campaignId);

    let fields = [];

    if (selectedCampaign.friendCustomFieldsConfigured().length > 0) {
      fields = selectedCampaign.friendCustomFieldsConfigured();
    }

    dispatchCampaignAction({
      type: "updateCampaignConfigData",
      data: {
        displayOptIns: selectedCampaign.friendRegistration,
        customFields: fields,
        rewardTypes: selectedCampaign.rewardTypes,
      },
    });
  }, [form.campaignId, selectedMarket]);

  const handleCampaignSelectorChange = (selection) =>
    dispatchFormAction({
      type: "updateCampaignId",
      campaignId: selection.value,
      selectedMarket,
    });

  const handleInputChange = (e) =>
    dispatchFormAction({
      type: "updateFormField",
      fieldKeyValuePair: {
        [e.target.id]: e.target.value,
      },
    });

  const handleCustomFieldInputChange = (e) =>
    dispatchFormAction({
      type: "updateCustomField",
      customField: {
        key: e.target.id,
        value: e.target.value,
      },
    });

  const handleCustomFieldSelectChange = (option, key) =>
    dispatchFormAction({
      type: "updateCustomField",
      customField: {
        key: key,
        value: option.value,
      },
    });

  const handleCheckboxChange = (_, checked, name) =>
    handleInputChange({
      target: { id: name, value: checked },
    });

  const handleRewardCategoryChange = (selection) =>
    dispatchFormAction({
      type: "updateRewardCategory",
      rewardCategory: selection.value,
    });

  const rewardCategoryList =
    rewardTypes && getRewardCategoryList(rewardTypes, noRewardCategoryOption);
  const areRewardCategoriesPresent = !!rewardCategoryList.length;

  return (
    <Form onSubmit={handleSubmit} errorKey="referral" errors={formErrors.referral}>
      <div className="name">
        <TextInput
          labelText={firstName}
          id="firstName"
          value={form.firstName}
          handleChange={handleInputChange}
          required
          focus
          errors={formErrors.first_name}
        />
        <TextInput
          labelText={lastName}
          id="lastName"
          required
          value={form.lastName}
          handleChange={handleInputChange}
          errors={formErrors.last_name}
        />
      </div>
      <TextInput
        labelText={email}
        inputType="email"
        id="email"
        value={form.email}
        required
        handleChange={handleInputChange}
        errors={formErrors.email_address}
      />
      <div className="field-group">
        <Select
          options={campaignOptions}
          handleOnChange={handleCampaignSelectorChange}
          selectedValue={form.campaignId}
          title={formSelectCampaignText}
          titleBold
          buttonAppearance="secondary"
          id="campaign_id"
          errors={formErrors.campaign_id}
        />
      </div>
      {customFields.map((selectedCampaignCustomField, index) => {
        switch (selectedCampaignCustomField.type) {
          case "list":
            return (
              <Select
                key={`cf-${selectedCampaignCustomField.label}-${index}`}
                options={selectedCampaignCustomField.items}
                handleOnChange={(e) =>
                  handleCustomFieldSelectChange(e, selectedCampaignCustomField.label)
                }
                title={capitalize(selectedCampaignCustomField.label)}
                titleBold
                selectedValue={
                  form.customFields.find((cf) => cf.key === selectedCampaignCustomField.label)
                    ?.value || ""
                }
                buttonAppearance="secondary"
                id={selectedCampaignCustomField.label}
                errors={formErrors.custom_field || formErrors[`custom_fields.${index}`]}
                errorKey="customField"
              />
            );
          case "text":
          default:
            return (
              <TextInput
                key={`cf-${selectedCampaignCustomField.label}`}
                labelText={capitalize(selectedCampaignCustomField.label)}
                id={selectedCampaignCustomField.label}
                handleChange={handleCustomFieldInputChange}
                value={
                  form.customFields.find((cf) => cf.key === selectedCampaignCustomField.label)
                    ?.value || ""
                }
                errors={formErrors.custom_field || formErrors[`custom_fields.${index}`]}
                errorKey="customField"
              />
            );
        }
      })}
      {form.campaignId && (
        <div
          {...(areRewardCategoriesPresent && {
            className: "referral-reward-category-and-order-number",
          })}
        >
          <TextInput
            labelText={orderNumber}
            id="orderNumber"
            value={form.orderNumber}
            handleChange={handleInputChange}
            focus
            errors={formErrors.orderNumber}
          />
          {areRewardCategoriesPresent && (
            <div className="field-group">
              <label>{rewardCategory}</label>
              <Select
                options={rewardCategoryList}
                id="rewardCategory"
                handleOnChange={handleRewardCategoryChange}
                selectedValue={form.rewardCategory}
                selectedPlaceholder={selectRewardCategoryPlaceholder}
                errors={formErrors.rewardCategory}
              />
            </div>
          )}
        </div>
      )}

      {displayOptIns.showMarketingOptIn && (
        <div className="field-group">
          <Checkbox
            name="marketingOptIn"
            checked={form.marketingOptIn}
            errors={formErrors.marketing_opt_in}
            label={formMarketingOptInLabelText}
            value={form.marketingOptIn}
            handleChange={handleCheckboxChange}
          />
        </div>
      )}

      {displayOptIns.showTermsOptIn && (
        <div className="field-group">
          <Checkbox
            name="termsOptIn"
            checked={form.termsOptIn}
            errors={formErrors.terms_opt_in}
            label={formTermAndConditionsLabelText}
            value={form.termsOptIn}
            handleChange={handleCheckboxChange}
          />
        </div>
      )}

      <Button
        buttonElement="submit"
        appearance="tertiary"
        className="u-stack-2"
        fullWidth
        loading={isLoading}
      >
        {saveButtonText}
      </Button>
    </Form>
  );
};

export default CreateReferralForm;
