import React from "react";

import { node, string } from "prop-types";

const ReferredByField = ({ children, label }) => (
  <div className="cis-account">
    <span className="cis-account__header">{label}</span>
    <span className="cis-account__detail">{children}</span>
  </div>
);

ReferredByField.propTypes = {
  children: node.isRequired,
  label: string.isRequired,
};

export default ReferredByField;
