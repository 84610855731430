import React from "react";

import { validDateRange } from "helpers/Helpers";
import { DateTime } from "luxon";
import Market from "models/Market";
import PropTypes from "prop-types";
import qs from "query-string";
import { Navigate, useLocation } from "react-router-dom-v5-compat";

import BiCampaignReport from "../bi_campaign_report/BiCampaignReport";

const ReportingDashboard = ({ selectedMarket, type }) => {
  const location = useLocation();

  if (!selectedMarket.hasVisibleCampaigns()) {
    return <Navigate replace to={`/${selectedMarket.subdomain}/users/search`} />;
  }

  const searchParams = qs.parse(location.search);
  const selectedStartDate = parseInt(searchParams.start_date, 10) || null;
  const selectedEndDate = parseInt(searchParams.end_date, 10) || null;

  const selectedCampaigns = selectedMarket
    .loadCampaignsBySlug([].concat(searchParams.campaigns))
    .filter((f) => f.programType === type && f.showInPortal === true);

  const redirectWithDefaultCampaignsParam = () => {
    const defaultQuery = {
      campaigns: selectedMarket.visibleCampaigns().map((c) => c.slug),
      start_date: selectedStartDate,
      end_date: selectedEndDate,
    };

    return (
      <Navigate
        to={`/${selectedMarket.subdomain}/reports/${type}/dashboard?${qs.stringify(defaultQuery)}`}
      />
    );
  };

  if (selectedCampaigns.length === 0) {
    return redirectWithDefaultCampaignsParam();
  }

  const redirectWithDefaultDateRangeParams = () => {
    const defaultEndDate = Math.floor(DateTime.now().toSeconds());
    const statsStartDate = selectedMarket.minStatsStartDate(selectedCampaigns);
    const thirtyDaysAgo = Math.floor(DateTime.now().minus({ days: 30 }).toSeconds());
    const defaultStartDate = Math.max(statsStartDate, thirtyDaysAgo);

    const defaultQuery = {
      campaigns: selectedCampaigns.map((c) => c.slug),
      start_date: defaultStartDate,
      end_date: defaultEndDate,
    };

    return (
      <Navigate
        to={`/${selectedMarket.subdomain}/reports/${type}/dashboard?${qs.stringify(defaultQuery)}`}
      />
    );
  };

  if (
    !validDateRange(
      selectedStartDate,
      selectedEndDate,
      selectedMarket.minStatsStartDate(selectedCampaigns)
    )
  ) {
    return redirectWithDefaultDateRangeParams();
  }

  return (
    <BiCampaignReport
      selectedMarket={selectedMarket}
      selectedCampaigns={selectedCampaigns}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      programType={type}
    />
  );
};

ReportingDashboard.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  type: PropTypes.oneOf(["partner", "reward", "referrer"]).isRequired,
};

export default ReportingDashboard;
