import Campaign from "models/Campaign";
import BiReports from "services/bi_reports/BiReports";
import CisUsers from "services/cis_users/CisUsers";
import Imports from "services/imports/Imports";
import Partner from "services/partner/Partner";
import PlatformReports from "services/platform_reports/PlatformReports";
import Stats from "services/stats/Stats";
import Users from "services/users/Users";

export default class Market {
  constructor(data, axios) {
    this.axios = axios;
    this.id = data.id;
    this.name = data.name;
    this.subdomain = data.subdomain;
    this.timezone_offset = data.timezone_offset;
    this.campaigns = data.campaigns.map((campaignData) => new Campaign(campaignData));
    this.cis = data.cis;
    this.cis_fulfilments_in_platform = data.cis_fulfilments_in_platform;
    this.validate_reconciliation_reward_category = data.validate_reconciliation_reward_category;
    this.supported_locales = data.supported_locales;
    this.defaultLocale = data.default_locale;

    this.users = new Users(this, axios);
    this.cisUsers = new CisUsers(this, axios);
    this.biReports = new BiReports(this, axios);
    this.stats = new Stats(this, axios);
    this.platformReports = new PlatformReports(this, axios);
    this.imports = new Imports(this, axios);
    this.partner = new Partner(this, axios);
  }

  isCis() {
    return this.cis;
  }

  cisFulfilmentsInPlatform() {
    return this.cis_fulfilments_in_platform;
  }

  campaignBySlug(slug) {
    return this.campaigns.find((c) => c.slug === slug) || null;
  }

  campaignById(id) {
    return this.campaigns.find((c) => c.id === id) || null;
  }

  defaultCampaign() {
    return this.campaigns.find((c) => c.default === true) || this.campaigns[0] || null;
  }

  visibleCampaigns() {
    return this.campaigns.filter((c) => c.showInPortal);
  }

  hasCampaigns() {
    return this.campaigns.length > 0;
  }

  hasVisibleCampaigns() {
    return this.visibleCampaigns().length > 0;
  }

  hasMultipleCampaigns() {
    return this.campaigns.length > 1;
  }

  loadCampaignsBySlug(slugs) {
    if (slugs.length > 0) {
      return slugs.map((slug) => this.campaignBySlug(slug)).filter((c) => c != null);
    }

    return null;
  }

  hasAnyManuallyIssuableRewardTypesFor(user) {
    return this.campaigns.some((c) => c.hasAnyManuallyIssuableRewardTypesFor(user));
  }

  minStatsStartDate(selectedCampaigns = this.campaigns) {
    const campaigns = selectedCampaigns.length === 0 ? this.campaigns : selectedCampaigns;

    return campaigns.reduce(
      (min, campaign) => (campaign.statsStartingAt < min ? campaign.statsStartingAt : min),
      campaigns[0].statsStartingAt
    );
  }

  async getParagonToken() {
    const resp = await this.axios.post("/paragon_token", {
      market_id: this.id,
    });

    return resp.data.payload;
  }
}
