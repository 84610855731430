import { buttonBase, buttonPrimary } from "bp-ui";
import { CSVLink } from "react-csv";

export const CSVDownloadButton = ({ filename, headers, data, children, className, icon }) => {
  return (
    <CSVLink
      className={`${buttonBase} ${buttonPrimary} ${className || ""}`}
      filename={filename}
      headers={headers}
      data={data}
      target="_blank"
    >
      <>
        {icon && <i className="pr-2">{icon}</i>}
        {children}
      </>
    </CSVLink>
  );
};
