import React from "react";

import { Card } from "bp-ui";
import { useTranslation } from "react-i18next";
import { FaGift } from "react-icons/fa6";

import FormattedDate from "components/formatted_date/FormattedDate";

import {
  fulfilmentMonetaryValue,
  getFulfilmentStatusMessage,
  getTimelineDataForFulfilment,
} from "../helpers";
import { ReferralStatus } from "./ReferralStatus";

export const ManualFulfilmentInfoCard = ({
  fulfilment,
  timezoneOffset,
  timelineData = [],
  selectedMarket,
  reloadData,
  user,
}) => {
  const [rewardOpen, setRewardOpen] = React.useState(false);

  const { t } = useTranslation();

  return (
    <Card.Card className="flex-auto flex flex-col text-sm pb-2">
      <div className="flex gap-6 mb-6">
        <div className="flex flex-none flex-row">
          <div className="rounded-full bg-dark-blue aspect-square w-14 h-14 p-3.5 mr-10">
            <FaGift className="text-white w-full h-full text-center" />
          </div>
          <div className="flex flex-1 flex-col">
            <div className="mb-1 font-bold">{t("referrerProfile.referralInfoCard.campaign")}</div>
            <div className="text-dark-blue text-2xl font-bold break-all">
              {fulfilment.campaign.name}
            </div>
          </div>
        </div>
      </div>

      <div className="border-0 border-solid border-b border-grey h-px" />

      <Card.Collapse
        key={fulfilment.id}
        open={rewardOpen}
        detail={
          <div className="flex flex-col">
            <h2 className="text-xl mt-0 mb-8">
              {t("referrerProfile.referralInfoCard.rewardFulfilmentLog")}
            </h2>
            <Card.Timeline
              events={getTimelineDataForFulfilment(
                timelineData,
                fulfilment,
                timezoneOffset,
                selectedMarket,
                reloadData,
                user.id
              )}
              timezoneOffset={timezoneOffset}
              emptyPlaceholderTitle={t("referrerProfile.referralTimeline.dataUnavailable")}
              emptyPlaceholderContent={
                <>
                  <span>{t("referrerProfile.referralTimeline.dataUnavailableDescription")}</span>
                  <span>{t("referrerProfile.referralTimeline.dataUnavailableSupportInfo")}</span>
                </>
              }
            />
          </div>
        }
      >
        <Card.Row border={false}>
          <div className="flex gap-6 mb-2">
            <div className="flex flex-none w-2/6 flex-row">
              <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
              <FaGift className="text-2xl mr-3 mt-3" />
              <Card.Detail border={false} label={t("referrerProfile.referralInfoCard.rewardName")}>
                {fulfilment.name}
              </Card.Detail>
            </div>
            <Card.Detail label={t("referrerProfile.referralInfoCard.rewardValue")}>
              {fulfilmentMonetaryValue(fulfilment)}
            </Card.Detail>
            <Card.Detail label={t("referrerProfile.referralInfoCard.fulfilmentDate")}>
              <FormattedDate
                timezoneOffset={timezoneOffset}
                type="numeral_short_with_time"
                timestamp={fulfilment.createdAt}
              />
            </Card.Detail>
            <Card.Detail label={t("referrerProfile.referralInfoCard.rewardStatus")}>
              <ReferralStatus
                status={fulfilment.status.value}
                tooltip={getFulfilmentStatusMessage(fulfilment)}
              />
            </Card.Detail>
            <div className="flex flex-none justify-end">
              <Card.CollapseButton
                onClick={() => setRewardOpen(!rewardOpen)}
                collapsed={!rewardOpen}
                expandSectionLabel={t("global.expandSection")}
                collapseSectionLabel={t("global.collapseSection")}
              />
            </div>
          </div>
        </Card.Row>
      </Card.Collapse>
    </Card.Card>
  );
};
