export const Reports = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    height="1.5rem"
    width="1.5rem"
    aria-hidden="true"
    role="img"
    focusable="false"
  >
    <path
      fill="currentColor"
      d="M87 22c-5.5 0-10 4.5-10 10a9.92 9.92 0 0 0 2.094 6.094L66.875 52.28A9.908 9.908 0 0 0 62 51c-2.16 0-4.172.698-5.813 1.875L44.532 42.156C45.45 40.646 46 38.886 46 37c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 2.554.971 4.883 2.563 6.656L17.218 58.938A9.874 9.874 0 0 0 13 58C7.5 58 3 62.5 3 68s4.5 10 10 10 10-4.5 10-10a9.931 9.931 0 0 0-2.563-6.656L31.782 46.03A9.836 9.836 0 0 0 36 47a9.937 9.937 0 0 0 5.844-1.906l11.625 10.75C52.55 57.354 52 59.114 52 61c0 5.5 4.5 10 10 10s10-4.5 10-10a9.913 9.913 0 0 0-2.125-6.125l12.25-14.156A9.908 9.908 0 0 0 87 42c5.5 0 10-4.5 10-10s-4.5-10-10-10zm0 4c3.337 0 6 2.663 6 6s-2.663 6-6 6a5.97 5.97 0 0 1-6-6c0-3.337 2.663-6 6-6zm-51 5c3.337 0 6 2.663 6 6s-2.663 6-6 6a5.97 5.97 0 0 1-6-6c0-3.337 2.663-6 6-6zm26 24c3.337 0 6 2.663 6 6s-2.663 6-6 6a5.97 5.97 0 0 1-6-6c0-3.337 2.663-6 6-6zm-49 7c3.337 0 6 2.663 6 6s-2.663 6-6 6a5.97 5.97 0 0 1-6-6c0-3.337 2.663-6 6-6z"
    />
  </svg>
);
