import React from "react";

import { useAuth } from "@/AuthWrapper";
import classnames from "classnames";
import { arrayOf, bool, shape, string } from "prop-types";
import { NavLink } from "react-router-dom-v5-compat";

import "./sub_navigation.scss";

// TODO - sc-33290 - Refactor the other component to use v6 apis and delete this directory
// This component is a temporary remedy to the shared status of subnavigation between component trees using v5 and v6 apis

const SubNavigation = ({
  subdomain,
  availableLinks,
  isSecondary = false,
  cisMarket = false,
  isFloating = false,
}) => {
  const { currentAdmin } = useAuth();
  const authorisedLinks = currentAdmin.super
    ? availableLinks
    : availableLinks.filter((link) => currentAdmin.canPerformAnyOf(link.permissions));

  const visibleLinks = authorisedLinks.filter((f) => f.visible === undefined || f.visible);

  const subNavClasses = classnames("c-sub-navigation", {
    "c-sub-navigation--secondary": isSecondary,
    "c-sub-navigation--floating": isFloating,
  });

  const className = "c-sub-navigation-item__link";
  return (
    <ul className={subNavClasses}>
      {visibleLinks.map(
        (link) =>
          (!link.cisOnly || link.cisOnly === cisMarket) && (
            <li className="c-sub-navigation-item" key={link.path}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${className}  c-sub-navigation-item__link--selected` : className
                }
                to={`/${subdomain}${link.path}`}
              >
                {link.text}
              </NavLink>
            </li>
          )
      )}
    </ul>
  );
};

SubNavigation.propTypes = {
  subdomain: string.isRequired,
  cisMarket: bool,
  availableLinks: arrayOf(
    shape({
      text: string.isRequired,
      path: string.isRequired,
      permissions: arrayOf(string).isRequired,
    })
  ).isRequired,
  isSecondary: bool,
  isFloating: bool,
};

export default SubNavigation;
