import React, { useCallback, useEffect, useState } from "react";

import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ErrorLoader from "components/error_loader/ErrorLoader";
import Button from "components/forms/button/Button";
import Modal from "components/modals/Modal";
import RewardForm from "components/partner_management/partner_profile/rewards/reward_form/RewardForm";
import RewardList from "components/partner_management/partner_profile/rewards/reward_list/RewardList";

import "./rewards.scss";

const Rewards = ({ market, partnerId, campaignUrls = [] }) => {
  const { t } = useTranslation();
  const [partnerRewards, setPartnerRewards] = useState({});
  const [refreshPartnerRewards, setRefreshPartnerRewards] = useState({});
  const toggleRefreshPartnerRewards = () => setRefreshPartnerRewards((a) => !a);

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((a) => !a);

  const hasCampaignUrls = useCallback(
    () => campaignUrls && campaignUrls.length > 0,
    [campaignUrls]
  );

  const hasPartnerRewards = () =>
    (partnerRewards.partner_rewards && partnerRewards.partner_rewards.length > 0) ||
    (partnerRewards.audience_rewards && partnerRewards.audience_rewards.length > 0);

  useEffect(() => {
    if (!hasCampaignUrls()) {
      return;
    }

    const getPartnerRewards = async () => {
      try {
        const res = await market.partner.getPartnerRewards(partnerId, campaignUrls[0].campaign_id);

        setPartnerRewards(res);
      } catch (errors) {
        setPartnerRewards({ error: true });
      }
    };

    getPartnerRewards();
  }, [campaignUrls, hasCampaignUrls, market?.partner, partnerId, refreshPartnerRewards]);

  if (!hasCampaignUrls()) {
    return <></>;
  }

  return (
    <>
      <Modal openModal={showModal} closeModal={toggleModal} showCloseButton={false}>
        <RewardForm
          market={market}
          campaignId={campaignUrls[0].campaign_id}
          partnerId={partnerId}
          partnerRewards={partnerRewards}
          onCloseForm={toggleModal}
          onSubmit={toggleRefreshPartnerRewards}
        />
      </Modal>

      <div className="card">
        <div className="partner-rewards-header">
          <h2>{t("partnerProfile.rewards.title")}</h2>

          <Button size="small" appearance="primary" onClick={toggleModal}>
            {hasPartnerRewards()
              ? t("partnerProfile.rewards.editRewards")
              : t("partnerProfile.rewards.setRewards")}
          </Button>
        </div>

        <p className="c-partner-profile__description">{t("partnerProfile.rewardsInfo")}</p>

        <div className="partner-rewards-wrapper">
          {partnerRewards.error && (
            <ErrorLoader errorText={t("partnerProfile.rewards.errorLoadingRewards")} showError />
          )}
          {!partnerRewards.error && !hasPartnerRewards() && (
            <div>
              <div className="c-partner-profile__meta-header">
                {t("partnerProfile.rewards.instructionsTitle")}
              </div>
              {t("partnerProfile.rewards.instructions")}
            </div>
          )}

          {!partnerRewards.error && hasPartnerRewards() && (
            <div className="parter-rewards-list-wrapper">
              <div>
                <div className="c-partner-profile__meta-header">
                  {t("partnerProfile.rewards.partnerReward")}
                </div>
                <RewardList list={partnerRewards.partner_rewards} />
              </div>
              <div>
                <div className="c-partner-profile__meta-header">
                  {t("partnerProfile.rewards.audienceReward")}
                </div>
                <RewardList list={partnerRewards.audience_rewards} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Rewards.propTypes = {
  market: PropTypes.instanceOf(Market).isRequired,
  partnerId: PropTypes.string.isRequired,
  campaignUrls: PropTypes.arrayOf(
    PropTypes.shape({
      campaign: PropTypes.string,
      campaign_id: PropTypes.string,
      campaign_name: PropTypes.string,
      share_url: PropTypes.string,
      date_registered: PropTypes.string,
      dashboard_url: PropTypes.string,
    })
  ),
};

export default Rewards;
