import { forwardRef, useState } from "react";

import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import { ChevronDownIcon } from "primereact/icons/chevrondown";
import { ChevronUpIcon } from "primereact/icons/chevronup";

import { StatusDropdown } from "../StatusDropdown/StatusDropdown";

const valueWithLabelTemplate = (placeholder, optionLabel) =>
  function valueWithLabelTemplate(selectedOption) {
    return (
      <div className="w-full flex flex-col gap-0.5">
        <div className="text-sm font-normal truncate">{placeholder}</div>
        <span className="truncate">
          {optionLabel ? selectedOption?.[optionLabel] : selectedOption}
        </span>
      </div>
    );
  };

export const Dropdown = forwardRef(function Dropdown(
  {
    value,
    onChange,
    options,
    optionLabel,
    optionValue,
    placeholder,
    valueTemplate = null,
    itemTemplate = null,
    secondary = false,
    size = "small",
  },
  ref
) {
  const bgClass = "bg-white rounded-md min-w-64";
  const borderClass = "border-solid border-grey border-1/2";
  const typographyClass = "text-dark-blue text-base";
  const scrollClass = "box-content max-h-56 overflow-y-auto overflow-x-hidden";
  const itemHoverClass = "hover:bg-light-grey hover:cursor-pointer";
  const itemSelectedClass = secondary
    ? "aria-selected:font-normal"
    : "aria-selected:font-semibold";
  let selectedValueTemplate = valueTemplate;

  // need space for this so force the size
  if (size === "large") {
    selectedValueTemplate = valueWithLabelTemplate(placeholder, optionLabel);
  }

  const heightClass =
    size === "small" ? "h-10" : size === "medium" ? "h-12" : "h-15";

  const pt = {
    root: {
      className: `${bgClass} ${borderClass} ${typographyClass} hover:cursor-pointer flex items-center justify-between px-4 ${heightClass}`,
    },
    input: {
      className: `w-full pe-2 truncate ${secondary ? "font-normal" : "font-semibold"}`,
    },
    wrapper: {
      style: { maxHeight: "unset" },
    },
    trigger: {
      className: "flex text-mid-blue",
    },
    panel: {
      className: `max-h-10`,
    },
    list: {
      className: `${bgClass} ${borderClass} ${typographyClass} ${scrollClass} list-none p-0 m-0`,
    },
    item: {
      className: `${itemHoverClass} ${itemSelectedClass} h-14 flex items-center px-4`,
    },
    itemLabel: {
      className: "w-full flex justify-between items-center",
    },
  };

  const primeDropdownProps = {
    value,
    onChange,
    options,
    optionLabel,
    optionValue,
    placeholder,
    pt,
    itemTemplate,
    valueTemplate: selectedValueTemplate,
    dropdownIcon: (opts) => {
      return opts.iconProps["data-pr-overlay-visible"] ? (
        <ChevronUpIcon {...opts.iconProps} />
      ) : (
        <ChevronDownIcon {...opts.iconProps} />
      );
    },
    ref,
  };

  return <PrimeDropdown {...primeDropdownProps} />;
});

export const Preview = () => {
  const [campaign, setCampaign] = useState();
  const [city, setCity] = useState();
  const [version, setVersion] = useState();
  const campaigns = [
    "Sky Refer a Friend 2024",
    "Sky Refer a Friend 2022",
    "Wish you were here ",
    "Skytastic Sign Ups",
    "Skytastic example campaign with an extra long name for testing",
    "Sky RAF 1 (CA)",
    "Sky RAF 1 (UK)",
  ];
  const cities = [
    "London",
    "Paris",
    "Berlin",
    "New York",
    "San Francisco",
    "Los Angeles",
    "Tokyo",
    "Sydney",
    "Melbourne",
    "Brisbane",
  ];
  const versions = [
    { name: "version 1", status: "Live" },
    {
      name: "version 2 - extra long name for testing",
      status: "Draft",
    },
    { name: "version 3", status: "Draft" },
    { name: "version 4", status: "Draft" },
    {
      name: "version 5",
      status: "Draft",
    },
  ];

  const states = ["Default", "Melting", "Sausages"];
  const [state, setState] = useState();

  return (
    <div className="flex w-full gap-4">
      <div className="flex-1 flex flex-col w-24 gap-4">
        <h4>Size: small</h4>

        <Dropdown
          value={campaign}
          onChange={(e) => setCampaign(e.value)}
          options={campaigns}
          placeholder="Primary"
        />

        <Dropdown
          value={city}
          onChange={(e) => setCity(e.value)}
          options={cities}
          placeholder="Secondary"
          secondary={true}
        />

        <StatusDropdown
          value={version}
          onChange={(e) => setVersion(e.value)}
          options={versions}
          placeholder="Secondary with status"
          optionLabel="name"
        />
      </div>

      <div className="flex-1 flex flex-col w-24 gap-4">
        <h4>Size: large</h4>

        <Dropdown
          value={state}
          onChange={(e) => setState(e.value)}
          options={states}
          placeholder="Large with placeholder"
          size="large"
          integratedLabel="Test"
        />
      </div>
    </div>
  );
};
