import React from "react";

import { getHistoryStatusText } from "helpers/Helpers";
import { arrayOf, bool, number, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";

import ErrorLoader from "components/error_loader/ErrorLoader";
import FormattedDate from "components/formatted_date/FormattedDate";

import "./signups_history.scss";

const SignupsHistory = ({ importList, timezoneOffset, importListLoadingError }) => {
  const { t } = useTranslation();
  const shouldHide = importList.length === 0 && !importListLoadingError;

  if (shouldHide) {
    return "";
  }

  return (
    <div className="c-import-history">
      <h2 className="card__title">{t("bulkActions.signups.history.header")}</h2>
      {importListLoadingError || (
        <div className="table-container">
          <table className="portal-table c-import-history--table">
            <thead>
              <tr>
                <th>{t("bulkActions.signups.history.tableHeaders.uploadedAt")}</th>
                <th>{t("bulkActions.signups.history.tableHeaders.uploadedBy")}</th>
                <th>{t("bulkActions.signups.history.tableHeaders.campaign")}</th>
                <th>{t("bulkActions.signups.history.tableHeaders.status")}</th>
                <th className="w-25">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {importList.map((importRecord) => (
                <tr key={importRecord.id}>
                  <td>
                    <FormattedDate
                      timestamp={importRecord.uploaded_at}
                      timezoneOffset={timezoneOffset}
                      type="long_with_time"
                    />
                  </td>
                  <td>{importRecord.created_by}</td>
                  <td>{importRecord.campaign_name}</td>
                  <td>{getHistoryStatusText(importRecord.status)}</td>
                  <td className="column">
                    {importRecord.download_link ? (
                      <a href={importRecord.download_link}>
                        {t("bulkActions.signups.history.tableHeaders.successfulImports")}
                      </a>
                    ) : (
                      ""
                    )}

                    {importRecord.error_download_link ? (
                      <a href={importRecord.error_download_link}>
                        {t("bulkActions.signups.history.tableHeaders.failedImports")}
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ErrorLoader
        errorText={t("forms.errors.bulkActions.load_history")}
        showError={importListLoadingError}
      />
    </div>
  );
};

SignupsHistory.propTypes = {
  importList: arrayOf(
    shape({
      id: string.isRequired,
      uploaded_at: number.isRequired,
      created_by: string.isRequired,
      status: string.isRequired,
      campaign_name: string.isRequired,
      download_link: string,
      error_download_link: string,
      failure_reason: string,
    })
  ).isRequired,
  timezoneOffset: string.isRequired,
  importListLoadingError: bool.isRequired,
};

export default SignupsHistory;
