import BaseUser from "models/BaseUser";
import Referral from "models/Referral";

export default class Referrer extends BaseUser {
  constructor(market, data) {
    super(market, data);

    this.referrals = data.referrals.map((referralData) => new Referral(referralData));
  }

  displayType() {
    return "referrer";
  }

  isA(className) {
    return className === "Customer";
  }

  isCustomer() {
    return true;
  }

  isExternalUser() {
    return false;
  }
}
