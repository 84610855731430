import React, { forwardRef, useEffect, useState } from "react";

import cn from "classnames";
import i18next from "i18next";
import PropTypes from "prop-types";
import { FaInfoCircle } from "react-icons/fa";

import Tooltip from "../../tooltip/Tooltip";
import "./text-input.scss";

const TextInput = forwardRef(function TextInput(
  {
    id,
    labelText,
    handleChange,
    required = false,
    plain = false,
    showRequiredIndicator = false,
    icon = null,
    className = "",
    placeholder = "",
    addInputStyling = true,
    value = "",
    focus = false,
    hideLabel = false,
    disableLastPassAutofill = true,
    errors = [],
    errorKey = null,
    handleOnKeyDown = () => {},
    handleOnBlur = () => {},
    inputType = "text",
    showTooltip = false,
    infoText = "",
    testId = null,
    tooltipPlain = true,
    tooltipDirection = "up",
    tooltipShift = "",
    tooltipSpacing = "",
    hint = null,
    labelClass = "",
  },
  ref
) {
  if (!ref) ref = React.createRef();

  useEffect(() => {
    if (focus) ref.current.focus();
  }, []);

  const [tipActive, setTipActive] = useState(false);

  return (
    <div
      className={cn({
        [className]: true,
        "text-input__required": showRequiredIndicator,
        "text-input__info": showTooltip,
        "field-error": errors.length,
        "field-group": !plain,
      })}
    >
      <div className="flex-between">
        <label className={hideLabel ? "no-label" : labelClass} htmlFor={id}>
          {labelText}
        </label>
        {infoText && (
          <div
            className={cn({
              "text-input__info-container": true,
              "text-input__tooltip-active": tipActive,
            })}
          >
            <Tooltip
              tipContentClassName={cn({
                "text-input__info-plain": tooltipPlain,
                "text-input__info": !tooltipPlain,
                [`info-left-${tooltipShift}`]: !tooltipPlain,
                [`info-spacing-${tooltipSpacing}`]: !tooltipPlain && tooltipSpacing,
              })}
              content={infoText}
              testId={testId && `${testId}-tooltip`}
              direction={tooltipDirection}
              onToggle={setTipActive}
            >
              <FaInfoCircle />
            </Tooltip>
          </div>
        )}
      </div>
      <div className={cn("input-group", { input: addInputStyling })}>
        {icon && <div className="input-group__icon">{icon}</div>}
        <input
          type={inputType}
          required={required}
          id={id}
          data-testid={testId}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
          onBlur={handleOnBlur}
          ref={ref}
          autoComplete="off"
          data-lpignore={disableLastPassAutofill}
        />
      </div>
      {hint && <div className="text-input__hint">{hint}</div>}
      {errors.map((error) => (
        <div className="field-error-message" key={error.type}>
          {i18next.t(`forms.errors.${errorKey || id}.${error.type}`)}
        </div>
      ))}
    </div>
  );
});

TextInput.propTypes = {
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  required: PropTypes.bool,
  plain: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  icon: PropTypes.element,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  addInputStyling: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  hideLabel: PropTypes.bool,
  disableLastPassAutofill: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    })
  ),
  errorKey: PropTypes.string,
  handleOnKeyDown: PropTypes.func,
  handleOnBlur: PropTypes.func,
  inputType: PropTypes.oneOf(["text", "password", "email", "number"]),
  tooltipPlain: PropTypes.bool,
  showTooltip: PropTypes.bool,
  infoText: PropTypes.string,
  testId: PropTypes.string,
  tooltipDirection: PropTypes.string,
  tooltipShift: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hint: PropTypes.string,
  labelClass: PropTypes.string,
};

export default TextInput;
