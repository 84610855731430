import { useAuth } from "@/AuthWrapper";
import { arrayOf, node, string } from "prop-types";

const VisibleWith = ({ actions, children }) => {
  const { currentAdmin } = useAuth();
  const canAction = currentAdmin.canPerformAnyOf(actions);

  return canAction ? children : null;
};

VisibleWith.propTypes = {
  actions: arrayOf(string).isRequired,
  children: node.isRequired,
};

export default VisibleWith;
