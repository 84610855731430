import React from "react";

import { Info } from "assets/images/CustomIcons";

import Tooltip from "components/tooltip/Tooltip";

const FieldLabel = ({ labelText, tooltipText }) => (
  <div className="assets-form__label__with__tooltip">
    <span>{labelText}</span>

    <Tooltip
      tipContentClassName="c-dropdown__info-plain"
      content={tooltipText}
      direction="up"
      useHover
    >
      <Info />
    </Tooltip>
  </div>
);

export default FieldLabel;
