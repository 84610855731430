import { useEffect } from "react";

// using a default export is hard to test so had to do this
export const useGetPartners = (market, dispatch, deps) => {
  useEffect(() => {
    const getPartnerList = async () => {
      dispatch({ type: "loading" });
      try {
        const res = await market.users.getPartners();
        dispatch({
          type: "loadedSuccessfully",
          partners: res.data.payload,
        });
      } catch (errors) {
        dispatch({
          type: "errorsOccurred",
          errors,
        });
      }
    };
    getPartnerList();
  }, [dispatch, market.users, ...(deps || [])]);
};
