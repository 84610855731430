import { twMerge } from "tailwind-merge";

import { Card } from "../Card.jsx";

export const HeroRow = ({ shadow = true, className, children }) => {
  return (
    <div
      className={`${twMerge(
        [
          "bg-dark-blue",
          "p-3",
          "-mx-10",
          "text-center",
          "text-white",
          "font-bold",
          "items-middle",
          shadow ? "shadow-inset-dark" : "",
          "flex",
          "justify-center",
        ],
        className
      )}`}
    >
      {children}
    </div>
  );
};

export const Preview = () => {
  return (
    <Card>
      <div className="py-3">Hero row inside card content</div>
      <HeroRow>
        <div>Hero Row</div>
      </HeroRow>
      <div className="py-3">Further card content</div>
    </Card>
  );
};
