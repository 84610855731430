// import "babel-polyfill";
import CisUser from "models/CisUser";

class CisUsers {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async find(cisId) {
    const resp = await this.axios.get(`/cis_users/${cisId}`, {
      params: {
        market_id: this.market.id,
      },
    });

    return new CisUser(resp.data.payload);
  }

  async resendForValidation(cisId) {
    try {
      const resp = await this.axios.post(`/cis_users/${cisId}/resend_for_validation`, {
        market_id: this.market.id,
      });

      return resp.data.ok;
    } catch (e) {
      // TODO: what does this mean?
      if (e.response.status === 400) {
        return e.response.data.ok;
      }

      throw e;
    }
  }

  async claimFulfillment(fulfillmentId) {
    try {
      const resp = await this.axios.post(`/cis_fulfillments/${fulfillmentId}/claim`, {
        market_id: this.market.id,
      });

      return resp.data.ok;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async denyReferral(referralId) {
    try {
      const resp = await this.axios.post(`/cis_referrals/${referralId}/manually_deny`, {
        market_id: this.market.id,
      });

      return resp.data.ok;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async restoreReferral(referralId) {
    try {
      const resp = await this.axios.post(`/cis_referrals/${referralId}/restore_referral`, {
        market_id: this.market.id,
      });

      return resp.data.ok;
    } catch (err) {
      throw err.response.data.payload;
    }
  }
}

export default CisUsers;
