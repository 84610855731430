import BiBuiltReport from "models/BiBuiltReport";
import BiDownloadedReport from "models/BiDownloadedReport";

class BiReports {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async build(campaignIds, startDate, endDate) {
    const resp = await this.axios.get(`${import.meta.env.VITE_BASE_API_URL}/bi_report/build`, {
      params: {
        campaign_ids: campaignIds,
        start_date: startDate,
        end_date: endDate,
      },
    });

    return new BiBuiltReport(resp.data.payload);
  }

  async download(reportId) {
    const resp = await this.axios.get(`${import.meta.env.VITE_BASE_API_URL}/bi_report/download`, {
      responseType: "blob",
      params: {
        market_id: this.market.id,
        report_id: reportId,
      },
    });

    return new BiDownloadedReport(resp);
  }
}

export default BiReports;
