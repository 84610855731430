import React, { useEffect, useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

import TextArea from "components/forms/text_area/TextArea";
import "components/partner_management/partner_profile/assets/assets.scss";
import "components/partner_management/partner_profile/assets/assets_modal.scss";
import FieldLabel from "components/partner_management/partner_profile/assets/form/FieldLabel.jsx";

const TextField = ({
  id,
  value = "",
  onChange,
  placeholder,
  labelText,
  tooltipText,
  setValidationErrors,
  maxLength,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (value.length > maxLength) {
      setError(true);
      setValidationErrors((prevState) => {
        if (!prevState.includes(id)) {
          return [...prevState, id];
        }
        return prevState;
      });
    } else {
      setError(false);
      setValidationErrors((prevState) => prevState.filter((item) => item !== id));
    }
  }, [value, maxLength]);

  return (
    <>
      <TextArea
        labelText={<FieldLabel labelText={labelText} tooltipText={tooltipText} />}
        labelClass="assets-form__label"
        id={id}
        value={value}
        placeholder={placeholder}
        handleChange={(e) => onChange(e.target.value)}
        required
      />

      <div>
        {error && (
          <span className="assets-form__character-error">
            {t("forms.errors.partnerAssets.characterLimit")}
          </span>
        )}
        <span
          className={classnames(
            error ? "assets-form__character-count__error" : "assets-form__character-count"
          )}
        >
          {value.length}/{maxLength}
        </span>
      </div>
    </>
  );
};

export default TextField;
