import Status from "./Status";

export default class CisRewardFulfillment {
  constructor(data) {
    this.id = data.attributes.id;

    this.platformId = data.attributes.bp_id;

    this.rewardIdentifier = data.attributes.reward.data.attributes.identifier;
    this.createdAt = data.attributes.created_at;

    this.status = new Status({
      value: data.attributes.status,
      updated_at: data.attributes.updated_at || 0,
      failure_reason: data.attributes.failure_reason,
    });

    this.address = null;
    this.source = null;

    if (data.attributes.address) {
      this.address = {
        street: data.attributes.address.data.attributes.street,
        city: data.attributes.address.data.attributes.city,
        state: data.attributes.address.data.attributes.state,
        country: data.attributes.address.data.attributes.country,
        zip: data.attributes.address.data.attributes.zip,
      };
    }

    if (data.attributes.referral.data) {
      this.source = {
        firstName: data.attributes.referral.data.attributes.friend.data.attributes.first_name,
        lastName: data.attributes.referral.data.attributes.friend.data.attributes.last_name,
      };
    }
  }
}
