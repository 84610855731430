// import "babel-polyfill";
import { DirectUpload } from "@rails/activestorage";
import PendingReferralsByCampaignResult from "models/PendingReferralsByCampaignResult";
import ProcessImportResult from "models/ProcessImportResult";
import UploadUrlResult from "models/UploadUrlResult";

class Imports {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async getList(importType) {
    try {
      const resp = await this.axios.get("/imports", {
        params: {
          market_id: this.market.id,
          import_type: importType,
        },
      });

      return resp.data.payload.imports;
    } catch (err) {
      throw err.response.data.payload.errors;
    }
  }

  async generateUploadUrl(importType) {
    try {
      const resp = await this.axios.post("/imports/generate_upload_url", {
        market_id: this.market.id,
        import_type: importType,
      });

      return new UploadUrlResult(resp.data.payload);
    } catch (err) {
      throw err.response.data.payload.errors;
    }
  }

  async uploadFileToS3(externalAxios, presignedUrl, file) {
    try {
      const options = {
        headers: {
          "Content-Type": file.type,
        },
      };

      await externalAxios.put(presignedUrl, file, options);
    } catch {
      const uploadError = {
        upload_error: [
          {
            type: "upload_error",
          },
        ],
      };

      throw uploadError;
    }
  }

  async processFile(importType, secureFile, options) {
    try {
      const resp = await this.axios.post(`/imports/process_${importType}_file`, {
        market_id: this.market.id,
        import_type: importType,
        secure_file: secureFile,
        options,
      });

      return new ProcessImportResult(resp.data.payload);
    } catch (err) {
      throw err.response.data.payload.errors;
    }
  }

  async pendingReferralsByCampaign() {
    try {
      const resp = await this.axios.get("/referrals/pending_referrals_by_campaign", {
        params: {
          market_id: this.market.id,
        },
      });

      return new PendingReferralsByCampaignResult(resp.data.payload);
    } catch (err) {
      throw err.response.data.payload.errors;
    }
  }

  async directUpload(file, getAuthHeaders, onFileUploaded) {
    const directUploadUrl = `${import.meta.env.VITE_BASE_API_URL}/imports/generate_direct_upload/${this.market.id}`;

    const delegate = {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.onload = () => {
          onFileUploaded(JSON.parse(xhr.responseText));
        };
      },
    };

    try {
      return new DirectUpload(file, directUploadUrl, delegate, await getAuthHeaders());
    } catch {
      const uploadError = {
        upload_error: [
          {
            type: "upload_error",
          },
        ],
      };
      throw uploadError;
    }
  }
}

export default Imports;
