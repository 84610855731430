import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CalendarDay from "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar_day/CalendarDay";
import "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar_month/calendar_month.scss";

const CalendarMonth = ({ currentDate, updateNewDateRange, newStartDate, newEndDate }) => {
  const { t } = useTranslation();
  const days = [];
  const startDay = currentDate.startOf("month").weekday;

  for (let i = 0; i < currentDate.endOf("month").day; i += 1) {
    const day = i + 1;

    days.push(
      <CalendarDay
        key={day}
        startDay={startDay}
        day={day}
        currentDate={currentDate}
        newStartDate={newStartDate}
        newEndDate={newEndDate}
        updateNewDateRange={updateNewDateRange}
      />
    );
  }

  return (
    <div>
      <div className="calendar-month-grid calendar-month-header">
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.sunday"
          )}
        </div>
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.monday"
          )}
        </div>
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.tuesday"
          )}
        </div>
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.wednesday"
          )}
        </div>
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.thursday"
          )}
        </div>
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.friday"
          )}
        </div>
        <div>
          {t(
            "reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.calendarMonth.saturday"
          )}
        </div>
      </div>
      <div className="calendar-month-grid">{days}</div>
    </div>
  );
};

CalendarMonth.propTypes = {
  currentDate: PropTypes.instanceOf(DateTime).isRequired,
  updateNewDateRange: PropTypes.func.isRequired,
  newStartDate: PropTypes.instanceOf(DateTime).isRequired,
  newEndDate: PropTypes.instanceOf(DateTime).isRequired,
};

export default CalendarMonth;
