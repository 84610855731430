import React from "react";

import CisUser from "models/CisUser";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import FormattedDate from "components/formatted_date/FormattedDate";
import YesNo from "components/yes_no/YesNo";

import "./account_details.scss";

const AccountDetails = ({ cisUser, timezoneOffset }) => {
  const { t } = useTranslation();
  const accountCreationDateText = t("accountDetails.accountCreationDate");
  const accountIdentifierText = t("accountDetails.accountIdentifier");
  const accountTypeText = t("accountDetails.accountType");
  const accountStatusText = t("accountDetails.accountStatus");
  const eligibleForRewardsText = t("accountDetails.eligibleForRewards");
  const internalIdentifierText = t("accountDetails.internalIdentifier");
  const subAccountTypeText = t("accountDetails.subAccountType");
  return (
    <div className="cis-account-container">
      <div className="cis-account">
        <span className="cis-account__header">{accountIdentifierText}</span>
        <span className="cis-account__detail">{cisUser.identifier}</span>
      </div>
      {cisUser.account && (
        <div className="cis-account">
          <span className="cis-account__header">{internalIdentifierText}</span>
          <span className="cis-account__detail">{cisUser.account.internalIdentifier}</span>
        </div>
      )}
      <div className="cis-account">
        <span className="cis-account__header">{accountCreationDateText}</span>
        <span className="cis-account__detail">
          <FormattedDate
            timestamp={cisUser.createdAt}
            timezoneOffset={timezoneOffset}
            type="long"
          />
        </span>
      </div>
      <div className="cis-account">
        <span className="cis-account__header">{accountStatusText}</span>
        <span className="cis-account__detail">{cisUser.status}</span>
      </div>
      {cisUser.account && (
        <div className="cis-account">
          <span className="cis-account__header">{accountTypeText}</span>
          <span className="cis-account__detail">{cisUser.account.type}</span>
        </div>
      )}
      {cisUser.account && (
        <div className="cis-account">
          <span className="cis-account__header">{subAccountTypeText}</span>
          <span className="cis-account__detail">{cisUser.account.subType}</span>
        </div>
      )}
      <div className="cis-account">
        <span className="cis-account__header">{eligibleForRewardsText}</span>
        <span className="cis-account__detail">
          <YesNo value={cisUser.eligibleForRewards} />
        </span>
      </div>
    </div>
  );
};

AccountDetails.propTypes = {
  cisUser: PropTypes.instanceOf(CisUser).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default AccountDetails;
