import { ErrorBoundary, Provider } from "@rollbar/react";

import { useAuth } from "@/AuthWrapper";
import { Spinner } from "bp-ui";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { ReactNotifications } from "react-notifications-component";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import ScrollMemory from "react-router-scroll-memory";
import MarketRoot from "routers/market/Root.jsx";
import { QueryParamProvider } from "use-query-params";

import ErrorPage from "components/error_page/ErrorPage";
import MarketSelector from "components/market_selector/MarketSelector.jsx";

import "./App.scss";
import { ReactRouter5Adapter } from "./QueryParamsReactRouter5Adapter";
import "./assets/helper_styles/_helper_styles.scss";

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.environment,
};

const App = () => {
  const { currentAdmin } = useAuth();

  if (!currentAdmin) return <Spinner />;

  const ldContext = {
    kind: "portal_user",
    name: currentAdmin.email,
    key: currentAdmin.email,
  };

  return (
    <LDProvider clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID} context={ldContext}>
      <BrowserRouter>
        <CompatRouter>
          <ReactNotifications className="c-notification" />
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Provider config={rollbarConfig}>
              <ErrorBoundary fallbackUI={ErrorPage}>
                <ScrollMemory />
                <Switch>
                  <Route exact path="/">
                    <MarketSelector />
                  </Route>

                  <Route path="/:market">
                    <MarketRoot />
                  </Route>
                </Switch>
              </ErrorBoundary>
            </Provider>
          </QueryParamProvider>
        </CompatRouter>
      </BrowserRouter>
    </LDProvider>
  );
};

export default App;
