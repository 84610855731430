import React, { useState } from "react";

import { DateTime } from "luxon";
import Market from "models/Market";
import PropTypes, { instanceOf } from "prop-types";
import { useTranslation } from "react-i18next";

import Select from "components/forms/select/Select";

import ReportDownloadCard from "./report_download_card/ReportDownloadCard";
import "./reports_management.scss";

const ReportsManagement = ({ selectedMarket, type }) => {
  const { t } = useTranslation();

  const header = t("reporting.management.header");
  const selectTitleText = `${t("reporting.management.selectTitle")}:`;

  const reports = [
    {
      type: "signups_report",
      title: t(`reporting.management.navigation.${type}.customerSignups`),
      description: t(`reporting.management.navigation.${type}.customerSignupsDescription`),
      recommendedRange: t(
        `reporting.management.navigation.${type}.customerSignupsRecommendedRange`
      ),
    },
    {
      type: "friends_report",
      title: t(`reporting.management.navigation.${type}.friends`),
      description: t(`reporting.management.navigation.${type}.friendsDescription`),
      recommendedRange: t(`reporting.management.navigation.${type}.friendsRecommendedRange`),
    },
    {
      type: "friend_voucher_distribution_report",
      title: t(`reporting.management.navigation.${type}.friendVouchers`),
      description: t(`reporting.management.navigation.${type}.friendVouchersDescription`),
      recommendedRange: t(`reporting.management.navigation.${type}.friendVouchersRecommendedRange`),
    },
    {
      type: "top_referrers_report",
      title: t(`reporting.management.navigation.${type}.topReferrers`),
      description: t(`reporting.management.navigation.${type}.topReferrersDescription`),
      recommendedRange: t(`reporting.management.navigation.${type}.topReferrersRecommendedRange`),
    },
    {
      type: "who_referred_whom_report",
      title: t(`reporting.management.navigation.${type}.whoReferredWhom`),
      description: t(`reporting.management.navigation.${type}.whoReferredWhomDescription`),
      recommendedRange: t(
        `reporting.management.navigation.${type}.whoReferredWhomRecommendedRange`
      ),
    },
    {
      type: "all_vouchers_distribution_report",
      title: t(`reporting.management.navigation.${type}.allDistributedVouchers`),
      description: t(`reporting.management.navigation.${type}.allDistributedVouchersDescription`),
      recommendedRange: t(
        `reporting.management.navigation.${type}.allDistributedVouchersRecommendedRange`
      ),
    },
    {
      type: "opt_outs_report",
      title: t(`reporting.management.navigation.${type}.optOuts`),
      description: t(`reporting.management.navigation.${type}.optOutsDescription`),
      recommendedRange: t(`reporting.management.navigation.${type}.optOutsRecommendedRange`),
    },
    {
      type: "fulfilments_report",
      title: t(`reporting.management.navigation.${type}.fulfilments`),
      description: t(`reporting.management.navigation.${type}.fulfilmentsDescription`),
      recommendedRange: t(`reporting.management.navigation.${type}.fulfilmentsRecommendedRange`),
    },
    {
      type: "reward_choice_fulfilments_report",
      title: t(`reporting.management.navigation.${type}.rewardChoiceFulfilments`),
      description: t(`reporting.management.navigation.${type}.rewardChoiceFulfilmentsDescription`),
      recommendedRange: t(
        `reporting.management.navigation.${type}.rewardChoiceFulfilmentsRecommendedRange`
      ),
    },
    {
      type: "referral_data_report",
      title: t(`reporting.management.navigation.${type}.rewardStreamLegacyReferralData`),
      description: t(
        `reporting.management.navigation.${type}.rewardStreamLegacyReferralDataDescription`
      ),
      recommendedRange: t(
        `reporting.management.navigation.${type}.rewardStreamLegacyReferralDataRecommendedRange`
      ),
    },
  ];

  const filteredCampaigns = selectedMarket
    .visibleCampaigns()
    .filter((f) => !type || f.programType === type);

  const options = filteredCampaigns.map((campaign) => ({
    value: campaign.id,
    name: campaign.name,
  }));

  const defaultCampaign = filteredCampaigns.find(
    (f) => f.id === selectedMarket.defaultCampaign().id
  );

  const [selectedCampaignId, setSelectedCampaignId] = useState(
    (defaultCampaign && defaultCampaign.id) ||
      (filteredCampaigns.length > 0 && filteredCampaigns[0].id)
  );

  const allowedReportTypes =
    selectedMarket.visibleCampaigns().find((c) => c.id === selectedCampaignId)
      ?.allowedReportTypes || [];

  const displayableReports = reports.filter((r) => allowedReportTypes.includes(r.type));
  const handleCampaignSelectorChange = (item) => setSelectedCampaignId(item.value);

  const endDate = DateTime.now().setZone("UTC" + selectedMarket.timezone_offset);
  const startDate = endDate.minus({ days: 30 });

  return (
    <div className="c-reports-management card">
      <header className="u-stack-5">
        <h2 className="card__title">{header}</h2>
        <div className="c-reports-management__select-wrapper">
          <Select
            options={options}
            handleOnChange={handleCampaignSelectorChange}
            selectedValue={selectedCampaignId}
            title={selectTitleText}
            positionRight
          />
        </div>
      </header>
      <div className="c-reports-management__wrapper">
        {displayableReports.length > 0 &&
          displayableReports.map((report) => (
            <ReportDownloadCard
              key={report.type}
              title={report.title}
              description={report.description}
              type={report.type}
              selectedMarket={selectedMarket}
              selectedCampaignId={selectedCampaignId}
              recommendedRange={report.recommendedRange}
              defaultStartDate={Math.floor(startDate.toSeconds())}
              defaultEndDate={Math.floor(endDate.toSeconds())}
            />
          ))}
      </div>
    </div>
  );
};

ReportsManagement.propTypes = {
  selectedMarket: instanceOf(Market).isRequired,
  type: PropTypes.oneOf(["partner", "reward", "referrer"]).isRequired,
};

export default ReportsManagement;
