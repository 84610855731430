import { snakeCase } from "lodash";

import { toCamel } from "../helpers/Helpers";

export const UserTypes = {
  CUSTOMER: "customer",
  EXTERNAL_USER: "external_user",
  PARTNER: "partner",
};

export class User {
  static from(market, data) {
    return Object.assign(new User(), toCamel(data));
  }

  isCIS() {
    return !!this.cisId;
  }

  isCustomer() {
    return this.userType === UserTypes.CUSTOMER;
  }

  isExternalUser() {
    return this.userType === UserTypes.EXTERNAL_USER;
  }

  isA(className) {
    return snakeCase(className) === this.userType;
  }
}
