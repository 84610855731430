export default class Order {
  constructor(data) {
    this.id = data.id;
    this.clientOrderId = data.client_order_id;
    this.currency = data.currency;
    this.date = data.date;
    this.generatedReferral = data.generated_referral;
    this.value = data.value;
    this.voucherCode = data.voucher_code;
    this.referralIneligibilityReason = data.referral_ineligibility_reason;
  }
}
