import { useState } from "react";

import { FaChevronUp, FaGlobe } from "react-icons/fa6";

import { Link } from "../Link/Link.jsx";

export const LanguageSelector = ({
  className,
  activeLanguage,
  languages,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  if (!languages || languages.length === 0) return null;

  return (
    <div className={`${className} group`}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="flex flex-row gap-2 bg-transparent text-white border-0 text-lg items-center my-2 mx-3 font-light"
      >
        <FaGlobe />

        {activeLanguage && activeLanguage.title && (
          <span className="name">{activeLanguage.title}</span>
        )}

        <FaChevronUp className="transition-all group-hover:rotate-180" />
      </button>
      <div className="absolute hidden group-hover:block">
        <ul className="list-none bg-white rounded-2xl !p-6 flex flex-col gap-3 shadow">
          {languages.map((language) => (
            <li className="" key={language.value}>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onChange && onChange(language);
                }}
              >
                {language.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
