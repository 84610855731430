import React from "react";

import ErrorImage from "assets/images/sad-file.png";

import Button from "components/forms/button/Button";

export default function ErrorPage() {
  return (
    <div className="c-app-error">
      <div className="c-app-error__image">
        <img src={ErrorImage} alt="Page Not Found" />
      </div>
      <div className="c-app-error__copy">
        <h1 className="u-stack-1">We&apos;re sorry</h1>
        <p>Looks like something went wrong on our side. Please try again later.</p>
        <Button buttonElement="link" href="/" appearance="secondary" size="tiny">
          Back to portal
        </Button>
      </div>
      <div className="c-app-error__help-text">
        <p>
          If you can&apos;t find what you need, have a chat with us at{" "}
          <a className="c-button c-button--link" href="mailto:help@buyapowa.com">
            help@buyapowa.com
          </a>{" "}
          and we&apos;ll sort it out.
        </p>
      </div>
    </div>
  );
}
