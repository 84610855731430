import { useRollbar } from "@rollbar/react";
import React, { useCallback, useEffect, useState } from "react";

import { useAuth } from "@/AuthWrapper";
import { SDK_EVENT, paragon } from "@useparagon/connect";
import { Permissions } from "bp-ui";
import useParagonToken from "hooks/useParagonToken";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";

import Navigation from "components/navigation_new/Navigation";
import Spinner from "components/spinner/Spinner";

import Integration from "./Integration";

const ParagonConnect = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const { currentAdmin } = useAuth();
  const { manageIntegrations } = Permissions;

  const {
    token,
    projectId,
    host,
    error: tokenError,
  } = useParagonToken(selectedMarket, currentAdmin.can(manageIntegrations));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [configured, setConfigured] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [integrations, setIntegrations] = useState([]);

  const updateUser = useCallback(() => {
    const authedUser = paragon.getUser();
    if (authedUser.authenticated) {
      setUser({ ...authedUser });
    }
  }, []);

  // Listen for account state changes
  useEffect(() => {
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);

    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);
    };
  }, []);

  useEffect(() => {
    if (host) {
      paragon.configureGlobal({ host: host });
      setConfigured(true);
    }

    return () => setConfigured(false);
  }, [host]);

  const authenticateWithParagon = async () => {
    if (projectId && token) {
      try {
        await paragon.authenticate(projectId, token, {
          metadata: {
            Name: selectedMarket.name,
            market_id: selectedMarket.id,
            market_subdomain: selectedMarket.subdomain,
          },
        });
        setAuthenticated(true);
      } catch (e) {
        handleApiError(e);
        rollbar.error(e);
      }
    }
  };

  useEffect(() => {
    if (configured) {
      authenticateWithParagon();
    }

    return () => setAuthenticated(false);
  }, [configured]);

  useEffect(() => {
    const setParagonUser = () => {
      try {
        const paragonUser = paragon.getUser();
        if (paragonUser.authenticated) {
          setUser(paragonUser);
        }
      } catch (e) {
        handleApiError(e);
        rollbar.error(e);
      }
    };

    if (authenticated) {
      setParagonUser();
    }
  }, [authenticated]);

  useEffect(() => {
    const setParagonIntegrations = () => {
      try {
        const projectIntegrations = paragon.getIntegrationMetadata();
        const sortedIntegrations = sortBy(projectIntegrations, [
          (integration) => integration.name.toLowerCase(),
        ]);
        setIntegrations(sortedIntegrations);
      } catch (e) {
        handleApiError();
        rollbar.error(e);
      }
    };

    if (user) {
      setParagonIntegrations();
      setLoading(false);
    }

    return () => setIntegrations([]);
  }, [user]);

  useEffect(() => {
    if (tokenError) {
      handleApiError();
    }
  }, [tokenError]);

  const handleApiError = () => {
    setError(t("paragon.connectionError"));
    setLoading(false);
  };

  return (
    <>
      <Navigation selectedMarket={selectedMarket} />

      {loading ? (
        <Spinner showSpinner loadingText="Loading..." fullHeight={true} />
      ) : (
        <div className="container m-auto p-4 md:p-8">
          <h2 className="w-full text-left">{t("paragon.integrations")}</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {!error &&
              integrations.length >= 1 &&
              integrations.map((integration) => (
                <Integration key={integration.type} integration={integration} user={user} />
              ))}

            {error && <p>{error}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default ParagonConnect;
