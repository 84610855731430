import React from "react";

export const Card = ({ children, className }) => {
  return (
    <div
      className={`bg-white rounded-xl shadow px-10 pt-8 pb-12 ${className || ""} relative`}
    >
      {children}

      <div
        className={`rounded-b-xl overflow-hidden absolute left-0 right-0 bottom-0 z-0 h-3 bg-blue`}
      />
    </div>
  );
};

export const Preview = () => {
  return <Card>Card with text content</Card>;
};
