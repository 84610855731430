import { PropTypes } from "prop-types";

export const PartnerErrorType = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
  })
);

export const PartnerProfileType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  locale: PropTypes.string,
  businessName: PropTypes.string,
  jobTitle: PropTypes.string,
  industrySector: PropTypes.string,
  businessCountry: PropTypes.string,
  website: PropTypes.string,
  signupReason: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  snapchat: PropTypes.string,
  linkedin: PropTypes.string,
  youtube: PropTypes.string,
  audienceSize: PropTypes.number,
  audienceLocation: PropTypes.arrayOf(PropTypes.string),
  mobile: PropTypes.string,
  mobileCountryCode: PropTypes.string,
});

export const PartnerProfileErrorType = PropTypes.shape({
  creation: PartnerErrorType,
  business_name: PartnerErrorType,
  first_name: PartnerErrorType,
  last_name: PartnerErrorType,
  email: PartnerErrorType,
  mobile: PartnerErrorType,
  locale: PartnerErrorType,
  job_title: PartnerErrorType,
  industry_sector: PartnerErrorType,
  business_country: PartnerErrorType,
  website: PartnerErrorType,
  twitter_handle: PartnerErrorType,
  instagram_handle: PartnerErrorType,
  facebook_handle: PartnerErrorType,
  linkedin_handle: PartnerErrorType,
  youtube_handle: PartnerErrorType,
  snapchat_handle: PartnerErrorType,
  signup_reason: PartnerErrorType,
  audience_size: PartnerErrorType,
  audience_location: PartnerErrorType,
  mobile_country_code: PartnerErrorType,
  campaign_id: PartnerErrorType,
});

export const PartnerLocaleType = PropTypes.shape({
  id: PropTypes.string,
  locale: PropTypes.string,
  avatarUrl: PropTypes.string,
  bannerUrl: PropTypes.string,
  avatarId: PropTypes.string,
  bannerId: PropTypes.string,
  quote: PropTypes.string,
  displayName: PropTypes.string,
  offerHeader: PropTypes.string,
});

export const PartnerType = PropTypes.shape({
  partner: PropTypes.shape({
    id: PropTypes.string,
    user_id: PropTypes.string,
    type: PropTypes.string,
    profile: PartnerProfileType,
    campaignUrls: PropTypes.arrayOf(
      PropTypes.shape({
        campaign: PropTypes.string,
        campaign_id: PropTypes.string,
        campaign_name: PropTypes.string,
        dashboard_url: PropTypes.string,
        date_registered: PropTypes.string,
        share_url: PropTypes.string,
      })
    ),
    audienceSizeMeasuredAt: PropTypes.number,
    partnerLocales: PartnerLocaleType,
  }),
});
