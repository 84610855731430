import React, { Component } from "react";

import PropTypes from "prop-types";

export const SearchContext = React.createContext({
  query: "No SearchContext Provider found",
  hasSearched: false,
  results: [],
  update: () => {
    throw new Error("No SearchContext Provider found");
  },
});

class SearchWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    query: "",
    hasSearched: false,
    results: [],
  };

  render() {
    const { query, results, hasSearched } = this.state;
    const { children } = this.props;

    const value = {
      query,
      results,
      hasSearched,
      update: ({ ...updatedQuery }) => {
        this.setState({
          query: updatedQuery.query || "",
          hasSearched: updatedQuery.hasSearched || false,
          results: updatedQuery.results || [],
        });
      },
    };

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
  }
}

export default SearchWrapper;
