import React, { useEffect, useState } from "react";

import { Button, Dropdown, Modal } from "bp-ui";
import { camelCase } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ConfirmExistingFriendModal from "./ConfirmExistingFriendModal.jsx";

const CreateReferralModal = ({
  open,
  onClose,
  onSubmit,
  selectedMarket,
  usersCampaigns,
  serviceErrors,
  showConfirmModal,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [rewardCategoryOptions, setRewardCategoryOptions] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  const defaultValues = {
    campaign: null,
    customFields: [],
    email: "",
    firstName: "",
    lastName: "",
    orderNumber: "",
    rewardCategory: null,
  };

  const {
    control,
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
  } = useForm({ defaultValues });

  const watchCampaign = watch("campaign");

  const campaignOptions = usersCampaigns.map((campaign) => ({
    value: campaign.id,
    label: campaign.name,
  }));

  const fieldClassName =
    "focus:border-solid focus:border-blue focus:border-2 bg-lighter-grey/70 text-dark-blue text-md ";
  const labelClassName = "text-dark-blue text-sm h-2 py-2 my-0 mb-3 mt-0 pb-3";
  const errorClassName = "text-red text-base m-0";

  const handleCancel = (e) => {
    e.preventDefault();
    clearErrors();
    reset(defaultValues);
    onClose();
    setCustomFields([]);
    setRewardCategoryOptions([]);
  };

  const loadErrors = (errors) => {
    clearErrors();
    if (!errors) {
      setError("root.serverError", { type: "manual", message: t("forms.errors.generic.invalid") });
      return;
    }

    if (
      errors.referral &&
      Array.isArray(errors.referral) &&
      errors.referral[0].type === "unsuccessful"
    ) {
      let message = t(`forms.errors.referral.${errors.referral[0].args[0]}`);
      if (message === `forms.errors.referral.${errors.referral[0].args[0]}`) {
        message = t("forms.errors.generic.invalid");
      }
      setError("root.serverError", { type: "manual", message: message });
      return;
    }

    // specific field errors come as key-value pairs that are not 'referral'
    for (const [errorKey, errorValue] of Object.entries(errors)) {
      let { formKey, messageKey } = generateFormKeys(errorKey);

      setError(
        formKey,
        {
          type: "manual",
          message: t(`forms.errors.${messageKey}.${errorValue[0].type}`) || "Error",
        },
        { shouldFocus: true }
      );
    }
  };

  function generateFormKeys(errorKey) {
    if (errorKey.includes("email")) {
      return { formKey: "email", messageKey: "email" };
    }

    const errorKeyParts = errorKey.split(".");
    let messageKey = camelCase(errorKeyParts[0]);
    errorKeyParts[0] = messageKey;
    messageKey = messageKey.replace(/s$/, "");

    const formKey = errorKeyParts.slice(0, 2).join(".");

    return { formKey, messageKey };
  }

  const handleCustomFieldBlur = (customFieldName) => {
    clearErrors(customFieldName);
  };

  useEffect(() => {
    if (watchCampaign) {
      const selectedCampaign = selectedMarket.campaigns.filter((c) => c.id === watchCampaign)[0];

      const categoryOptions = [
        ...new Set(
          selectedCampaign.rewardTypes
            ?.filter((rt) => rt.rewardCategory && !rt.archived)
            .map((rt) => {
              return { value: rt.rewardCategory, label: rt.rewardCategory };
            })
        ),
      ];

      if (categoryOptions.length) {
        categoryOptions.push({
          value: t("forms.labels.noRewardCategory"),
          label: t("forms.labels.noRewardCategory"),
        });
      }

      setCustomFields(selectedCampaign.friendCustomFieldsConfigured());
      setRewardCategoryOptions(categoryOptions);
      // clear only the campaign-specific fields:
      reset({ ...getValues(), customFields: [], rewardCategory: null, orderNumber: "" });
      clearErrors();
    } else {
      setCustomFields([]);
      setRewardCategoryOptions([]);
    }
  }, [watchCampaign]);

  useEffect(() => {
    if (serviceErrors) {
      clearErrors();
      loadErrors(serviceErrors.errors);
    }
  }, [serviceErrors]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Modal
        open={open && !showConfirmModal}
        onClose={handleCancel}
        title={t("userProfile.createReferralModal.title")}
      >
        <div>
          <form onSubmit={handleSubmit((data, event) => onSubmit(data, event, false))}>
            <div className={"grid grid-cols-2 gap-4 mb-4"}>
              <div className={"grid grid-rows-2 mb-0 mt-0"}>
                <div className={"grid col-span-1 row-span-1 items-end"}>
                  <label htmlFor="firstName" className={labelClassName}>
                    {t("forms.labels.firstName")}
                  </label>
                </div>
                <div className={"grid col-span-1 row-span-1 mt-0 mb-0"}>
                  <input
                    {...register("firstName", {
                      required: t("forms.errors.firstName.filled"),
                      minLength: { value: 2, message: t("forms.errors.firstName.min_size") },
                    })}
                    className={fieldClassName}
                    id="firstName"
                  />
                  {errors.firstName && (
                    <div role={"alert"}>
                      <p className={errorClassName}>{errors.firstName.message}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={"grid grid-rows-2 mb-0 mt0"}>
                <div className={"grid col-span-1 row-span-1 items-end"}>
                  <label htmlFor="lastName" className={labelClassName}>
                    {t("forms.labels.lastName")}
                  </label>
                </div>
                <div className={"grid col-span-1 row-span-1 gap-0 mt-0"}>
                  <input
                    {...register("lastName", { required: t("forms.errors.lastName.filled") })}
                    className={fieldClassName}
                    id="lastName"
                    type={"text"}
                  />
                  {errors.lastName && (
                    <div role={"alert"}>
                      <p className={errorClassName}>{errors.lastName.message}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={"grid col-span-2"}>
                <label htmlFor="email" className={labelClassName}>
                  Email address
                </label>
                <input
                  {...register("email", {
                    required: t("forms.errors.email.filled"),
                    pattern: { value: /.+@.+\..+/, message: `${t("forms.errors.email.format")}` },
                  })}
                  id="email"
                  className={fieldClassName}
                  type={"email"}
                />
                {errors.email && (
                  <div role={"alert"}>
                    <p className={errorClassName}>{errors.email.message}</p>
                  </div>
                )}
              </div>
              <div className={"grid col-span-2"}>
                <label htmlFor="campaignSelect" className={labelClassName}>
                  {t("userSearch.addReferrer.modal.form.selectCampaignText")}
                </label>
                <Controller
                  control={control}
                  name={"campaign"}
                  render={({ field }) => (
                    <Dropdown
                      {...register("campaign", { required: t("forms.errors.campaign_id.filled") })}
                      {...field}
                      options={campaignOptions}
                      secondary
                      size={"medium"}
                      placeholder={t("forms.placeholders.select")}
                    />
                  )}
                />
                {errors.campaign && (
                  <div>
                    <p className={errorClassName}>{errors.campaign?.message}</p>
                  </div>
                )}
              </div>

              {customFields?.map((selectedCampaignCustomField, index) => {
                switch (selectedCampaignCustomField.type) {
                  case "list":
                    const listOptions = selectedCampaignCustomField.items.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }));
                    return (
                      <div
                        className={"grid col-span-2"}
                        key={`cf-${selectedCampaignCustomField.label}-${index}`}
                      >
                        <label htmlFor={`customFields.${index}.value`} className={labelClassName}>
                          {selectedCampaignCustomField.label}
                        </label>
                        <input
                          hidden
                          {...register(`customFields.${index}.key`)}
                          value={selectedCampaignCustomField.label}
                        />
                        <Controller
                          control={control}
                          name={`customFields.${index}.value`}
                          render={({ field }) => (
                            <Dropdown
                              id={`customFields.${index}.value`}
                              {...register(`customFields.${index}.value`)}
                              {...field}
                              options={listOptions}
                              secondary
                              size={"medium"}
                              placeholder={t("forms.placeholders.select")}
                            />
                          )}
                        />
                        {errors.customFields && errors.customFields[index] && (
                          <div role={"alert"}>
                            <p className={errorClassName}>{errors.customFields[index].message}</p>
                          </div>
                        )}
                      </div>
                    );
                  case "text":
                  default:
                    return (
                      <div
                        className={"grid col-span-2"}
                        key={`cf-${selectedCampaignCustomField.label}-${index}`}
                      >
                        <label htmlFor={`customFields.${index}.value`} className={labelClassName}>
                          {selectedCampaignCustomField.label}
                        </label>
                        <input
                          hidden
                          {...register(`customFields.${index}.key`)}
                          value={selectedCampaignCustomField.label}
                        />
                        <input
                          {...register(`customFields.${index}.value`)}
                          id={`customFields.${index}.value`}
                          required
                          className={fieldClassName}
                          onBlur={() => handleCustomFieldBlur(`customFields.${index}`)}
                        />
                        {errors.customFields && errors.customFields[index] && (
                          <div role={"alert"}>
                            <p className={errorClassName}>{errors.customFields[index].message}</p>
                          </div>
                        )}
                      </div>
                    );
                }
              })}
              {watchCampaign && (
                <div className={"grid col-span-2"}>
                  <label htmlFor="orderNumber" className={labelClassName}>
                    {t("forms.labels.orderNumber")}
                  </label>
                  <input
                    {...register("orderNumber")}
                    id="orderNumber"
                    required
                    className={fieldClassName}
                  />
                  {errors.orderNumber && (
                    <div role={"alert"}>
                      <p className={errorClassName}>{errors.orderNumber.message}</p>
                    </div>
                  )}
                </div>
              )}
              {!!rewardCategoryOptions?.length && (
                <div className={"grid col-span-2"}>
                  <label htmlFor="rewardCategory" className={labelClassName}>
                    {t("forms.labels.selectRewardCategory")}
                  </label>
                  <Controller
                    className={"mb-10"}
                    control={control}
                    name={"rewardCategory"}
                    render={({ field }) => (
                      <Dropdown
                        {...register("rewardCategory")}
                        {...field}
                        options={rewardCategoryOptions}
                        secondary
                        size={"medium"}
                        placeholder={t("forms.placeholders.select")}
                      />
                    )}
                  />
                  {errors.rewardCategory && (
                    <div role={"alert"}>
                      <p className={errorClassName}>{errors.rewardCategory.message}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            {errors.root && errors.root.serverError && (
              <div className={"grid col-span-2"}>
                <p className={errorClassName}>Error: {errors.root.serverError.message}</p>
              </div>
            )}
            <div className={"grid grid-cols-2 gap-4 pt-4"}>
              <Button onClick={handleCancel} secondary>
                {t("global.cancel")}
              </Button>
              <Button
                loading={isLoading}
                onClick={handleSubmit((data, event) => onSubmit(data, event, false))}
                submit
              >
                {t("global.save")}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmExistingFriendModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        handleSubmit={(e) => onSubmit(getValues(), e, true)}
      />
    </>
  );
};

export default CreateReferralModal;
