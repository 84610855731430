import React from "react";

import PropTypes from "prop-types";

import "./small-form-layout.scss";

const SmallFormLayout = ({ children }) => <div className="small-form-layout">{children}</div>;

SmallFormLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SmallFormLayout;
