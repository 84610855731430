import { useEffect, useRef } from "react";

import { createPortal } from "react-dom";
import { FaX } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";

import { Card } from "../Card/Card";

export const Modal = ({
  open,
  title,
  onClose,
  children,
  className,
  closeButtonAriaLabel,
  showCloseButton = true,
}) => {
  const getRoot = () => document.getElementById("root");
  const modalRef = useRef(null);

  useEffect(() => {
    if (open) {
      modalRef.current.focus();
    }
  }, [open, modalRef]);

  useEffect(() => {
    const root = getRoot();

    if (root) {
      if (open) {
        root.classList.add("lock-scroll");
        root.setAttribute("inert", "");
      } else {
        root.classList.remove("lock-scroll");
        root.removeAttribute("inert");
      }

      return () => {
        root.classList.remove("lock-scroll");
        root.removeAttribute("inert");
      };
    }
  }, [open]);

  const handleKeyDownModal = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  const handleKeyDownIcon = (e) => {
    if (e.key === "Enter") {
      onClose();
    }
  };

  return open
    ? createPortal(
        <div
          className="absolute top-0 left-0 w-full h-full bg-dark-blue/60 overflow-auto"
          role="dialog"
          aria-modal="true"
          tabIndex="-1"
          ref={modalRef}
          onKeyDown={handleKeyDownModal}
        >
          <div
            className={twMerge(
              "relative top-1/3 mb-1/10 min-w-128 max-w-1/3 m-auto",
              className
            )}
          >
            <Card className="w-full p-8 overflow-visible">
              <div className="flex">
                <h1 className="flex-1 m-0 p-0 mb-5 text-2xl font-semibold">
                  {title}
                </h1>
                {showCloseButton && (
                  <FaX
                    className="flex-none text-2xl -mt-1 cursor-pointer"
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeyDownIcon}
                    aria-label={closeButtonAriaLabel}
                    onClick={onClose}
                  />
                )}
              </div>
              {children}
            </Card>
          </div>
        </div>,
        document.body
      )
    : null;
};
