import { useRollbar } from "@rollbar/react";
import { useEffect, useState } from "react";

import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

const useParagonToken = (market, hasPermission) => {
  const rollbar = useRollbar();
  const { t } = useTranslation();

  const [refreshToken, setRefreshToken] = useState(false);
  const [token, setToken] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [host, setHost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    requestNewToken();
  }, [refreshToken]);

  const requestNewToken = async () => {
    if (!hasPermission) {
      setError(t("paragon.connectionError"));
      return;
    }

    try {
      const res = await market.getParagonToken();
      setToken(res.token);
      setProjectId(res.project);
      setHost(res.host);
    } catch (e) {
      rollbar.error(e);
      setError(t("paragon.connectionError"));
    }
  };

  useEffect(() => {
    let timeoutId;

    if (token) {
      const decoded = jwtDecode(token);
      const tokenExpiresAt = decoded.exp; // `exp` is in seconds (unix timestamp)
      const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
      const thirtySecondsBeforeExpiry = tokenExpiresAt - 30;
      const delay = (thirtySecondsBeforeExpiry - currentTime) * 1000; // in milliseconds

      timeoutId = setTimeout(() => {
        triggerRefreshToken();
      }, delay);
    }

    return () => clearTimeout(timeoutId);
  }, [token]);

  const triggerRefreshToken = () => setRefreshToken(!refreshToken);

  return { token, projectId, host, error };
};

export default useParagonToken;
