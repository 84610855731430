import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Settings } from "luxon";
import { initReactI18next } from "react-i18next";

import En from "./en";
import Es from "./es";
import Fr from "./fr";
import FrCa from "./fr-CA";

export const languages = [
  {
    label: "English (en)",
    title: "en",
    value: "en",
  },
  {
    label: "Español (es)",
    title: "es",
    value: "es",
  },
  {
    label: "Français (fr)",
    title: "fr",
    value: "fr",
  },
  {
    label: "Français (fr-CA)",
    title: "fr-CA",
    value: "fr-CA",
  },
];

export default function initLocales(debug) {
  i18next
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .init({
      debug,
      fallbackLng: "en",
      resources: {
        en: En,
        es: Es,
        fr: Fr,
        "fr-CA": FrCa,
      },
      react: {
        transKeepBasicHtmlNodesFor: ["br", "code", "i", "strong", "td", "tr"],
      },
    });

  if (
    window.navigator.language.startsWith("en") &&
    i18next.language !== window.navigator.language &&
    i18next.language.startsWith("en")
  ) {
    Settings.defaultLocale = window.navigator.language;
  } else {
    Settings.defaultLocale = i18next.language;
  }
}

export function compareLocales() {
  const locales = [
    {
      key: "Es",
      locale: Es,
    },
    {
      key: "Fr",
      locale: Fr,
    },
    {
      key: "FrCa",
      locale: FrCa,
    },
  ];

  // recursively compare each locale to English
  const compare = (base, comparee, compareeLocale, path = "") => {
    Object.keys(base).forEach((key) => {
      if (typeof base[key] === "object") {
        compare(base[key], comparee && comparee[key], compareeLocale, `${path}/${key}`);
      } else if (!comparee || !comparee[key])
        console.log(`${compareeLocale} - Missing key: ${path}/${key}`);
    });
  };

  locales.forEach((locale) => compare(En, locale.locale, locale.key));
}
