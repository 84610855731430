import React from "react";

import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";

const ConfirmAlreadyExistingFriend = ({ handleSubmit, isLoading }) => {
  const { t } = useTranslation();

  const content = t("userProfile.confirmAlreadyExistingFriend.content");
  const confirmText = t("global.confirm");
  return (
    <>
      <p>{content}</p>
      <Button
        buttonElement="submit"
        appearance="tertiary"
        className="u-stack-2"
        fullWidth
        onClick={handleSubmit}
        loading={isLoading}
      >
        {confirmText}
      </Button>
    </>
  );
};

ConfirmAlreadyExistingFriend.propTypes = {
  handleSubmit: func.isRequired,
  isLoading: bool.isRequired,
};

export default ConfirmAlreadyExistingFriend;
