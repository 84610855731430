import PartnerPerformance from "models/PartnerPerformance";

class Partner {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async getProgramPerformance(partnerIds) {
    const resp = await this.axios.get("/partners/program_performance", {
      params: {
        market_id: this.market.id,
        partners: partnerIds,
      },
    });

    return new PartnerPerformance(resp.data.payload);
  }

  async getPartnerPerformance(id) {
    const resp = await this.axios.get(`/partners/${id}/partner_performance`);

    return new PartnerPerformance(resp.data.payload);
  }

  async getPartnerRewards(partnerId, campaignId) {
    const resp = await this.axios.get("/partner_rewards", {
      params: {
        partner_id: partnerId,
        campaign_id: campaignId,
      },
    });

    return resp.data.payload;
  }

  async getPartnerAvailableRewards(partnerId, campaignId) {
    const resp = await this.axios.get("/partner_rewards/available_rewards", {
      params: {
        partner_id: partnerId,
        campaign_id: campaignId,
      },
    });

    return resp.data.payload;
  }

  async setPartnerRewards(partnerId, campaignId, partnerRewards, audienceRewards) {
    await this.axios.patch("/partner_rewards", {
      partner_id: partnerId,
      campaign_id: campaignId,
      partner_rewards: partnerRewards || [],
      audience_rewards: audienceRewards || [],
    });
  }
}

export default Partner;
