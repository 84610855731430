import { useEffect } from "react";

export const useGetPartnerPerformance = (market, dispatch, id) => {
  useEffect(() => {
    const getPartnerPerformance = async () => {
      dispatch({ type: "loading" });
      try {
        const performance = await market.partner.getPartnerPerformance(id);
        dispatch({ type: "loadedSuccessfully", programPerformance: performance });
      } catch (errors) {
        dispatch({ type: "errorsOccurred", errors });
      }
    };
    getPartnerPerformance();
  }, [dispatch, id, market?.partner]);
};
