import React from "react";

import TooltipLite from "react-tooltip-lite";

export const Tooltip = ({
  children,
  testId = null,
  className = null,
  ...tooltipProps
}) => {
  const { direction = "down" } = tooltipProps;

  return (
    <div data-testid={testId} role="tooltip" className={className}>
      <TooltipLite
        direction={direction}
        {...tooltipProps}
        className="c-tooltip"
      >
        {children}
      </TooltipLite>
    </div>
  );
};
