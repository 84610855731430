import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";

const RewardList = ({ list }) => {
  const { t } = useTranslation();

  return (
    <ul>
      {list &&
        list.map((item, i) => <li key={item}>{`${item}${i < list.length - 1 ? "," : ""}`}</li>)}
      {(!list || list.length === 0) && (
        <div className="partner-rewards-list-info-wrapper">
          <span className="partner-rewards-list-info-icon">
            <FiInfo />
          </span>
          <span>{t("partnerProfile.rewards.unconfiguredWarning")}</span>
        </div>
      )}
    </ul>
  );
};

RewardList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RewardList;
