import BiDownloadStatus from "models/BiDownloadStatus";
import { clearIntervalAsync } from "set-interval-async";
import { setIntervalAsync } from "set-interval-async/dynamic";

class PlatformReports {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async generateReportPoller(requestedReportId, request, pollFrequency = 3000) {
    let cancelled = false;
    let intervalId;

    const promise = new Promise((resolve, reject) => {
      const cancel = () => {
        clearIntervalAsync(intervalId);
        return reject();
      };

      const succeed = (data) => {
        clearIntervalAsync(intervalId);
        return resolve(data);
      };

      const updater = async () => {
        if (cancelled) {
          cancel();
          return;
        }

        await this.getReportWhenReady(requestedReportId, succeed, cancel, request);
      };

      intervalId = setIntervalAsync(updater, pollFrequency);
    });

    return [
      promise,
      () => {
        cancelled = true;
        return null;
      },
    ];
  }

  async generateMarketReportPoller(requestedReportId) {
    return this.generateReportPoller(requestedReportId, this.getMarketReport);
  }

  async generateCampaignReportPoller(requestedReportId) {
    return this.generateReportPoller(requestedReportId, this.getCampaignReport);
  }

  async build(reportType, campaignId, startsAt, endsAt) {
    try {
      const resp = await this.axios.post(`${import.meta.env.VITE_BASE_API_URL}/reports`, {
        report_type: reportType,
        campaign_id: campaignId,
        starts_at: startsAt,
        ends_at: endsAt,
      });

      return resp.data.payload.id;
    } catch (e) {
      throw e.response.data.payload;
    }
  }

  async buildForMarket(reportType, marketId) {
    try {
      const resp = await this.axios.post(
        `${import.meta.env.VITE_BASE_API_URL}/market_scoped_reports`,
        {
          report_type: reportType,
          market_id: marketId,
        }
      );

      return resp.data.payload.id;
    } catch (e) {
      throw e.response.data.payload;
    }
  }

  async getReportWhenReady(requestedReportId, succeed, cancel, request) {
    try {
      const info = await request(requestedReportId);

      if (info.ready) {
        succeed(info);
      }
    } catch {
      cancel();
    }
  }

  getCampaignReport = async (reportId) => {
    const resp = await this.axios.get(`${import.meta.env.VITE_BASE_API_URL}/reports/${reportId}`);

    return resp.data.payload;
  };

  getMarketReport = async (reportId) => {
    const resp = await this.axios.get(
      `${import.meta.env.VITE_BASE_API_URL}/market_scoped_reports/${reportId}`
    );

    return resp.data.payload;
  };

  async recentCampaignReport(reportType, campaignId) {
    const resp = await this.axios.get(
      `${import.meta.env.VITE_BASE_API_URL}/reports/info/${reportType}?campaign_id=${campaignId}`
    );

    return new BiDownloadStatus(resp.data.payload);
  }
}

export default PlatformReports;
