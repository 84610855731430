import React, { useState } from "react";

import { arrayOf, func, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { MdInfo } from "react-icons/md";

import "./file_select.scss";

const FileSelect = (props) => {
  const { t } = useTranslation();
  const {
    name,
    infoText = t("fileSelect.infoText"),
    buttonText = t("fileSelect.buttonText"),
    id = "file-select",
    acceptedFileTypes = "",
    handleChange = () => {},
    messages = {},
    errors = [],
    errorKey = null,
  } = props;
  const [fileSelectInfoText, setFileSelectInfoText] = useState(infoText);

  const handleOnChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    setFileSelectInfoText(file.name);
    handleChange(e.target.name, file);
  };

  const infoMessages = () => {
    if (messages.info) {
      return messages.info;
    }

    return [];
  };

  const errorMessages = () => {
    if (messages.errors) {
      return messages.errors;
    }

    return [];
  };

  const parsedErrorMessages = (errorObject) => {
    if (errorObject.messages) {
      return `\n${errorObject.messages.join("\n")}`;
    }

    return [];
  };

  return (
    <div className="c-file-select">
      <div className="c-file-select-input">
        <label className="c-file-select-label">
          <label htmlFor={id} className="c-file-select-info">
            {fileSelectInfoText}
          </label>
          <span className="c-file-select-button">{buttonText}</span>
          <input
            type="file"
            className="c-file-select-input__input"
            name={name}
            accept={acceptedFileTypes}
            id={id}
            onChange={handleOnChange}
          />
        </label>
      </div>
      {infoMessages().map((info) => (
        <div className="field-message-info" key={info}>
          <i className="field-message-info-icon">
            <MdInfo />
          </i>
          {info}
        </div>
      ))}
      {errorMessages().map((error) => (
        <div className="field-error-message" key={error}>
          <i className="field-error-icon">
            <MdInfo />
          </i>
          {error}
        </div>
      ))}
      {errors.map((error) => (
        <div className="field-error-message" key={error.type}>
          <i className="field-error-icon">
            <MdInfo />
          </i>
          {t(`forms.errors.${errorKey || id}.${error.type}`)}
          {parsedErrorMessages(error)}
        </div>
      ))}
    </div>
  );
};

FileSelect.propTypes = {
  name: string.isRequired,
  infoText: string,
  buttonText: string,
  acceptedFileTypes: string,
  id: string,
  handleChange: func,
  messages: shape({}),
  errors: arrayOf(
    shape({
      type: string,
    })
  ),
  errorKey: string,
};

export default FileSelect;
