import React from "react";

import { useTranslation } from "react-i18next";

const Assets = ({ defaultLocale }) => {
  const { t } = useTranslation();
  const { quote, displayName, offerHeader, banner, avatar } = defaultLocale;

  return (
    <div className="assets">
      <div className="container">
        <div className="assets__logo_preview_container">
          {avatar?.url && (
            <img
              className="assets__logo_preview_img"
              src={avatar.url}
              alt={t("partnerProfile.logoImage")}
            />
          )}
        </div>

        <div className="assets__display_name">
          <span className="c-partner-profile__meta-header">{t("partnerProfile.displayName")}</span>
          <span>{displayName}</span>
        </div>
      </div>

      <div className="assets__offer_header">
        <span className="c-partner-profile__meta-header">{t("partnerProfile.offerHeader")}</span>
        <span>{offerHeader}</span>
      </div>

      <div className="assets__banner">
        <span className="c-partner-profile__meta-header">{t("partnerProfile.bannerImage")}</span>
        {banner?.url && (
          <img
            className="assets__banner_preview_img"
            src={banner.url}
            alt={t("partnerProfile.bannerImage")}
          />
        )}
      </div>

      <div className="assets__quote">
        <span className="c-partner-profile__meta-header">{t("partnerProfile.quote")}</span>
        <p className="assets__quote__text">{quote}</p>
      </div>
    </div>
  );
};

export default Assets;
