import React from "react";

import { func, string } from "prop-types";
import { MdIndeterminateCheckBox } from "react-icons/md";

import "components/forms/checkbox/checkbox.scss";

const IndeterminateCheckbox = ({ name, label, handleChange }) => {
  const handleOnChange = (e) => {
    handleChange(e.target.value, e.target.checked, e.target.name);
  };

  return (
    <label className="c-checkbox">
      <input
        type="checkbox"
        className="c-checkbox__item"
        name={name}
        checked
        onChange={handleOnChange}
        value
      />
      <div className="c-checkbox-icon">
        <i className="c-checkbox-icon__checked">
          <MdIndeterminateCheckBox />
        </i>
      </div>
      <span className="c-checkbox__label">{label}</span>
    </label>
  );
};

IndeterminateCheckbox.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  handleChange: func.isRequired,
};

export default IndeterminateCheckbox;
