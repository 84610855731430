import React, { Component } from "react";

import BaseUser from "models/BaseUser";
import Market from "models/Market";
import PropTypes from "prop-types";

import UserCampaign from "components/customer_lookup/user/user_campaigns/UserCampaign";
import UserCampaignCustomFieldEditor from "components/customer_lookup/user/user_campaigns/user_campaign_custom_field_editor/UserCampaignCustomFieldEditor";

import "./user_campaigns.scss";

class UserCampaigns extends Component {
  state = {
    selectedCampaign: null,
    selectedCustomFieldLabel: null,
    toggleEditIdModal: false,
  };

  handleIdentifierEdit = (sc, label) => {
    const { toggleEditIdModal } = this.state;
    this.setState({
      selectedCampaign: sc,
      selectedCustomFieldLabel: label,
      toggleEditIdModal: !toggleEditIdModal,
    });
  };

  handleUserCampaignEditorSave = async () => {
    const { reloadView } = this.props;
    this.setState({ toggleEditIdModal: false });

    await reloadView();
  };

  render() {
    const { user, selectedMarket } = this.props;
    const { selectedCampaign, toggleEditIdModal, selectedCustomFieldLabel } = this.state;

    return (
      <div className="c-campaign-referrals">
        {user.campaignParticipations.map((cp) => (
          <UserCampaign
            key={cp.campaign.id}
            selectedMarket={selectedMarket}
            campaign={cp.campaign}
            joinedAt={cp.joinedAt}
            user={user}
            handleIdentifierEdit={(c, label) => this.handleIdentifierEdit(c, label)}
          />
        ))}

        {selectedCampaign && selectedCustomFieldLabel && (
          <UserCampaignCustomFieldEditor
            selectedCampaign={selectedCampaign}
            selectedMarket={selectedMarket}
            toggleModalState={toggleEditIdModal}
            handleToggleClose={() => this.setState({ toggleEditIdModal: !toggleEditIdModal })}
            handleToggleSave={this.handleUserCampaignEditorSave}
            user={user}
            customFieldLabel={selectedCustomFieldLabel}
          />
        )}
      </div>
    );
  }
}

UserCampaigns.propTypes = {
  user: PropTypes.instanceOf(BaseUser).isRequired,
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  reloadView: PropTypes.func.isRequired,
};

export default UserCampaigns;
