import React from "react";

import { OpenWindowButton } from "assets/images/CustomIcons";
import PropTypes from "prop-types";

import Tooltip from "components/tooltip/Tooltip";

import "./tooltip_link.scss";

const TooltipLink = ({ href, tooltipMessage }) => (
  <div className="tooltip-link-container">
    <Tooltip content={tooltipMessage}>
      <a href={href} target="_blank" rel="noreferrer" className="tooltip-link__icon">
        <OpenWindowButton />
      </a>
    </Tooltip>
  </div>
);

TooltipLink.propTypes = {
  href: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
};

export default TooltipLink;
