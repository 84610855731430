import React from "react";

import { CopyToClipboard, CopyToClipboardButton } from "assets/images/CustomIcons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import addNotification from "components/notifications/Notifications";
import Tooltip from "components/tooltip/Tooltip";

import "./copy_text.scss";

const CopyText = ({ text, tooltipMessage, appearance = "default" }) => {
  const { t } = useTranslation();

  const handleCopyToClipboard = (copyText) => {
    navigator.clipboard.writeText(copyText);
    addNotification.success(t("global.success"), t("components.copyText.notificationMessage"));
  };

  return (
    <button
      onClick={() => handleCopyToClipboard(text)}
      className="c-button c-button--link"
      type="button"
      data-testid="copy-to-clip-button"
    >
      <Tooltip content={tooltipMessage}>
        <span className="copy-text__icon">
          {appearance === "default" ? <CopyToClipboard /> : <CopyToClipboardButton />}
        </span>
      </Tooltip>
    </button>
  );
};

CopyText.propTypes = {
  text: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf(["default", "button"]),
  tooltipMessage: PropTypes.string.isRequired,
};

export default CopyText;
