const passwordFormatError =
  "La contraseña introducida no es lo suficientemente fuerte, considera añadir números, símbolos o más letras para hacerla más fuerte .";

export default {
  translation: {
    accountDetails: {
      accountCreationDate: "fecha de creación de cuenta",
      accountIdentifier: "identificador de cuenta",
      accountType: "tipo de cuenta",
      accountStatus: "estado de cuenta",
      eligibleForRewards: "apto para recompensas",
      internalIdentifier: "identificador interno",
      subAccountType: "tipo de subcuenta",
    },
    auth: {
      generic: "Incapaz de acceder esta vez. Intentalo más tarde",
      unauthorized_ip_address: "Acceso denegado desde esta dirección IP.",
    },
    bulkActions: {
      addCustomers: {
        fileImport: {
          buttonLabel: "Añadir clientes",
          checkboxLabel: "Correos electrónicos son MD5 hashes",
        },
        header: "Añadir clientes",
        history: {
          header: "Historia",
          tableHeaders: {
            customers: "Clientes",
            existing: "Existentes",
            new: "Nuevo",
            numberOf: "Numero de",
            processedRows: "Filas procesadas",
            status: "Estado",
            uploadedAt: "Subido a las",
            uploadedBy: "Subido por",
          },
        },
        modal: {
          emailDescription:
            ' La dirección de correos electrónicos del recomendador es o texto simple (ej. "john@doe.com") o un MD5 hash de un correo electrónico en minúscula (ej. "30123074929abe9168856af4f4bc904b").',
          openButtonText: "¿Cuales son las columnas requeridas?",
          title: "Columnas requeridas",
        },
        subtitle:
          "Sube una lista de correos electrónicos de clientes que la plataforma utilizará para validar la elegibilidad del amigo para recibir una recompensa en la página del amigo. Los correos electrónicos pueden ser o texto simple o MD5 hashes en minúscula. Es posible que tengas que ponerte en contacto con tu 'Client Success Manager' para configurar esta función si la utilizas por primera vez.",
      },
      forgetUsers: {
        header: "Olvidar usuario",
        history: {
          header: "Historia",
          tableHeaders: {
            forgottenUserCount: "Cuenta de usuario olvidada",
            status: "Estado",
            uploadedAt: "Subido a las",
            uploadedBy: "Subido por",
          },
        },
        fileImport: {
          buttonLabel: "Importar CSV",
        },
        modal: {
          emailDescription:
            'La dirección del correo electrónico del recomendador ej. "john@doe.com"',
          openButtonText: "¿Cuales son las columnas requeridas para un subida CSV?",
          title: "Columnas requeridas",
        },
        subtitle:
          "Puedes subir una lista de correos electrónicos de usuarios para que sean eliminados de nuestro sistema. Alternativamente introduce una dirección de correo electrónico individual por cada usuario, uno en cada línea.",
        textImport: {
          buttonLabel: "Enviar",
          placeholder:
            "Manualmente introduce una lista de correos electrónicos, cada uno en nueva nueva línea.",
          invalidWarning: "Los siguientes correos electrónicos son inválidos:",
        },
      },
      navigation: {
        addCustomers: "Añadir clientes",
        forgetUsers: "Olvidar usuarios",
        referralManagement: "Gestión de recompensas",
        signups: "Pre-registros",
        transactionTracking: "Vinculación de recomendaciones",
      },
      referralManagement: {
        history: {
          header: "Historia",
          tableHeaders: {
            status: "Estado",
            uploadedAt: "Subido a las",
            skippedReferrals: "Omitido",
            confirmedReferrals: "Confirmado",
            cancelledReferral: "Cancelado",
            restoredReferrals: "Restaurado",
          },
        },
        campaignListPlaceholder: {
          withResults: "Selecciona campaña",
          noResults: "No hay recomendaciones pendientes en ninguna campaña",
        },
        exportButton: {
          loading: "Generando, por favor espera...",
          text: "Exportar CSV",
          errorSubmittedMessage:
            "Se ha producido un error al procesar la exportación, por favor, inténtelo de nuevo.",
        },
        fileImport: {
          buttonLabel: "Importar CSV",
          checkboxLabel: "¿Validar categorias de recompensas?",
        },
        header: "Gestión de recompensas",
        referralCount: "{{count}} Recomendaciones pendientes",
        referralCount_one: "{{count}} Recomendación pendiente",
        stepFirstHeader: "Paso uno",
        stepFirstSubtitle:
          "Este CVS contiene todas tus recomendaciones que actualmente están en estado pendiente. Los confirmados o cancelados anteriormente no apareceran aqui.",
        stepSecondHeader: "Step 2 -Cambiar estado",
        stepSecondDescription: {
          updateStatusColumn: "En el CSV cambia <code>{{statusColumnHeader}}</code> columna a:",
          confirmedValueDescription: "para marcarlo como exitoso",
          cancelledValueDescription: "para marcarlo como fallido.",
          unmodifiedRowsNote: "No actualices datos que no deben de ser alterados.",
        },
        stepThirdHeader: "Paso 3 - Columnas opcionales",
        stepThirdDescription: {
          addRewardCategoryColumn: [
            "Añade una adicional <code>{{rewardCategoryColumnHeader}}</code> en la columna al <strong>después</strong> de la columna <code>{{statusColumnHeader}}</code>",
            "Las filas que no tengan una categoría de recompensa pueden dejarse en blanco para esta columna.",
          ],

          addClientDataColumns: [
            "Añadir Datos del cliente<br>",
            "<strong>Añadir columna(s):</strong> Añada columna(s) que contenga(n) <code>Client Data</code> al CSV después de <code>{{statusColumnHeader}}</code> y (opcional) <code>{{rewardCategoryColumnHeader}}</code>. El título de la columna debe tener el formato <code>ClientData_{name}</code>; por ejemplo: <code>ClientData_Customer ID</code> para <code>Customer ID</code>.",
            "<strong>Actualización de datos:</strong> Para actualizar los datos existentes, asegúrese de que los títulos de las nuevas columnas <code>Client Data</code> coinciden con los de las columnas existentes.",
            "<strong>Sin datos:</strong> Las filas sin <code>Client Data</code> pueden dejarse vacías.",
            "<strong>Borrar:</strong> No es posible borrar <code>Client Data</code> utilizando este proceso.",
          ],
        },
        stepFourthHeader: "Paso 4 - Subida",
        stepFourthDescription: {
          requiredColumns: "EL CSV debe contener las siguientes dos columnas:",
          optionalColumns:
            "E CVS contiene ademas la opcion de añadir una tercera columna adiciona:",
          requiredColumnSeparator: "o",
        },
        stepFifthHeader: "Paso 5 - Completar",
        stepFifthSubtitle:
          "Una vez el archivo haya sido recibido, el sistema procesa los datos y distribuye las recompensas apropiadas a los clientes.",
        subtitle:
          "Cualquier cliente que complete una recomendación satisfactoria, será marcado como pendiente, para que puedas asegurar que la transacción es válida antes de distribuir una recompensa.",
      },
      signups: {
        campaignSelectPlaceholder: "Seleccionar campaña",
        campaignListPlaceholder: "No hay campañas para este mercado",
        fileImport: {
          buttonLabel: "Subir",
          checkboxLabel:
            "Envía un correo electrónico de invitación a los usuarios que no se han registrado todavia (Solo envia correos electrónicos de invitación a los usuarios que hayan acordado recibir material de marketing)",
        },
        header: "Registros",
        history: {
          header: "Historia",
          tableHeaders: {
            campaign: "Campaña",
            status: "Estado",
            uploadedAt: "Subido a las",
            uploadedBy: "Subido por",
          },
        },
        modal: {
          optional: {
            clientDataDescription:
              'Metadatos adicionales especificados por el cliente asociados con el recomendador registrado en la campaña, ej. "ClientData_customer_id", "ClientData_phone_number". Siempre opcional.',
            customFieldDescription:
              "Si estás utilizando un solo campo personalizado para identificar a un cliente, este campo puede contener contenido como una identificación de membresía o un número de teléfono. Si se requiere que un usuario ingrese este valor durante el registro, entonces también se requiere en este archivo.",
            customFieldsDescription:
              'Si estás utilizando multiples campos personalizados para identificar a un cliente, entonces esos campos pueden contener valores como la identificación de la membresía, número de telefono, etc. "CustomField_phone_number", "CustomField_membership_number" Si al usuario se le requiere que ingrese esos valores durante el registro entonces son requeridos en este archivo.',
            header: "Configuración de campaña pendiente requerida u opcional",
            lastNameDescription: 'Apellido de la persona, ej. "Smith". Siempre opcional.',
            localeDescription:
              "La región del cliente. Cuando un cliente recibe cualquier correo electrónico relacionado con la campaña, será desplegado en la regionalidad proporcionada aquí. Esto es opcional. Si no se proporciona, se utilizara el de defecto",
          },
          required: {
            emailDescription: 'Correo electrónico de la persona, ej. "john@doe.com".',
            firstNameDescription: 'El nombre de la persona, ej. "Jane".',
          },
          response: {
            dashboardUrlDescription: "Dashboard URL unico de cada persona",
            header:
              "Cuando se suba, recibirás un CSV actualizado conteniendo las siguientes columnas adicionales",
            shareUrlDescription: "URL para compartir, único de cada persona",
            statusDescription:
              "Especifica si se ha creado un registro o el motivo por el cual no se ha creado. Esto podría deberse a que ya se registraron en la campaña o ya se registraron a través de la API de registro (en cuyo caso, la URL existente para compartir es lo que se incluye)",
          },
          openButtonText: "¿Cuales son las columnas requeridas?",
          title: "Columnas requeridas",
          downloadCsvDescription:
            "Descarga nuestra plantilla lista para usar y asegúrate de proporcionarnos los datos correctos.",
          downloadCsvButtonText: "Descargar plantilla CSV",
        },
        subtitle: "Sube un CSV que contenga los detalles de las personas que quieras registrar.",
        limit: "Hay un límite de 200000 filas por subida.",
        notification:
          "Recibirás una notificación por correo electrónico con el estado de tu importación.",
      },
      transactionTracking: {
        fileImport: {
          buttonLabel: "Importar Transaccion",
          checkboxLabel: "No validar la elegibilidad para las recomendaciones",
        },
        header: "Vinculación de recomendaciones",
        history: {
          header: "Historia",
          tableHeaders: {
            campaign: "Campaña",
            referrals: "Recomendaciones",
            rejected: "Rechazado",
            status: "Estado",
            successful: "Exitoso",
            transactions: "Transacciones",
            unsuccessful: "Fallido",
            uploadedAt: "Subido a las",
            uploadedBy: "Subido por",
          },
        },
        modal: {
          clientDataContent:
            "Metadatos adicionales especificados por el cliente asociados con el cliente que completó la transacción ej. ClienteData_movil_numbero, ClienteData_membersia_numbero",
          customFieldContent:
            "Si estás utilizando un campo personalizado para identificar amigos, este puede hacer referencia a un número de usuario o un número telefónico. Nota: Si el amigo ya tiene un valor de campo personalizado, éste lo reemplazará; si dejas esta columna en blanco, se mantendrá el antiguo valor existente; ej. 07123456789",
          customFieldsContent:
            'Si estás utilizando varios campos personalizados para identificar amigos, los mismos pueden hacer referencia a un número de usuario o un número telefónico; por ejemplo, "CustomField_phone_number", "CustomField_membership_number".',
          emailAddressContent: 'Correo electrónico del amigo. "jane@smith.com"',
          fullNameHContent:
            'Nombre completo del cliente.Formateado como"Nombre, Apellido", ej. "Jane, Smith"',
          localeContent:
            'La configuración regional preferida del cliente: solo aplicable para mercados multilingües":"Regional"',
          marketingOptinContent:
            "Si el cliente ha optado por recibir correos electrónicos de marketing, es decir, verdadero o falso. Si se omite, el valor predeterminado es verdadero.",
          orderNumberContent:
            "Este valor puede ser cualquier identificador único que utilices para identificar transacciones, p. TRANSACCIÓN12345678",
          orderValueContent:
            "El valor de la transacción. Excluir símbolos de moneda; formateado como XXX.YY, p. 12.89",
          orderTimestampContent:
            "Cuando se completó la transacción, como una marca de tiempo UNIX, p. 1321009871 (la fecha no debe exceder a los 4 años previos al seguimiento)",
          optionalText: "Opcional",
          title: "Columnas requeridas",
          referrerEmailContent:
            'Correo electrónico del recomendador ej. "john@doe.com" (Solo se necesita si no hay un VoucherCode)',
          voucherCodeContent: "El código utilizado por el cliente en el check-out",
        },
        showRequiredModalColumnsButtonText: "¿Cuáles son las columnas requeridas?",
        subtitle:
          "Cada fila en el CSV debe incluir al menos el código de cupón utilizado por el cliente o la dirección de correo electrónico del recomendador.",
      },
      textArea: {
        noLabelText: "Sin etiqueta",
      },
    },
    campaign: {
      editor: {
        modalTitle: "Campaña: {{campaignName}}",
      },
      header: "Campaña",
      missingCustomFieldText: "No proporcionado",
      referredByDateLabel: "Recomendación empezó el ",
      referredByFieldLabel: "Recomendado por",
      revalidateButtonText: "Revalidar",
      subheader: "Fecha registro",
      validator: {
        buttonLabel: "Enviar",
        errorMessage: "Falló ya que la usuario no está en el estado correcto.",
        formPrompt: "Ten en cuenta que esto puede tomar algún tiempo en procesar.",
        modalTitle: "Enviar para revalidación",
      },
    },
    cis: {
      headerText: "Perfil",
      noResultErrorMessage: "Error al recuperar el perfil CIS",
      retryButtonText: "Reintentar",
    },
    components: {
      textfield: {
        characters: "{{count}} characters",
        characters_one: "{{count}} character",
      },
      copyText: {
        tooltipMessage: "Copiar enlace",
        notificationMessage: "Enlace copiado",
      },
      openNewTab: {
        tooltipMessage: "Esto te llevará a tu Panel de control",
      },
      multiSelectFilter: {
        header: "Lista de filtros",
        allSelected: "Todo seleccionado",
        countSelected: "{{count}} Seleccionado",
      },
      checkboxDropdown: {
        noOptions: "No hay opciones",
      },
    },
    cta: {
      changeMarket: "Cambiar mercado",
      signOut: "Cerrar sesión",
    },
    fileImport: {
      fileSelect: {
        buttonText: "Navegar",
        infoText: "Subir archivo CSV",
      },
    },
    fileSelect: {
      buttonText: "Navegar",
      infoText: "Seleccionar archivo",
    },
    forms: {
      errors: {
        generic: {
          invalid: "Hubo un problema al enviar este formulario, inténtalo de nuevo más tarde.",
        },
        imageUpload: {
          format: "Por favor, sube la imagen en formato png o jpeg",
          size: "Por favor, sube una imagen más pequeña",
        },
        referral: {
          unsuccessful: "La creación de la recomendación no tuvo éxito..",
          pending_count_unsuccessful:
            "No se puede obtener el recuento de recomendaciones pendientes de la campaña.",
        },
        email: {
          format:
            "La dirección de correo electrónico que ingresaste no es válida. Por favor, asegúrate de que sea correcto.",
          not_unique:
            "Este correo electrónico ya existe en otro programa. Por favor, utilice otro.",
          filled: "Debes ingresar un valor",
        },
        confirmationCode: {
          invalid:
            "El código introducido no es correcto. Por favor revisa tu correo electrónico y vuelva a intentarlo.",
        },
        passwordConfirmation: {
          doesnt_match:
            "Las contraseñas que has ingresado no coinciden, asegúrate de que sean correctas.",
        },
        password: {
          invalid: passwordFormatError,
          format: passwordFormatError,
        },
        firstName: {
          min_size: "Este nombre no es lo suficientemente largo.",
          invalid: "¿Seguro que has introducido tu nombre correctamente?",
        },
        lastName: {
          invalid: "¿Seguro que has introducido tu nombre correctamente?",
        },
        customField: {
          format: "Esto no está en el formato correcto.",
          filled: "Debes ingresar un valor",
        },
        testInputId: {
          filled: "Debes ingresar un valor",
          format: "El valor no está en el formato correcto",
        },
        testAnotherInputId: {
          filled: "Debes ingresar otro valor",
          format: "El valor no está en el formato correcto",
        },
        campaign_id: {
          filled: "Debe seleccionar una campaña",
        },
        campaignId: {
          key: "Debe seleccionar una campaña",
        },
        registration: {
          not_allowed: "El usuario no está autorizado a registrarse como recomendador.",
        },
        termsOptIn: {
          filled: "Debes estar de acuerdo con los términos",
        },
        emailComplianceOptIn: {
          filled: "Debe aceptar los términos y condiciones del correo electrónico",
        },
        marketingOptIn: {
          filled: "Debe aceptar los términos y condiciones de marketing",
        },
        fileImport: {
          empty: "Archivo CSV está vacío",
          missing_headers:
            "Al CSV le faltan encabezados, revisa las columnas requeridas arriba e inclúyelas como encabezados",
          unprocessable:
            "CSV no es válido, revisa las columnas obligatorias anteriores y vuelve a enviar el archivo",
          upload_error:
            "El servicio de subida devolvió un error. Por favor, inténtalo de nuevo más tarde",
          included_in: "Hubo un problema al enviar este formulario, inténtalo de nuevo más tarde",
          data_errors:
            "Ha habido un problema con los datos CSV y el archivo no se ha podido cargar.",
        },
        textImport: {
          empty: "El valor está vacío",
          missing_headers:
            "Hubo un problema al enviar este formulario, inténtalo de nuevo más tarde",
          data_errors: "Hubo un problema con los datos importados.",
        },
        bulkActions: {
          load_history:
            "Aquí hubo un problema al cargar el historial. Por favor, inténtalo de nuevo más tarde",
        },
        expiryDate: {
          date: "Por favor, introduzca una fecha válida",
          invalid: "Verifique que ha seleccionado una fecha futura y vuelva a intentarlo",
        },
        partnerAssets: {
          partnerAssets:
            "Hubo un problema al enviar este formulario, inténtalo de nuevo más tarde.",
          characterLimit: "Se ha alcanzado el límite de caracteres",
        },
      },
      labels: {
        partner: {
          businessName: "Nombre comercial o marca del socio",
          firstName: "Nombre del contacto",
          lastName: "Apellido del contacto",
          email: "Dirección de correo electrónico del contacto",
          mobile: "Número de teléfono del contacto",
          jobTitle: "Título laboral del contacto",
          industrySector: "Sector",
          language: "Idioma",
          businessCountry: "Ubicación del socio",
          twitter: "Twitter del socio",
          instagram: "Instagram del socio",
          facebook: "Facebook del socio",
          linkedin: "LinkedIn del socio",
          snapchat: "Snapchat del socio",
          youtube: "YouTube del socio",
          website: "Sitio web del socio",
          signupReason: "Información adicional o notas sobre el socio",
          audienceSize: "Tamaño de la audiencia del socio",
          audienceLocation: "¿Dónde está la audiencia del socio?",
          worldSector: "Seleccionar un sector mundial",
        },
        firstName: "Nombre",
        lastName: "Apellido",
        email: "Dirección de email",
        orderNumber: "Número de orden",
        rewardCategory: "Categoría de recompensa",
        selectRewardCategory: "Elegir una categoría de recompensa",
        mobile: "Numéro de teléfono",
        expiryDate: "Introduzca una nueva fecha de vencimiento",
        mandatoryField: "indica información requerida",
        campaign: "Seleccione una campaña",
        campaignPlaceholder: "Seleccione una campaña",
      },
      headers: {
        addPartner: "Agregar Socio",
        editProfile: "Editar Perfil",
      },
      placeholders: {
        select: "Selecciona una opción",
      },
      hints: {
        expiryDate: "Formato: dd/mm/aaaa",
      },
      infoText: {
        twitter: "Por favor, introduce el nombre de usuario de Twitter del socio",
        instagram: "Por favor, introduce el nombre de usuario de Instagram del socio",
        facebook: "Por favor, introduce el nombre de usuario de Facebook del socio",
        linkedin: "Por favor, introduce el nombre de usuario de LinkedIn del socio",
        youtube: "Por favor, introduce el nombre de usuario de Youtube del socio",
        snapchat: "Por favor, introduce el nombre de usuario de Snapchat del socio",
        signupReason:
          "Coméntanos sobre ti/la compañía. Por favor, incluye información sobre el sector/industria en el que te desarrollas y el tipo de público/clientes que tienes",
        audienceSize:
          "¿Cuál es el tamaño de la audiencia del socio en todos los puntos de contacto?",
        audienceLocation: "¿Dónde está ubicada principalmente la audiencia del socio?",
      },
    },
    fulfilments: {
      header: "Recompensas",
      infoBox:
        "Al ver disponible el ícono <1></1>, podrá ver más información al desplazarse sobre el mismo.",
      tableHeaders: {
        name: "Nombre",
        dateEarned: "Fecha de la recompensa",
        source: "fuente",
        expiryDate: "Fecha de vencimiento",
        status: "estado",
      },
      source: {
        referral: "Recomendación",
      },
      actions: {
        errorRewardEmailResenMessage:
          "Hubo un problema al enviar el correo electrónico para esta recompensa",
        resentOption: "Reenviar correo electrónico de recompensa",
        successRewardEmailResentMessage: "Correo electrónico de recompensa reenviado.",
        changeExpiry: "Modificar la fecha de vencimiento",
        expire: "EVencimiento de confimación",
        unexpire: "Revalidar confirmación",
      },
      changeExpiryDateText: "Se ha extendido la fecha de vencimiento",
      unexpireModalText: "Sin vencimiento",
      expireModalText: "¿Desea continuar?",
      expireModalSubtitle:
        "Si hace clic en 'Expirar', la confirmación vinculada con esta referencia caducará",
      expireModalButton: "Expirar",
      notifications: {
        successfulExpiryDateUpdate: "Expiry date has been extended",
        successfulExpiry: "La confirmación ha expirado",
        expiryErrorMessage: "Hubo un problema al actualizar esta confirmación",
      },
      paymentProviderStatuses: {
        PAYPAL_BLOCKED: "Esta solicitud de pago ha sido bloqueada.",
        PAYPAL_CURRENCY_NOT_SUPPORTED_FOR_RECEIVER:
          "Esta divisa no puede aceptarse para la cuenta de este destinatario. Puede volver a enviar este pago con otra divisa.",
        PAYPAL_FAILED: "Esta solicitud de pago ha fallado, por lo que no se han deducido fondos.",
        PAYPAL_INVALID_EMAIL: "Email inválido",
        PAYPAL_ONHOLD: "Esta solicitud de pago se está revisando y está en espera.",
        PAYPAL_PENDING: "Esta solicitud de pago se ha recibido y se procesará.",
        PAYPAL_PENDING_RECIPIENT_NON_HOLDING_CURRENCY_PAYMENT_PREFERENCE:
          "Este pago está pendiente porque el destinatario ha configurado las preferencias de su cuenta para revisar los créditos en esta divisa. Se ha notificado al destinatario. Por favor, compruebe el estado de este pago más tarde.",
        PAYPAL_RECEIVER_ACCOUNT_LIMITATION:
          "Motivo: La cuenta del receptor está actualmente limitada. Todos los pagos realizados a esta cuenta se suspenderán hasta que se resuelva el problema.",
        PAYPAL_RECEIVER_ACCOUNT_LOCKED:
          "Motivo: No hemos podido enviar un pago porque la cuenta del destinatario está inactiva o restringida.",
        PAYPAL_RECEIVER_COUNTRY_NOT_ALLOWED:
          "Motivo: No podemos enviar este pago porque el destinatario vive en un país en el que los pagos no están permitidos.",
        PAYPAL_RECEIVER_STATE_RESTRICTED:
          "Motivo: No podemos enviar este pago porque el destinatario vive en una región en la que los pagos no están permitidos.",
        PAYPAL_RECEIVER_UNCONFIRMED:
          "Motivo: El correo electrónico o el número de teléfono del destinatario no están confirmados. Cualquier pago realizado a esta cuenta se marcará como Procesando hasta que el destinatario confirme la información de su cuenta.",
        PAYPAL_RECEIVER_UNREGISTERED:
          "Motivo: El destinatario de este pago no tiene una cuenta. Se ha enviado al destinatario un enlace para registrarse en una cuenta.",
        PAYPAL_RECEIVER_YOUTH_ACCOUNT:
          "Motivo: No hemos podido enviar un pago porque el destinatario tiene una cuenta joven.",
        PAYPAL_RECEIVING_LIMIT_EXCEEDED:
          "Motivo: El destinatario no puede aceptar este pago porque supera el importe que puede recibir en este momento.",
        PAYPAL_REGULATORY_BLOCKED:
          "Esta transacción está bloqueada debido a restricciones de cumplimiento de la normativa.",
        PAYPAL_REFUNDED: "Esta solicitud de pago ha sido reembolsada.",
        PAYPAL_RETURNED:
          "El beneficiario no ha reclamado este pago, por lo que los fondos han sido devueltos.",
        PAYPAL_REVERSED: "Esta solicitud de pago ha sido anulada.",
        PAYPAL_RISK_DECLINE: "Motivo: Este pago se ha rechazado por motivos de riesgo de PayPal.",
        PAYPAL_UNCLAIMED:
          "El destinatario de este pago no tiene cuenta PayPal. Se ha enviado al destinatario un enlace para registrarse y obtener una cuenta PayPal.",
        HYPERWALLET_CREATED: "Esta solicitud de pago fue recibida y será procesada.",
        HYPERWALLET_SCHEDULED: "Esta solicitud de pago se ha recibido y será procesada",
        HYPERWALLET_PENDING_ACCOUNT_ACTIVATION: "Cuenta pendiente de activación.",
        HYPERWALLET_PENDING_ID_VERIFICATION: "Verificación de usuario pendiente.",
        HYPERWALLET_PENDING_TAX_VERIFICATION: "Verificación impositiva pendiente.",
        HYPERWALLET_PENDING_TRANSFER_METHOD_ACTION: "Método de transferecia pendiente.",
        HYPERWALLET_PENDING_TRANSACTION_VERIFICATION: "Pendiente verificación de transacción.",
        HYPERWALLET_IN_PROGRESS: "Esta solicitud de pago fue recibida y será procesada.",
        HYPERWALLET_UNCLAIMED: "No redimido",
        HYPERWALLET_CANCELLED: "Cancelado",
        HYPERWALLET_FAILED:
          "Esta solicitud de pago ha fallado, por lo que no se han deducido fondos.",
        HYPERWALLET_RECALLED: "Retirado",
        HYPERWALLET_RETURNED:
          "El beneficiario no ha reclamado este pago, por lo que los fondos han sido devueltos.",
        HYPERWALLET_EXPIRED: "Caducado",
        HYPERWALLET_CONSTRAINT_VIOLATIONS:
          "Esta solicitud incluye valores que no cumplen con nuestros requisitos.",
      },
    },
    global: {
      add: "Anadir",
      back: "Atras",
      cancel: "Cancelar",
      close: "Cerrar",
      confirm: "Confirmar",
      edit: "Editar",
      email: "Email",
      error: "Error",
      customers: "Clientes",
      download: "Descargar",
      no: "No",
      none: "Ninguno",
      ok: "Ok",
      or: "o",
      referrals: "Recomendaciones",
      reporting: "Informes",
      save: "Guardar",
      search: "Buscar",
      submitted: "Subido",
      success: "Exito",
      undo: "Deshacer",
      yes: "Si",
      apply: "Aplicar",
      clearAll: "Borrar todo",
      filter: "Filtro:",
      deselectAll: "Deseleccionar todo",
    },
    worldSector: [
      "África",
      "Australasia",
      "Asia Oriental",
      "Europa",
      "Centroamérica",
      "Norteamérica",
      "Sudamérica",
      "Asia del Sur",
    ],
    languages: {
      ar: "Árabe (ar)",
      ms: "Malayo (ms)",
      eu: "Basco (eu)",
      bg: "Búlgaro (bg)",
      ca: "Catalan (ca)",
      cs: "Checo (cs)",
      "zh-CN": "Chino - Simplificado (zh-CN)",
      "zh-TW": "Chino - Tradicional (zh-TW)",
      da: "Danés (da)",
      nl: "Neerlandés (nl)",
      en: "Inglés (en)",
      "en-US": "Inglés (EE. UU.) (en-US)",
      fi: "Finés (fi)",
      fr: "Francés (fr)",
      "fr-CA": "Francés - Canadá (fr-CA)",
      gl: "Gallego (gl)",
      hu: "Hungría (hu)",
      de: "Alemán (de)",
      "de-informal": "Alemán - Informal (de-informal)",
      "de-AT-informal": "Alemán - Austria (de-AT-informal)",
      "de-CH-informal": "Alemán - Suiza (de-CH-informal)",
      el: "Griego (el)",
      id: "Indonesio (id)",
      it: "Italiano (it)",
      ja: "Japonés (ja)",
      ko: "Coreano (ko)",
      nb: "Noruego (nb)",
      pl: "Polaco (pl)",
      pt: "Portugués (pt)",
      "pt-BR": "Portugués - Brasil (pt-BR)",
      ro: "Rumano (ro)",
      ru: "Ruso (ru)",
      es: "Español (es)",
      sv: "Sueco (sv)",
      tl: "Tagalo (tl)",
      th: "Tailandés (th)",
      tr: "Turco (tr)",
      vi: "Vietnamita (vi)",
    },
    marketSelector: {
      title: "Seleccionado un mercado",
      textInput: {
        label: "Filtrar mercados",
        placeholder: "Buscar...",
      },
    },
    orders: {
      header: "Pedidos",
      tableHeaders: {
        codeUsed: "Codigo usado",
        madeOn: "Creado el",
        referralLinked: "Recomendacion vinculada",
        reference: "Referencia",
        value: "Valor",
      },
    },
    referrals: {
      header: "Recomendaciones hechas",
      tableHeaders: {
        friendName: "Nombre del amigo",
        referredOn: "Recomendado el",
        reward: "Recompensa",
        status: "estado",
      },
      cancelReferralModalText: "Cancelar recomendación",
      delayedConfirmationModal: {
        cancelReferral: {
          confirmationText: "Si, Cancelar recomendación",
          title: "¿Estás seguro de que quieres cancelar esta recomendación?",
          undoMessage: "La cancelación se ha deshecho.",
        },
        closeModalText: "No, vuelve atrás!",
        confirmReferral: {
          confirmationText: "Si, confirmar recomendación",
          content: "Este cambio es irreversible",
          title: "¿Estás seguro de que quieres confirmar esta recomendación?",
          undoMessage: "La confirmación se ha desecho.",
        },
        restoreReferral: {
          confirmationText: "Si, Revertir recomendación",
          title: "¿Está seguro de que desea revertir esta recomendación a un estado pendiente?",
          undoMessage: "La reversión se ha deshecho.",
        },
        selectRewardCategory: "Selecciona una categoría de recompensa",
        undoCounterText: "demasiado tarde!",
      },
      notifications: {
        errorCancelled: "Hubo un problema al cancelar esta recomendación.",
        errorConfirming: "Hubo un problema al confirmar esta recomendación",
        errorRevertedToPending: "Hubo un problema al revertir esta recomendación a pendiente",
        successCancelled: "Recomendación cancelada con éxito",
        successConfirmed: "Recomendación confirmada con éxito",
        successRevertedToPending: "Recomendación revertida exitosamente a pendiente",
        noConfirmReferralWarning: "No se puede confirmar manualmente una recomendación de CIS",
      },
      optionsLabels: {
        cancelReferral: "Cancelar recomendación",
        confirmReferral: "Confirmar recomendación",
        noRewardCategory: "Ninguna categoría de recompensa",
        revertToPending: "Revertir a pendiente",
      },
      ineligibilityReasons: {
        ordered_previously: "Solicitado anteriormente",
        no_referrer: "Recomendador no existente",
        self_referral: "Auto recomendación",
        existing_referrer: "Recomendador existente",
        referral_limit_reached: "“Límite de recomendaciones alcanzado",
        friend_voucher_already_redeemed: "Cupones de amigos ya canjeados",
        email_required: "Correo electrónico requerido",
        name_required: "Nombre requerido",
        terms_opt_in_required: "Se requiere la aceptación de términos de adhesión",
        unique_referred_friends_reached: "Límite de recomendaciones únicas de amigos alcanzado",
      },
    },
    reporting: {
      dashboard: {
        errorLoadingData: "Hubo un problema al cargar los datos del informe.",
        errorTimeLineData: "Hubo un problema al cargar los datos de la línea de tiempo",
        loadingText: "Cargando",
        modal: {
          title: "Déjanos ayudar",
        },
        overview: {
          contactBar: {
            modal: {
              emailSubject: "Ponte en contacto",
              firstParagraph:
                "Los gestores de cuentas de Buyapowa son expertos en promoción y marketing de recomendación, y están aquí para ayudar a que tu campaña alcance todo su potencial..",
              reachOutAt: "Contactar en",
              secondParagraph:
                "Estarán encantados de hablar sobre cualquier cosa, desde <strong>afinar tu oferta, promocionar tu campaña</strong> y nuestra <strong>API de registro</strong>, hasta <strong>consejos de diseño, conocimientos psicológicos </strong> y más.",
            },
            firstParagraph: "Ponte en contacto con tu gestor de cuentas",
            secondParagraph: "Podemos ayudarte a mejorar estos números",
          },
          legendText: {
            first: "Recomendadores",
            second: "Amigos",
          },
          metricColumns: {
            confirmedReferrals: "Recomendaciones confirmadas",
            confirmedReferralsTooltipContent:
              "El número de recomendaciones que se confirmaron dentro del período seleccionado. Las recompensas solo se pagan después de que se confirma una recomendación.",
            newReferrerRegistrations: "Recomendadores registrados",
            newReferrerRegistrationsTooltipContent:
              "El número de clientes que se registraron en su programa de recomendación en el período seleccionado.",
            pendingReferrals: "Recomendaciones pendientes",
            pendingReferralsTooltipContent:
              "El número de recomendaciones pendientes que se crearon dentro del período seleccionado. Una recomendación pendiente requiere una validación adicional antes de convertirse en una recomendación confirmada.",
            sharers: "Clientes que compartieron",
            sharersTooltipContent:
              "Para que un recomendador cuente como compartidor durante el período seleccionado, tuvo que presionar un botón de compartir o copiar el enlace",
            title: "Entre",
            uniqueFriendVisits: "Visitas únicas de amigos",
            uniqueFriendVisitsTooltipContent:
              "El número de amigos que visitaron su programa después de haber sido invitados por un recomendador registrado",
          },
          totalConfirmedReferrals:
            "Recomendaciones confirmadas en total desde el lanzamiento de la campaña",
          totalRegisteredReferrers:
            "Recomendadores Registrados en total desde el lanzamiento de la campaña",
          totals: {
            since: "Dede",
          },
        },
        performanceTiles: {
          confirmedReferrals: "Recomendaciones confirmadas",
          confirmedReferralsContent:
            "Este es el número de clientes recomendados que fueron confirmados como recomendadores (según tus reglas comerciales) durante el intervalo de fechas seleccionado.",
          confirmedReferralsPerSharer: "Recomendaciones confirmadas por persona que compartió",
          confirmedReferralsPerSharerContent:
            "Esta es la relación entre la cantidad de recomendaciones que se confirmaron durante este período y la cantidad de clientes que compartieron al menos una vez.",
          friendVisitsPerSharer: "Visitas de amigos por persona que compartió",
          friendVisitsPerSharerContent:
            "Esta es la relación entre el número de visitas de amigos (personas invitadas por sus clientes) y clientes que compartieron al menos una vez.",
          friendVisitsToConfirmedReferral: "Visitas de amigos por recomendación confirmada",
          friendVisitsToConfirmedReferralContent:
            "Este es el porcentaje de amigos (personas invitadas por sus clientes) que se convirtieron en recomendaciones confirmadas durante el período seleccionado.",
          referrersWhoShared: "Recomendadores que compartieron",
          referrersWhoSharedContent:
            "De todos los clientes que se inscribieron en su programa de recomendación, este es el porcentaje que luego compartió con sus amigos al menos una vez. Para que cuente como compartidor, debe haber presionado el botón de compartir o haber copiado el enlace.",
        },
        tablist: {
          overview: "Rendimiento general",
          timeline: "Línea de tiempo",
        },
        topbar: {
          buttonDownloadReportLoadingText: "Generando, por favor espere...",
          buttonDownloadReportText: "Descargar",
          campaignSelector: {
            campaignHandlerTitle: "Campaña",
            deselectAll: "Deseleccionar todo",
            campaignSelectorText: {
              multiple: "Múltiples seleccionados",
              none: "Seleccione una campaña",
            },
          },
          dateRangeSelector: {
            applyLinkText: "Aplicar",
            customRangeSelector: {
              title: "Rango seleccinado",
              calendarMonth: {
                sunday: "D",
                monday: "L",
                tuesday: "M",
                wednesday: "M",
                thursday: "J",
                friday: "V",
                saturday: "S",
              },
            },
            helpText: {
              firstLine:
                "Selecciona un rango de fechas personalizado o una de las opciones predefinidas a la derecha.",
              secondLine:
                "Si su campaña es nueva, los datos pueden tardar un poco en actualizarse..",
            },
            presetsSelector: {
              lastMonth: "Último mes",
              lastSevenDays: "Últimos 7 dias",
              lastThirtyDays: "Últimos 30 dias",
              lastYear: "Último año",
              thisMonth: "Este mes",
              thisYear: "Este año",
              today: "Hoy",
              yesterday: "Ayer",
            },
          },
          downloadErrorContent:
            "La descarga tardó demasiado y no se pudo finalizar. Selecciona un intervalo de fechas más pequeño o vuelve a intentarlo.",
          downloadErrorTitle: "La descarga falló",
          tooltipContent: "Este informe contendrá los datos del periodo que hayas seleccionado",
        },
      },
      navigation: {
        noMobileWarningTitle: "No se puede acceder a los informes en dispositivos móviles.",
        noMobileWarningSubtitle:
          "Vuelva a visitar esta página usando su ordenador de escritorio o portátil.",
        operationalReports: "Informes operacionales",
        reportingDashboard: "Panel de informes",
        partnerReports: "Socios de Marca",
        referrerReports: "Programas de Refiridos",
        rewardReports: "Distribución de recompensas automatizada",
      },
      management: {
        downloadCard: {
          buttonGenerateReport: {
            loading: "Generando, por favor espere...",
            hasReport: "Descargar",
            noReport: "Generar y Descargar",
          },
          generatedText: "Último generado",
          neverGenaratedText:
            'Nunca antes generado. Seleccione un intervalo de fechas y haga clic en "Generar y descargar". Si la descarga falla, intente reducir el intervalo de fechas.',
          generateAgainButtonText: "Generar de nuevo",
          changeDateText:
            "Para ver otras fechas, seleccione un nuevo intervalo en el calendario y haga clic en 'generar de nuevo'. Si la descarga falla, intente reducir el rango de fechas.",
          errorSubmittedMessage:
            "Asegúrese de que la fecha de inicio es anterior a la fecha de finalización y que el intervalo está dentro de los límites permitidos del informe.",
          dateText: "Fecha",
        },
        header: "Informes operacionales",
        navigation: {
          partner: {
            allDistributedVouchers: "Cupones distribuídos",
            allDistributedVouchersDescription:
              "Una lista de todos los cupones distribuidos de esta campaña..",
            allDistributedVouchersRecommendedRange: "Rango recomendado de 1 año",
            customerSignups: "Registros de clientes",
            customerSignupsDescription:
              "Una lista completa de todos los clientes que se han registrado para convertirse en recomendadores, incluye detalles de suscripción de marketing, compartir URL y cualquier campo personalizado..",
            customerSignupsRecommendedRange: "Rango recomendado de 1 año",
            friends: "Amigos",
            friendsDescription:
              "Todos los amigos que completaron sus datos en la página de inicio de amigos (si corresponde), además de los amigos que realizaron transacciones en su sitio a través de una recomendación.",
            friendsRecommendedRange: "Rango recomendado de 90 días",
            friendVouchers: "Cupones de amigos",
            friendVouchersDescription:
              "Para recuperar todos los códigos que se han distribuido a amigos, incluidos los cupones posteriores a la recomendación.",
            friendVouchersRecommendedRange: "Rango recomendado de 90 días",
            optOuts: "Opt-outs",
            optOutsDescription:
              "Reporte de todos los usuarios que han cancelado la suscripción a los correos electrónicos.",
            optOutsRecommendedRange: "Rango recomendado 1 año",
            fulfilments: "Recompensas",
            fulfilmentsDescription:
              "Para recuperar todas las recompensas que se han enviado en el intervalo de tiempo especificado.",
            fulfilmentsRecommendedRange: "Rango recomendado de 90 días",
            rewardStreamLegacyReferralData: "Datos de referencia heredados de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Una lista de recomendaciones (similar al informe de quién refirió a quién) con un formato que se asemeja mucho a lo que estaba disponible en RewardStream.",
            topReferrers: "Recomendadores Top",
            topReferrersDescription:
              "Una lista de los 100 principales recomendadores de la campaña, ordenados por número de recomendación..",
            topReferrersRecommendedRange: "Rango recomendado de 1 año",
            whoReferredWhom: "Quién recomendó a quién",
            whoReferredWhomDescription:
              "Recupera una lista de todas las recomendaciones, el estado de la recomendación, la fecha en que se actualizaron los registros por última vez y si la recomendación fue a través del píxel o un cupón.",
            whoReferredWhomRecommendedRange: "Rango recomendado de 90 días",
          },
          referrer: {
            allDistributedVouchers: "Cupones distribuídos",
            allDistributedVouchersDescription:
              "Una lista de todos los cupones distribuidos de esta campaña..",
            allDistributedVouchersRecommendedRange: "Rango recomendado de 1 año",
            customerSignups: "Registros de clientes",
            customerSignupsDescription:
              "Una lista completa de todos los clientes que se han registrado para convertirse en recomendadores, incluye detalles de suscripción de marketing, compartir URL y cualquier campo personalizado..",
            customerSignupsRecommendedRange: "Rango recomendado de 1 año",
            friends: "Amigos",
            friendsDescription:
              "Todos los amigos que completaron sus datos en la página de inicio de amigos (si corresponde), además de los amigos que realizaron transacciones en su sitio a través de una recomendación.",
            friendsRecommendedRange: "Rango recomendado de 90 días",
            friendVouchers: "Cupones de amigos",
            friendVouchersDescription:
              "Para recuperar todos los códigos que se han distribuido a amigos, incluidos los cupones posteriores a la recomendación.",
            friendVouchersRecommendedRange: "Rango recomendado de 90 días",
            optOuts: "Cancelación de suscripciones",
            optOutsDescription:
              "Una lista de todos los usuarios que se han dado de baja de los correos electrónicos.",
            optOutsRecommendedRange: "Rango recomendado de 1 año",
            fulfilments: "Recompensas",
            fulfilmentsDescription:
              "Para recuperar todas las recompensas que se han enviado en el intervalo de tiempo especificado.",
            fulfilmentsRecommendedRange: "Rango recomendado de 90 días",
            rewardChoiceFulfilments: "Estado de recompensas",
            rewardChoiceFulfilmentsDescription:
              "Para recuperar todas las recompensas que se han enviado en el intervalo de tiempo especificado.",
            rewardChoiceFulfilmentsRecommendedRange: "Rango recomendado de 90 días",
            rewardStreamLegacyReferralData: "Datos de referencia heredados de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Una lista de recomendaciones (similar al informe de quién refirió a quién) con un formato que se asemeja mucho a lo que estaba disponible en RewardStream.",
            topReferrers: "Recomendadores Top",
            topReferrersDescription:
              "Una lista de los 100 principales recomendadores de la campaña, ordenados por número de recomendación..",
            topReferrersRecommendedRange: "Rango recomendado de 1 año",
            whoReferredWhom: "Quién recomendó a quién",
            whoReferredWhomDescription:
              "Recupera una lista de todas las recomendaciones, el estado de la recomendación, la fecha en que se actualizaron los registros por última vez y si la recomendación fue a través del píxel o un cupón.",
            whoReferredWhomRecommendedRange: "Rango recomendado de 90 días",
          },
          reward: {
            allDistributedVouchers: "Cupones distribuídos",
            allDistributedVouchersDescription:
              "Una lista de todos los cupones distribuidos de esta campaña..",
            allDistributedVouchersRecommendedRange: "Rango recomendado de 1 año",
            customerSignups: "Registros de clientes",
            customerSignupsDescription:
              "Una lista completa de todos los clientes que se han registrado para convertirse en recomendadores, incluye detalles de suscripción de marketing, compartir URL y cualquier campo personalizado..",
            customerSignupsRecommendedRange: "Rango recomendado de 1 año",
            friends: "Amigos",
            friendsDescription:
              "Todos los amigos que completaron sus datos en la página de inicio de amigos (si corresponde), además de los amigos que realizaron transacciones en su sitio a través de una recomendación.",
            friendsRecommendedRange: "Rango recomendado de 90 días",
            friendVouchers: "Cupones de amigos",
            friendVouchersDescription:
              "Para recuperar todos los códigos que se han distribuido a amigos, incluidos los cupones posteriores a la recomendación.",
            friendVouchersRecommendedRange: "Rango recomendado de 90 días",
            optOuts: "Cancelación de suscripciones",
            optOutsDescription:
              "Una lista de todos los usuarios que se han dado de baja de los correos electrónicos.",
            optOutsRecommendedRange: "Rango recomendado de 1 año",
            fulfilments: "Recompensas",
            fulfilmentsDescription:
              "Para recuperar todas las recompensas que se han enviado en el intervalo de tiempo especificado.",
            fulfilmentsRecommendedRange: "Rango recomendado de 90 días",
            rewardStreamLegacyReferralData: "Datos de referencia heredados de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Una lista de recomendaciones (similar al informe de quién refirió a quién) con un formato que se asemeja mucho a lo que estaba disponible en RewardStream.",
            topReferrers: "Recomendadores Top",
            topReferrersDescription:
              "Una lista de los 100 principales recomendadores de la campaña, ordenados por número de recomendación..",
            topReferrersRecommendedRange: "Rango recomendado de 1 año",
            whoReferredWhom: "Quién recomendó a quién",
            whoReferredWhomDescription:
              "Recupera una lista de todas las recomendaciones, el estado de la recomendación, la fecha en que se actualizaron los registros por última vez y si la recomendación fue a través del píxel o un cupón.",
            whoReferredWhomRecommendedRange: "Rango recomendado de 90 días",
          },
        },
        selectTitle: "Campaña",
      },
    },
    rewards: {
      header: "Recompensas",
      tableHeaders: {
        address: "Direccion",
        dateEarned: "Ganado el",
        item: "Articulo",
        source: "Fuente",
        status: "Estado",
      },
      actions: {
        claimSubmitted: "Reclamación enviada",
        clickToClaimOption: "Click para reclamar",
        errorRewardEmailResenMessage:
          "Hubo un problema al enviar el correo electrónico para esta recompensa",
        errorSubmittedMessage: "Hubo un problema al actualizar esta reclamación.",
        resentOption: "Reenviar correo electrónico de recompensa",
        successRewardEmailResentMessage: "Reenvío de correo electrónico de recompensa",
        successSubmittedMessage: "Reclamación enviada con éxito",
      },
    },
    statuses: {
      cancelled: "cancelado",
      complete: "completado",
      failed: "fallido",
      fulfilled: "realizado",
      pending: "pendiente",
      processed: "procesado",
      processing: "procesando",
      redeemed: "redimido",
      waiting: "en espera",
      expired: "expirado",
      paused: "pausado",
    },
    subscriptions: {
      header: "Suscripciones",
      tableHeaders: {
        activationDate: "Fecha de activación",
        identifier: "Identificador",
        plan: "Plan",
        status: "Estado",
      },
    },
    partners: {
      header: "Socios",
      tableTotal: "Total",
      tableHeader: {
        businessName: "Nombre comercial o marca del socio",
        email: "Correo electrónico",
        contactName: "Nombre",
      },
      programPerformance: {
        header: "Rendimiento",
        info: "Este informe muestra el rendimiento de todos tus Socios.",
        tableHeaders: {
          month: "Mes",
          visits: "Visitas",
          created: "Creado",
          confirmed: "Confirmado",
          cancelled: "Cancelado",
          rewardsConfirmed: "Recompensas obtenidas",
        },
        tooltips: {
          visits: "Cuántas personas han visitado la página de destino de tu socio",
          created: "Cuántas recomendaciones se crearon a través de tus socios",
          confirmed: "Cuántas recomendaciones se confirmaron a través de tus socios",
          cancelled: "Cuántas recomendaciones se han cancelado",
        },
        partnerFilter: {
          selectAll: "Todos los socios",
        },
        downloadCsvButton: {
          loading: "Generando, por favor espera...",
          text: "Descargar Informe (CSV)",
        },
      },
    },
    addPartner: {
      prompt: "Agregar Socio",
      modal: {
        successMessage: "Socio creado exitosamente",
      },
    },
    partnerProfile: {
      profile: "Perfil",
      createFormIntro: "Agregar detalles del socio",
      editFormIntro: "Editar información del socio",
      audienceInfo:
        "Esta información te ayuda a entender el tamaño de la audiencia/contactos del socio",
      formCampaignInfo: "Elige en qué campañas quieres que participe el socio",
      contentInfo:
        "Aquí es donde puedes personalizar el contenido en la página de perfil del socio",
      rewardsInfo: "Elige qué recompensas están disponibles para el socio y su audiencia",
      campaignsInfo: "Consulta a qué campaña(s) pertenece el socio",
      editProfile: {
        prompt: "Editar perfil",
      },
      lastSeen: "Última interacción",
      dateEnrolled: "Fecha de inscripción",
      contactName: "Nombre",
      audience: "Audiencia del socio",
      displayName: "Nombre de visualización del socio",
      displayNameTooltip:
        "Así es como se mostrará el nombre del negocio o marca del socio en su página de perfil",
      offerHeader: "Título de la página de perfil del socio",
      offerHeaderTooltip: "Este es el título que aparecerá en la página de perfil del socio",
      quote: "Cita del socio",
      quoteTooltip: "Esta es la cita que aparecerá en la página de perfil del socio",
      assets: "Contenido del socio",
      informationContent: "Contenido de la página de perfil",
      assetsDescription:
        "Completa la página de perfil del socio añadiendo el texto e imágenes requeridos",
      bannerImage: "Imagen de banner",
      addAssets: {
        prompt: "Añadir contenido",
        editPrompt: "Editar contenido",
        sectionOneHeading: "Copia de la página de perfil del socio",
        sectionOneDescription:
          "Por favor, añade o edita el contenido de la página de perfil del socio",
        sectionTwoHeading: "Imágenes",
        sectionTwoDescription:
          "Por favor, sube un logotipo e imagen de banner para la página de perfil del socio",
        logoImporterHeader: "Sube el logotipo del socio",
        logoImporterInfo: "Recomendaciones de dimensiones: 48 x 48 Píxeles",
        bannerImporterHeader: "Sube la imagen de banner del socio",
        bannerImporterInfo: "Recomendaciones de dimensiones: 350 x 415 Píxeles",
        importerButtonText: "Buscar archivos",
        successTitle: "Su publicación se ha realizado con éxito",
        successMessage:
          "Ahora puede ver y editar su perfil o explorar otras de nuestras increíbles funciones.",
        congratulations: "¡Enhorabuena!",
        quotePlaceholder: "Por favor, introduce una cita del socio",
        offerHeaderPlaceholder:
          "Por favor, proporciona el título para la página de perfil del socio",
      },
      activate: "Activar",
      campaigns: "Campaña(s) activa(s)",
      campaignDetail: "Detalle de la campaña",
      campaignName: "Nombre de la campaña",
      campaignDateRegistered: "Fecha de registro del socio",
      campaignDashboardUrlText: "Enlace al panel de control del socio",
      campaignShareUrlText: "Enlace único de oferta del socio",
      rewards: {
        title: "Premios y recompensas",
        setRewards: "Establecer recompensas",
        editRewards: "Editar recompensas",
        errorLoadingRewards: "Error al cargar recompensas, por favor actualiza la página.",
        instructionsTitle: "Gestionar recompensas",
        instructions: "Añadir, eliminar, o modificar recompensas",
        partnerReward: "Recompensa para el socio",
        audienceReward: "Recompensa para la audiencia",
        unconfiguredWarning:
          "Todas las opciones de recompensa están disponibles por defecto hasta que se realice una selección",
        form: {
          partnerRewardLabel: "Recompensa para el socio",
          audienceRewardLabel: "Recompensa para la audiencia",
          rewardPlaceholder: "Seleccionar recompensa(s)",
        },
      },
      errorLoadingText: "Hubo un problema al cargar los datos del socio",
      loadingText: "Esta página tiene muchas recomendaciones y puede tardar un poco en cargar",
      performance: "Rendimiento",
      performanceSubHeading: "Este informe muestra el rendimiento del socio",
      downloadPerformanceCsv: "Descargar Informe (CSV)",
    },
    userProfile: {
      confirmAlreadyExistingFriend: {
        content:
          "Confirme que desea realizar un seguimiento de una nueva recomendación en relación con este amigo existente",
      },
      createReferralButtonText: "Crear recomendacion",
      createReferralModal: {
        title: "Create referral",
        friendExists: "Este amigo ya existe",
      },
      errorLoadingText: "Hubo un problema al cargar los datos del usuario",
      editDetailsText: "Editar detalles",
      loadingText: "Esta página tiene muchas recomendaciones y puede tardar un poco en cargar",
      identifier: "Identificador",
      issueNewRewardText: "Enviar una nueva recompensa",
      issueNewRewardModal: {
        campaignText: "Campaña",
        errorMessage: "Hubo un problema al crear esta recompensa, inténtalo otra vez más tarde",
        errorLimitMessage:
          "Has alcanzado el límite de recompensas que puedes emitir durante un período de 24h",
        prompt: "¿Estás seguro de que quieres emitir esta recompensa?",
        recipientText: "Recipiente",
        rewardInfo: {
          isCustomer:
            "Si esta campaña recompensa al amigo que fue recomendado, es posible que también se le deba otorgar una recompensa.",
          isExternalUser:
            "Si esta campaña recompensa al recomendador que hizo la recomendación, es posible que también se le deba otorgar una recompensa.",
        },
        rewardTypes: {
          archived: "Archivado",
          inactive: "Inactivo",
        },
        rewardText: "Recompensa",
        subtitle: "Solo se pueden emitir 10 recompensas manualmente por día",
        successMessage: "Recompensa emitida con éxito a",
        title: "Emitir recompensa",
      },
      firstSeen: "​​visto por primera vez el",
      lastSeen: "visto por última vez el",
      shareURL: "Compartir URL",
      successReferralCreationMessage: "Referral created successfully",
      unsubscribeFromEmailsText: "Cancelar la suscripción de correos electrónicos",
      unsubscribeModal: {
        closeModalText: "No, Cancelar",
        errorMessage: "Hubo un problema al actualizar este usuario",
        prompt: "Si, cancelar la suscripción",
        successMessage: "dado de baja con éxito",
        title:
          "¿Está seguro de que desea cancelar la suscripción de {{fullName}} de todos los correos electrónicos?",
      },
    },
    referrerProfile: {
      referrerInfoCard: {
        rewardsEarned: "Recompensas obtenidas",
      },
    },
    userResults: {
      noResultMessage:
        "Lo sentimos, encontramos <strong>0 resultados</strong> para <strong>{{searchTerm}}</strong>. Intenta una búsqueda diferente.",
      tableHeader: {
        name: "Nombre",
        email: "email",
        identifier: "Identificador",
        type: "tipo",
        referrals: "recomendaciones",
      },
      userType: {
        friend: "amigo",
        referrer: "recomendador",
      },
    },
    users: {
      navigation: {
        bulkActions: "Acciones masivas",
        customerManagement: "Gestión de clientes",
        partnerManagement: "Gestión de socios",
      },
    },
    userSearch: {
      addReferrer: {
        prompt: "Añadir recomendador",
        modal: {
          successMessage: "Recomendación creada con éxito",
          form: {
            selectCampaignText: "Seleccionar campaña",
            emailComplianceLabelText:
              "Marca esta casilla para confirmar que este remitente confirma que solo envía mensajes de recomendación a personas con las que tiene una relación personal o familiar.",
            marketingOptInLabelText:
              "Marque esta casilla para confirmar que tiene el consentimiento de este remitente para optar a Marketing",
            termAndConditionsLabelText:
              "Marque esta casilla para confirmar que este remitente aceptó los Términos y condiciones",
          },
        },
      },
      searchResult: "{{count}} resultados",
      searchResult_one: "{{count}} resultadis",
      searchInstructionPrompt:
        "Busca el nombre del cliente, el correo electrónico, el ID del pedido o cualquier campo personalizado que hayas configurado.",
    },
    vouchers: {
      header: "Cupones",
      tableHeaders: {
        manuallyIssued: "Enviado manualmente",
        obtainedThrough: "Obtenido a través de",
        redeemed: "¿Vinculado?",
        redeemedBy: "Redimido por",
        voucher: "Cupon",
      },
    },
    paragon: {
      integrations: "Integraciones",
      connectionError: "Hubo un problema al conectarse a este servicio.",
      connected: "Conectado",
      notConnected: "No conectado",
      connectedDescription:
        "Gestione su campaña de forma más eficaz a través de los ajustes de integración.",
      notConnectedDescription:
        "Conecte y sincronice sus cuentas, contactos y clientes potenciales de {{integrationName}}.",
      connect: "Conectar",
      settings: "Configuración de integración",
    },
  },
};
