import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import MetricColumns from "components/reports/overview/metrics/MetricColumns";
import Totals from "components/reports/overview/totals/Totals";

import "./overview.scss";

const Overview = ({
  statsStartingDate,
  stats,
  totalRegisteredCustomers,
  totalConfirmedReferrals,
  timezoneOffset,
  selectedEndDate,
  selectedStartDate,
}) => {
  const { t } = useTranslation();
  const firstLegendText = t("reporting.dashboard.overview.legendText.first");
  const secondLegendText = t("reporting.dashboard.overview.legendText.second");
  const totalConfirmedReferralsText = t("reporting.dashboard.overview.totalConfirmedReferrals");
  const totalRegisteredReferrersText = t("reporting.dashboard.overview.totalRegisteredReferrers");

  return (
    <>
      <div className="overview__header">
        <div className="overview__legend">
          <span>{firstLegendText}</span>
          <span>{secondLegendText}</span>
        </div>
      </div>
      <div className="overview__inner">
        <Totals
          text={totalRegisteredReferrersText}
          value={totalRegisteredCustomers}
          date={statsStartingDate}
          timezoneOffset={timezoneOffset}
        />
        <MetricColumns
          stats={stats}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          timezoneOffset={timezoneOffset}
        />
        <Totals
          text={totalConfirmedReferralsText}
          value={totalConfirmedReferrals}
          date={statsStartingDate}
          timezoneOffset={timezoneOffset}
        />
      </div>
    </>
  );
};

Overview.propTypes = {
  statsStartingDate: PropTypes.number.isRequired,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  totalRegisteredCustomers: PropTypes.number.isRequired,
  totalConfirmedReferrals: PropTypes.number.isRequired,
  timezoneOffset: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.number.isRequired,
  selectedStartDate: PropTypes.number.isRequired,
};

export default Overview;
