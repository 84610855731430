import Status from "models/Status";

export default class Fulfilment {
  constructor(data) {
    this.id = data.id;
    this.createdAt = data.created_at;
    this.name = data.name;
    this.source = data.source;
    this.sends_pending_email = data.sends_pending_email;
    this.status = new Status(data.status);
    this.canExpire = data.can_expire;
    this.expiryDate = data.expiry_date;
  }

  canResendEmail() {
    return this.status.isFulfilled() || (this.sends_pending_email && this.status.isPending());
  }

  canUpdateExpiryDate() {
    return this.canExpire && !this.status.isExpired() && !this.status.isFulfilled();
  }

  canUnexpire() {
    return this.status.isExpired();
  }

  showExpiryDate() {
    return this.canExpire || this.canUnexpire;
  }

  hasActions() {
    return this.canResendEmail() || this.canUpdateExpiryDate() || this.canUnexpire();
  }
}
