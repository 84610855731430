import React, { Component } from "react";

import BaseUser from "models/BaseUser";
import Market from "models/Market";
import PropTypes from "prop-types";

import CisUserCampaign from "components/customer_lookup/cis_user/cis_user_campaigns/CisUserCampaign";
import CisUserCampaignValidator from "components/customer_lookup/cis_user/cis_user_campaigns/cis_user_campaign_validator/CisUserCampaignValidator";
import UserCampaignCustomFieldEditor from "components/customer_lookup/user/user_campaigns/user_campaign_custom_field_editor/UserCampaignCustomFieldEditor";
import "components/customer_lookup/user/user_campaigns/user_campaigns.scss";

class CisUserCampaigns extends Component {
  state = {
    selectedCampaign: null,
    selectedCustomFieldLabel: null,
    toggleEditIdModal: false,
    toggleValidatorModal: false,
  };

  handleIdentifierEdit = (sc, label) => {
    const { toggleEditIdModal } = this.state;
    this.setState({
      selectedCampaign: sc,
      selectedCustomFieldLabel: label,
      toggleEditIdModal: !toggleEditIdModal,
    });
  };

  handleRevalidate = () => {
    const { toggleValidatorModal } = this.state;
    this.setState({ toggleValidatorModal: !toggleValidatorModal });
  };

  handleUserCampaignEditorSave = async () => {
    const { reloadView } = this.props;
    this.setState({ toggleEditIdModal: false });
    this.setState({ toggleValidatorModal: true });

    await reloadView();
  };

  sendForRevalidation = async () => {
    const { reloadView } = this.props;

    this.setState({ toggleValidatorModal: false });
    await reloadView();
  };

  render() {
    const { user, selectedMarket } = this.props;
    const { toggleValidatorModal, selectedCampaign, toggleEditIdModal, selectedCustomFieldLabel } =
      this.state;

    return (
      <div className="c-campaign-referrals">
        {user.campaignParticipations.map((cp) => (
          <CisUserCampaign
            key={cp.campaign.id}
            selectedMarket={selectedMarket}
            campaign={cp.campaign}
            joinedAt={cp.joinedAt}
            user={user}
            handleIdentifierEdit={(c, label) => this.handleIdentifierEdit(c, label)}
            handleRevalidate={this.handleRevalidate}
          />
        ))}

        <CisUserCampaignValidator
          toggleModalState={toggleValidatorModal}
          handleToggleClose={() => this.setState({ toggleValidatorModal: !toggleValidatorModal })}
          handleToggleSave={this.sendForRevalidation}
          selectedMarket={selectedMarket}
          user={user}
        />

        {selectedCampaign && selectedCustomFieldLabel && (
          <UserCampaignCustomFieldEditor
            selectedCampaign={selectedCampaign}
            selectedMarket={selectedMarket}
            toggleModalState={toggleEditIdModal}
            handleToggleClose={() => this.setState({ toggleEditIdModal: !toggleEditIdModal })}
            handleToggleSave={this.handleUserCampaignEditorSave}
            user={user}
            customFieldLabel={selectedCustomFieldLabel}
          />
        )}
      </div>
    );
  }
}

CisUserCampaigns.propTypes = {
  user: PropTypes.instanceOf(BaseUser).isRequired,
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  reloadView: PropTypes.func.isRequired,
};

export default CisUserCampaigns;
