import React, { useReducer, useState } from "react";

import { useTranslation } from "react-i18next";

import Modal from "components/modals/Modal";

import { defaultFormData, formReducer, noRewardCategoryString } from "../user_utils";
import ConfirmAlreadyExistingFriend from "./ConfirmAlreadyExistingFriend";
import Form from "./Form";

const CreateReferral = ({
  selectedMarket,
  onReferralCreated,
  referrerEmail,
  campaignParticipations,
  openModal,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [{ form, isLoading, formErrors }, dispatchFormAction] = useReducer(
    formReducer,
    defaultFormData
  );

  const [openForceCreateModal, setOpenForceCreateModal] = useState(false);

  const handleSubmit = async (event, force) => {
    event.preventDefault();
    dispatchFormAction({ type: "submit" });

    const handleFriendOrderedPreviously = (e) => {
      if (!Array.isArray(e.errors.referral)) {
        return false;
      }

      if (e.errors.referral.length !== 1) {
        return false;
      }

      if (e.errors.referral[0].type !== "unsuccessful") {
        return false;
      }

      if (e.errors.referral[0].args[0] !== "ordered_previously") {
        return false;
      }

      setOpenForceCreateModal(true);
      dispatchFormAction({ type: "loaded" });

      return true;
    };

    try {
      const result = await selectedMarket.users.createNewReferral(
        form.campaignId,
        form.firstName,
        form.lastName,
        form.email,
        form.customFields,
        form.termsOptIn,
        form.marketingOptIn,
        referrerEmail,
        form.orderNumber,
        form.rewardCategory === noRewardCategoryString ? undefined : form.rewardCategory,
        force
      );

      onReferralCreated(result.friendId);
    } catch (e) {
      if (handleFriendOrderedPreviously(e)) return;

      const { errors } = e;
      dispatchFormAction({ type: "errorsOccurred", errors });
    }
  };

  const cancelText = t("global.cancel");
  const modalTitle = t("userProfile.createReferralModal.title");
  const modalTitleFriendExists = t("userProfile.createReferralModal.friendExists");

  return (
    <>
      <Modal
        closeModal={closeModal}
        closeModalText={cancelText}
        openModal={openModal && !openForceCreateModal}
        title={modalTitle}
      >
        <Form
          selectedMarket={selectedMarket}
          handleSubmit={(event) => handleSubmit(event, false)}
          isLoading={isLoading}
          formState={form}
          dispatchFormAction={dispatchFormAction}
          formErrors={formErrors}
          campaignParticipations={campaignParticipations}
          referrerEmail={referrerEmail}
        />
      </Modal>

      <Modal
        title={modalTitleFriendExists}
        openModal={openForceCreateModal}
        closeModal={() => setOpenForceCreateModal(false)}
      >
        <ConfirmAlreadyExistingFriend
          isLoading={isLoading}
          handleSubmit={(event) => handleSubmit(event, true)}
        />
      </Modal>
    </>
  );
};

export default CreateReferral;
