import React, { useState } from "react";

import { Button, Card, Link, Modal, Switch } from "bp-ui";
import { fullName } from "helpers/Helpers";
import { t } from "i18next";
import { FaPencil } from "react-icons/fa6";
import { RiUserFill, RiUserVoiceFill } from "react-icons/ri";

import addNotification from "components/notifications/Notifications";

import { FriendCampaign } from "./FriendCampaign";
import { ReferrerCampaign } from "./ReferrerCampaign";

export const UserCard = ({ selectedMarket, user, timezoneOffset, editDetails, reloadData }) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onUnsubscribe = async () => {
    setIsLoading(true);

    try {
      await selectedMarket.users.emailUnsubscribe(user.id);
      addNotification.success(
        t("global.success"),
        `${user.firstName} ${user.lastName} ${t("userProfile.unsubscribeModal.successMessage")}`
      );

      setIsLoading(false);
      setShowEmailModal(false);

      reloadData && reloadData();
    } catch (e) {
      setIsLoading(false);
      addNotification.warning(t("global.error"), t("userProfile.unsubscribeModal.errorMessage"));
    }
  };

  const onSubscribe = async () => {
    setIsLoading(true);

    try {
      await selectedMarket.users.emailSubscribe(user.id);
      addNotification.success(
        t("global.success"),
        `${user.firstName} ${user.lastName} ${t("referrerProfile.subscribeEmail.successMessage")}`
      );

      setIsLoading(false);
      setShowEmailModal(false);

      reloadData && reloadData();
    } catch (e) {
      setIsLoading(false);
      addNotification.warning(t("global.error"), t("referrerProfile.subscribeEmail.errorMessage"));
    }
  };

  if (!user) {
    return <div>{t("referrerProfile.referrerInfoCard.loading")}</div>;
  }

  const campaigns = (user.campaign ? [user.campaign] : user.campaigns) || [];

  const userFullName = fullName(user.firstName, user.lastName);

  return (
    <div className="flex justify-center">
      <Card.Card className="flex-auto max-w-6xl flex flex-col text-sm !pb-3">
        <div className="flex gap-6 mb-6">
          <div className="flex flex-none w-2/6 flex-row">
            <div className="rounded-full bg-dark-blue aspect-square w-14 h-14 p-3.5 mr-10">
              {user.isCustomer() ? (
                <RiUserVoiceFill className="text-white w-full h-full text-center" />
              ) : (
                <RiUserFill className="text-white w-full h-full text-center" />
              )}
            </div>
            <div className="flex flex-auto flex-col">
              <div className="mb-1 font-bold">{t("referrerProfile.referrerInfoCard.name")}</div>
              <div className="text-dark-blue text-xl font-bold break-all">{`${userFullName}`}</div>
            </div>
          </div>
          <div className="flex flex-auto flex-col">
            <div className="mb-1 font-bold">
              {t("referrerProfile.referrerInfoCard.emailAddress")}
            </div>
            <div className="text-dark-blue text-xl font-bold break-all">{user.email}</div>
          </div>

          {editDetails && (
            <div className="flex flex-none items-end pb-1 text-base">
              <div className="flex items-center">
                <FaPencil className="mr-2 w-4 h-4 text-mid-blue" />
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    editDetails && editDetails();
                  }}
                >
                  {t("referrerProfile.editDetailsButton")}
                </Link>
              </div>
            </div>
          )}
        </div>

        <Card.HeroRow>
          <Switch
            label={t("referrerProfile.referrerInfoCard.emailStatus")}
            value={user.subscribed}
            onChange={() => {}}
            onClick={(e) => {
              e.preventDefault();
              setShowEmailModal(true);
            }}
          />
          <span className="ml-3 font-normal">
            {user.subscribed
              ? t("referrerProfile.referrerInfoCard.emailSubscribed")
              : t("referrerProfile.referrerInfoCard.emailUnsubscribed")}
          </span>
        </Card.HeroRow>

        {campaigns.map((campaign, i) =>
          user.isCustomer() ? (
            <ReferrerCampaign
              key={i}
              selectedMarket={selectedMarket}
              campaign={campaign}
              user={user}
              timezoneOffset={timezoneOffset}
              reloadData={reloadData}
            />
          ) : (
            <FriendCampaign
              key={i}
              selectedMarket={selectedMarket}
              campaign={campaign}
              user={user}
              timezoneOffset={timezoneOffset}
              referrals={user.referrals}
              reloadData={reloadData}
            />
          )
        )}
      </Card.Card>

      <Modal
        className="max-w-1/3"
        onClose={() => setShowEmailModal(false)}
        open={showEmailModal}
        title={
          user.subscribed
            ? t("referrerProfile.unsubscribeModal.title")
            : t("referrerProfile.subscribeModal.title")
        }
      >
        <p className="text-xl">
          {user.subscribed
            ? t("referrerProfile.unsubscribeModal.subtitle", {
                fullName: userFullName,
              })
            : t("referrerProfile.subscribeModal.subtitle", {
                fullName: userFullName,
              })}
        </p>
        <div className="flex gap-3 mt-10">
          <Button className="flex-1" secondary={true} onClick={() => setShowEmailModal(false)}>
            {user.subscribed
              ? t("referrerProfile.unsubscribeModal.closeText")
              : t("referrerProfile.subscribeModal.closeText")}
          </Button>
          <Button
            className="flex-1"
            onClick={user.subscribed ? onUnsubscribe : onSubscribe}
            loading={isLoading}
          >
            {user.subscribed
              ? t("referrerProfile.unsubscribeModal.prompt")
              : t("referrerProfile.subscribeModal.prompt")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
