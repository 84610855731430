import React from "react";

import BaseUser from "models/BaseUser";
import Campaign from "models/Campaign";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";

const CisIdentifierField = ({
  user,
  campaign,
  campaignReferral,
  handleIdentifierEdit,
  handleRevalidate,
}) => {
  const { t } = useTranslation();

  const addText = t("global.add");
  const editText = t("global.edit");
  const missingCustomFieldText = t("campaign.missingCustomFieldText");
  const revalidateButtonText = t("campaign.revalidateButtonText");
  const { label } = campaign.customFieldFor(user);

  return (
    <div className="cis-account">
      <span className="cis-account__header">{label}</span>
      <div>
        {campaignReferral.customField.value ? (
          <>
            <span className="u-inline-2">{campaignReferral.customField.value}</span>
            <Button
              appearance="link"
              onClick={() => handleIdentifierEdit(campaign, label)}
              className="u-inline-2"
            >
              {editText}
            </Button>
            <Button appearance="link" onClick={handleRevalidate}>
              {revalidateButtonText}
            </Button>
          </>
        ) : (
          <>
            <span className="u-inline-2 c-campaign-referrals__missing-custom-field">
              {missingCustomFieldText}
            </span>
            <Button
              appearance="link"
              onClick={() => handleIdentifierEdit(campaign, label)}
              className="u-inline-2"
            >
              {addText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

CisIdentifierField.propTypes = {
  user: PropTypes.instanceOf(BaseUser).isRequired,
  campaign: PropTypes.instanceOf(Campaign).isRequired,
  campaignReferral: PropTypes.shape({
    referringFullName: PropTypes.string,
    referringId: PropTypes.number,
    campaignId: PropTypes.string,
    dateRegistered: PropTypes.number,
    campaignName: PropTypes.string,
    customField: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }).isRequired,
  handleIdentifierEdit: PropTypes.func.isRequired,
  handleRevalidate: PropTypes.func.isRequired,
};

export default CisIdentifierField;
