import React, { useCallback, useContext, useState } from "react";

import { Permissions } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom-v5-compat";

import CreateReferrer from "components/customer_lookup/create_referrer/CreateReferrer";
import { SearchContext } from "components/customer_lookup/search/SearchWrapper";
import UserResults from "components/customer_lookup/search/user_results/UserResults";
import Button from "components/forms/button/Button";
import SearchBar from "components/forms/search_bar/SearchBar";
import Modal from "components/modals/Modal";
import addNotification from "components/notifications/Notifications";
import VisibleWith from "components/visibleWith/VisibleWith";

import "./user_search.scss";

const UserSearch = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [createCustomerModal, setCreateCustomerModal] = useState(false);
  const { query, results, hasSearched, update } = useContext(SearchContext);
  const { createReferrer } = Permissions;

  const navigate = useNavigate();

  const successNotificationTitle = t("global.success");

  const cancelText = t("global.cancel");
  const addReferrerPrompt = t("userSearch.addReferrer.prompt");
  const addReferrerModalSuccessMessage = t("userSearch.addReferrer.modal.successMessage");
  const searchInstructionPrompt = t("userSearch.searchInstructionPrompt");

  const handleQueryChanged = (newQuery) => {
    update({ query: newQuery, hasSearched: false });
  };

  const handleSearchSubmitted = async () => {
    setIsLoading(true);
    const newResults = await selectedMarket.users.search(query);

    update({ query, results: newResults, hasSearched: true });
    setIsLoading(false);
  };

  const toggleCreateCustomerModal = useCallback(() => {
    setCreateCustomerModal(!createCustomerModal);
  }, [createCustomerModal]);

  const onReferrerCreated = (newReferrerId) => {
    toggleCreateCustomerModal();
    addNotification.success(successNotificationTitle, addReferrerModalSuccessMessage);
    navigate(`/${selectedMarket.subdomain}/users/search/${newReferrerId}`);
  };

  return (
    <div className="c-search">
      <VisibleWith actions={[createReferrer]}>
        <div className="c-search__action-bar">
          <Button
            size="tiny"
            appearance="secondary"
            onClick={toggleCreateCustomerModal}
            icon={<FaPlus />}
          >
            {addReferrerPrompt}
          </Button>
        </div>
      </VisibleWith>

      <div className="search-wrapper">
        <div className="card">
          <SearchBar
            searchTerm={query}
            action={handleSearchSubmitted}
            handleChange={handleQueryChanged}
            hasSearched={hasSearched}
            resultsCount={results.length}
            loading={isLoading}
          />
          {hasSearched === false && <p className="search-instruction">{searchInstructionPrompt}</p>}

          <UserResults
            searchResults={results}
            hasSearched={hasSearched}
            searchTerm={query}
            selectedMarket={selectedMarket}
          />
        </div>
      </div>

      <Modal
        closeModal={toggleCreateCustomerModal}
        closeModalText={cancelText}
        openModal={createCustomerModal}
        title={addReferrerPrompt}
      >
        <CreateReferrer selectedMarket={selectedMarket} onReferrerCreated={onReferrerCreated} />
      </Modal>
    </div>
  );
};

UserSearch.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default UserSearch;
