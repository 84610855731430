import React from "react";

import { Permissions } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom-v5-compat";

import SubNavigation from "components/sub_navigation_new/SubNavigation";

const BulkActions = ({ selectedMarket }) => {
  const { t } = useTranslation();

  const {
    bulkActionTransactions,
    bulkActionReferrals,
    bulkActionSignups,
    bulkActionCustomers,
    bulkActionForgetUsers,
  } = Permissions;

  const availableLinks = [
    {
      text: t("bulkActions.navigation.transactionTracking"),
      path: "/users/bulk-actions/transaction-tracking",
      permissions: [bulkActionTransactions],
    },
    {
      text: t("bulkActions.navigation.referralManagement"),
      path: "/users/bulk-actions/referral-management",
      permissions: [bulkActionReferrals],
    },
    {
      text: t("bulkActions.navigation.signups"),
      path: "/users/bulk-actions/signups",
      permissions: [bulkActionSignups],
    },
    {
      text: t("bulkActions.navigation.addCustomers"),
      path: "/users/bulk-actions/add-customers",
      permissions: [bulkActionCustomers],
    },
    {
      text: t("bulkActions.navigation.forgetUsers"),
      path: "/users/bulk-actions/forget-users",
      permissions: [bulkActionForgetUsers],
    },
  ];

  return (
    <div>
      <SubNavigation
        subdomain={selectedMarket.subdomain}
        availableLinks={availableLinks}
        isSecondary
      />

      <Outlet />
    </div>
  );
};

BulkActions.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default BulkActions;
