import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const YesNo = ({ value }) => {
  const { t } = useTranslation();
  if (value) {
    return t("global.yes");
  }
  return t("global.no");
};

YesNo.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default YesNo;
