export const defaultCampaignConfigData = {
  displayOptIns: {
    showMarketingOptIn: false,
    showTermsOptIn: false,
  },
  customFields: [],
  rewardTypes: [],
};

export const campaignConfigReducer = (state, action) => {
  switch (action.type) {
    case "resetCampaign":
      return defaultCampaignConfigData;
    case "updateCampaignConfigData":
      return { ...state, ...action.data };
    default:
      throw new Error("Invalid Action Type for campaignConfigReducer");
  }
};

export const defaultFormData = {
  form: {
    campaignId: "",
    firstName: "",
    lastName: "",
    email: "",
    customFields: [],
    orderNumber: "",
    rewardCategory: "",
    termsOptIn: false,
    marketingOptIn: false,
  },
  isLoading: false,
  formErrors: {},
};

const { error } = console;

const customFieldsConfiguredForFriend = (selectedMarket, campaignId) => {
  const selectedCampaign = selectedMarket.campaigns.find((c) => c.id === campaignId);

  let fields = [];

  if (selectedCampaign.friendCustomFieldsConfigured().length > 0) {
    fields = selectedCampaign.friendCustomFieldsConfigured().map((cf) => ({
      key: cf.label,
      value: "",
    }));
  }

  return fields;
};

export const formReducer = (state, action) => {
  switch (action.type) {
    case "updateFormField":
      return {
        ...state,
        form: {
          ...state.form,
          ...action.fieldKeyValuePair,
        },
      };
    case "updateCampaignId": {
      return {
        ...state,
        form: {
          ...state.form,
          ...{
            campaignId: action.campaignId,
            customFields: customFieldsConfiguredForFriend(action.selectedMarket, action.campaignId),
          },
        },
      };
    }
    case "updateRewardCategory":
      return {
        ...state,
        form: {
          ...state.form,
          ...{ rewardCategory: action.rewardCategory },
        },
      };
    case "updateCustomField": {
      const fields = [...state.form.customFields];

      fields.find((cf) => cf.key === action.customField.key).value = action.customField.value;

      return {
        ...state,
        form: {
          ...state.form,
          customFields: fields,
        },
      };
    }
    case "submit":
      return { ...state, isLoading: true, formErrors: {} };
    case "loaded":
      return { ...state, isLoading: false };
    case "errorsOccurred":
      return { ...state, isLoading: false, formErrors: action.errors };
    default:
      error(`Invalid Action Type "${action.type} from action: `, action);
      throw new Error("Invalid Action Type for formReducer");
  }
};

export const getRewardCategoryList = (rewardTypes, noRewardCategory) => {
  const activeRewardTypes = rewardTypes.filter((rt) => !rt.archived);
  const uniqRewardCategories = [...new Set(activeRewardTypes.map((rt) => rt.rewardCategory))];
  const filteredRewardCategories = uniqRewardCategories.filter(Boolean);

  if (!filteredRewardCategories.length) return [];

  const hasNulls = uniqRewardCategories.length !== filteredRewardCategories.length;

  const rewardCategoryOptions = filteredRewardCategories.map((rc) => ({
    value: rc,
    name: rc,
  }));

  if (hasNulls) {
    rewardCategoryOptions.push(noRewardCategory);
  }
  return rewardCategoryOptions;
};

export const noRewardCategoryString = "no reward category";
