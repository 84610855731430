import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Permissions } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import addNotification from "components/notifications/Notifications";
import { PartnerProfileType } from "components/partner_management/PartnerPropTypes";
import ProfileFormModal from "components/partner_management/profile_form/profile_form_modal/ProfileFormModal";
import VisibleWith from "components/visibleWith/VisibleWith";

const EditPartner = ({ selectedMarket, partnerId, partnerProfile, reloadPartner }) => {
  const { t } = useTranslation();
  const { editPartner } = Permissions;
  const initialFormState = useMemo(
    () => ({
      fields: {
        ...partnerProfile,
      },
      formErrors: {
        creation: [],
        business_name: [],
        first_name: [],
        last_name: [],
        email: [],
        mobile: [],
        mobile_country_code: [],
        job_title: [],
        industry_sector: [],
        locale: [],
        business_country: [],
        website: [],
        twitter_handle: [],
        instagram_handle: [],
        facebook_handle: [],
        linkedin_handle: [],
        youtube_handle: [],
        snapchat_handle: [],
        signup_reason: [],
        audience_size: [],
        audience_location: [],
      },
    }),
    [partnerProfile]
  );

  const [form, setForm] = useState(initialFormState);

  const setFormFields = useCallback((key, value) => {
    setForm((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [key]: value,
      },
    }));
  }, []);

  const setFormErrors = (errors) => {
    setForm((prevState) => ({
      ...prevState,
      formErrors: errors,
    }));
  };

  const resetForm = () => {
    reloadPartner();
    setForm(initialFormState);
  };

  useEffect(() => {
    setForm(initialFormState);
  }, [partnerProfile]);

  const [openProfileFormModal, setOpenProfileFormModal] = useState(false);
  const toggleProfileFormModal = () => setOpenProfileFormModal((value) => !value);

  const validateEmail = async (e) => {
    e.preventDefault();
    const email = e.target.value;
    if (!email) {
      setFormErrors({ email: [{ type: "filled" }] });
      return;
    }

    // TODO: sc-30965 Add inline format validation for speed
    // TODO: sc-30965 Add a spinner while server response is loading
    try {
      const response = await selectedMarket.users.validateEmail(email, partnerId);
      setFormErrors({ email: [] });
    } catch (exception) {
      setFormErrors(exception.errors);
    }
  };

  const handleProfileFormSubmit = async () => {
    await selectedMarket.users.updatePartner(
      partnerId,
      form.fields.firstName,
      form.fields.lastName,
      form.fields.email,
      form.fields.mobile,
      form.fields.mobileCountryCode,
      form.fields.locale,
      form.fields.signupReason,
      form.fields.businessName,
      form.fields.jobTitle,
      form.fields.industrySector,
      form.fields.businessCountry,
      form.fields.website,
      form.fields.twitter,
      form.fields.instagram,
      form.fields.facebook,
      form.fields.linkedin,
      form.fields.youtube,
      form.fields.snapchat,
      form.fields.audienceSize,
      form.fields.audienceLocation
    );

    addNotification.success(
      t("partnerProfile.editProfile.successTitle"),
      t("partnerProfile.editProfile.successMessage")
    );
  };

  return (
    <>
      <ProfileFormModal
        selectedMarket={selectedMarket}
        action="Edit"
        form={form}
        setFormFields={setFormFields}
        setFormErrors={setFormErrors}
        resetForm={resetForm}
        openModal={openProfileFormModal}
        toggleModal={toggleProfileFormModal}
        handleFormSubmit={handleProfileFormSubmit}
        validateEmail={validateEmail}
      />

      <VisibleWith actions={[editPartner]}>
        <div>
          <Button size="medium" appearance="primary" onClick={toggleProfileFormModal}>
            {t("partnerProfile.editProfile.prompt")}
          </Button>
        </div>
      </VisibleWith>
    </>
  );
};

EditPartner.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  partnerId: PropTypes.string.isRequired,
  partnerProfile: PartnerProfileType.isRequired,
  reloadPartner: PropTypes.func.isRequired,
};

export default EditPartner;
