import React, { Component } from "react";

import { Download } from "assets/images/CustomIcons";
import FileSaver from "file-saver";
import i18next from "i18next";
import { DateTime } from "luxon";
import Campaign from "models/Campaign";
import Market from "models/Market";
import PropTypes from "prop-types";
import { clearIntervalAsync } from "set-interval-async";
import { setIntervalAsync } from "set-interval-async/dynamic";

import Button from "components/forms/button/Button";
import addNotification from "components/notifications/Notifications";
import CampaignSelector from "components/reports/campaign_selector/CampaignSelector";
import DateRangeSelected from "components/reports/date_picker/date_range_selected/DateRangeSelected";
import Tooltip from "components/tooltip/Tooltip";

import "./top_bar.scss";

class TopBar extends Component {
  state = {
    isLoading: false,
    downloadRetries: 50,
  };

  componentWillUnmount() {
    if (this.downloadInterval) {
      clearIntervalAsync(this.downloadInterval);
    }
  }

  resetDownloadInterval = (interval) => {
    clearIntervalAsync(interval);
    this.setState({ downloadRetries: 50 });
  };

  toggleDownloadingState = (isLoading, downloadError) => {
    this.setState({
      isLoading,
    });

    if (downloadError) {
      addNotification.warning(
        i18next.t("reporting.dashboard.topbar.downloadErrorTitle"),
        i18next.t("reporting.dashboard.topbar.downloadErrorContent")
      );
    }
  };

  buildAndDownloadReport = async () => {
    const { selectedMarket, selectedCampaigns, selectedStartDate, selectedEndDate } = this.props;

    const selectedCampaignIds = selectedCampaigns.map((c) => c.id);
    try {
      const builtReport = await selectedMarket.biReports.build(
        selectedCampaignIds,
        selectedStartDate,
        selectedEndDate
      );

      if (builtReport.ready) {
        const downloadedReport = await selectedMarket.biReports.download(builtReport.id);

        const startDateFormatted = DateTime.fromSeconds(selectedStartDate)
          .setZone("UTC" + selectedMarket.timezone_offset)
          .toFormat("YYYY-MM-DD");
        const endDateFormatted = DateTime.fromSeconds(selectedEndDate)
          .setZone("UTC" + selectedMarket.timezone_offset)
          .toFormat("YYYY-MM-DD");

        FileSaver.saveAs(
          downloadedReport.file,
          `buyapowa-report-${startDateFormatted}-to-${endDateFormatted}.xlsx`
        );

        this.resetDownloadInterval(this.downloadInterval);
        this.toggleDownloadingState(false, false);
        return;
      }
    } catch (e) {
      this.toggleDownloadingState(false, true);
      this.resetDownloadInterval(this.downloadInterval);
    }

    this.setState((prevState) => ({
      downloadRetries: prevState.downloadRetries - 1,
    }));

    const { downloadRetries } = this.state;

    if (downloadRetries === 0) {
      this.toggleDownloadingState(false, true);
      this.resetDownloadInterval(this.downloadInterval);
    }
  };

  downloadSelectedReport = async () => {
    this.toggleDownloadingState(true, false);
    this.downloadInterval = setIntervalAsync(this.buildAndDownloadReport, 1000);
  };

  render() {
    const {
      selectedMarket,
      selectedCampaigns,
      selectedStartDate,
      selectedEndDate,
      campaignFilter = () => true,
    } = this.props;
    const { isLoading } = this.state;

    const startDate = DateTime.fromSeconds(selectedStartDate).setZone(
      "UTC" + selectedMarket.timezone_offset
    );
    const endDate = DateTime.fromSeconds(selectedEndDate).setZone(
      "UTC" + selectedMarket.timezone_offset
    );
    const statsStartingDate = selectedMarket.minStatsStartDate(selectedCampaigns);

    // TODO: sc-33290: Refactor this to a functional component and then fix the dratted date range selector
    return (
      <div className="top-bar">
        <div className="top-bar__actions">
          <div className="u-inline-3">
            <DateRangeSelected
              timezoneOffset={selectedMarket.timezone_offset}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              statsStartingDate={statsStartingDate}
            />
          </div>
          {selectedMarket.visibleCampaigns().length > 1 && (
            <CampaignSelector
              selectedMarket={selectedMarket}
              selectedCampaigns={selectedCampaigns}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              campaignFilter={campaignFilter}
            />
          )}
        </div>
        <div className="top-bar__actions">
          <Tooltip
            content={`${i18next.t("reporting.dashboard.topbar.tooltipContent")} (${startDate.toFormat("L")} to ${endDate.toFormat("L")})`}
          >
            <Button
              appearance="primary"
              size="small"
              icon={<Download />}
              loading={isLoading}
              onClick={this.downloadSelectedReport}
            >
              {isLoading
                ? i18next.t("reporting.dashboard.topbar.buttonDownloadReportLoadingText")
                : i18next.t("reporting.dashboard.topbar.buttonDownloadReportText")}
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  selectedCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(Campaign)).isRequired,
  selectedStartDate: PropTypes.number.isRequired,
  selectedEndDate: PropTypes.number.isRequired,
  campaignFilter: PropTypes.func,
};

export default TopBar;
