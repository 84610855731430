import React from "react";

import PropTypes from "prop-types";
import { FaBullhorn, FaUser } from "react-icons/fa";

import Icon from "components/icon/Icon";

const UserType = ({ type }) =>
  type === "customer" ? <Icon type={FaBullhorn} /> : <Icon type={FaUser} />;

UserType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default UserType;
