export const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
  },
  borderRadius: 10,
  // Used to calculate consistent margin/padding on elements
  baseUnit: 4,
  // The minimum height of the control
  controlHeight: 38,
  // The amount of space between the control and menu */
  menuGutter: 0,
});

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(var(--b-light-blue))",
    boxShadow: "none",
    borderWidth: "2px",
    fontSize: "14px",
    margin: "0px",
    border: state.selectProps.menuIsOpen ? "2px solid #24a7e6" : "2px solid transparent",
    borderTopColor: state.selectProps.menuIsOpen ? "transparent" : "undefined",
    borderTopLeftRadius: state.selectProps.menuIsOpen ? 0 : "undefined",
    borderTopRightRadius: state.selectProps.menuIsOpen ? 0 : "undefined",
    borderTop: state.selectProps.menuIsOpen ? "unset" : "undefined",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "20px",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(var(--b-light-blue))",
    boxShadow: "none",
    transition: "all .1s ease",
    fontSize: "0.875rem",
    borderWidth: "2px",
    borderColor: state.isFocused && state.selectProps.menuIsOpen ? "#24a7e6" : "transparent",
    borderBottomColor:
      state.isFocused && state.selectProps.menuIsOpen ? "transparent" : "undefined",
    borderBottomLeftRadius: state.isFocused && state.selectProps.menuIsOpen ? 0 : "undefined",
    borderBottomRightRadius: state.isFocused && state.selectProps.menuIsOpen ? 0 : "undefined",
    borderBottom: state.isFocused && state.selectProps.menuIsOpen ? "unset" : "undefined",
    ":hover": {
      borderColor: "#24a7e6",
      borderBottomColor: state.selectProps.menuIsOpen ? "transparent" : "undefined",
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : "undefined",
      borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : "undefined",
      borderBottom: state.isFocused && state.selectProps.menuIsOpen ? "unset" : "undefined",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(var(--b-light-blue))",
    borderBottom: "1px solid",
    borderColor: "#9fa7ab",
    fontWeight: state.isSelected ? "bold" : "normal",
    color: "$text-color",
    padding: "10px 0px",
    "&:hover": { color: "#3199CD" },
    "&:active": { backgroundColor: "rgb(var(--b-light-blue))" },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#9fa7ab" : "$text-color",
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: "all .1s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "undefined",
  }),
};
