import React from "react";

import Campaign from "models/Campaign";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";

const IdentifierField = ({ campaign, label, handleIdentifierEdit, existingValue = null }) => {
  const { t } = useTranslation();

  const addText = t("global.add");
  const editText = t("global.edit");
  const missingCustomFieldText = t("campaign.missingCustomFieldText");

  return (
    <div className="cis-account">
      <span className="cis-account__header">{label}</span>
      <div>
        {existingValue ? (
          <>
            <span className="u-inline-2">{existingValue}</span>
            <Button
              appearance="link"
              onClick={() => handleIdentifierEdit(campaign, label)}
              className="u-inline-2"
            >
              {editText}
            </Button>
          </>
        ) : (
          <>
            <span className="u-inline-2 c-campaign-referrals__missing-custom-field">
              {missingCustomFieldText}
            </span>
            <Button
              appearance="link"
              onClick={() => handleIdentifierEdit(campaign, label)}
              className="u-inline-2"
            >
              {addText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

IdentifierField.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
  label: PropTypes.string.isRequired,
  existingValue: PropTypes.string,
  handleIdentifierEdit: PropTypes.func.isRequired,
};

export default IdentifierField;
