import React, { useState } from "react";

import Market from "models/Market";
import PropTypes, { arrayOf, bool, func, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import externalAxios from "services/external_axios/ExternalAxios";

import Button from "components/forms/button/Button";
import Checkbox from "components/forms/checkbox/Checkbox";
import FileSelect from "components/forms/file_select/FileSelect";
import Form from "components/forms/form/Form";

import Select from "../../forms/select/Select";
import "./file_import.scss";

const FileImport = (props) => {
  const { t } = useTranslation();
  const buttonText = t("fileImport.fileSelect.buttonText");
  const infoText = t("fileImport.fileSelect.infoText");
  const {
    selectedMarket,
    importType,
    buttonLabel,
    checkBoxOptionKey = null,
    checkBoxDefault = false,
    checkBoxLabel = "",
    onImportSuccess,
    selectOptions = [],
    selectPlaceholder = t("forms.placeholders.select"),
    selectKey = null,
  } = props;

  const [formState, setFormState] = useState({
    file: "",
    checkbox: checkBoxDefault,
    select: "",
  });
  const [formErrors, setFormErrors] = useState([]);
  const [formMessages, setFormMessages] = useState({});
  const [isLoading, setLoading] = useState(false);

  const combineAndSetFormErrors = (errorObject) => {
    const combinedErrors = Object.values(errorObject).flat();
    setFormErrors(combinedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFormErrors([]);
    setFormMessages({});

    try {
      const uploadUrlResponse = await selectedMarket.imports.generateUploadUrl(importType);

      await selectedMarket.imports.uploadFileToS3(
        externalAxios,
        uploadUrlResponse.presignedUrl,
        formState.file
      );

      let formOptions = {};
      if (checkBoxOptionKey != null) {
        formOptions = { ...formOptions, ...{ [checkBoxOptionKey]: formState.checkbox } };
      }
      if (selectKey != null) {
        formOptions = { ...formOptions, ...{ [selectKey]: formState.select } };
      }

      const fileImportResponse = await selectedMarket.imports.processFile(
        importType,
        uploadUrlResponse.secureFile,
        formOptions
      );

      if (fileImportResponse.messages) {
        setFormMessages(fileImportResponse.messages);
      }

      // Backend processes files asynchronously
      // and the recent one may not be included
      // in the importList right away
      setTimeout(() => {
        onImportSuccess();
        setLoading(false);
      }, 3000);
    } catch (err) {
      combineAndSetFormErrors(err);
      setLoading(false);
    }
  };

  const handleFileSelectChange = (name, file) => {
    setFormState({ ...formState, [name]: file });
  };

  const handleSelectorChange = (item) => {
    setFormState({ ...formState, select: item.value });
  };

  const handleCheckboxChange = (value, checked, name) => {
    setFormState({ ...formState, [name]: checked });
  };

  return (
    <div className="c-file-import">
      <Form onSubmit={handleSubmit} errorKey="fileImport">
        <div className="field-group">
          <FileSelect
            name="file"
            infoText={infoText}
            buttonText={buttonText}
            acceptedFileTypes=".csv"
            id="file_select"
            handleChange={handleFileSelectChange}
            messages={formMessages}
            errors={formErrors}
            errorKey="fileImport"
          />
          {selectKey && (
            <Select
              options={selectOptions}
              handleOnChange={handleSelectorChange}
              title=""
              selectedValue={formState.select}
              selectedPlaceholder={selectPlaceholder}
              buttonAppearance="secondary"
              disabled={selectOptions.length === 0}
            />
          )}
          {checkBoxOptionKey && (
            <Checkbox
              name="checkbox"
              label={checkBoxLabel}
              checked={formState.checkbox}
              value={formState.checkbox}
              handleChange={handleCheckboxChange}
            />
          )}
        </div>
        <Button
          buttonElement="submit"
          appearance="tertiary"
          loading={isLoading}
          disabled={!formState.file || (selectKey != null ? !formState.select : false)}
        >
          {buttonLabel}
        </Button>
      </Form>
    </div>
  );
};

FileImport.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  importType: string.isRequired,
  buttonLabel: string.isRequired,
  checkBoxOptionKey: string,
  checkBoxDefault: bool,
  checkBoxLabel: string,
  onImportSuccess: func.isRequired,
  selectOptions: arrayOf(
    shape({
      value: string.isRequired,
      name: string.isRequired,
    })
  ),
  selectPlaceholder: string,
  selectKey: string,
};

export default FileImport;
