import React from "react";

import { Card } from "../Card";
import { HeroRow } from "../HeroRow/HeroRow";

export const Collapse = ({
  children,
  open,
  footer,
  detail,
  onClick,
  className,
}) => {
  return (
    <details
      className={`w-full ${className ? className : ""}`}
      open={open}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      <summary>{children}</summary>

      <div
        className={`bg-light-grey p-10 -mx-10 flex flex-wrap pl-36 gap-9 shadow-inset`}
      >
        {detail}
      </div>

      {footer && <HeroRow>{footer}</HeroRow>}
    </details>
  );
};

export const Preview = () => {
  return (
    <Card>
      <Collapse open={true} detail={<>Collapse detail content</>}>
        <div className="py-5">Collapse summary content</div>
      </Collapse>
    </Card>
  );
};
