import ReferralFriend from "models/ReferralFriend";
import Reward from "models/Reward";
import Status from "models/Status";

export default class Referral {
  constructor(data) {
    this.id = data.id;
    this.createdAt = data.created_at;
    this.friend = new ReferralFriend(data.friend);
    this.campaignId = data.campaign_id;
    this.status = new Status(data.status);
    this.rewards = data.rewards.map((rewardData) => new Reward(rewardData));
    this.order_number = data.order_number;
  }
}
