export default class BiDownloadStatus {
  constructor(data) {
    this.id = data.id;
    this.ready = data.ready;
    this.url = data.url;
    this.generatedAt = data.generated_at;
    this.startsAt = data.starts_at;
    this.endsAt = data.ends_at;
  }
}
