import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import qs from "query-string";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Button from "components/forms/button/Button";
import "components/reports/date_picker/date_range_selector/presets/preset_link.scss";

const PresetLink = ({
  text,
  presetStartDate,
  presetEndDate,
  customStartDate,
  customEndDate,
  location,
  setPresetRangeStartDate,
  closeDateRangeSelector = null,
  action = null,
}) => {
  const className = ["preset"];

  const newRange = {
    start_date: setPresetRangeStartDate(presetStartDate),
    end_date: Math.floor(presetEndDate.toSeconds()),
  };

  if (
    customStartDate.hasSame(presetStartDate, "day", { useLocaleWeeks: true }) &&
    customEndDate.hasSame(presetEndDate, "day", { useLocaleWeeks: true })
  ) {
    className.push("selected");
  }

  const searchParams = {
    ...newRange,
    campaigns: qs.parse(location.search).campaigns,
  };

  const handleButtonClick = () => {
    action(setPresetRangeStartDate(presetStartDate), Math.floor(presetEndDate.toSeconds()));
    closeDateRangeSelector();
  };

  return !action ? (
    <Link
      className={className.join(" ")}
      to={{ search: `?${qs.stringify(searchParams)}` }}
      onClick={closeDateRangeSelector}
    >
      {text}
    </Link>
  ) : (
    <Button appearance="link" className={className.join(" ")} onClick={handleButtonClick}>
      {text}
    </Button>
  );
};

PresetLink.propTypes = {
  text: PropTypes.string.isRequired,
  presetStartDate: PropTypes.instanceOf(DateTime).isRequired,
  presetEndDate: PropTypes.instanceOf(DateTime).isRequired,
  customStartDate: PropTypes.instanceOf(DateTime).isRequired,
  customEndDate: PropTypes.instanceOf(DateTime).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  setPresetRangeStartDate: PropTypes.func.isRequired,
  closeDateRangeSelector: PropTypes.func,
  action: PropTypes.func,
};

export default withRouter(PresetLink);
