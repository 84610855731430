import React from "react";

import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import Fulfilment from "models/Fulfilment";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DropdownButton from "components/dropdown_button/DropdownButton";
import addNotification from "components/notifications/Notifications";

const UserFulfilmentActions = ({
  fulfilment,
  userId,
  selectedMarket,
  openChangeExpiryModal,
  openUnexpireModal,
  openExpireModal,
}) => {
  const { t } = useTranslation();
  const { currentAdmin } = useAuth();
  const { resendFulfilmentEmail, manageExpiryOperations } = Permissions;

  const errorNotificationTitle = t("global.error");
  const successNotificationTitle = t("global.success");

  const errorRewardEmailResenMessage = t("fulfilments.actions.errorRewardEmailResenMessage");
  const successRewardEmailResentMessage = t("fulfilments.actions.successRewardEmailResentMessage");

  const resentOptionText = t("fulfilments.actions.resentOption");
  const changeExpiryText = t("fulfilments.actions.changeExpiry");
  const expireText = t("fulfilments.actions.expire");
  const unexpireText = t("fulfilments.actions.unexpire");

  const handleResendRewardEmail = async () => {
    try {
      await selectedMarket.users.resendFulfillment(userId, fulfilment.id);
      addNotification.success(successNotificationTitle, successRewardEmailResentMessage);
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorRewardEmailResenMessage);
    }
  };

  const handleChangeExpiryDate = () => {
    openChangeExpiryModal(fulfilment.id);
  };
  const handleUnexpire = () => {
    openUnexpireModal(fulfilment.id);
  };
  const handleExpire = () => {
    openExpireModal(fulfilment.id);
  };

  const options = [];
  if (currentAdmin.can(resendFulfilmentEmail) && fulfilment.canResendEmail()) {
    options.push({ text: resentOptionText, onClick: handleResendRewardEmail });
  }

  if (currentAdmin.can(manageExpiryOperations)) {
    if (fulfilment.canUpdateExpiryDate()) {
      options.push({ text: changeExpiryText, onClick: handleChangeExpiryDate });
      options.push({ text: expireText, onClick: handleExpire });
    }
    if (fulfilment.canUnexpire()) {
      options.push({ text: unexpireText, onClick: handleUnexpire });
    }
  }

  if (options.length === 0) {
    return null;
  }

  return <DropdownButton dropdownList={options} />;
};

UserFulfilmentActions.propTypes = {
  fulfilment: PropTypes.instanceOf(Fulfilment).isRequired,
  userId: PropTypes.string.isRequired,
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  openChangeExpiryModal: PropTypes.func.isRequired,
  openUnexpireModal: PropTypes.func.isRequired,
  openExpireModal: PropTypes.func.isRequired,
};

export default UserFulfilmentActions;
