import React from "react";

import { fullName } from "helpers/Helpers";
import BaseUser from "models/BaseUser";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import UserTypeIcon from "components/customer_lookup/icons/user_type/UserType";
import UserType from "components/customer_lookup/row_atoms/user_type/UserType";
import FormattedDate from "components/formatted_date/FormattedDate";

import "./user-profile.scss";

const UserProfile = ({ user, timezoneOffset }) => {
  const { t } = useTranslation();
  const emailText = t("global.email");
  const userProfileIdentifierText = t("userProfile.identifier");
  const userProfileFirstSeenText = t("userProfile.firstSeen");
  const userProfileLastSeenText = t("userProfile.lastSeen");
  const userProfileShareURLText = t("userProfile.shareURL");

  const anyCampaignHasShareUrl = () => user.campaignParticipations.some((cp) => !!cp.shareUrl);

  return (
    <div className="user-profile card">
      <div className="user-profile__details">
        <div className="user-profile__title u-stack-2">
          <span className="user-profile__title--primary">
            {fullName(user.firstName, user.lastName)}
          </span>
          <span className="user-profile__title--secondary">{user.email}</span>
        </div>

        <div className="tags">
          <UserType icon={<UserTypeIcon type={user.type} />} descriptor={user.displayType()} />
        </div>
      </div>

      <div>
        <div className="user-profile__meta-block">
          <span className="user-profile__meta-header">{emailText}</span>
          <span>{user.email}</span>
        </div>

        {user.customFields && user.customFields.length > 0 && (
          <div className="user-profile__meta-block">
            <span className="user-profile__meta-header">{userProfileIdentifierText}</span>
            {user.customFields.map((customField) => (
              <div
                className="user-profile__identifier"
                key={`${customField.label}${customField.value}`}
              >
                <span className="user-profile__identifier-label">{customField.label}: </span>
                <span className="user-profile__identifier-content">{customField.value}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <div className="user-profile__meta-block">
          <span className="user-profile__meta-header">{userProfileFirstSeenText}</span>
          <span>
            <FormattedDate timestamp={user.firstSeen} timezoneOffset={timezoneOffset} type="long" />
          </span>
        </div>
        <div className="user-profile__meta-block">
          <span className="user-profile__meta-header">{userProfileLastSeenText}</span>
          <span>
            <FormattedDate timestamp={user.lastSeen} timezoneOffset={timezoneOffset} type="long" />
          </span>
        </div>
      </div>

      {anyCampaignHasShareUrl() && (
        <div>
          <div className="user-profile__meta-block">
            <span className="user-profile__meta-header">{userProfileShareURLText}</span>
            {user.campaignParticipations.map((cp) => (
              <span key={cp.campaign.id}>{cp.shareUrl}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

UserProfile.propTypes = {
  user: PropTypes.instanceOf(BaseUser).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default UserProfile;
