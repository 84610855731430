import React from "react";

import qs from "query-string";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import Button from "components/forms/button/Button";
import "components/reports/date_picker/date_range_selector/presets/preset_link.scss";

// TODO: sc-33290: Can we get rid of this HOC pattern withRouter?  It works, but there's a better way.
function withRouter(Component) {
  function ComponentWithRouterProps(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProps;
}

const PresetLink = ({
  text,
  presetStartDate,
  presetEndDate,
  customStartDate,
  customEndDate,
  setPresetRangeStartDate,
  closeDateRangeSelector = null,
  action = null,
}) => {
  const className = ["preset"];

  const newRange = {
    start_date: setPresetRangeStartDate(presetStartDate),
    end_date: Math.floor(presetEndDate.toSeconds()),
  };

  if (
    customStartDate.hasSame(presetStartDate, "day", { useLocaleWeeks: true }) &&
    customEndDate.hasSame(presetEndDate, "day", { useLocaleWeeks: true })
  ) {
    className.push("selected");
  }

  const searchParams = {
    ...newRange,
    campaigns: qs.parse(location.search).campaigns,
  };

  const handleButtonClick = () => {
    action(setPresetRangeStartDate(presetStartDate), Math.floor(presetEndDate.toSeconds()));
    closeDateRangeSelector();
  };

  return !action ? (
    <Link
      className={className.join(" ")}
      to={{ search: `?${qs.stringify(searchParams)}` }}
      onClick={closeDateRangeSelector}
    >
      {text}
    </Link>
  ) : (
    <Button appearance="link" className={className.join(" ")} onClick={handleButtonClick}>
      {text}
    </Button>
  );
};

export default withRouter(PresetLink);
