import React from "react";

import { StatusColours, Statuses } from "../enums.js";

export const InfoPanel = ({ statusType = Statuses.DEFAULT, children }) => {
  return (
    <div
      className={`text-base flex flex-col gap-1 border-0 border-solid border-l-2 border-${StatusColours[statusType]} p-1 pl-5`}
    >
      {children}
    </div>
  );
};

export const Preview = () => {
  return (
    <div className="flex flex-col gap-3">
      {Object.keys(Statuses).map((status) => (
        <InfoPanel statusType={Statuses[status]} key={status}>
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </InfoPanel>
      ))}
    </div>
  );
};
