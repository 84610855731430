import React, { Component } from "react";

import { CloseCross } from "assets/images/CustomIcons";
import classnames from "classnames";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { FiAlertCircle } from "react-icons/fi";
import "wicg-inert";

import "./modal.scss";

let getModalContainer = () => document.body;
export function setTestContainer(container) {
  getModalContainer = () => container;
}
export function restoreTestContainer() {
  getModalContainer = () => document.body;
}

const getRoot = () => document.getElementById("root");
class Modal extends Component {
  componentDidUpdate(prevProps) {
    const { openModal } = this.props;
    if (openModal !== prevProps.openModal) {
      this.toggleModal(openModal);
    }
  }

  componentWillUnmount() {
    // in tests, it is possible for the clean up to wipe the document
    // before the unmount is called, due to using a portal.
    if (getRoot()) {
      this.toggleModal(false);
    }
  }

  toggleModal(value) {
    const root = getRoot();

    if (value) {
      root.classList.add("lock-scroll");
      root.setAttribute("inert", ""); // adds aria-hidden="true"
    } else {
      root.classList.remove("lock-scroll");
      root.removeAttribute("inert"); // removes aria-hidden="true"
    }
  }

  render() {
    const {
      children,
      closeModal,
      openModal,
      closeModalText = "close",
      className = "",
      buttonClassName = "button--secondary",
      id = "",
      title = "",
      subTitle = null,
      fullWidth = false,
      showCloseButton = true,
      showCrossButton = false,
      disableCloseButton = false,
      header = null,
      headerType = null,
    } = this.props;

    const modalClasses = classnames("modal", { "modal--full-width": fullWidth }, className);
    const headerClasses = classnames("", {
      "u-stack-3": !!children,
    });
    const buttonClasses = classnames("button", {}, buttonClassName);
    const headerContainerClasses = classnames("modal__header", `modal__header--${headerType}`);

    const handleOnKeyDown = (e) => {
      if (e.keyCode === 13) {
        closeModal();
      }
    };

    if (openModal) {
      return ReactDOM.createPortal(
        <div className="modal-container">
          <div className={modalClasses} role="dialog" aria-labelledby={`modal-title-${id}`}>
            {header && (
              <div className={headerContainerClasses}>
                <FiAlertCircle />
              </div>
            )}
            {showCrossButton && (
              <span
                role="button"
                type="button"
                className="modal__close_cross_icon"
                tabIndex={0}
                onClick={closeModal}
                onKeyDown={handleOnKeyDown}
              >
                <CloseCross />
              </span>
            )}
            <div className="modal__body">
              <div className={headerClasses}>
                <header className="modal__title">{title}</header>
                {subTitle && <div className="modal__sub-title">{subTitle}</div>}
              </div>
              {children}
              {showCloseButton && (
                <button
                  type="button"
                  className={buttonClasses}
                  onClick={closeModal}
                  disabled={disableCloseButton}
                >
                  {closeModalText}
                </button>
              )}
            </div>
          </div>
        </div>,
        getModalContainer()
      );
    }
    return null;
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModalText: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  subTitle: PropTypes.string,
  fullWidth: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showCrossButton: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  header: PropTypes.bool,
  headerType: PropTypes.string,
};

export default Modal;
