import React from "react";

import PropTypes from "prop-types";

import "./icon.scss";

const Icon = ({ type: Type, className = "" }) => (
  <i className={`c-icon ${className}`}>
    <Type />
  </i>
);

Icon.propTypes = {
  type: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Icon;
