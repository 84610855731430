import React, { useState } from "react";

import i18next from "i18next";
import { DateTime } from "luxon";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom-v5-compat";

import CustomRangeSelector from "components/reports/date_picker/date_range_selector/custom_range/CustomRangeSelector";
import "components/reports/date_picker/date_range_selector/date_range_selector.scss";
import PresetsSelector from "components/reports/date_picker/date_range_selector/presets/PresetsSelector";

const DateRangeSelector = ({
  closeDateRangeSelector,
  timezoneOffset,
  statsStartingDate,
  selectedStartDate,
  selectedEndDate,
}) => {
  const location = useLocation();
  const [newStartDate, setNewStartDate] = useState(
    DateTime.fromSeconds(selectedStartDate)
      .setZone("UTC" + timezoneOffset)
      .startOf("day")
  );
  const [newEndDate, setNewEndDate] = useState(
    DateTime.fromSeconds(selectedEndDate)
      .setZone("UTC" + timezoneOffset)
      .endOf("day")
  );

  const updateNewDateRange = (date) => {
    if (
      DateTime.fromISO(date) < DateTime.fromISO(newStartDate) ||
      !DateTime.fromISO(newStartDate).hasSame(DateTime.fromISO(newEndDate), "day", {
        useLocaleWeeks: true,
      })
    ) {
      setNewStartDate(date);
      setNewEndDate(date);
      return;
    } else if (DateTime.fromISO(date) > DateTime.fromISO(newStartDate)) {
      setNewEndDate(date);
      return;
    }

    setNewStartDate(newStartDate);
    setNewEndDate(newEndDate);
  };

  const setRangeStartDate = () => {
    let result;
    const newStartDateInTimezone = newStartDate;
    const statsStartingDateInTimezone = DateTime.fromSeconds(statsStartingDate).setZone(
      "UTC" + timezoneOffset
    );

    if (
      newStartDateInTimezone.hasSame(statsStartingDateInTimezone, "day", {
        useLocaleWeeks: true,
      }) ||
      newStartDateInTimezone < statsStartingDateInTimezone
    ) {
      result = statsStartingDate;
    } else {
      result = Math.floor(newStartDateInTimezone.startOf("day").toSeconds());
    }

    return result;
  };

  const setPresetRangeStartDate = (presetStartDate) => {
    let result;

    const presetStartDateInTimezone = presetStartDate;
    const statsStartingDateInTimezone = DateTime.fromSeconds(statsStartingDate, {
      zone: "UTC",
    }).setZone("UTC" + timezoneOffset);

    if (presetStartDateInTimezone < statsStartingDateInTimezone) {
      result = statsStartingDate;
    } else {
      result = Math.floor(presetStartDateInTimezone.toSeconds());
    }

    return result;
  };

  const newRange = {
    start_date: setRangeStartDate(),
    end_date: Math.floor(newEndDate.endOf("day").toSeconds()),
  };

  const searchParams = {
    ...newRange,
    campaigns: qs.parse(location.search).campaigns,
  };

  return (
    <div role="select" aria-label="date-range-selector" className="date-range-selector--selector">
      <div className="date-range-selector--main">
        <CustomRangeSelector
          newStartDate={newStartDate}
          newEndDate={newEndDate}
          updateNewDateRange={(date) => updateNewDateRange(date)}
          timezoneOffset={timezoneOffset}
        />

        <PresetsSelector
          customStartDate={newStartDate}
          customEndDate={newEndDate}
          timezoneOffset={timezoneOffset}
          setPresetRangeStartDate={setPresetRangeStartDate}
          closeDateRangeSelector={closeDateRangeSelector}
        />
      </div>

      <div className="date-range-selector--footer">
        <Link
          className="apply"
          to={{ search: `?${qs.stringify(searchParams)}` }}
          onClick={closeDateRangeSelector}
        >
          {i18next.t("reporting.dashboard.topbar.dateRangeSelector.applyLinkText")}
        </Link>

        <button type="button" className="cancel" onClick={closeDateRangeSelector}>
          {i18next.t("global.cancel")}
        </button>

        <div className="date-range-selector__help-text">
          {i18next.t("reporting.dashboard.topbar.dateRangeSelector.helpText.firstLine")}
          <br />
          {i18next.t("reporting.dashboard.topbar.dateRangeSelector.helpText.secondLine")}
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;
