import React, { useState } from "react";

import { Button, ReferralActions } from "bp-ui";
import { useTranslation } from "react-i18next";

import Select from "components/forms/select/Select";
import addNotification from "components/notifications/Notifications";

import DelayedConfirmationModal from "../../../modals/delayed_confirmation_modal/DelayedConfirmationModal";
import { getRewardCategoryList, noRewardCategoryString } from "../../user/user_utils";

export const TimelineReferralDetailAndActions = ({
  referral,
  timezoneOffset,
  selectedMarket,
  reloadData,
  userId,
}) => {
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const errorNotificationTitle = t("global.error");
  const successNotificationTitle = t("global.success");

  const handleOnChange = (option) => {
    setSelectedAction(option.value);
  };

  const confirmReferral = async (rewardCategory) => {
    try {
      await selectedMarket.users.reconcileReferral(
        selectedMarket.id,
        referral.orderNumber,
        "confirmed",
        rewardCategory === noRewardCategoryString ? undefined : rewardCategory
      );
      reloadData && (await reloadData());

      processSuccessfulRewardAction(
        successNotificationTitle,
        t("referrals.notifications.successConfirmed"),
        () => setShowConfirmModal(false)
      );
    } catch (e) {
      addNotification.warning(errorNotificationTitle, t("referrals.notifications.errorConfirming"));
      setShowConfirmModal(false);
    }
  };

  const cancelReferral = async () => {
    try {
      // Leaving this here for when CIS clients presumably become supported here
      // if (isCis) {
      //   await selectedMarket.cisUsers.denyReferral(referral.id);
      // } else {
      await selectedMarket.users.reconcileReferral(
        selectedMarket.id,
        referral.orderNumber,
        "cancelled"
      );
      // }

      reloadData && (await reloadData());
      processSuccessfulRewardAction(
        successNotificationTitle,
        t("referrals.notifications.successCancelled"),
        () => setShowCancelModal(false)
      );
    } catch (e) {
      addNotification.warning(errorNotificationTitle, t("referrals.notifications.errorCancelled"));
      setShowCancelModal(false);
    }
  };

  const restoreReferral = async () => {
    try {
      // Leaving this here for when CIS clients presumably become supported here
      // if (isCis) {
      //   await selectedMarket.cisUsers.restoreReferral(referral.id);
      // } else {
      await selectedMarket.users.reconcileReferral(
        selectedMarket.id,
        referral.orderNumber,
        "restore"
      );
      // }

      reloadData && (await reloadData());
      processSuccessfulRewardAction(
        successNotificationTitle,
        t("referrals.notifications.successRevertedToPending"),
        () => setShowRestoreModal(false)
      );
    } catch (e) {
      addNotification.warning(
        errorNotificationTitle,
        t("referrals.notifications.errorRevertedToPending")
      );
      setShowRestoreModal(false);
    }
  };

  const processRewardAction = () => {
    switch (selectedAction) {
      case ReferralActions.CONFIRM:
        setShowConfirmModal(true);
        break;
      case ReferralActions.CANCEL:
        setShowCancelModal(true);
        break;
      case ReferralActions.REVERT:
        setShowRestoreModal(true);
        break;
      default:
        break;
    }
  };

  const processSuccessfulRewardAction = (title, message, modalFunction) => {
    modalFunction(false);
    addNotification.success(title, message);
    setSelectedAction(null);
    reloadData && reloadData();
  };

  const options = [];

  if (referral.availableActions.indexOf(ReferralActions.CONFIRM) > -1) {
    options.push({
      name: t("referrals.optionsLabels.confirmReferral"),
      value: ReferralActions.CONFIRM,
    });
  }

  if (referral.availableActions.indexOf(ReferralActions.CANCEL) > -1) {
    options.push({
      name: t("referrals.optionsLabels.cancelReferral"),
      value: ReferralActions.CANCEL,
    });
  }

  if (referral.availableActions.indexOf(ReferralActions.REVERT) > -1) {
    options.push({
      name: t("referrals.optionsLabels.revertToPending"),
      value: ReferralActions.REVERT,
    });
  }

  return (
    <div>
      {options.length > 0 ? (
        <>
          <h4 className="mt-8 mb-0">{t("referrerProfile.referralTimeline.actionDescriptor")}</h4>
          <Select
            options={options}
            handleOnChange={handleOnChange}
            selectedValue={selectedAction}
            buttonAppearance="secondary"
            selectedPlaceholder={t("referrerProfile.referralTimeline.selectPlaceholder")}
          />
          <div>
            <Button
              className="mt-4"
              disabled={!selectedAction}
              onClick={processRewardAction}
              loading={loading}
            >
              {t("referrerProfile.referralTimeline.confirm")}
            </Button>
          </div>

          <DelayedConfirmationModal
            id="confirm-referral"
            title={t("referrals.delayedConfirmationModal.confirmReferral.title")}
            content={t("referrals.delayedConfirmationModal.confirmReferral.content")}
            confirmationText={t(
              "referrals.delayedConfirmationModal.confirmReferral.confirmationText"
            )}
            onConfirmation={confirmReferral}
            undoMessage={t("referrals.delayedConfirmationModal.confirmReferral.undoMessage")}
            closeModal={() => setShowConfirmModal(false)}
            openModal={showConfirmModal}
            selectOptions={getRewardCategoryList(
              selectedMarket.campaignById(referral.campaign.id).rewardTypes,
              {
                name: t("referrals.optionsLabels.noRewardCategory"),
                value: noRewardCategoryString,
              }
            )}
          />

          <DelayedConfirmationModal
            id="cancel-referral"
            title={t("referrals.delayedConfirmationModal.cancelReferral.title")}
            confirmationText={t(
              "referrals.delayedConfirmationModal.cancelReferral.confirmationText"
            )}
            onConfirmation={cancelReferral}
            undoMessage={t("referrals.delayedConfirmationModal.cancelReferral.undoMessage")}
            closeModal={() => setShowCancelModal(false)}
            openModal={showCancelModal}
          />

          <DelayedConfirmationModal
            id="restore-referral"
            title={t("referrals.delayedConfirmationModal.restoreReferral.title")}
            confirmationText={t(
              "referrals.delayedConfirmationModal.restoreReferral.confirmationText"
            )}
            onConfirmation={restoreReferral}
            undoMessage={t("referrals.delayedConfirmationModal.restoreReferral.undoMessage")}
            closeModal={() => setShowRestoreModal(false)}
            openModal={showRestoreModal}
          />
        </>
      ) : null}
    </div>
  );
};
