import React from "react";

import { Statuses } from "bp-ui";
import { t } from "i18next";
import { DateTime } from "luxon";
import {
  FaCalendarXmark,
  FaCheck,
  FaCirclePause,
  FaHourglassStart,
  FaRotate,
  FaXmark,
} from "react-icons/fa6";

import { TimelineFulfilmentDetailAndActions } from "components/customer_lookup/new_user/TimelineFulfilmentDetailAndActions/TimelineFulfilmentDetailAndActions";

import { TimelineReferralDetailAndActions } from "./TimelineReferralDetailAndActions/TimelineReferralDetailAndActions";

export const getReferralStatusTypeFromValue = (value) => {
  switch (value) {
    case "complete":
      return Statuses.SUCCESS;
    case "pending":
      return Statuses.OK;
    case "failed":
      return Statuses.ERROR;
    case "processing":
      return Statuses.BUSY;
    case "paused":
      return Statuses.DEFAULT;
    case "expired":
      return Statuses.WARNING;
    case "cancelled":
      return Statuses.ERROR;
    default:
      return Statuses.DEFAULT;
  }
};

export const getReferralStatusLabelFromValue = (value) => {
  switch (value) {
    case "complete":
      return t("referrerProfile.statuses.complete");
    case "pending":
      return t("referrerProfile.statuses.pending");
    case "failed":
      return t("referrerProfile.statuses.failed");
    case "processing":
      return t("referrerProfile.statuses.processing");
    case "paused":
      return t("referrerProfile.statuses.paused");
    case "expired":
      return t("referrerProfile.statuses.expired");
    case "cancelled":
      return t("referrerProfile.statuses.cancelled");
    default:
      return value;
  }
};

export const getReferralStatusIconFromValue = (value) => {
  switch (value) {
    case "complete":
      return FaCheck;
    case "pending":
      return FaHourglassStart;
    case "failed":
      return FaXmark;
    case "processing":
      return FaRotate;
    case "paused":
      return FaCirclePause;
    case "expired":
      return FaCalendarXmark;
    case "cancelled":
      return FaXmark;
    default:
      return FaCheck;
  }
};

export const getTimelineDataForReferral = (
  timelineData,
  referral,
  userId,
  timezoneOffset,
  selectedMarket,
  reloadData
) => {
  const timeline = timelineData
    .sort((a, b) => (DateTime.fromISO(a.timestamp) < DateTime.fromISO(b.timestamp) ? -1 : 1))
    .filter(
      (f) =>
        f.orderId === referral.referrerOrderId &&
        !f.fulfilmentId &&
        f.userId === userId &&
        f.referralId === referral.id
    )
    .map((m) => ({
      complete: true,
      title: t(`referrerProfile.referralTimeline.emailTitle_${m.emailType}_${m.emailEvent}`),
      timestamp: m.timestamp,
    }));

  const hideNumber = timeline.length === 0;

  if (timeline.length === 0) {
    timeline.push({
      status: Statuses.DEFAULT,
      title: t("referrerProfile.referralTimeline.dataUnavailable"),
      content: (
        <div>
          <div>{t("referrerProfile.referralTimeline.dataUnavailableDescription")}</div>
          <div>{t("referrerProfile.referralTimeline.dataUnavailableSupportInfo")}</div>
        </div>
      ),
    });
  }

  timeline.push({
    status: getReferralStatusTypeFromValue(referral.status),
    icon: getReferralStatusIconFromValue(referral.status),
    title: t(`referrerProfile.referralTimeline.referral_${referral.status}`),
    timestamp: referral.statusUpdatedAt,
    hideNumber,
    content: (
      <>
        <div>{t(`referrerProfile.referralTimeline.referral_${referral.status}_description`)}</div>
        <TimelineReferralDetailAndActions
          referral={referral}
          timezoneOffset={timezoneOffset}
          reloadData={reloadData}
          userId={userId}
          status={getReferralStatusTypeFromValue(referral.status)}
          selectedMarket={selectedMarket}
        />
      </>
    ),
  });

  return timeline;
};

export const getTimelineDataForFulfilment = (
  timelineData,
  fulfilment,
  timezoneOffset,
  selectedMarket,
  reloadData,
  userId,
  addToTimeline
) => {
  const timeline = timelineData
    .sort((a, b) => (DateTime.fromISO(a.timestamp) < DateTime.fromISO(b.timestamp) ? -1 : 1))
    .filter((f) => f.fulfilmentId === fulfilment.id)
    .map((m) => ({
      status: Statuses.SUCCESS,
      title: t(`referrerProfile.referralTimeline.emailTitle_${m.emailType}_${m.emailEvent}`),
      timestamp: m.timestamp,
    }));

  const hideNumber = timeline.length === 0;

  if (timeline.length === 0) {
    timeline.push({
      status: Statuses.DEFAULT,
      // icon: getReferralStatusIconFromValue(fulfilment.status.value),
      title: t("referrerProfile.referralTimeline.dataUnavailable"),
      hideNumber,
      content: (
        <div>
          <div>{t("referrerProfile.referralTimeline.dataUnavailableDescription")}</div>
          <div>{t("referrerProfile.referralTimeline.dataUnavailableSupportInfo")}</div>
        </div>
      ),
    });
  }

  timeline.push({
    status: getReferralStatusTypeFromValue(fulfilment.status.value),
    icon: getReferralStatusIconFromValue(fulfilment.status.value),
    title: t(`referrerProfile.referralTimeline.reward_${fulfilment.status.value}`),
    timestamp: fulfilment.status.updatedAt,
    hideNumber,
    content: (
      <>
        <div>
          {t(`referrerProfile.referralTimeline.reward_${fulfilment.status.value}_description`)}
        </div>
        <TimelineFulfilmentDetailAndActions
          fulfilment={fulfilment}
          timezoneOffset={timezoneOffset}
          selectedMarket={selectedMarket}
          reloadData={reloadData}
          userId={userId}
          status={getReferralStatusTypeFromValue(fulfilment.status.value)}
          addToTimeline={addToTimeline}
        />
      </>
    ),
  });

  return timeline;
};

export const fulfilmentMonetaryValue = (fulfilment) => {
  if (fulfilment.issuedCents === 0) {
    return t("referrerProfile.referralInfoCard.rewardValueNotSet");
  }

  return `${(fulfilment.issuedCents / 100).toFixed(2)} ${fulfilment.issuedCurrency?.toUpperCase()}`;
};

export const getFulfilmentStatusMessage = (fulfilment) => {
  const { status: fulfilmentStatus } = fulfilment;
  const paymentProviderStatusExists = fulfilmentStatus.paymentProviderStatus != null;
  const paymentProviderErrorExists = fulfilmentStatus.paymentProviderError != null;

  const statusMessage = paymentProviderStatusExists
    ? t(`fulfilments.paymentProviderStatuses.${fulfilmentStatus.paymentProviderStatus}`)
    : fulfilmentStatus.failureReason;

  const paymentProviderErrors = () => {
    if (
      fulfilmentStatus.paymentProviderError &&
      fulfilmentStatus.paymentProviderError.indexOf(" ") >= 0
    ) {
      return fulfilmentStatus.paymentProviderError;
    }

    return t(`fulfilments.paymentProviderStatuses.${fulfilmentStatus.paymentProviderError}`);
  };

  return paymentProviderErrorExists
    ? `${statusMessage}\n\n${paymentProviderErrors()}`
    : statusMessage;
};
