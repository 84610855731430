import React, { useState } from "react";

import PartnerLocale from "models/PartnerLocale";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Form from "components/forms/form/Form";
import ImageDirectUploader from "components/forms/image_upload/ImageDirectUploader";
import "components/partner_management/partner_profile/assets/assets.scss";
import "components/partner_management/partner_profile/assets/assets_modal.scss";
import Congratulations from "components/partner_management/partner_profile/assets/form/Congratulations.jsx";
import InputField from "components/partner_management/partner_profile/assets/form/InputField";
import TextField from "components/partner_management/partner_profile/assets/form/TextField";
import LocaleModal from "components/partner_management/partner_profile/assets/locale_modal/LocaleModal";
import LocaleSwitcher from "components/partner_management/partner_profile/assets/locale_switcher/LocaleSwitcher";

const AssetsForm = ({ market, onCancel, reloadPartner, partner, defaultLocale = undefined }) => {
  const { t } = useTranslation();
  const newPartnerAssets = (localeString) =>
    new PartnerLocale({
      id: null,
      locale: localeString,
      quote: null,
      display_name: partner.profile.businessName,
      offer_header: null,
      avatar: {
        url: null,
        id: null,
        filename: null,
      },
      banner: {
        url: null,
        id: null,
        filename: null,
      },
    });
  const populatedLocales = partner.partnerLocales.map((partnerLocale) => partnerLocale.locale);

  const defaultForm = defaultLocale || newPartnerAssets(market.supported_locales[0].value);
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const [assetsSubmitted, setAssetsSubmitted] = useState(false);
  const [avatarUpdated, setAvatarUpdated] = useState(false);
  const [bannerUpdated, setBannerUpdated] = useState(false);
  const [localeModalIsOpen, setLocaleModalIsOpen] = useState(false);

  const toggleLocaleModal = () => setLocaleModalIsOpen((a) => !a);

  const saveDisabled =
    loading ||
    submissionErrors.length > 0 ||
    validationErrors.length > 0 ||
    !form.avatar?.id ||
    !form.banner?.id ||
    !form.quote ||
    !form.displayName ||
    !form.offerHeader;

  const setFormFields = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCreate = async () => {
    try {
      await market.users.createPartnerLocale(
        partner.id,
        form.locale,
        form.avatar.id,
        form.banner.id,
        form.quote,
        form.displayName,
        form.offerHeader
      );

      setAssetsSubmitted(true);
      reloadPartner();
    } catch {
      setSubmissionErrors([{ type: "partnerAssets" }]);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      await market.users.updatePartnerLocale(
        partner.id,
        form.id,
        avatarUpdated ? form.avatar.id : undefined, // we don't want to re-submit the same attachment
        bannerUpdated ? form.banner.id : undefined, // we don't want to re-submit the same attachment
        form.quote,
        form.displayName,
        form.offerHeader
      );

      setAssetsSubmitted(true);
      reloadPartner();
    } catch {
      setSubmissionErrors([{ type: "partnerAssets" }]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // if we already have a populated PartnerLocale for the form's locale (e.g. "en")
    if (populatedLocales.find((partnerLocale) => partnerLocale === form.locale)) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const deleteImage = (imageType) => {
    setFormFields(imageType, {
      url: undefined,
      id: undefined,
      filename: undefined,
    });
  };

  const avatarUploadSuccessful = (fileData) => {
    setFormFields("avatar", fileData);
    setAvatarUpdated(true);
  };

  const bannerUploadSuccessful = (fileData) => {
    setFormFields("banner", fileData);
    setBannerUpdated(true);
  };

  return (
    <>
      {assetsSubmitted ? (
        <Congratulations onCancel={onCancel} />
      ) : (
        <Form onSubmit={handleSubmit} errorKey="partnerAssets" errors={submissionErrors}>
          <LocaleModal
            isOpen={localeModalIsOpen}
            closeModal={toggleLocaleModal}
            clear={(localeStr) => setForm(newPartnerAssets(localeStr))}
            chosenLocale={form.locale}
            populatedLocales={populatedLocales}
            availableLocales={partner.partnerLocales}
            updateField={setFormFields}
            updateAvatar={avatarUploadSuccessful}
            updateBanner={bannerUploadSuccessful}
          />

          <div className="assets-form">
            <div className="c-partner-profile__section">
              <div className="c-partner-profile__header">
                <div>
                  <h2 className="c-partner-profile__header-form">
                    {t("partnerProfile.addAssets.sectionOneHeading")}
                  </h2>
                  <span className="c-partner-profile__text-form">
                    {t("partnerProfile.addAssets.sectionOneDescription")}
                  </span>
                </div>

                <LocaleSwitcher
                  populatedLocales={populatedLocales}
                  marketLocales={market.supported_locales}
                  selectedLocale={form.locale}
                  availableLocales={partner.partnerLocales}
                  updateLocale={(newLocale) => setFormFields("locale", newLocale)}
                  toggleLocaleModal={toggleLocaleModal}
                  setForm={setForm}
                />
              </div>

              <div className="assets-form__text-fields-container">
                <div className="assets-form__text-fields-container__column">
                  <InputField
                    id="display_name"
                    value={form.displayName}
                    onChange={(value) => setFormFields("displayName", value)}
                    labelText={t("partnerProfile.displayName")}
                    tooltipText={t("partnerProfile.displayNameTooltip")}
                    maxLength={50}
                    setValidationErrors={setValidationErrors}
                  />
                </div>

                <div className="assets-form__text-fields-container__double_column">
                  <TextField
                    id="offer_header"
                    value={form.offerHeader}
                    onChange={(value) => setFormFields("offerHeader", value)}
                    labelText={t("partnerProfile.offerHeader")}
                    tooltipText={t("partnerProfile.offerHeaderTooltip")}
                    placeholder={t("partnerProfile.addAssets.offerHeaderPlaceholder")}
                    maxLength={100}
                    setValidationErrors={setValidationErrors}
                  />
                </div>
              </div>

              <div className="assets-form__quote-container">
                <TextField
                  id="quote"
                  value={form.quote}
                  onChange={(value) => setFormFields("quote", value)}
                  labelText={t("partnerProfile.quote")}
                  tooltipText={t("partnerProfile.quoteTooltip")}
                  placeholder={t("partnerProfile.addAssets.quotePlaceholder")}
                  maxLength={500}
                  setValidationErrors={setValidationErrors}
                />
              </div>
            </div>

            <div className="c-partner-profile__section">
              <div className="c-partner-profile__header">
                <h2 className="c-partner-profile__header-form">
                  {t("partnerProfile.addAssets.sectionTwoHeading")}
                </h2>
              </div>
              <span className="c-partner-profile__text-form">
                {t("partnerProfile.addAssets.sectionTwoDescription")}
              </span>

              <div className="assets-form__uploaders-container">
                <ImageDirectUploader
                  id="logo_import"
                  name="logo image"
                  title={t("partnerProfile.addAssets.logoImporterHeader")}
                  infoText={t("partnerProfile.addAssets.logoImporterInfo")}
                  market={market}
                  onUploadSucceeded={avatarUploadSuccessful}
                  onDeleteImage={() => deleteImage("avatar")}
                  currentFile={form.avatar}
                  setValidationErrors={setValidationErrors}
                />

                <ImageDirectUploader
                  id="banner_import"
                  name="banner image"
                  title={t("partnerProfile.addAssets.bannerImporterHeader")}
                  infoText={t("partnerProfile.addAssets.bannerImporterInfo")}
                  market={market}
                  onUploadSucceeded={bannerUploadSuccessful}
                  onDeleteImage={() => deleteImage("banner")}
                  currentFile={form.banner}
                  setValidationErrors={setValidationErrors}
                  withLargeIcon
                />
              </div>

              <div className="assets-form__modal-footer">
                <Button
                  buttonElement="submit"
                  size="large"
                  appearance="primary"
                  loading={loading}
                  disabled={saveDisabled}
                >
                  {t("global.save")}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default AssetsForm;
