import React, { useState } from "react";

import { Button } from "bp-ui";
import { t } from "i18next";

import TextInput from "components/forms/text_input/TextInput";

export const EditUser = ({ user, selectedMarket, onSuccessfulEdit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [formState, setFormState] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setFormErrors({});

    const { firstName, lastName, email } = formState;

    try {
      await selectedMarket.users.updateUser(user.id, { firstName, lastName, email });

      onSuccessfulEdit();
    } catch (e) {
      if (e && e.errors) {
        setFormErrors(e.errors);
      }
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex gap-5">
        <TextInput
          labelText={t("forms.labels.firstName")}
          id="firstName"
          value={formState.firstName}
          handleChange={handleChange}
          required
          focus
          errors={formErrors.first_name}
          className="flex-1"
        />
        <TextInput
          labelText={t("forms.labels.lastName")}
          id="lastName"
          value={formState.lastName}
          handleChange={handleChange}
          errors={formErrors.last_name}
          className="flex-1"
        />
      </div>
      <TextInput
        labelText={t("forms.labels.email")}
        id="email"
        value={formState.email}
        required
        handleChange={handleChange}
        errors={formErrors.email}
      />

      <div className="flex gap-3 mt-10">
        <Button className="flex-1" secondary={true} onClick={onCancel}>
          {t("global.cancel")}
        </Button>
        <Button appearance="tertiary" loading={loading} className="flex-1" submit={true}>
          {t("global.save")}
        </Button>
      </div>
    </form>
  );
};
