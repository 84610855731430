import React from "react";

import { CloseCross } from "assets/images/CustomIcons";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import "wicg-inert";

import "components/partner_management/partner_profile/assets/locale_modal/locale_modal.scss";

const getModalContainer = () => document.body;

// NOTE: this component is used inside another modal therefore I'm not adding the "lock-scroll"
// className here when open because it's already applied to "root" in the parent modal.
// If this component is ever to be used outside of a modal we might need to add the lock scroll.
const LocaleModal = ({
  closeModal,
  clear,
  isOpen,
  chosenLocale,
  populatedLocales,
  availableLocales,
  updateField,
  updateAvatar,
  updateBanner,
}) => {
  const { t } = useTranslation();

  const duplicatePartnerLocale = (locale) => {
    const templateLocale = availableLocales.find(
      (partnerLocale) => partnerLocale.locale === locale
    );

    updateField("quote", templateLocale.quote);
    updateField("offerHeader", templateLocale.offerHeader);
    updateField("displayName", templateLocale.displayName);
    updateAvatar({
      id: templateLocale.avatar.id,
      filename: templateLocale.avatar.filename,
      url: templateLocale.avatar.url,
    });
    updateBanner({
      id: templateLocale.banner.id,
      filename: templateLocale.banner.filename,
      url: templateLocale.banner.url,
    });
    closeModal();
  };

  const closeAndClear = () => {
    clear(chosenLocale);
    closeModal();
  };

  const handleOnKeyDown = (e) => {
    const esc = e.keyCode === 27;
    if (esc) {
      closeAndClear();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="locale-modal-container">
      <div className="locale-modal" role="dialog" aria-labelledby="modal-locale-switcher">
        <span
          role="button"
          type="button"
          className="modal__close_cross_icon"
          tabIndex={0}
          onClick={closeAndClear}
          onKeyDown={handleOnKeyDown}
        >
          <CloseCross />
        </span>

        <div className="locale-modal__body">
          <header className="locale-modal__title">
            {t("partnerProfile.addAssets.assetsModal.header")} {t(`languages.${chosenLocale}`)}
          </header>

          <div className="locale-modal__body-content">
            <p>
              {t("partnerProfile.addAssets.assetsModal.notice")} {t(`languages.${chosenLocale}`)}
            </p>
            <p>
              {t("partnerProfile.addAssets.assetsModal.instruction")}{" "}
              {t(`languages.${chosenLocale}`)}
            </p>
          </div>

          <div className="locale-modal__buttons">
            {populatedLocales.map((populatedLocale) => (
              <button
                key={populatedLocale}
                type="button"
                className="c-button c-button--primary"
                onClick={() => duplicatePartnerLocale(populatedLocale)}
              >
                {t("partnerProfile.addAssets.assetsModal.copyButton")}{" "}
                {t(`languages.${populatedLocale}`)}
              </button>
            ))}

            <button
              type="button"
              className="c-button c-button--secondary c-button--outline"
              onClick={closeAndClear}
            >
              {t("global.cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>,
    getModalContainer()
  );
};

export default LocaleModal;
