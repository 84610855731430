import React from "react";

import { Card } from "bp-ui";

import { ReferralInfoDetails } from "./ReferralInfoDetails";

export const ReferralInfoCard = ({
  referral,
  fulfilments,
  timezoneOffset,
  timelineData = [],
  user,
  selectedMarket,
  reloadData,
}) => {
  return (
    <Card.Card className="flex-auto flex flex-col text-sm !pt-0 pb-2">
      <ReferralInfoDetails
        fulfilments={fulfilments}
        timelineData={timelineData}
        timezoneOffset={timezoneOffset}
        referral={referral}
        user={user}
        selectedMarket={selectedMarket}
        reloadData={reloadData}
      />
    </Card.Card>
  );
};
