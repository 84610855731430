import React, { Component } from "react";

import i18next from "i18next";
import BaseUser from "models/BaseUser";
import Campaign from "models/Campaign";
import Market from "models/Market";
import PropTypes from "prop-types";

import Button from "components/forms/button/Button";
import TextInput from "components/forms/text_input/TextInput";
import Modal from "components/modals/Modal";

class UserCampaignCustomFieldEditor extends Component {
  static propTypes = {
    selectedCampaign: PropTypes.instanceOf(Campaign).isRequired,
    toggleModalState: PropTypes.bool.isRequired,
    handleToggleClose: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(BaseUser).isRequired,
    selectedMarket: PropTypes.instanceOf(Market).isRequired,
    handleToggleSave: PropTypes.func.isRequired,
    customFieldLabel: PropTypes.string.isRequired,
  };

  state = {
    customFieldValue: "",
    formErrors: [],
    isLoading: false,
  };

  getAllCustomFields = (updatedCustomField) => {
    const { user } = this.props;

    const remainingCustomFields = user.customFields.filter(
      ({ label, campaignId }) =>
        campaignId !== updatedCustomField.campaignId || label !== updatedCustomField.label
    );

    return [...remainingCustomFields, updatedCustomField];
  };

  componentDidMount = () => this.buildForm();

  componentDidUpdate = (prevProps) => {
    const { toggleModalState } = this.props;

    if (toggleModalState !== prevProps.toggleModalState) {
      this.buildForm();
    }
  };

  buildForm = () => {
    const { user, selectedCampaign, customFieldLabel } = this.props;

    this.setState({
      customFieldValue: user.customFieldFor(selectedCampaign, customFieldLabel)?.value || "",
    });
  };

  handleCampaignEditorSave = async () => {
    const { user, selectedMarket, handleToggleSave, selectedCampaign, customFieldLabel } =
      this.props;
    const { customFieldValue } = this.state;

    this.setState({
      formErrors: [],
      isLoading: true,
    });

    const customFields = this.getAllCustomFields({
      campaignId: selectedCampaign.id,
      label: customFieldLabel,
      value: customFieldValue,
    });

    try {
      await selectedMarket.users.updateDetails(
        user.id,
        user.firstName,
        user.lastName,
        user.email,
        customFields
      );

      handleToggleSave();
    } catch (error) {
      this.setState({ formErrors: { errors: [{ type: "format" }] } });
    }

    this.setState({ isLoading: false });
  };

  handleCampaignEditorClose = () => {
    const { handleToggleClose } = this.props;

    this.setState({ formErrors: [] });
    handleToggleClose();
  };

  handleInputChange = (e) => this.setState({ customFieldValue: e.target.value });

  handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleCampaignEditorSave();
    }
  };

  render() {
    const { toggleModalState, selectedCampaign, customFieldLabel } = this.props;
    const { customFieldValue, formErrors, isLoading } = this.state;

    return (
      <Modal
        closeModal={this.handleCampaignEditorClose}
        closeModalText={i18next.t("global.cancel")}
        openModal={toggleModalState}
        title={i18next.t("campaign.editor.modalTitle", {
          campaignName: selectedCampaign.name,
        })}
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="u-stack-5">
            <TextInput
              labelText={customFieldLabel}
              value={customFieldValue}
              id="identifier-update"
              handleChange={this.handleInputChange}
              closeModalText={i18next.t("global.cancel")}
              errorKey="customField"
              handleOnKeyDown={this.handleOnKeyDown}
              required
              errors={formErrors.errors}
            />
          </div>
          <Button
            appearance="tertiary"
            className="u-stack-2"
            onClick={this.handleCampaignEditorSave}
            fullWidth
            loading={isLoading}
          >
            {i18next.t("global.save")}
          </Button>
        </form>
      </Modal>
    );
  }
}

export default UserCampaignCustomFieldEditor;
