import React from "react";

import PropTypes from "prop-types";
import { FaCheck, FaHourglassEnd, FaListUl, FaTimes } from "react-icons/fa";

import Counter from "components/customer_lookup/row_atoms/counter/Counter";

import "./referral_count.scss";

const ReferralCount = ({
  totalReferrals,
  completedReferrals,
  pendingReferrals,
  cancelledReferrals,
}) => (
  <div className="referral-count">
    <Counter icon={<FaListUl />} count={totalReferrals || 0} status="total" />
    <Counter icon={<FaCheck />} count={completedReferrals || 0} status="completed" />
    <Counter icon={<FaHourglassEnd />} count={pendingReferrals || 0} status="pending" />
    <Counter icon={<FaTimes />} count={cancelledReferrals || 0} status="cancelled" />
  </div>
);

ReferralCount.propTypes = {
  cancelledReferrals: PropTypes.number.isRequired,
  completedReferrals: PropTypes.number.isRequired,
  pendingReferrals: PropTypes.number.isRequired,
  totalReferrals: PropTypes.number.isRequired,
};

export default ReferralCount;
