import React from "react";

import { FaSquare } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import { IconContext } from "react-icons/lib";

export const CheckboxChecked = () => (
  <IconContext.Provider
    value={{
      style: {
        verticalAlign: "middle",
        background: "var(--b-text-color)",
        border: "1px solid var(--b-text-color)",
        borderRadius: "4px",
        color: "white",
      },
    }}
  >
    <i>
      <GoCheck />
    </i>
  </IconContext.Provider>
);

export const CheckboxEmpty = () => (
  <IconContext.Provider
    value={{
      style: {
        verticalAlign: "middle",
        color: "transparent",
        background: "var(--b-background-color)",
        border: "1px solid rgba(var(--b-blue), 0.4)",
        borderRadius: "4px",
      },
    }}
  >
    <i>
      <FaSquare />
    </i>
  </IconContext.Provider>
);
