let controller = new AbortController();

const createFetchRequest = async (url, options, getAuthHeaders) => {
  const baseURL = import.meta.env.VITE_BASE_API_URL;
  const signalSetup = (signal) => {
    signal.addEventListener("abort", () => controller.abort());
    return signal;
  };

  // Check if the URL is a full address (starts with 'http://' or 'https://')
  const isFullAddress = /^(http|https):\/\//.test(url);

  let urlWithParams = isFullAddress ? url : baseURL ? `${baseURL}${url}` : url;

  if (options.params) {
    const params = options.params;

    const processedParams = params
      ? Object.keys(params)
          .flatMap((param) => {
            if (params[param]) {
              if (Array.isArray(params[param])) {
                if (params[param].length > 0) {
                  const acc = [];

                  params[param].forEach((value) => {
                    acc.push(`${param}[]=${value}`);
                  });

                  return acc;
                }
              } else {
                return `${param}=${params[param]}`;
              }
            }

            return null;
          }, [])
          .filter((f) => f)
      : [];

    const queryString = processedParams.join("&");

    urlWithParams += `?${queryString}`;

    delete options.params;
  }

  const requestOptions = {
    ...options,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    signal: controller.signal,
  };

  if (signalSetup) {
    requestOptions.signal = signalSetup(requestOptions.signal);
  }

  const authHeaders = await getAuthHeaders();
  requestOptions.headers = { ...authHeaders, ...requestOptions.headers };
  return fetch(urlWithParams, requestOptions)
    .then(async (response) => {
      let responseData;

      if (options.responseType === "blob") {
        // Handle response as a blob
        responseData = await response.blob();
      } else {
        // Handle response as JSON (default behavior)
        responseData = await response.json();
      }

      if (!response.ok) {
        const error = new Error(responseData.message || "Request failed");
        error.response = response;
        error.response.data = responseData;
        throw error;
      }

      return {
        data: responseData,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        config: {
          baseURL: isFullAddress ? undefined : baseURL, // Set baseURL to undefined if it's a full address
        },
      };
    })
    .catch((error) => {
      throw error;
    });
};

export const createFetchInstance = (getAuthHeaders) => {
  const instance = {
    get: (url, options = {}) =>
      createFetchRequest(
        url,
        {
          ...options,
          method: "GET",
        },
        getAuthHeaders
      ),
    post: (url, data = {}, options = {}) =>
      createFetchRequest(
        url,
        {
          ...options,
          method: "POST",
          body: JSON.stringify(data),
        },
        getAuthHeaders
      ),
    put: (url, data = {}, options = {}) =>
      createFetchRequest(
        url,
        {
          ...options,
          method: "PUT",
          body: JSON.stringify(data),
        },
        getAuthHeaders
      ),
    delete: (url, options = {}) =>
      createFetchRequest(
        url,
        {
          ...options,
          method: "DELETE",
        },
        getAuthHeaders
      ),
    patch: (url, data = {}, options = {}) =>
      createFetchRequest(
        url,
        {
          ...options,
          method: "PATCH",
          body: JSON.stringify(data),
        },
        getAuthHeaders
      ),
  };

  return instance;
};

export const cancelRequests = () => {
  controller.abort();
  controller = new AbortController();
};

export const areRequestsCancelled = (err) => {
  return err && err.name === "AbortError";
};
