import React from "react";

import { Permissions } from "bp-ui";
import { t } from "i18next";
import Market from "models/Market";
import PropTypes from "prop-types";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";

import ReportsManagement from "../../campaign_management/reports_management/ReportsManagement";
import SubNavigation from "../../sub_navigation/SubNavigation";
import ReportingPartnerDashboard from "../partner-reporting-dashboard/PartnerReportingDashboard";
import ReportingDashboard from "../reporting-dashboard/ReportingDashboard";

const ReportingNavigation = ({ selectedMarket, type }) => {
  const location = useLocation();
  let { path } = useRouteMatch();
  const { viewReports } = Permissions;

  const availableLinks = [
    {
      text: t("reporting.navigation.reportingDashboard"),
      path: `/reports/${type}/dashboard`,
      cisOnly: false,
      permissions: [viewReports],
      visible: type !== "reward",
    },
    {
      text: t("reporting.navigation.operationalReports"),
      path: `/reports/${type}/operational`,
      cisOnly: false,
      permissions: [viewReports],
      visible: true,
    },
  ];

  const rootPath = path.substring(0, path.indexOf("/reports"));

  return (
    <div className="bi-campaign-wrapper">
      <div>
        <h1>{t(`reporting.navigation.${type}Reports`)}</h1>
      </div>
      <SubNavigation
        isSecondary
        isFloating
        subdomain={selectedMarket.subdomain}
        cisMarket={selectedMarket.cis}
        availableLinks={availableLinks}
      />
      <Switch>
        <CompatRoute path={`${rootPath}/reports/partner/dashboard`}>
          <ReportingPartnerDashboard selectedMarket={selectedMarket} location={location} />
        </CompatRoute>

        <CompatRoute path={`${rootPath}/reports/referrer/dashboard`}>
          <ReportingDashboard selectedMarket={selectedMarket} location={location} type={type} />
        </CompatRoute>

        <CompatRoute path={`${rootPath}/reports/referrer/operational`}>
          <ReportsManagement selectedMarket={selectedMarket} type={type} />
        </CompatRoute>

        <CompatRoute path={`${rootPath}/reports/partner/operational`}>
          <ReportsManagement selectedMarket={selectedMarket} type={type} />
        </CompatRoute>

        <CompatRoute path={`${rootPath}/reports/reward/operational`}>
          <ReportsManagement selectedMarket={selectedMarket} type={type} />
        </CompatRoute>
      </Switch>
    </div>
  );
};

ReportingNavigation.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  type: PropTypes.oneOf(["partner", "reward", "referrer"]).isRequired,
};

export default ReportingNavigation;
