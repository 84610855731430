import React from "react";

import classnames from "classnames";
import { bigint, oneOf, string } from "prop-types";

import "./badge.scss";

const Badge = ({ content, type = "primary" }) => {
  const badgeClasses = classnames("c-badge", {
    [`c-badge--${type}`]: type,
  });

  return (
    <div className={badgeClasses}>
      <span>{content}</span>
    </div>
  );
};

Badge.propTypes = {
  type: oneOf(["primary", "positive", "negative"]),
  content: string.isRequired,
};

export default Badge;
