import { Link as RouterLink } from "react-router-dom";

export const Link = ({
  href,
  to,
  className,
  onClick,
  children,
  elementToUse,
}) => {
  const ElementToUse = elementToUse || (to ? RouterLink : "a");

  return (
    <ElementToUse
      className={`cursor-pointer text-mid-blue underline text-base hover:text-dark-blue ${className ? className : ""}`}
      href={href || "#"}
      to={to}
      onClick={onClick}
    >
      {children}
    </ElementToUse>
  );
};
