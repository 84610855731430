import React, { useEffect, useState } from "react";

import { useAuth } from "@/AuthWrapper";
import BuyapowaLogo from "assets/images/buyapowa-electric-blue-white.svg";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom-v5-compat";

import TextInput from "components/forms/text_input/TextInput";
import Icon from "components/icon/Icon";
import SmallFormLayout from "components/small_form_layout/SmallFormLayout";

import "./market_selector.scss";

const MarketSelector = () => {
  const { t } = useTranslation();

  const marketSelectorTitle = t("marketSelector.title");
  const marketTextInputLabel = t("marketSelector.textInput.label");
  const marketTextInputPlaceholder = t("marketSelector.textInput.placeholder");

  const { currentAdmin, logout } = useAuth();
  const [marketFilter, setMarketFilter] = useState("");
  const [markets, setMarkets] = useState([]);

  const handleFilterChange = (event) => {
    setMarketFilter(event.target.value);
  };

  const filteredMarkets = () =>
    markets.filter((market) => {
      const filter = marketFilter.toLowerCase();

      return (
        market.name.toLowerCase().includes(filter) ||
        market.subdomain.toLowerCase().includes(filter)
      );
    });

  useEffect(() => {
    const sortedMarkets = sortBy(currentAdmin.markets, [(market) => market.name.toLowerCase()]);
    setMarkets(sortedMarkets);
  }, [currentAdmin]);

  useEffect(() => {
    if (currentAdmin.markets.length === 0) {
      logout();
    }
  }, [currentAdmin?.markets?.length, logout]);

  return (
    <SmallFormLayout>
      <div className="market-selector-wrapper">
        <img src={BuyapowaLogo} alt="Buyapowa Logo" />
        <div className="market-selector-container">
          <form className="input-bar--filter" onSubmit={(e) => e.preventDefault()}>
            <div className="search-container">
              <h1>{marketSelectorTitle}</h1>
              <TextInput
                labelText={marketTextInputLabel}
                placeholder={marketTextInputPlaceholder}
                id="marketFilter"
                value={marketFilter}
                handleChange={handleFilterChange}
                focus
                hideLabel
              />
            </div>
            <ul>
              {filteredMarkets().map((market) => (
                <li key={market.id}>
                  <Link to={`/${market.subdomain}`}>
                    <div className="details">
                      {market.name}
                      <span className="link__subdomain">{market.subdomain}</span>
                    </div>
                    <Icon type={FiChevronRight} />
                  </Link>
                </li>
              ))}
            </ul>
          </form>
        </div>
      </div>
    </SmallFormLayout>
  );
};

export default MarketSelector;
