import React from "react";

import classnames from "classnames";
import { Link } from "react-router-dom-v5-compat";

const Button = ({
  type,
  size,
  onClick,
  href,
  children,
  download,
  icon,
  loading,
  disabled,
  className,
  fullWidth,
  appearance,
  outline,
  buttonElement = "button",
  ...props
}) => {
  const buttonClasses = classnames(
    "c-button",
    {
      [`c-button--${appearance}`]: appearance,
      [`c-button--${size}`]: size,
      "c-button--disabled": disabled,
      "c-button--outline": outline,
      "c-button--is-loading": loading,
      "c-button--full-width": fullWidth,
    },
    className
  );

  const content = (
    <>
      {loading && <i className="c-button__loader" />}
      {icon && !loading && <i className="c-button__icon">{icon}</i>}
      {children && <span className="c-button__content">{children}</span>}
    </>
  );

  let component;
  if (buttonElement === "button") {
    component = (
      <button
        type="button"
        className={buttonClasses}
        onClick={onClick}
        disabled={loading || disabled}
        {...props}
        aria-busy={loading}
      >
        {content}
      </button>
    );
  } else if (buttonElement === "submit") {
    component = (
      <button
        type="submit"
        className={buttonClasses}
        onClick={onClick}
        disabled={loading || disabled}
        {...props}
      >
        {content}
      </button>
    );
  } else if (buttonElement === "link") {
    component = (
      <Link to={href} className={buttonClasses} download={download} {...props}>
        {content}
      </Link>
    );
  }

  return component;
};

export default Button;
