export default class PartnerLocale {
  constructor(data) {
    this.id = data.id;
    this.locale = data.locale;
    this.avatarUrl = data.avatar?.url || undefined;
    this.bannerUrl = data.banner?.url || undefined;
    this.avatarId = data.avatar?.id || undefined;
    this.bannerId = data.banner?.id || undefined;
    this.avatarFilename = data.avatar?.filename || undefined;
    this.bannerFilename = data.banner?.filename || undefined;
    this.quote = data.quote || "";
    this.displayName = data.display_name || "";
    this.offerHeader = data.offer_header || "";
    this.avatar = data.avatar || undefined;
    this.banner = data.banner || undefined;
  }
}
