import React, { useState } from "react";

import { fullName } from "helpers/Helpers";
import BaseUser from "models/BaseUser";
import Market from "models/Market";
import { func, instanceOf } from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Select from "components/forms/select/Select";
import addNotification from "components/notifications/Notifications";

import "./issue_reward.scss";

const IssueReward = ({ selectedMarket, user, onSuccessfulRewardCreation }) => {
  const { t } = useTranslation();
  const [selectedRewardType, setSelectedRewardType] = useState(null);
  const [selectedCampaignName, setSelectedCampaignName] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const errorNotificationTitle = t("global.error");
  const successNotificationTitle = t("global.success");

  const errorMessage = t("userProfile.issueNewRewardModal.errorMessage");
  const errorLimitMessage = t("userProfile.issueNewRewardModal.errorLimitMessage");
  const successMessage = t("userProfile.issueNewRewardModal.successMessage");

  const confirmText = t("global.confirm");
  const campaignText = `${t("userProfile.issueNewRewardModal.campaignText")}:`;
  const promptText = t("userProfile.issueNewRewardModal.prompt");
  const recipientText = `${t("userProfile.issueNewRewardModal.recipientText")}:`;
  const rewardText = t("userProfile.issueNewRewardModal.rewardText");
  const rewardTextWithColon = `${rewardText}:`;

  const inactiveRewardTypeText = t("userProfile.issueNewRewardModal.rewardTypes.inactive");

  const rewardInfoIsCustomerText = t("userProfile.issueNewRewardModal.rewardInfo.isCustomer");
  const rewardInfoIsExternalUserText = t(
    "userProfile.issueNewRewardModal.rewardInfo.isExternalUser"
  );

  const availableRewardTypes = selectedMarket.campaigns.map((campaign) => {
    const campaignName = `${campaign.name}${campaign.live ? "" : ` (${inactiveRewardTypeText})`}`;

    if (!campaign.rewardTypes) {
      return {
        name: campaignName,
        subOptions: [],
      };
    }

    const subOptions = campaign.rewardTypes
      .filter((rewardType) => rewardType.canBeIssuedTo(user))
      .filter((f) => !f.archived)
      .map((rewardType) => ({
        value: `${rewardType.id}|${campaign.name}`,
        name: `${rewardType.name}`,
      }));

    return {
      name: campaignName,
      subOptions,
    };
  });

  const handleRewardSelect = (e) => {
    const [rewardTypeId, campaignName] = e.value.split("|", 2);
    setSelectedRewardType({
      name: e.name,
      id: rewardTypeId,
    });
    setSelectedCampaignName(campaignName);
  };

  const handleIssueReward = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await selectedMarket.users.createFulfillment(selectedRewardType.id, user.id);
      addNotification.success(
        successNotificationTitle,
        `${successMessage} ${fullName(user.firstName, user.lastName)}`
      );
      onSuccessfulRewardCreation();
    } catch (e) {
      const message = Object.prototype.hasOwnProperty.call(e.errors, "limit")
        ? errorLimitMessage
        : errorMessage;

      addNotification.warning(errorNotificationTitle, message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="c-issue-reward">
      <form onSubmit={handleIssueReward}>
        {!selectedRewardType ? (
          <Select
            options={availableRewardTypes}
            handleOnChange={handleRewardSelect}
            selectedValue={selectedRewardType}
            title={rewardText}
            buttonAppearance="secondary"
            id="campaign_id"
          />
        ) : (
          <div className="c-issue-reward__confirmation">
            <p>
              <strong>{promptText}</strong>
            </p>
            <ul className="c-issue-reward__details">
              <li>
                {rewardTextWithColon} <span>{selectedRewardType.name}</span>
              </li>
              <li>
                {recipientText} <span>{fullName(user.firstName, user.lastName)}</span>
              </li>
              {user.customFields &&
                user.customFields.length > 0 &&
                user.customFields.map((customField) => (
                  <li key={`${customField.label}${customField.value}`}>
                    {customField.label}: <span>{customField.value}</span>
                  </li>
                ))}
              <li>
                {campaignText} <span>{selectedCampaignName}</span>
              </li>
            </ul>

            {user.isCustomer() && (
              <p className="c-issue-reward__info">{rewardInfoIsCustomerText}</p>
            )}
            {user.isExternalUser() && (
              <p className="c-issue-reward__info">{rewardInfoIsExternalUserText}</p>
            )}
          </div>
        )}
        <Button
          buttonElement="submit"
          appearance="tertiary"
          className="u-stack-2"
          fullWidth
          disabled={!selectedRewardType}
          loading={isLoading}
        >
          {confirmText}
        </Button>
      </form>
    </div>
  );
};

IssueReward.propTypes = {
  selectedMarket: instanceOf(Market).isRequired,
  user: instanceOf(BaseUser).isRequired,
  onSuccessfulRewardCreation: func.isRequired,
};

export default IssueReward;
