import React from "react";

import { Button, Modal } from "bp-ui";
import { useTranslation } from "react-i18next";

const ConfirmExistingFriendModal = ({ open, onClose, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      closeModal={onClose}
      title={t("userProfile.createReferralModal.friendExists")}
    >
      <p>{t("userProfile.confirmAlreadyExistingFriend.content")}</p>
      <div className={"grid grid-cols-2 gap-4 pt-4"}>
        <Button onClick={onClose} secondary>
          {t("global.cancel")}
        </Button>
        <Button onClick={handleSubmit}>{t("global.confirm")}</Button>
      </div>
    </Modal>
  );
};
export default ConfirmExistingFriendModal;
