import React from "react";

import { useTranslation } from "react-i18next";

const NoAssets = () => {
  const { t } = useTranslation();

  return (
    <div className="c-partner-profile__meta-block">
      <span className="c-partner-profile__meta-header">
        {t("partnerProfile.informationContent")}
      </span>
      <span>{t("partnerProfile.assetsDescription")}</span>
    </div>
  );
};

export default NoAssets;
