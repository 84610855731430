export const accessCrm = "crm:access";
export const viewReports = "crm:view-reports";
export const manuallyFulfil = "crm:manually-fulfil";
export const resendFulfilmentEmail = "crm:resend-fulfilment-email";
export const cancelRewards = "crm:cancel-rewards";
export const createReferrer = "crm:create-referrer";
export const createPartner = "crm:create-partner";
export const editPartner = "crm:edit-partner-details";
export const issueRewardReferrer = "crm:issue-reward-referrer";
export const editUserDetails = "crm:edit-user-details";
export const createReferral = "crm:create-referral";
export const bulkActionTransactions = "crm:bulk-action-transactions";
export const bulkActionReferrals = "crm:bulk-action-referrals";
export const bulkActionSignups = "crm:bulk-action-signups";
export const bulkActionCustomers = "crm:bulk-action-customers";
export const bulkActionForgetUsers = "crm:bulk-action-forget-users";
export const managePartners = "crm:manage-partners";
export const manageExpiryOperations = "crm:expiry-operations";
export const manageIntegrations = "crm:manage-integrations";
export const revealVouchers = "crm:reveal-vouchers";
export const accessCms = "cms:access";

export const can = (admin, action) => {
  if (admin.super) {
    return true;
  }

  return admin.permissions.includes(action);
};

export const canPerformAnyOf = (admin, actions) => {
  if (actions.length === 0) {
    return true;
  }

  return actions.some((action) => can(admin, action));
};
