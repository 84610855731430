export const Spinner = ({ className }) => {
  return (
    <div
      className={`border-solid border-white h-4 w-4 animate-spin rounded-full border-2 border-t-blue ${className}`}
    ></div>
  );
};

export const Preview = () => {
  return <Spinner />;
};
