import React from "react";

import { Tick } from "assets/images/CustomIcons";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";

const Congratulations = ({ onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <span className="flex justify-center items-center w-[80px] h-[80px] rounded-xl text-green">
        <Tick />
      </span>

      <h1 className="text-green mb-12">{t("partnerProfile.addAssets.congratulations")}</h1>

      <h2 className="m-0 mb-2">{t("partnerProfile.addAssets.successTitle")}</h2>

      <span className="text-grey-blue mb-12">{t("partnerProfile.addAssets.successMessage")}</span>

      <Button size="large" appearance="primary" onClick={onCancel}>
        {t("partnerProfile.addAssets.viewProfile")}
      </Button>
    </div>
  );
};

export default Congratulations;
