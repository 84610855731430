import { useCallback, useEffect, useState } from "react";

import * as Cookies from "es-cookie";

export const BpAuthProvider = ({ onAuth, children, jwt }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (jwt) {
      onAuth({
        logout,
        getAuthHeaders: authHeadersFetcher,
      });

      setLoading(false);
    }
  }, [jwt]);

  const authHeadersFetcher = async () => ({
    BP_AUTHORIZATION: `Bearer ${jwt}`,
  });
  const logout = useCallback(async () => {
    Cookies.remove("buyapowa_jwt", {
      domain: import.meta.env.VITE_SSO_COOKIE_DOMAIN,
    });
    window.location.replace(import.meta.env.VITE_SSO_REDIRECT_URL);
  }, []);

  if (loading) {
    return null;
  }

  return children;
};
