import React from "react";

import classnames from "classnames";
import { bool, string } from "prop-types";

import "./error_loader.scss";

const ErrorLoader = ({ errorText, showError, hasCardWrapper = false }) => {
  const errorClassNames = classnames("c-error-loader", {
    card: hasCardWrapper,
  });

  return (
    showError && (
      <div className={errorClassNames}>
        <p>{errorText}</p>
      </div>
    )
  );
};

ErrorLoader.propTypes = {
  errorText: string.isRequired,
  showError: bool.isRequired,
  hasCardWrapper: bool,
};

export default ErrorLoader;
