import i18next from "i18next";
import { camelCase, isArray, isObject, transform } from "lodash";
import { DateTime } from "luxon";

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export function fullName(first, last) {
  const forename = first === null || typeof first === "undefined" ? "" : first;
  const surname = last === null || typeof last === "undefined" ? "" : last;
  return `${forename} ${surname}`.trim();
}

export function pluralize(count, noun, suffix = "s") {
  if (count === 1) {
    return `${count} ${noun}`;
  }
  return `${count} ${noun}${suffix}`;
}

export function sortObjectAlphabetically(list, property) {
  return list.sort((a, b) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
}

export function sortObjectByDate(list, property, isDesc) {
  return list.sort((a, b) => {
    const dateA = new Date(a[property]);
    const dateB = new Date(b[property]);

    return isDesc ? dateA - dateB : dateB - dateA;
  });
}

export function validDateRange(startDate, endDate, minStartDate) {
  if (!startDate || !endDate || !minStartDate) {
    return false;
  }

  if (startDate > endDate) {
    return false;
  }

  if (startDate < minStartDate) {
    return false;
  }

  return true;
}

export const readFile = async (file) => {
  const reader = new FileReader();
  reader.readAsText(file);
  const result = await new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });

  return result;
};

export const getHistoryStatusText = (status) => {
  const historyStatuses = {
    failed: i18next.t("statuses.failed"),
    pending: i18next.t("statuses.pending"),
    processed: i18next.t("statuses.processed"),
    processing: i18next.t("statuses.processing"),
  };
  const translatedStatus = historyStatuses[status];
  return capitalize(translatedStatus);
};

export const toCamel = (obj) =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? toCamel(value) : value;
  });

// Some dates are returned from backend in English
// example: translateFromEn("April 2024", "MMMM yyyy", "es")
//   => "Abril 2024"
export const translateFromEn = (date, format, newLocale) => {
  return DateTime.fromFormat(date, format, { locale: "en" }).setLocale(newLocale).toFormat(format);
};
