import React from "react";

import { FaAngleDown } from "react-icons/fa6";

import { Card } from "../Card";

export const CollapseButton = ({
  onClick,
  collapsed,
  expandSectionLabel,
  collapseSectionLabel,
}) => {
  return (
    <button
      className="border-0 bg-white p-0 m-0"
      title={collapsed ? expandSectionLabel : collapseSectionLabel}
      onClick={onClick}
    >
      <FaAngleDown
        className={`cursor-pointer w-7 h-7 transition-all ${collapsed ? "" : "rotate-180"}`}
      />
    </button>
  );
};

export const Preview = () => {
  return (
    <Card className="flex gap-3">
      Opened:
      <CollapseButton
        collapsed={false}
        expandSectionLabel="Expand section"
        collapseSectionLabel="Collapse section"
      />
      Closed:
      <CollapseButton
        collapsed={true}
        expandSectionLabel="Expand section"
        collapseSectionLabel="Collapse section"
      />
    </Card>
  );
};
