import React from "react";

import classnames from "classnames";
import i18next from "i18next";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "./notification.scss";

class AddNotification {
  success(title, message) {
    this.create("success", title, message, <FaCheck />);
  }

  warning(title, message) {
    this.create("warning", title, message, <FaExclamationTriangle />);
  }

  create(type, title, message, icon) {
    const contentClassnames = classnames(
      {
        [`c-notification__container--${type}`]: type,
      },
      "c-notification__container"
    );

    const content = (
      <div className={contentClassnames}>
        <div className="c-notification__icon">{icon}</div>
        <div className="c-notification__inner">
          {title && <p className="c-notification__title">{title}</p>}
          {message && <p className="c-notification__message">{message}</p>}
        </div>
        <div className="c-notification__action">
          <span>{i18next.t("global.ok")}</span>
        </div>
      </div>
    );

    Store.addNotification({
      insert: "bottom",
      content,
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
      },
      showIcon: true,
    });
  }
}

const instance = new AddNotification();

export default instance;
