import React, { useEffect, useState } from "react";

import { paragon } from "@useparagon/connect";
import { Button, Card } from "bp-ui";
import { useTranslation } from "react-i18next";

const Integration = ({ integration, user }) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(user?.integrations[integration.type]?.enabled);

  useEffect(() => {
    if (user) setEnabled(user.integrations[integration.type]?.enabled);
  }, [user]);

  if (!integration || !user || !user.authenticated) return <></>;

  return (
    <Card.Card className="max-w-96 px-4 md:px-8 text-left">
      <div className="flex justify-between gap-3 font-semibold">
        <img src={integration.icon} style={{ maxWidth: 115 }} height={80} />

        {enabled ? (
          <p className="text-green">{t("paragon.connected")}</p>
        ) : (
          <p className="text-yellow">{t("paragon.notConnected")}</p>
        )}
      </div>

      <h3 className="text-2xl text-dark-blue font-semibold">{integration.name}</h3>

      {enabled ? (
        <p className="text-dark-grey">{t("paragon.connectedDescription")}</p>
      ) : (
        <p className="text-dark-grey">
          {t("paragon.notConnectedDescription", { integrationName: integration.name })}
        </p>
      )}

      <Button
        secondary={enabled}
        className={`w-full ${enabled ? "hover:brightness-95" : "hover:bg-dark-blue hover:border-dark-blue"}`}
        onClick={() => paragon.connect(integration.type)}
      >
        {enabled ? t("paragon.settings") : t("paragon.connect")}
      </Button>
    </Card.Card>
  );
};

export default Integration;
