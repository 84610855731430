import { FaUser } from "react-icons/fa6";

import BuyapowaLogo from "../../assets/logo/buyapowa-electric-blue-white.svg";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector.jsx";
import { Link } from "../Link/Link.jsx";

export const Header = ({
  links,
  languages,
  activeLanguage,
  onLanguageChange,
  userMenuLinks,
  userName,
  marketName,
  className,
}) => {
  return (
    <header
      className={`flex flex-row bg-black items-center px-5 ${className ? className : ""}`}
    >
      <div className="flex-1">
        <img
          src={BuyapowaLogo}
          className="w-40 py-7 mr-5"
          alt="Buyapowa logo"
        />
      </div>
      <nav className="flex-1">
        <div className="flex justify-center gap-2">
          {links &&
            links.map(
              (
                {
                  icon: Icon,
                  text,
                  linkElement,
                  path,
                  linkElementProps,
                  active,
                },
                i
              ) => {
                const ElementToUse = linkElement || "a";

                return (
                  <div key={i}>
                    <ElementToUse
                      href={path}
                      {...linkElementProps}
                      className={`${active ? "bg-white text-black" : "text-white hover:opacity-80"} rounded-3xl py-3 px-5 text-lg flex items-center no-underline flex gap-2`}
                    >
                      {Icon && <Icon />}
                      <span
                        className={`m-0 p-0 flex-1 text-lg ${active ? "font-bold" : "font-thin"}`}
                      >
                        {text}
                      </span>
                    </ElementToUse>
                  </div>
                );
              }
            )}
        </div>
      </nav>

      <div className="flex-1 flex justify-end min-w-0 overflow-hidden">
        <LanguageSelector
          className="mr-3 flex-0"
          languages={languages}
          onChange={onLanguageChange}
          activeLanguage={activeLanguage}
        />

        {userName && (
          <div className="group flex-0 min-w-0">
            <div className="flex">
              <div className="flex flex-col text-right flex-1 min-w-0">
                <span className="text-white text-lg whitespace-nowrap overflow-hidden text-ellipsis">
                  {userName}
                </span>
                <span className="text-sm text-mid-grey whitespace-nowrap overflow-hidden text-ellipsis">
                  {marketName}
                </span>
              </div>
              <div className="box-border rounded-full bg-dark-grey aspect-square w-11 h-11 p-3.5 ml-5 flex-0">
                <FaUser className="text-white w-full h-full text-center" />
              </div>
            </div>

            <div className="hidden group-hover:block absolute right-5">
              {userMenuLinks && (
                <ul className="list-none bg-white rounded-2xl !p-6 flex flex-col gap-3 shadow">
                  {userMenuLinks.map(
                    ({
                      text,
                      linkElement,
                      path,
                      linkElementProps,
                      onClick,
                    }) => {
                      const ElementToUse = linkElement || Link;

                      return (
                        <li className="" key={text}>
                          <ElementToUse
                            href={path || "#"}
                            {...linkElementProps}
                            onClick={(e) => {
                              if (onClick) {
                                e.preventDefault();
                                onClick();
                              }
                            }}
                          >
                            {text}
                          </ElementToUse>
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export const Preview = () => {
  return (
    <Header
      links={[
        {
          text: "Home",
          active: true,
        },
        {
          icon: FaUser,
          text: "Away",
          active: false,
        },
      ]}
      languages={[
        {
          value: "en",
          label: "English",
        },
      ]}
      activeLanguage={{
        title: "en",
        value: "en",
      }}
      onLanguageChange={() => {}}
      userMenuLinks={[
        {
          text: "Settings",
        },
      ]}
      userName="John Doe"
      marketName="Selected Market"
    />
  );
};
