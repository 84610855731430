import { createContext, useCallback, useContext, useState } from "react";

import { AuthProvider } from "bp-ui";
import History from "routers/History";

import { createFetchInstance } from "./axiosInterceptor.js";
import Admin from "./models/Admin.js";

export const AuthContext = createContext(null);

export const useAuth = () => {
  const result = useContext(AuthContext);

  if (!result) {
    throw new Error("components using useAuth must be wrapped in a AuthContext at some point");
  }

  return result;
};

const loadAdmin = async (fetch) => {
  const resp = await fetch.get("/session");
  return new Admin(resp.data.payload, fetch);
};

export const AuthWrapper = ({ children }) => {
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [logout, setLogout] = useState(() => null);
  const [getAuthHeaders, setGetAuthHeaders] = useState(() => null);

  const onRedirectCallback = useCallback((appState) => {
    History.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  }, []);

  const initAuth = useCallback(async ({ logout, getAuthHeaders }) => {
    const newFetch = createFetchInstance(getAuthHeaders);
    setCurrentAdmin(await loadAdmin(newFetch));
    setLogout(() => logout);
    setGetAuthHeaders(() => getAuthHeaders);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        getAuthHeaders,
        currentAdmin,
        logout,
      }}
    >
      <AuthProvider onRedirectCallback={onRedirectCallback} onAuth={initAuth}>
        {children}
      </AuthProvider>
    </AuthContext.Provider>
  );
};
