import React, { useCallback, useState } from "react";

import { Card, Link } from "bp-ui";
import { useTranslation } from "react-i18next";
import { FaBullhorn, FaRegCopy } from "react-icons/fa6";

import { UserCampaignDetails } from "components/customer_lookup/new_user/UserCampaignDetails/UserCampaignDetails";
import addNotification from "components/notifications/Notifications";

export const ReferrerCampaign = ({
  selectedMarket,
  campaign,
  user,
  timezoneOffset,
  reloadData,
}) => {
  const { t } = useTranslation();

  const [sectionOpen, setSectionOpen] = useState(false);

  const handleCopyToClipboard = useCallback((copyText) => {
    navigator.clipboard.writeText(copyText);
    addNotification.success(t("global.success"), t("components.copyText.notificationMessage"));
  }, []);

  return (
    <Card.Collapse
      key={campaign.name}
      open={sectionOpen}
      detail={
        <UserCampaignDetails
          selectedMarket={selectedMarket}
          campaign={campaign}
          user={user}
          timezoneOffset={timezoneOffset}
          reloadData={reloadData}
        />
      }
    >
      <div className="flex flex-row gap-6 my-8 items-start">
        <div className="flex flex-none w-2/6 flex-row">
          <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
          <Card.Detail border={false} label={t("referrerProfile.referrerInfoCard.campaign")}>
            <span className="flex text-xl justify-center">
              <FaBullhorn className="text-2xl mr-2" />
              {campaign.name}
            </span>
          </Card.Detail>
        </div>
        <div className="flex flex-col gap-8 flex-1">
          <Card.Detail label={t("referrerProfile.referrerInfoCard.shareUrl")}>
            <div>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleCopyToClipboard(campaign.shareUrl);
                }}
              >
                {campaign.shareUrl}
                <FaRegCopy className="ml-2 text-xl" />
              </Link>
            </div>
          </Card.Detail>
          <div className="flex flex-row flex-1">
            <Card.Detail
              className="flex-2"
              label={t("referrerProfile.referrerInfoCard.dashboardUrl")}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleCopyToClipboard(campaign.dashboardUrl);
                }}
              >
                {t("referrerProfile.referrerInfoCard.copyDashboardUrl")}
                <FaRegCopy className="ml-2 text-xl" />
              </Link>
            </Card.Detail>
            <Card.Detail label={t("referrerProfile.referrerInfoCard.referralsMade")}>
              <div className="text-dark-blue">
                {campaign.rewardCapping?.max
                  ? `${campaign.rewardCapping?.value}/${campaign.rewardCapping?.max}`
                  : t("referrerProfile.referrerInfoCard.rewardCapNotSet")}
              </div>
            </Card.Detail>
            <Card.Detail label={t("referrerProfile.referrerInfoCard.cappingTypeTitle")}>
              <div className="text-dark-blue">
                {t(
                  "referrerProfile.referrerInfoCard.cappingPeriod." + campaign.rewardCapping?.period
                ) +
                  " " +
                  t(
                    "referrerProfile.referrerInfoCard.cappingType." + campaign.rewardCapping?.type
                  ) +
                  " " +
                  t("referrerProfile.referrerInfoCard.cap")}
              </div>
            </Card.Detail>
          </div>
        </div>
        <div className="flex flex-none self-center">
          <Card.CollapseButton
            onClick={() => setSectionOpen(!sectionOpen)}
            collapsed={!sectionOpen}
            expandSectionLabel={t("global.expandSection")}
            collapseSectionLabel={t("global.collapseSection")}
          />
        </div>
      </div>
    </Card.Collapse>
  );
};
