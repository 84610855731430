import React from "react";

import { arrayOf, bool, func, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { MdInfo } from "react-icons/md";

import "./text_area.scss";

const TextArea = ({
  id,
  placeholderText = "",
  labelText = "",
  disabled = false,
  handleOnInput = () => {},
  messages = {},
  errors = [],
  errorKey = null,
}) => {
  const { t } = useTranslation();

  const noLabelText = t("bulkActions.textArea.noLabelText");

  const infoMessages = () => {
    if (messages.info) {
      return messages.info;
    }

    return [];
  };

  const errorMessages = () => {
    if (messages.errors) {
      return messages.errors;
    }

    return [];
  };

  const parsedErrorMessages = (errorObject) => {
    if (errorObject.messages) {
      return `\n${errorObject.messages.join("\n")}`;
    }

    return [];
  };

  return (
    <div className="c-text-area">
      <label className={labelText ? "" : noLabelText} htmlFor={id}>
        {labelText}
      </label>
      <textarea
        id={id}
        placeholder={placeholderText}
        autoComplete="off"
        onInput={handleOnInput}
        disabled={disabled}
      />
      {infoMessages().map((info) => (
        <div className="field-message-info" key={info}>
          <i className="field-message-info-icon">
            <MdInfo />
          </i>
          {info}
        </div>
      ))}
      {errorMessages().map((error) => (
        <div className="field-error-message" key={error}>
          <i className="field-error-icon">
            <MdInfo />
          </i>
          {error}
        </div>
      ))}
      {errors.map((error) => (
        <div className="field-error-message" key={error.type}>
          <i className="field-error-icon">
            <MdInfo />
          </i>
          {t(`forms.errors.${errorKey || id}.${error.type}`)}
          {parsedErrorMessages(error)}
        </div>
      ))}
    </div>
  );
};

TextArea.propTypes = {
  id: string.isRequired,
  placeholderText: string,
  labelText: string,
  disabled: bool,
  handleOnInput: func,
  messages: shape({}),
  errors: arrayOf(
    shape({
      type: string,
    })
  ),
  errorKey: string,
};

export default TextArea;
