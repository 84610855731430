import React, { useEffect, useRef, useState } from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FiCalendar } from "react-icons/fi";

import FormattedDate from "components/formatted_date/FormattedDate";
import CampaignHandler from "components/reports/campaign_handler/CampaignHandler";
import DateRangePicker from "components/reports/date_picker/date_range_picker/DateRangePicker";
import DateRangeSelector from "components/reports/date_picker/date_range_selector/DateRangeSelector";

import "./date_range_selected.scss";

const DateRangeSelected = ({
  selectedStartDate,
  selectedEndDate,
  timezoneOffset,
  statsStartingDate = null,
  action = null,
}) => {
  const { t } = useTranslation();
  const [showSelector, setShowSelector] = useState(false);
  const datePickerRef = useRef();
  const toggleSelector = () => setShowSelector(!showSelector);
  const dateRangeClasses = classnames("c-date-range-handle", {
    "c-date-range-handle--active": showSelector,
  });

  const handleClickOutside = ({ target }) =>
    datePickerRef.current && !datePickerRef.current.contains(target) && setShowSelector(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => document.removeEventListener("click", handleClickOutside, true);
  });

  return (
    <div className="date-range-selector" ref={datePickerRef}>
      <div className={dateRangeClasses}>
        <CampaignHandler
          icon={<FiCalendar />}
          title={`${t("reporting.management.downloadCard.dateText")}:`}
          onClick={toggleSelector}
          isActive={showSelector}
        >
          <FormattedDate
            timestamp={selectedStartDate}
            timezoneOffset={timezoneOffset}
            type="short"
          />
          {" - "}
          <FormattedDate timestamp={selectedEndDate} timezoneOffset={timezoneOffset} type="short" />
        </CampaignHandler>
      </div>
      {showSelector &&
        (action ? (
          <DateRangePicker
            timezoneOffset={timezoneOffset}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            closeDateRangeSelector={toggleSelector}
            action={action}
          />
        ) : (
          <DateRangeSelector
            timezoneOffset={timezoneOffset}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            closeDateRangeSelector={toggleSelector}
            statsStartingDate={statsStartingDate}
          />
        ))}
    </div>
  );
};

DateRangeSelected.propTypes = {
  timezoneOffset: PropTypes.string.isRequired,
  selectedStartDate: PropTypes.number.isRequired,
  selectedEndDate: PropTypes.number.isRequired,
  statsStartingDate: PropTypes.number,
  action: PropTypes.func,
};

export default DateRangeSelected;
