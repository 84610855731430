import { useState } from "react";

export const Switch = ({
  className,
  onChange,
  disabled,
  onClick,
  value,
  label,
  labelPosition = "left",
}) => {
  return (
    <>
      {label && labelPosition === "left" && (
        <span className="mr-3" aria-hidden>
          {label}
        </span>
      )}
      <label
        className={`relative inline-flex items-center cursor-pointer ${className || ""}`}
        onClick={onClick}
      >
        <span className="hidden">{label}</span>
        <input
          type="checkbox"
          className="sr-only peer"
          onChange={onChange}
          disabled={disabled}
          checked={value}
        />
        <div className="w-8 h-5 bg-dark-grey peer-checked:bg-dark-green peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      </label>
      {label && labelPosition === "right" && (
        <span className="ml-3" aria-hidden>
          {label}
        </span>
      )}
    </>
  );
};

export const Preview = () => {
  const [state, setState] = useState(false);

  return (
    <Switch
      label="Switch"
      value={state}
      onChange={() => {
        setState(!state);
      }}
    />
  );
};
