import PropTypes from "prop-types";

const FormattedNumber = ({ number, displayType, roundTo }) => {
  let formattedValue;

  switch (displayType) {
    case "decimal":
      formattedValue = number.toFixed(roundTo);
      break;
    case "percentage": {
      const percentage = number * 100;
      formattedValue = `${percentage.toFixed(roundTo)}%`;
      break;
    }
    default:
      formattedValue = number.toFixed(roundTo);
  }

  return formattedValue;
};

FormattedNumber.propTypes = {
  number: PropTypes.number.isRequired,
  displayType: PropTypes.string,
  roundTo: PropTypes.number.isRequired,
};

export default FormattedNumber;
