import React, { useState } from "react";

import { Button, Card, Link, Modal } from "bp-ui";
import { t } from "i18next";

import TextInput from "components/forms/text_input/TextInput";

export const CustomField = ({ selectedMarket, campaign, user, customField, reloadData }) => {
  const [loading, setLoading] = useState(false);
  const [showEditCustomField, setShowEditCustomField] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [customFieldValue, setCustomFieldValue] = useState(customField.value);

  const handleCloseModal = () => {
    setShowEditCustomField(false);
    setCustomFieldValue(customField.value);
    setFormErrors({});
  };

  const handleChange = (e) => {
    setCustomFieldValue(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setFormErrors({});

    const newCustomField = {
      [campaign.id]: [{ key: customField.label, value: customFieldValue }],
    };

    try {
      await selectedMarket.users.updateUser(user.id, { customFields: newCustomField });

      reloadData && reloadData();
      setShowEditCustomField(false);
      setLoading(false);
    } catch (e) {
      if (e && e.errors) {
        setFormErrors(e.errors);
      }
      setLoading(false);
    }
  };

  return (
    <Card.Detail
      key={customField.label}
      border={false}
      label={customField.label}
      className="flex-none w-1/5"
    >
      {customField.value}
      <Link
        className="text-sm"
        onClick={(e) => {
          e.preventDefault();
          setShowEditCustomField(true);
        }}
      >
        {t("referrerProfile.editButton")}
      </Link>
      <Modal
        onClose={handleCloseModal}
        open={showEditCustomField}
        title={`${t("referrerProfile.editCustomFieldTitle")}${campaign.name}`}
        className="!max-w-1/3"
      >
        <form>
          <TextInput
            labelText={customField.label}
            id="customField"
            value={customFieldValue}
            required
            handleChange={handleChange}
            errors={formErrors[`custom_fields.${campaign.id}.0`]}
          />

          <div className="flex gap-3 mt-10">
            <Button className="flex-1" secondary={true} onClick={handleCloseModal}>
              {t("global.cancel")}
            </Button>
            <Button
              appearance="tertiary"
              loading={loading}
              className="flex-1"
              onClick={handleSubmit}
            >
              {t("global.save")}
            </Button>
          </div>
        </form>
      </Modal>
    </Card.Detail>
  );
};
