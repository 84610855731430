import React, { useEffect, useRef } from "react";

import i18next from "i18next";
import PropTypes from "prop-types";

import "./text_area.scss";

const TextArea = ({
  id,
  labelText,
  handleChange,
  labelClass = "",
  required = false,
  placeholder = "",
  value = "",
  focus = false,
  hideLabel = false,
  disableLastPassAutofill = true,
  errors = [],
  errorKey = null,
  handleOnKeyDown = () => {},
  inputType = "text",
  className = "",
}) => {
  const inputRef = useRef(null);
  const classes = ["field-group"];

  if (errors.length > 0) {
    classes.push("field-error");
  }

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, inputRef]);

  return (
    <div className="c-text-area">
      <label className={hideLabel ? "no-label" : labelClass} htmlFor={id}>
        {labelText}
      </label>
      <textarea
        className={className}
        type={inputType}
        required={required}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        ref={inputRef}
        autoComplete="off"
        data-lpignore={disableLastPassAutofill}
      />
      {errors.map((error) => (
        <div className="field-error-message" key={error}>
          {i18next.t(`forms.errors.${errorKey || id}.${error.type}`)}
        </div>
      ))}
    </div>
  );
};

TextArea.propTypes = {
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  hideLabel: PropTypes.bool,
  disableLastPassAutofill: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    })
  ),
  errorKey: PropTypes.string,
  handleOnKeyDown: PropTypes.func,
  inputType: PropTypes.oneOf(["text", "password", "email"]),
  className: PropTypes.string,
  labelClass: PropTypes.string,
};

export default TextArea;
