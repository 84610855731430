import React, { forwardRef, useCallback } from "react";

import { t } from "i18next";
import Market from "models/Market";
import { PropTypes, instanceOf } from "prop-types";
import {
  FaFacebook,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaSnapchatGhost,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import CheckboxDropdown from "components/forms/checkbox_dropdown/CheckboxDropdown";
import Dropdown from "components/forms/dropdown/Dropdown";
import LocaleDropdown from "components/forms/locale_dropdown/LocaleDropdown";
import MobilePhoneDropdown from "components/forms/mobile_phone_dropdown/MobilePhoneDropdown";
import TextInput from "components/forms/text_input/TextInput";
import {
  PartnerProfileErrorType,
  PartnerProfileType,
} from "components/partner_management/PartnerPropTypes";

import "./fields.scss";

const Fields = forwardRef(function Fields(
  { selectedMarket, action, form, setFormFields, validateEmail },
  ref
) {
  const handleInputChange = ({ target }, name) => {
    const { id, value, type } = target;
    const inputValue = type === "number" ? parseInt(value, 10) : value;
    const key = name || id;
    setFormFields(key, inputValue);
  };

  const handleDropdownChange = ({ value }, { name }) => {
    setFormFields(name, value);
  };

  const handleCountryCodeChange = (countryCode) => {
    setFormFields("mobileCountryCode", countryCode);
  };

  const handleLocaleChange = useCallback(
    (locale) => {
      setFormFields("locale", locale);
    },
    [setFormFields]
  );

  const handleMultiSelectDropdownChange = (name, selected) => {
    const values = selected.map((option) => option.value);
    setFormFields(name, values);
  };

  const formatDropdownData = (key, includeBlank = false) =>
    [...(includeBlank ? [""] : []), ...t(key, { returnObjects: true })].map((string) => ({
      label: string,
      value: string,
    }));
  const worldSectorList = formatDropdownData("worldSector");

  const liveCampaigns = selectedMarket
    .visibleCampaigns()
    .filter((c) => c.live && c.programType === "partner");
  const campaignFormValue = (campaign) => ({
    value: campaign.id,
    label: campaign.name,
  });
  const campaignOptions = liveCampaigns.map(campaignFormValue);
  const currentCampaign = liveCampaigns.find((campaign) => campaign.id === form.fields.campaignId);
  const currentCampaignValue = currentCampaign && campaignFormValue(currentCampaign);

  const infoBoxContent = (key) => t(`forms.infoText.${key}`);
  const isCreate = action === "Create";
  const actionName = () =>
    isCreate ? t("forms.headers.addPartner") : t("forms.headers.editProfile");

  return (
    <IconContext.Provider value={{ color: "#9fb4c2" }}>
      <h1>{actionName()}</h1>
      <p className="c-partner-profile__info">
        {t(`partnerProfile.${action.toLowerCase()}FormIntro`)}
      </p>
      <h2>{t("partnerProfile.profile")}</h2>
      <div className="partner-form__block">
        <TextInput
          labelText={t("forms.labels.partner.businessName")}
          inputType="text"
          id="businessName"
          testId="businessName"
          value={form.fields.businessName}
          handleChange={handleInputChange}
          showRequiredIndicator
          errors={form.formErrors?.business_name}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.firstName")}
          inputType="text"
          id="firstName"
          testId="firstName"
          value={form.fields.firstName}
          handleChange={handleInputChange}
          showRequiredIndicator
          errors={form.formErrors?.first_name}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.lastName")}
          inputType="text"
          id="lastName"
          testId="lastName"
          value={form.fields.lastName}
          handleChange={handleInputChange}
          showRequiredIndicator
          errors={form.formErrors?.last_name}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.email")}
          inputType="text"
          id="email"
          testId="email"
          value={form.fields.email}
          handleChange={handleInputChange}
          handleOnBlur={validateEmail}
          showRequiredIndicator
          errors={form.formErrors?.email}
          addInputStyling
          ref={ref}
        />
        <MobilePhoneDropdown
          id="mobile"
          phoneNumberErrorKey="mobile"
          countryCodeErrorKey="mobileCountryCode"
          labelText={t("forms.labels.partner.mobile")}
          mobileCountryCode={form.fields.mobileCountryCode}
          mobile={form.fields.mobile}
          phoneNumberErrors={form.formErrors?.mobile}
          countryCodeErrors={form.formErrors?.mobile_country_code}
          handleMobileChange={handleInputChange}
          handleCountryCodeChange={handleCountryCodeChange}
          showRequiredIndicator
        />
        <LocaleDropdown
          localeErrors={form.formErrors?.locale}
          localeErrorKey="locale"
          handleLocaleChange={handleLocaleChange}
          marketLocales={selectedMarket.supported_locales}
          selectedLocale={form.fields.locale}
        />
        <TextInput
          labelText={t("forms.labels.partner.jobTitle")}
          icon={<FaLink />}
          inputType="text"
          id="jobTitle"
          testId="jobTitle"
          value={form.fields.jobTitle}
          handleChange={handleInputChange}
          errors={form.formErrors?.job_title}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.businessCountry")}
          inputType="text"
          id="businessCountry"
          testId="businessCountry"
          value={form.fields.businessCountry}
          handleChange={handleInputChange}
          errors={form.formErrors?.business_country}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.website")}
          icon={<FaLink />}
          inputType="text"
          id="website"
          testId="website"
          value={form.fields.website}
          handleChange={handleInputChange}
          errors={form.formErrors?.website}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.twitter")}
          icon={<FaTwitter />}
          inputType="text"
          id="twitter"
          testId="twitter"
          value={form.fields.twitter}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("twitter")}
          errors={form.formErrors?.twitter_handle}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.instagram")}
          icon={<FaInstagram />}
          inputType="text"
          id="instagram"
          testId="instagram"
          value={form.fields.instagram}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("instagram")}
          errors={form.formErrors?.instagram_handle}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.facebook")}
          icon={<FaFacebook />}
          inputType="text"
          id="facebook"
          testId="facebook"
          value={form.fields.facebook}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("facebook")}
          errors={form.formErrors?.facebook_handle}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.linkedin")}
          icon={<FaLinkedin />}
          inputType="text"
          id="linkedin"
          testId="linkedin"
          value={form.fields.linkedin}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("linkedin")}
          errors={form.formErrors?.linkedin_handle}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.youtube")}
          icon={<FaYoutube />}
          inputType="text"
          id="youtube"
          testId="youtube"
          value={form.fields.youtube}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("youtube")}
          errors={form.formErrors?.youtube_handle}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.snapchat")}
          icon={<FaSnapchatGhost />}
          inputType="text"
          id="snapchat"
          testId="snapchat"
          value={form.fields.snapchat}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("snapchat")}
          errors={form.formErrors?.snapchat_handle}
          addInputStyling
        />
        <TextInput
          labelText={t("forms.labels.partner.signupReason")}
          inputType="text"
          id="signupReason"
          testId="signupReason"
          value={form.fields.signupReason}
          handleChange={handleInputChange}
          showRequiredIndicator
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("signupReason")}
          errors={form.formErrors?.signup_reason}
          addInputStyling
        />
      </div>

      <h2>{t("partnerProfile.audience")}</h2>

      <p className="c-partner-profile__info">{t("partnerProfile.audienceInfo")}</p>

      <div className="partner-form__block">
        <TextInput
          labelText={t("forms.labels.partner.audienceSize")}
          inputType="number"
          id="audienceSize"
          testId="audienceSize"
          value={`${form.fields.audienceSize}`}
          handleChange={handleInputChange}
          showInfoIcon
          tooltipDirection="up"
          infoText={infoBoxContent("audienceSize")}
          errors={form.formErrors?.audience_size}
          addInputStyling
        />
        <CheckboxDropdown
          name="audienceLocation"
          className="field-group partner-form__modal-select"
          labelText={t("forms.labels.partner.audienceLocation")}
          placeholder={t("forms.labels.partner.worldSector")}
          tooltipText={infoBoxContent("audienceLocation")}
          options={worldSectorList}
          value={
            form.fields.audienceLocation &&
            form.fields.audienceLocation.map((location) => ({
              label: location,
              value: location,
            }))
          }
          required
          errors={form.formErrors?.audience_location}
          handleOnChange={handleMultiSelectDropdownChange}
        />
      </div>

      {isCreate && (
        <>
          <h2>{t("partnerProfile.campaigns")}</h2>
          <p className="c-partner-profile__info">{t("partnerProfile.formCampaignInfo")}</p>
          <div className="partner-form__block">
            <Dropdown
              className="field-group partner-form__modal-select"
              placeholder={t("forms.labels.campaignPlaceholder")}
              showRequiredIndicator
              isSearchable={false}
              onChange={handleDropdownChange}
              name="campaignId"
              labelText={t("forms.labels.campaign")}
              value={currentCampaignValue}
              options={campaignOptions}
              errors={form.formErrors?.campaign_id}
            />
          </div>
        </>
      )}
    </IconContext.Provider>
  );
});

export default Fields;

Fields.propTypes = {
  selectedMarket: instanceOf(Market).isRequired,
  action: PropTypes.string.isRequired,
  form: PropTypes.shape({
    fields: PartnerProfileType,
    formErrors: PartnerProfileErrorType,
  }).isRequired,
  setFormFields: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
};
