import React from "react";

import { Card } from "../Card.jsx";

export const Row = ({ className, children, border = true }) => {
  return (
    <>
      <div
        className={`flex-auto ${border ? "pt-4" : "py-4"} ${className ? className : ""}`}
      >
        {children}
      </div>
      {border ? (
        <div
          className={`border-0 border-solid border-b border-grey h-px mt-4`}
        />
      ) : null}
    </>
  );
};

export const Preview = () => {
  return (
    <Card>
      <Row>Row with text content</Row>
    </Card>
  );
};
