import React from "react";

import Order from "models/Order";
import PropTypes from "prop-types";

import ReferralLinked from "components/customer_lookup/user/user_orders/user_order/referral_linked/ReferralLinked";
import VoucherCode from "components/customer_lookup/voucher_code/VoucherCode";
import FormattedDate from "components/formatted_date/FormattedDate";
import Placeholder from "components/placeholder/Placeholder";

const UserOrder = ({ order, timezoneOffset }) => (
  <tr className="single-result" key={order.id}>
    <td>{order.clientOrderId}</td>
    <td>
      <FormattedDate timestamp={order.date} timezoneOffset={timezoneOffset} type="long" />
    </td>
    <td>
      <Placeholder value={order.currency + order.value} />
    </td>
    <td>
      <VoucherCode>
        <Placeholder value={order.voucherCode} />
      </VoucherCode>
    </td>
    <td>
      <ReferralLinked order={order} />
    </td>
  </tr>
);

UserOrder.propTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default UserOrder;
