import React from "react";

import StatusModel from "models/Status";
import PropTypes from "prop-types";
import { FaCheck, FaHourglassEnd, FaTimes } from "react-icons/fa";

const ReferralStatus = ({ status }) => {
  if (status.isFulfilled()) {
    return <FaCheck />;
  }
  if (status.isCancelled()) {
    return <FaTimes />;
  }
  return <FaHourglassEnd />;
};

ReferralStatus.propTypes = {
  status: PropTypes.instanceOf(StatusModel).isRequired,
};

export default ReferralStatus;
