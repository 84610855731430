export default class Voucher {
  constructor(data) {
    this.id = data.id;
    this.voucherType = data.voucher_type;
    this.code = data.code;
    this.distributedByEmail = data.distributed_by_email;
    this.distributedByFullName = data.distributed_by_full_name;
    this.redeemerEmail = data.redeemer_email;
    this.redeemerFullName = data.redeemer_full_name;
    this.redeemed = data.redeemed;
  }

  distributedByDataPresent() {
    return this.distributedByEmail || this.distributedByFullName;
  }
}
