import React from "react";

import Order from "models/Order";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import NoResults from "components/customer_lookup/no_results/NoResults";
import UserOrder from "components/customer_lookup/user/user_orders/user_order/UserOrder";

const UserOrders = ({ orders, timezoneOffset }) => {
  const { t } = useTranslation();

  const headerText = t("orders.header");
  const tableHeaderCodeUsedText = t("orders.tableHeaders.codeUsed");
  const tableHeaderMadeOnText = t("orders.tableHeaders.madeOn");
  const tableHeaderReferralLinkedext = t("orders.tableHeaders.referralLinked");
  const tableHeaderReferenceText = t("orders.tableHeaders.reference");
  const tableHeaderValueext = t("orders.tableHeaders.value");

  return orders.length > 0 ? (
    <div id="table user-orders" className="card">
      <h2 className="card__title">{headerText}</h2>
      <div className="table-container">
        <table className="portal-table">
          <thead>
            <tr>
              <th>{tableHeaderReferenceText}</th>
              <th>{tableHeaderMadeOnText}</th>
              <th>{tableHeaderValueext}</th>
              <th>{tableHeaderCodeUsedText}</th>
              <th>{tableHeaderReferralLinkedext}</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <UserOrder key={order.id} order={order} timezoneOffset={timezoneOffset} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <NoResults table={headerText} />
  );
};

UserOrders.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.instanceOf(Order)).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default UserOrders;
