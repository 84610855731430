import React from "react";

import i18next from "i18next";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import FormattedDate from "components/formatted_date/FormattedDate";

import "./totals.scss";

const Totals = ({ text, value, date, timezoneOffset }) => {
  const { t } = useTranslation();
  const sinceText = t("reporting.dashboard.overview.totals.since");
  return (
    <div className="totals">
      <span className="totals__value">{value.toLocaleString(i18next.language)}</span>
      <span className="totals__title">{text}</span>
      <span className="totals__meta">
        {sinceText}{" "}
        <FormattedDate timestamp={date} type="numeral_short" timezoneOffset={timezoneOffset} />
      </span>
    </div>
  );
};

Totals.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  date: PropTypes.number.isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default Totals;
