import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import PropTypes from "prop-types";

const VoucherCode = ({ children }) => {
  const { currentAdmin } = useAuth();
  const { revealVouchers } = Permissions;

  if (currentAdmin.can(revealVouchers)) {
    return children;
  }

  return "*".repeat(7);
};

VoucherCode.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default VoucherCode;
