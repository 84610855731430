import StatsMain from "models/StatsMain";
import StatsTimeline from "models/StatsTimeline";

class Stats {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async main(campaignIds, startDate, endDate) {
    const resp = await this.axios.post(`${import.meta.env.VITE_BASE_API_URL}/stats/main`, {
      campaign_ids: campaignIds,
      start_date: `${startDate}`,
      end_date: `${endDate}`,
    });

    return new StatsMain(resp.data.payload);
  }

  async timeline(campaignIds, startDate, endDate) {
    const resp = await this.axios.post(`${import.meta.env.VITE_BASE_API_URL}/stats/timeline`, {
      campaign_ids: campaignIds,
      start_date: `${startDate}`,
      end_date: `${endDate}`,
    });

    return new StatsTimeline(resp.data.payload);
  }
}

export default Stats;
