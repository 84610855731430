import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import "./info_box.scss";

const InfoBox = ({ type = "primary", children = [] }) => {
  const infoBoxClasses = classnames("c-info-box", {
    [`c-info-box--${type}`]: type,
  });

  return <div className={infoBoxClasses}>{children}</div>;
};

InfoBox.propTypes = {
  type: PropTypes.oneOf(["primary", "positive", "negative"]),
  children: PropTypes.node,
};

export default InfoBox;
