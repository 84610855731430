import { FiInfo } from "react-icons/fi";

import { Tooltip } from "../Tooltip/Tooltip";
import { StatusIcons, Statuses } from "../enums";

export const Status = ({ type, label, icon: Icon, tooltip }) => {
  if (!Icon) Icon = StatusIcons[type];

  const statusClass = () => {
    switch (type) {
      case Statuses.SUCCESS:
        return "text-dark-green";
      case Statuses.ERROR:
        return "text-red";
      case Statuses.BUSY:
        return "text-dark-blue";
      case Statuses.OK:
        return "text-mid-blue";
      case Statuses.WARNING:
        return "text-orange";
      default:
      case Statuses.DEFAULT:
        return "text-mid-grey";
    }
  };

  return (
    <Tooltip content={tooltip || ""} direction="up" useHover={!!tooltip}>
      <div className={`flex items-center text-base font-bold ${statusClass()}`}>
        {<Icon className="mr-1 text-lg" />}
        {label}
        {tooltip && (
          <div className="flex items-center ml-1 text-mid-blue">
            <FiInfo />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export const Preview = () => {
  return (
    <div className="flex flex-col gap-3">
      {Object.keys(Statuses).map((status) => (
        <Status
          type={Statuses[status]}
          key={status}
          label={status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        />
      ))}
    </div>
  );
};
