import React, { useState } from "react";

import cn from "classnames";
import i18next from "i18next";
import { arrayOf, bool, element, number, object, oneOfType, shape, string } from "prop-types";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import Select, { components as prebuiltComponents } from "react-select";

import Tooltip from "../../tooltip/Tooltip";

// https://react-select.com/props

// All dropdown styling outside of this object is in _dropdown.scss
// Easier to hook into state from this object, but Buyapowa colour scheme
// is more easily accessed from the scss. There is a theme object that
// is available in react-select, that hasn't been used to minimise number
// changes needed to edit global theme.
const customStyles = {
  control: () => null,
  valueContainer: () => null,
  placeholder: () => null,
  dropdownIndicator: (provided) => ({ ...provided, color: "rgba(24, 84, 120, 1)" }),
  input: (provided) => ({ "&:after": provided["&:after"] }),
  option: (provided, state) => ({
    ...provided,
    height: "2.5em",
    padding: "0 1.25em",
    display: "flex",
    alignItems: "center",
    backgroundColor: state.isFocused ? "rgba(var(--b-blue), 0.08)" : "white",

    color: null,
    fontWeight: state.isSelected ? "700" : "400",
  }),
  menuList: (provided) => ({ ...provided, padding: "0em" }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "10px",
    marginTop: "0.3125em",
    boxShadow: "2px 2px 10px  0 rgba(21, 40, 50, 0.2)",
    padding: "0.96875em 0",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: null,
  }),
};

// Used the explanation linked below to embed the search icon and
// colour it from within the react-select component.
// Explanation of this block: https://react-select.com/components#defining-components

const ValueContainer = ({ children, ...props }) => {
  const hasContent = props.selectProps?.inputValue || props.hasValue ? "content" : "no-content";
  return (
    <div className="c-dropdown__search-container">
      {props?.selectProps?.showSearchIcon ? (
        <div className={cn("c-dropdown__search-icon", `c-dropdown__${hasContent}`)}>
          {props?.selectProps?.showSearchIcon && <IoIosSearch />}
        </div>
      ) : (
        <div className="c-dropdown__no-search-icon" />
      )}
      <prebuiltComponents.ValueContainer {...props}>{children}</prebuiltComponents.ValueContainer>
    </div>
  );
};

ValueContainer.propTypes = {
  children: arrayOf(element).isRequired,
  selectProps: shape({
    inputValue: string.isRequired,
    showSearchIcon: bool.isRequired,
  }).isRequired,
  hasValue: bool.isRequired,
};

const Dropdown = (DropdownProps) => {
  const [tipActive, setTipActive] = useState(false);

  const {
    labelText,
    components,
    customStyles: selectStyles = null,
    className = "",
    placeholder = "Select your choice",
    menuState = "Default",
    isDisabled = false,
    showTooltip = false,
    infoText = "",
    errors = [],
    errorKey = "",
    id = "",
    showRequiredIndicator = false,
    tooltipDirection = "up",
    tooltipSpacing = "",
    tooltipShift = 0,
    tooltipPlain = true,
    showSearchIcon = true,
    name = "",
    ...props
  } = DropdownProps;

  return (
    <div
      className={cn({ "c-dropdown__disabled": isDisabled }, className)}
      data-testid="dropdown-component"
    >
      <div className="c-dropdown__header">
        <div className="flex-between">
          <label
            className={cn({
              "c-dropdown__label": !showRequiredIndicator,
              "c-dropdown__label-required": showRequiredIndicator,
            })}
          >
            {labelText}
          </label>
          <div
            className={cn("c-dropdown__info-container", {
              "c-dropdown__tooltip-active": tipActive,
            })}
          >
            {
              // Use info-spacing-x to set a spacing of x pixels either side of the tooltip
              // content popup
              // This px value is converted to em units.
            }
            {showTooltip && (
              <Tooltip
                tipContentClassName={cn({
                  "c-dropdown__info-plain": tooltipPlain,
                  "c-dropdown__info": !tooltipPlain,
                  [`info-left-${tooltipShift}`]: !tooltipPlain,
                  [`info-spacing-${tooltipSpacing}`]: !tooltipPlain && tooltipSpacing,
                })}
                content={infoText}
                direction={tooltipDirection}
                onToggle={setTipActive}
              >
                <FaInfoCircle />
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      <Select
        name={name}
        placeholder={placeholder}
        showSearchIcon={showSearchIcon}
        classNamePrefix="c-dropdown"
        styles={{ ...customStyles, ...selectStyles }}
        captureMenuScroll
        closeMenuOnSelect
        openMenuOnFocus
        {...{ id }}
        {...(menuState !== "Default" && { menuIsOpen: menuState === "Open" })}
        components={{ ValueContainer, ...components }}
        {...props}
      />
      {errors.map((error) => (
        <div className="field-error-message" key={error}>
          {i18next.t(`forms.errors.${errorKey || id || name}.${error.type}`)}
        </div>
      ))}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  customStyles: object,
  className: string,
  labelText: string.isRequired,
  isSearchable: bool.isRequired,
  placeholder: string,
  options: arrayOf(shape({ value: string, label: string })).isRequired,
  menuState: string,
  isDisabled: bool,
  errors: arrayOf(shape({ type: string })),
  errorKey: string,
  id: string,
  showTooltip: bool,
  showRequiredIndicator: bool,
  infoText: string,
  tooltipDirection: string,
  tooltipSpacing: oneOfType([number, string]),
  tooltipShift: number,
  tooltipPlain: bool,
  showSearchIcon: bool,
  name: string,
};
