import RewardType from "./RewardType";

export default class Campaign {
  constructor(data) {
    this.id = data.id;
    this.live = data.live;
    this.name = data.name;
    this.slug = data.slug;
    this.showInPortal = data.show_in_portal;
    this.referrerCustomField = data.customer_custom_field;
    this.referrerCustomFields = data.referrer_custom_fields;
    this.friendCustomField = data.external_user_custom_field;
    this.friendCustomFields = data.friend_custom_fields;
    this.default = data.default;
    this.statsStartingAt = data.stats_starting_at;
    this.rewardTypes = [];
    this.programType = data.program_type || null;

    if (data.reward_types) {
      this.rewardTypes = data.reward_types.map((rewardType) => new RewardType(rewardType));
    }

    this.postPurchaseTracking = null;
    this.referrerRegistration = {};
    this.friendRegistration = {};
    this.allowedReportTypes = [];

    if (data.features) {
      this.postPurchaseTracking = data.features.post_purchase_tracking;

      if (data.features.referrer_registration) {
        this.referrerRegistration = {
          showEmailComplianceOptIn:
            data.features.referrer_registration.show_email_compliance_opt_in,
          showMarketingOptIn: data.features.referrer_registration.show_marketing_opt_in,
          showTermsOptIn: data.features.referrer_registration.show_terms_opt_in,
        };
      }

      if (data.features.friend_registration) {
        this.friendRegistration = {
          showMarketingOptIn: data.features.friend_registration.show_marketing_opt_in,
          showTermsOptIn: data.features.friend_registration.show_terms_opt_in,
        };
      }

      if (data.features.reports) {
        this.allowedReportTypes = data.features.reports.allowed_types;
      }
    }
  }

  customFieldsFor(user) {
    if (user.isCustomer()) {
      return this.referrerCustomFieldsConfigured();
    }

    if (user.isExternalUser()) {
      return this.friendCustomFieldsConfigured();
    }

    return [];
  }

  referrerCustomFieldsConfigured() {
    return this.customFieldsConfigured(this.referrerCustomFields, this.referrerCustomField);
  }

  friendCustomFieldsConfigured() {
    return this.customFieldsConfigured(this.friendCustomFields, this.friendCustomField);
  }

  customFieldsConfigured(customFields, customField) {
    if (customFields.length > 0) {
      return customFields;
    }

    if (customField) {
      return [customField];
    }

    return [];
  }

  customFieldFor(user) {
    if (user.isCustomer()) {
      return this.referrerCustomField;
    }

    return this.friendCustomField;
  }

  hasAnyManuallyIssuableRewardTypesFor(user) {
    return this.rewardTypes.some((rt) => rt.canBeIssuedTo(user));
  }
}
