import Fulfilment from "models/Fulfilment";
import PartnerLocale from "models/PartnerLocale";
import Referral from "models/Referral";

export default class Partner {
  constructor(data) {
    this.id = data.id;
    this.user_id = data.user_id;
    this.type = data.user_type;
    this.profile = {
      firstName: data.first_name || "",
      lastName: data.last_name || "",
      email: data.email || "",
      locale: data.locale || "",
      businessName: data.business_name || "",
      jobTitle: data.job_title || "",
      industrySector: data.industry_sector || "",
      businessCountry: data.business_country || "",
      website: data.website || "",
      signupReason: data.signup_reason || "",
      facebook: data.facebook_handle || "",
      twitter: data.twitter_handle || "",
      instagram: data.instagram_handle || "",
      snapchat: data.snapchat_handle || "",
      linkedin: data.linkedin_handle || "",
      youtube: data.youtube_handle || "",
      audienceSize: data.audience_size || 0,
      audienceLocation: data.audience_location || [],
      mobile: data.mobile || "",
      mobileCountryCode: data.mobile_country_code || "",
    };
    this.partnerLocales = data.partner_locales.map(
      (partnerLocaleData) => new PartnerLocale(partnerLocaleData)
    );
    this.campaignUrls = data.campaign_urls;
    this.audienceSizeMeasuredAt = data.audience_size_measured_at;

    this.referrals = data.referrals.map((referralData) => new Referral(referralData));
    this.fulfilments = data.fulfilments.map((fulfilmentData) => new Fulfilment(fulfilmentData));
  }

  displayType() {
    return "partner";
  }

  isA(className) {
    return className === "Partner";
  }

  isCustomer() {
    return false;
  }

  isExternalUser() {
    return false;
  }
}
