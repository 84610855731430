import React, { useReducer } from "react";

import { translateFromEn } from "helpers/Helpers";
import { useGetPartnerPerformance } from "hooks/useGetPartnerPerformance";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DownloadToCsv from "components/download_csv/DownloadToCsv";
import PartnerPerformanceTable from "components/partner_management/program_performance_table/ProgramPerformanceTable";
import Spinner from "components/spinner/Spinner";

const Performance = ({ selectedMarket, partnerId }) => {
  const initialPartnerPerformanceResponseState = {
    programPerformance: {
      monthly: [],
      totals: {
        visits: 0,
        created: 0,
        cancelled: 0,
        confirmed: 0,
      },
    },
    isPartnerPerformanceLoading: false,
  };

  const partnerPerformanceResponseReducer = (state, action) => {
    switch (action.type) {
      case "loading":
        return {
          ...state,
          isPartnerPerformanceLoading: true,
        };
      case "loadedSuccessfully":
        return {
          ...state,
          partnerPerformance: action.programPerformance,
          isPartnerPerformanceLoading: false,
        };
      case "errorsOccurred":
        throw action.errors;
      default:
        throw new Error("Invalid Action Type for partnerResponseReducer");
    }
  };

  const { t, i18n } = useTranslation();

  const [{ partnerPerformance, isPartnerPerformanceLoading }, partnerPerformanceDispatch] =
    useReducer(partnerPerformanceResponseReducer, initialPartnerPerformanceResponseState);

  useGetPartnerPerformance(selectedMarket, partnerPerformanceDispatch, partnerId);

  const csvHeaders = [
    { label: t("partners.programPerformance.tableHeaders.month"), key: "date" },
    { label: t("partners.programPerformance.tableHeaders.visits"), key: "visits" },
    { label: t("partners.programPerformance.tableHeaders.created"), key: "created" },
    { label: t("partners.programPerformance.tableHeaders.confirmed"), key: "confirmed" },
    { label: t("partners.programPerformance.tableHeaders.cancelled"), key: "cancelled" },
  ];

  const csvData = partnerPerformance?.monthly.map((monthlyDetails) => {
    const { date, visits, confirmed, created, cancelled } = monthlyDetails;
    return {
      date: translateFromEn(date, "MMMM yyyy", i18n.resolvedLanguage),
      visits,
      created,
      confirmed,
      cancelled,
    };
  });

  return (
    <div className="card">
      <div className="c-partner-profile__profile">
        <div className="card__toolbar">
          <h2 className="card__title">{t("partnerProfile.performance")}</h2>

          {csvData && (
            <DownloadToCsv
              headers={csvHeaders}
              data={csvData}
              filename="partner-performance.csv"
              buttonCopy={t("partnerProfile.downloadPerformanceCsv")}
            />
          )}
        </div>

        <p className="c-partner-profile__description">
          {t("partnerProfile.performanceSubHeading")}
        </p>

        {isPartnerPerformanceLoading || !partnerPerformance ? (
          <Spinner showSpinner loadingText="" fullHeight={false} />
        ) : (
          <PartnerPerformanceTable partnerPerformance={partnerPerformance} />
        )}
      </div>
    </div>
  );
};

Performance.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  partnerId: PropTypes.string.isRequired,
};

export default Performance;
