import React, { useEffect, useState } from "react";

import { Button, Dropdown, Modal } from "bp-ui";
import { camelCase } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ConfirmExistingFriendModal from "./ConfirmExistingFriendModal.jsx";
import CreateReferralModal from "./CreateReferralModal.jsx";

const CreateReferral = ({
  open,
  onClose,
  onReferralCreated,
  selectedMarket,
  campaignParticipation,
  referrerEmail,
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);

  const usersCampaigns = selectedMarket.visibleCampaigns().filter((c) => {
    const interactedCampaigns = campaignParticipation.find((cp) => cp.campaign.id === c.id);

    return c.live && !!interactedCampaigns;
  });

  const handleClose = () => {
    setConfirmationData(null);
    setErrors(null);
    onClose();
    setIsLoading(false);
  };

  const onSubmit = async (data, event, force) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors(null);

    if (!force) setConfirmationData(data);

    const rewardCategory =
      data.rewardCategory === t("forms.labels.noRewardCategory")
        ? undefined
        : data.rewardCategory || "";

    try {
      const result = await selectedMarket.users.createNewReferral(
        data.campaign,
        data.firstName,
        data.lastName,
        data.email,
        data.customFields,
        false,
        false,
        referrerEmail,
        data.orderNumber,
        rewardCategory,
        force
      );

      onReferralCreated(result.friendId);
      setIsLoading(false);
      setConfirmationData(null);
      if (force) {
        setShowConfirmModal(false);
      }
    } catch (e) {
      if (handleFriendOrderedPreviously(e)) return;
      setErrors(e);
      setIsLoading(false);
      setConfirmationData(null);
      setShowConfirmModal(false);
    }
  };

  const handleFriendOrderedPreviously = (e) => {
    if (friendOrderedPreviously(e)) {
      setShowConfirmModal(true);
      setIsLoading(false);
      return true;
    }
    return false;
  };

  const friendOrderedPreviously = (e) => {
    return (
      e.errors?.referral &&
      Array.isArray(e.errors.referral) &&
      e.errors.referral.length === 1 &&
      e.errors.referral[0].type === "unsuccessful" &&
      e.errors.referral[0].args[0] === "ordered_previously"
    );
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <CreateReferralModal
        open={open && !showConfirmModal}
        onClose={handleClose}
        onSubmit={onSubmit}
        showConfirmationModal={showConfirmModal}
        serviceErrors={errors}
        isLoading={isLoading}
        usersCampaigns={usersCampaigns}
        selectedMarket={selectedMarket}
      />
      <ConfirmExistingFriendModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        handleSubmit={(e) => onSubmit(confirmationData, e, true)}
      />
    </>
  );
};

export default CreateReferral;
