export default class StatsTimeline {
  constructor(data) {
    this.labels = data.labels;
    this.dataSets = data.data_sets;
    this.granularity = data.granularity;
  }

  datasetByKey(key) {
    return this.dataSets.find((ds) => ds.key === key);
  }
}
