import React, { useEffect, useState } from "react";

import { DateTime } from "luxon";
import Market from "models/Market";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import FileImport from "components/bulk_actions/file_import/FileImport";
import Button from "components/forms/button/Button";
import Select from "components/forms/select/Select";
import addNotification from "components/notifications/Notifications";

import "./referral_management.scss";
import ReferralsHistory from "./referrals_history/ReferralsHistory";

const ReferralManagement = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [liveCampaigns, setLiveCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getCampaignList, setGetCampaignList] = useState(true);
  const [campaignListPlaceholder, setCampaignListPlaceholder] = useState("Loading...");
  const [checkHistory, setCheckHistory] = useState(true);
  const [importList, setImportList] = useState([]);
  const [importListLoadingError, setImportListLoadingError] = useState(false);

  const statusColumnHeader = "Status";
  const rewardCategoryColumnHeader = "Reward Category";

  const header = t("bulkActions.referralManagement.header");
  const stepFirstHeader = t("bulkActions.referralManagement.stepFirstHeader");
  const stepFirstSubtitle = t("bulkActions.referralManagement.stepFirstSubtitle");
  const stepSecondHeader = t("bulkActions.referralManagement.stepSecondHeader");
  const stepSecondDescriptionUpdateStatusColumn = (
    <Trans
      t={t}
      i18nKey="bulkActions.referralManagement.stepSecondDescription.updateStatusColumn"
      values={{ statusColumnHeader }}
    />
  );
  const stepSecondDescriptionConfirmedValueDescription = ` ${t(
    "bulkActions.referralManagement.stepSecondDescription.confirmedValueDescription"
  )}`;
  const stepSecondDescriptionCancelledValueDescription = ` ${t(
    "bulkActions.referralManagement.stepSecondDescription.cancelledValueDescription"
  )}`;
  const stepSecondDescriptionUnmodifiedRowsNote = t(
    "bulkActions.referralManagement.stepSecondDescription.unmodifiedRowsNote"
  );
  const stepThirdHeader = t("bulkActions.referralManagement.stepThirdHeader");
  const stepThirdDescriptionAddRewardCategoryColumn = (
    <Trans
      t={t}
      i18nKey="bulkActions.referralManagement.stepThirdDescription.addRewardCategoryColumn"
      values={{
        statusColumnHeader,
        rewardCategoryColumnHeader,
      }}
      tOptions={{ joinArrays: "<br>" }}
    />
  );
  const stepThirdDescriptionAddClientDataColumns = (
    <Trans
      t={t}
      i18nKey="bulkActions.referralManagement.stepThirdDescription.addClientDataColumns"
      values={{
        statusColumnHeader,
        rewardCategoryColumnHeader,
      }}
      tOptions={{ joinArrays: "<br>" }}
    />
  );
  const stepFourthHeader = t("bulkActions.referralManagement.stepFourthHeader");
  const stepFourthDescriptionRequiredColumns = t(
    "bulkActions.referralManagement.stepFourthDescription.requiredColumns"
  );
  const stepFourthDescriptionOptionalColumns = t(
    "bulkActions.referralManagement.stepFourthDescription.optionalColumns"
  );
  const stepFourthDescriptionRequiredColumnSeparator = t(
    "bulkActions.referralManagement.stepFourthDescription.requiredColumnSeparator"
  );
  const stepFifthHeader = t("bulkActions.referralManagement.stepFifthHeader");
  const stepFifthSubtitle = t("bulkActions.referralManagement.stepFifthSubtitle");
  const subtitle = t("bulkActions.referralManagement.subtitle");

  const fileImportButtonLabel = t("bulkActions.referralManagement.fileImport.buttonLabel");
  const fileImportCheckboxLabel = t("bulkActions.referralManagement.fileImport.checkboxLabel");

  const campaignListPlaceholderWithResults = t(
    "bulkActions.referralManagement.campaignListPlaceholder.withResults"
  );
  const campaignListPlaceholderNoResults = t(
    "bulkActions.referralManagement.campaignListPlaceholder.noResults"
  );

  const exportButtonLoading = t("bulkActions.referralManagement.exportButton.loading");
  const exportButtonText = t("bulkActions.referralManagement.exportButton.text");

  const errorNotificationTitle = t("global.error");
  const errorSubmittedMessage = t(
    "bulkActions.referralManagement.exportButton.errorSubmittedMessage"
  );

  const endDate = DateTime.now().setZone("UTC" + selectedMarket.timezone_offset);
  const startDate = endDate.minus({ years: 5 });

  const campaignName = (campaign) => {
    const pendingCount = campaign.count;
    const referralCount = t("bulkActions.referralManagement.referralCount", {
      count: pendingCount,
    });

    return `${campaign.name} (${referralCount})`;
  };

  const campaignList = liveCampaigns.map((campaign) => ({
    value: campaign.id,
    name: campaignName(campaign),
  }));

  const handleCampaignSelectorChange = (item) => setSelectedCampaignId(item.value);

  const setPoller = async (reportId) => {
    const [reportPoller] =
      await selectedMarket.platformReports.generateCampaignReportPoller(reportId);

    const downloadReport = await reportPoller;

    return downloadReport;
  };

  const handleExport = async () => {
    setIsLoading(true);
    let downloadReport;

    try {
      const newReportId = await selectedMarket.platformReports.build(
        "pending_referrals_report",
        selectedCampaignId,
        String(Math.floor(startDate.toSeconds())),
        String(Math.floor(endDate.toSeconds()))
      );

      downloadReport = await setPoller(newReportId);
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorSubmittedMessage);
      setIsLoading(false);
    }

    if (downloadReport !== null) {
      window.location.href = downloadReport.url;
    }

    setIsLoading(false);
  };

  const onImportSuccess = () => {
    setIsLoading(false);
    setGetCampaignList(!getCampaignList);
    setCheckHistory(!checkHistory);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await selectedMarket.imports.pendingReferralsByCampaign();
      const visibleCampaigns = selectedMarket.visibleCampaigns();

      const filteredCampaigns = response.campaigns.filter((campaign) =>
        visibleCampaigns.some((visibleCampaign) => visibleCampaign.id === campaign.id)
      );

      setLiveCampaigns(filteredCampaigns);
      setCampaignListPlaceholder(
        filteredCampaigns.length > 0
          ? campaignListPlaceholderWithResults
          : campaignListPlaceholderNoResults
      );
      setSelectedCampaignId(null);
    };

    getData();
  }, [
    campaignListPlaceholderNoResults,
    campaignListPlaceholderWithResults,
    getCampaignList,
    selectedMarket?.imports,
  ]);

  useEffect(() => {
    const getData = async () => {
      try {
        const manualImportHistory = await selectedMarket.imports.getList("referrals");
        setImportList(manualImportHistory.slice(0, 2));
      } catch (err) {
        setImportListLoadingError(true);
      }
    };
    getData();
  }, [checkHistory, selectedMarket?.imports]);

  return (
    <div>
      <div className="c-referral-management card">
        <h2 className="card__title">{header}</h2>
        <p>{subtitle}</p>
        <div className="c-referral-management__step">
          <span className="c-referral-management__step__header">{stepFirstHeader}</span>
          <p>{stepFirstSubtitle}</p>
          <div className="c-referral-management__step__export__campaign-select">
            <Select
              options={campaignList}
              handleOnChange={handleCampaignSelectorChange}
              title=""
              selectedValue={selectedCampaignId}
              selectedPlaceholder={campaignListPlaceholder}
              buttonAppearance="secondary"
              disabled={campaignList.length === 0}
            />
          </div>
          <div className="c-referral-management__step__export__button">
            <Button
              buttonElement="submit"
              appearance="tertiary"
              loading={isLoading}
              disabled={!selectedCampaignId}
              onClick={handleExport}
            >
              {isLoading ? exportButtonLoading : exportButtonText}
            </Button>
          </div>
        </div>
        <div className="c-referral-management__combined-steps">
          <div className="c-referral-management__step">
            <span className="c-referral-management__step__header">{stepSecondHeader}</span>
            <p>
              {stepSecondDescriptionUpdateStatusColumn}
              <br />
              <br />
              <code>confirmed</code>
              {stepSecondDescriptionConfirmedValueDescription}
              <br />
              <code>cancelled</code>
              {stepSecondDescriptionCancelledValueDescription}
              <br />
              <br />
              {stepSecondDescriptionUnmodifiedRowsNote}
              <br />
            </p>
          </div>
          <div className="c-referral-management__step">
            <span className="c-referral-management__step__header">{stepThirdHeader}</span>
            <p>
              {stepThirdDescriptionAddRewardCategoryColumn}
              <br />
              <br />
              {stepThirdDescriptionAddClientDataColumns}
            </p>
          </div>
        </div>
        <div className="c-referral-management__step">
          <span className="c-referral-management__step__header">{stepFourthHeader}</span>
          <p>
            {stepFourthDescriptionRequiredColumns}
            <br />
            <br />
            <code>Referral ID</code>
            <span> {stepFourthDescriptionRequiredColumnSeparator} </span>
            <code>Your ID</code>
            <br />
            <code>Status</code>
            <br />
            <br />
            {stepFourthDescriptionOptionalColumns}
            <br />
            <br />
            <code>Reward Category</code>
          </p>
          <FileImport
            selectedMarket={selectedMarket}
            importType="referrals"
            buttonLabel={fileImportButtonLabel}
            checkBoxOptionKey="validate_reward_category"
            checkBoxDefault={selectedMarket.validate_reconciliation_reward_category}
            checkBoxLabel={fileImportCheckboxLabel}
            onImportSuccess={onImportSuccess}
          />
          <ReferralsHistory
            importList={importList}
            timezoneOffset={selectedMarket.timezone_offset}
            importListLoadingError={importListLoadingError}
          />
        </div>
        <div className="c-referral-management__step">
          <span className="c-referral-management__step__header">{stepFifthHeader}</span>
          <p>{stepFifthSubtitle}</p>
        </div>
      </div>
    </div>
  );
};

ReferralManagement.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default ReferralManagement;
