import React from "react";

import { getHistoryStatusText } from "helpers/Helpers";
import { arrayOf, bool, number, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";

import ErrorLoader from "components/error_loader/ErrorLoader";
import FormattedDate from "components/formatted_date/FormattedDate";

import "./forget_users_history.scss";

const ForgetUsersHistory = ({ importList, timezoneOffset, importListLoadingError }) => {
  const { t } = useTranslation();
  const shouldHide = importList.length === 0 && !importListLoadingError;

  const header = t("bulkActions.forgetUsers.history.header");
  const tableHeaderForgottenUserCount = t(
    "bulkActions.forgetUsers.history.tableHeaders.forgottenUserCount"
  );
  const tableHeaderStatus = t("bulkActions.forgetUsers.history.tableHeaders.status");
  const tableHeaderUploadedAt = t("bulkActions.forgetUsers.history.tableHeaders.uploadedAt");
  const tableHeaderUploadedBy = t("bulkActions.forgetUsers.history.tableHeaders.uploadedBy");

  return (
    shouldHide || (
      <div className="c-import-history">
        <h2 className="card__title">{header}</h2>
        {importListLoadingError || (
          <div className="table-container">
            <table className="portal-table c-import-history--table">
              <thead>
                <tr>
                  <th>{tableHeaderUploadedAt}</th>
                  <th>{tableHeaderUploadedBy}</th>
                  <th>{tableHeaderStatus}</th>
                  <th>{tableHeaderForgottenUserCount}</th>
                </tr>
              </thead>
              <tbody>
                {importList.map((importRecord) => (
                  <tr key={importRecord.id}>
                    <td>
                      <FormattedDate
                        timestamp={importRecord.uploaded_at}
                        timezoneOffset={timezoneOffset}
                        type="long_with_time"
                      />
                    </td>
                    <td>{importRecord.created_by}</td>
                    <td>{getHistoryStatusText(importRecord.status)}</td>
                    <td>{importRecord.forgotten_user_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <ErrorLoader
          errorText={t("forms.errors.bulkActions.load_history")}
          showError={importListLoadingError}
        />
      </div>
    )
  );
};

ForgetUsersHistory.propTypes = {
  importList: arrayOf(
    shape({
      id: string.isRequired,
      uploaded_at: number.isRequired,
      created_by: string.isRequired,
      status: string.isRequired,
      forgotten_user_count: number.isRequired,
    })
  ).isRequired,
  timezoneOffset: string.isRequired,
  importListLoadingError: bool.isRequired,
};

export default ForgetUsersHistory;
