import React from "react";

import { Permissions } from "bp-ui";
import { t } from "i18next";
import { Outlet } from "react-router-dom-v5-compat";

import SubNavigation from "../../sub_navigation_new/SubNavigation";

const ReportingNavigation = ({ selectedMarket, type }) => {
  const { viewReports } = Permissions;

  const availableLinks = [
    {
      text: t("reporting.navigation.reportingDashboard"),
      path: `/reports/${type}/dashboard`,
      cisOnly: false,
      permissions: [viewReports],
      visible: type !== "reward",
    },
    {
      text: t("reporting.navigation.operationalReports"),
      path: `/reports/${type}/operational`,
      cisOnly: false,
      permissions: [viewReports],
      visible: true,
    },
  ];

  return (
    <div className="bi-campaign-wrapper">
      <div>
        <h1>{t(`reporting.navigation.${type}Reports`)}</h1>
      </div>

      <SubNavigation
        isSecondary
        isFloating
        subdomain={selectedMarket.subdomain}
        cisMarket={selectedMarket.cis}
        availableLinks={availableLinks}
      />

      <Outlet />
    </div>
  );
};

export default ReportingNavigation;
