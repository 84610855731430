import PropTypes from "prop-types";

const FormattedRewardIdentifier = ({ value }) =>
  value && typeof value === "string" ? value.split(".").join(" ") : "Unknown";

FormattedRewardIdentifier.propTypes = {
  value: PropTypes.string.isRequired,
};

export default FormattedRewardIdentifier;
