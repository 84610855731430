import React from "react";

import classnames from "classnames";
import { bool, element, func, node, string } from "prop-types";

import "./campaign_handler.scss";

const CampaignHandler = ({
  icon,
  title,
  children,
  onClick,
  isActive = false,
  className = undefined,
}) => {
  const handlerClasses = classnames(
    "c-campaign-handler",
    {
      "c-campaign-handler--active": isActive,
    },
    className
  );

  return (
    <button type="button" onClick={onClick} tabIndex={0} className={handlerClasses}>
      <i className="c-campaign-handler__icon">{icon}</i>
      <span className="c-campaign-handler__title u-inline-1">{title}</span>
      <span className="c-campaign-handler__content">{children}</span>
    </button>
  );
};

CampaignHandler.propTypes = {
  icon: element.isRequired,
  title: string.isRequired,
  children: node.isRequired,
  onClick: func.isRequired,
  isActive: bool,
  className: string,
};

export default CampaignHandler;
