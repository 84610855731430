import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import FormattedDate from "components/formatted_date/FormattedDate";
import "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar/calendar.scss";
import CalendarMonth from "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar_month/CalendarMonth";

const icon = (type) => (type === "first" ? <FiChevronLeft /> : <FiChevronRight />);

const Calendar = ({
  type,
  currentDate,
  action,
  updateNewDateRange,
  newStartDate,
  newEndDate,
  timezoneOffset,
}) => (
  <div className={`${type}-month`}>
    <div className="calendar-header">
      {type === "first" && (
        <button type="button" onClick={action}>
          {icon(type)}
        </button>
      )}
      <div className="calendar-label">
        <FormattedDate
          timestamp={Math.floor(DateTime.fromISO(currentDate).toSeconds())}
          timezoneOffset={timezoneOffset}
          type="short_without_day"
        />
      </div>
      {type === "last" && (
        <button type="button" onClick={action}>
          {icon(type)}
        </button>
      )}
    </div>
    <CalendarMonth
      currentDate={currentDate}
      updateNewDateRange={updateNewDateRange}
      newStartDate={newStartDate}
      newEndDate={newEndDate}
    />
  </div>
);

Calendar.propTypes = {
  type: PropTypes.string.isRequired,
  currentDate: PropTypes.instanceOf(DateTime).isRequired,
  action: PropTypes.func.isRequired,
  updateNewDateRange: PropTypes.func.isRequired,
  newStartDate: PropTypes.instanceOf(DateTime).isRequired,
  newEndDate: PropTypes.instanceOf(DateTime).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default Calendar;
