import React from "react";

import { Card } from "../Card.jsx";

export const Detail = ({
  className,
  labelClassName,
  label,
  children,
  border = true,
}) => {
  return (
    <div className={`flex-1 flex flex-row py-1 ${className || ""}`}>
      {border ? (
        <div className="flex-none pl-2 border-0 border-solid border-l-2 border-grey w-px mt-1 mb-2" />
      ) : null}
      <div className={`flex flex-col`}>
        <div
          className={`mb-2.5 font-bold${labelClassName ? ` ${labelClassName}` : ""}`}
        >
          {label}
        </div>
        {children || <div>&nbsp;</div>}
      </div>
    </div>
  );
};

export const Preview = () => {
  return (
    <Card className="flex gap-3">
      <Detail label="Detail label">Detail content</Detail>
      <Detail label="Another detail label">Detail content</Detail>
      <Detail label="Yet another detail label">Detail content</Detail>
    </Card>
  );
};
