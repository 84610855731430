import React, { useCallback, useEffect, useState } from "react";

import BaseUser from "models/BaseUser";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AccountDetails from "components/customer_lookup/cis_user/account_details/AccountDetails";
import UserFulfilments from "components/customer_lookup/user/user_fulfilments/UserFulfilments";
import UserReferrals from "components/customer_lookup/user/user_referrals/UserReferrals";
import Button from "components/forms/button/Button";

import "./cis_user.scss";
import Rewards from "./rewards/Rewards";
import Subscriptions from "./subscriptions/Subscriptions";

const CisUser = ({ selectedMarket, user, isCustomer }) => {
  const { t } = useTranslation();
  const [cisUser, setCisUser] = useState(null);
  const [cisUserError, setCisUserError] = useState(false);
  const [isLoadingCisUser, setLoadingCisUser] = useState(false);

  const headerText = t("cis.headerText");
  const noResultErrorMessageText = t("cis.noResultErrorMessage");
  const retryButtonText = t("cis.retryButtonText");

  const getCisUserData = useCallback(async () => {
    setLoadingCisUser(true);
    try {
      const returnedCisUser = await selectedMarket.cisUsers.find(user.cisId);
      setCisUser(returnedCisUser);
    } catch (e) {
      setCisUserError(true);
    } finally {
      setLoadingCisUser(false);
    }
  }, [selectedMarket?.cisUsers, user?.cisId]);

  useEffect(() => {
    getCisUserData();
  }, [getCisUserData]);

  if (cisUserError) {
    return (
      <div className="card c-error-loader">
        <p>{noResultErrorMessageText}</p>
        <Button
          size="tiny"
          appearance="secondary"
          data-edit-user
          onClick={getCisUserData}
          loading={isLoadingCisUser}
        >
          {retryButtonText}
        </Button>
      </div>
    );
  }

  return (
    cisUser && (
      <>
        <div className="card client-profile">
          <h2 className="card__title">
            {selectedMarket.name} {headerText}
          </h2>
          <AccountDetails cisUser={cisUser} timezoneOffset={selectedMarket.timezone_offset} />
          <Subscriptions
            subscriptions={cisUser.subscriptions}
            timezoneOffset={selectedMarket.timezone_offset}
          />
        </div>

        {selectedMarket.cisFulfilmentsInPlatform() ? (
          <UserFulfilments
            fulfilments={user.fulfilments}
            userId={user.id}
            selectedMarket={selectedMarket}
            reloadView={getCisUserData}
          />
        ) : (
          <Rewards
            selectedMarket={selectedMarket}
            rewardFulfillments={cisUser.rewardFulfillments}
            refreshData={getCisUserData}
            userId={user.id}
          />
        )}

        {isCustomer && (
          <UserReferrals
            referrals={cisUser.referrals}
            timezoneOffset={selectedMarket.timezone_offset}
            selectedMarket={selectedMarket}
            reloadView={getCisUserData}
            isCis
          />
        )}
      </>
    )
  );
};

CisUser.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  user: PropTypes.instanceOf(BaseUser).isRequired,
  isCustomer: PropTypes.bool.isRequired,
};

export default CisUser;
