import React from "react";

import { Empty } from "assets/images/CustomIcons";
import PropTypes from "prop-types";

import "./no_results.scss";

const NoResults = ({ table }) => (
  <div className="no-results card">
    <p className="no-results__message">{table}</p>
    <Empty />
  </div>
);

NoResults.propTypes = {
  table: PropTypes.string.isRequired,
};

export default NoResults;
