import React from "react";

import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import CisRewardFulfillment from "models/CisRewardFulfillment";
import Market from "models/Market";
import { func, instanceOf, string } from "prop-types";
import { useTranslation } from "react-i18next";

import DropdownButton from "components/dropdown_button/DropdownButton";
import addNotification from "components/notifications/Notifications";

const RewardActions = ({ rewardFulfillment, refreshData, selectedMarket, userId }) => {
  const { t } = useTranslation();
  const { currentAdmin } = useAuth();

  const { resendFulfilmentEmail } = Permissions;
  const errorNotificationTitle = t("global.error");
  const submittedNotificationTitle = t("global.submitted");
  const successNotificationTitle = t("global.success");

  const claimSubmitted = t("rewards.actions.claimSubmitted");
  const errorRewardEmailResenMessage = t("rewards.actions.errorRewardEmailResenMessage");
  const errorSubmittedMessage = t("rewards.actions.errorSubmittedMessage");
  const successRewardEmailResentMessage = t("rewards.actions.successRewardEmailResentMessage");
  const successSubmittedMessage = t("rewards.actions.successSubmittedMessage");

  const clickToClaimOptionText = t("rewards.actions.clickToClaimOption");
  const resentOptionText = t("rewards.actions.resentOption");

  const handleClickToClaim = async () => {
    addNotification.success(submittedNotificationTitle, claimSubmitted);
    try {
      await selectedMarket.cisUsers.claimFulfillment(rewardFulfillment.id);
      addNotification.success(successNotificationTitle, successSubmittedMessage);
      refreshData();
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorSubmittedMessage);
    }
  };

  const handleResendRewardEmail = async () => {
    try {
      await selectedMarket.users.resendFulfillment(userId, rewardFulfillment.platformId);
      addNotification.success(successNotificationTitle, successRewardEmailResentMessage);
      refreshData();
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorRewardEmailResenMessage);
    }
  };

  const options = [];
  if (rewardFulfillment.status.isWaiting()) {
    options.push({ text: clickToClaimOptionText, onClick: handleClickToClaim });
  }

  if (
    currentAdmin.can(resendFulfilmentEmail) &&
    rewardFulfillment.status.isFulfilled() &&
    rewardFulfillment.platformId
  ) {
    options.push({ text: resentOptionText, onClick: handleResendRewardEmail });
  }

  if (options.length === 0) {
    return null;
  }

  return <DropdownButton dropdownList={options} />;
};

RewardActions.propTypes = {
  selectedMarket: instanceOf(Market).isRequired,
  rewardFulfillment: instanceOf(CisRewardFulfillment).isRequired,
  userId: string.isRequired,
  refreshData: func.isRequired,
};

export default RewardActions;
