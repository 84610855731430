import React, { useEffect, useRef, useState } from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { DateTime } from "luxon";
import millify from "millify";
import StatsTimeline from "models/StatsTimeline";
import { instanceOf } from "prop-types";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../formatted_date/FormattedDate";
import "./timeline_chart.scss";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const chartStyleDefaults = {
  fontFamily: "museo-sans",
  fontWeightNormal: "normal",
  fontSize: 16,
  fontSizeSmall: 13,
  primaryFontColor: "#185478",
  gridLineColor: "#cedde5",
  ticks: {
    borderCapStyle: "round",
    pointBorderWidth: 3,
    pointHoverBorderWidth: 3,
    pointRadius: 8,
    pointHoverRadius: 8,
    pointBackgroundColor: "white",
    pointHoverBackgroundColor: "white",
    fill: false,
  },
  lineTension: 0,
  lineColors: ["#007b25", "#00567b", "#009cd2", "#eca100", "#c3422a"],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: "#152832",
      titleFontSize: chartStyleDefaults.fontSizeSmall,
      titleFontColor: "#fff",
      titleFontWeight: chartStyleDefaults.fontWeightNormal,
      titleFontFamily: chartStyleDefaults.fontFamily,
      bodyFontSize: chartStyleDefaults.fontSizeSmall,
      bodyFontColor: "#fff",
      bodyFontWeight: chartStyleDefaults.fontWeightNormal,
      bodyFontFamily: chartStyleDefaults.fontFamily,
      xPadding: 14,
      yPadding: 14,
      cornerRadius: 12,
      displayColors: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      offset: true,
      ticks: {
        font: {
          color: chartStyleDefaults.primaryFontColor,
          size: 22,
          family: chartStyleDefaults.fontFamily,
          lineHeight: 3,
        },
      },
    },
    y: {
      grid: {
        color: chartStyleDefaults.gridLineColor,
      },
      ticks: {
        font: {
          color: chartStyleDefaults.primaryFontColor,
          size: chartStyleDefaults.fontSize,
          family: chartStyleDefaults.fontFamily,
          lineHeight: 3,
        },
        callback(value) {
          if (Number.isNaN(value)) {
            return "0";
          }

          return millify(value, { lowerCase: true });
        },
      },
      beginAtZero: true,
    },
  },
};

const dataSetsOrder = [
  "new_registered_customers",
  "sharers",
  "friend_visits",
  "pending_referrals",
  "confirmed_referrals",
];

const TimelineChart = ({ statsTimeline }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef();

  const dataSetsDefaults = {
    new_registered_customers: {
      label: t("reporting.dashboard.overview.metricColumns.newReferrerRegistrations"),
      lineTension: chartStyleDefaults.lineTension,
      ...chartStyleDefaults.ticks,
    },
    sharers: {
      label: t("reporting.dashboard.overview.metricColumns.sharers"),
      lineTension: chartStyleDefaults.lineTension,
      ...chartStyleDefaults.ticks,
    },
    friend_visits: {
      label: t("reporting.dashboard.overview.metricColumns.uniqueFriendVisits"),
      lineTension: chartStyleDefaults.lineTension,
      ...chartStyleDefaults.ticks,
    },
    pending_referrals: {
      label: t("reporting.dashboard.overview.metricColumns.pendingReferrals"),
      lineTension: chartStyleDefaults.lineTension,
      ...chartStyleDefaults.ticks,
    },
    confirmed_referrals: {
      label: t("reporting.dashboard.overview.metricColumns.confirmedReferrals"),
      lineTension: chartStyleDefaults.lineTension,
      ...chartStyleDefaults.ticks,
    },
  };

  const labels = statsTimeline.labels.map((label) => {
    const date = DateTime.fromISO(label);
    switch (statsTimeline.granularity) {
      case "week": {
        const endOfWeek = date.set({ weekday: 7 });
        return date.month === endOfWeek.month
          ? `${formatDate(date, "day_only")} - ${formatDate(endOfWeek, "day_month_only")}` // 2 - 8 Mar
          : `${formatDate(date, "day_month_only")} - ${formatDate(endOfWeek, "day_month_only")}`; // 2 Mar - 8 Mar
      }
      case "month":
        return formatDate(date, "month_only"); // Jan
      case "day":
      default:
        return formatDate(date, "day_month_only"); // Mon 28
    }
  });

  const datasets = dataSetsOrder.map((key, index) => {
    const dataSet = statsTimeline.datasetByKey(key);
    const dataSetDefaults = dataSetsDefaults[dataSet.key];

    return {
      id: dataSet.key,
      data: dataSet.values,
      pointBorderColor: chartStyleDefaults.lineColors[index],
      borderColor: chartStyleDefaults.lineColors[index],
      ...dataSetDefaults,
    };
  });

  const toggleLineItem = (item) => {
    chartData[item].hidden = !chartData[item].hidden || false;
    setChartData(() => [...chartData]);
  };

  useEffect(() => {
    if (chartRef.current) {
      setChartData(chartRef.current.config.data.datasets);
    }
  }, [chartRef]);

  return (
    <>
      <div className="c-timeline-chart">
        <div>
          <ul className="c-timeline-chart__legend">
            {chartData.length > 0 &&
              chartData.map((legend, key) => (
                <li
                  key={legend.label}
                  className={legend.hidden ? "c-timeline-chart__item--hidden" : ""}
                >
                  <button
                    className="c-timeline-chart__toggle"
                    type="button"
                    onClick={() => toggleLineItem(key)}
                  >
                    <span
                      className="c-timeline-chart__notifier"
                      style={{ borderColor: legend.borderColor }}
                    />
                    <span className="c-timeline-chart__label">{legend.label}</span>
                  </button>
                </li>
              ))}
          </ul>
        </div>

        <div className="c-timeline-chart__inner">
          <Line
            data={{
              labels,
              datasets,
            }}
            height={550}
            ref={chartRef}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

TimelineChart.propTypes = {
  statsTimeline: instanceOf(StatsTimeline).isRequired,
};

export default TimelineChart;
