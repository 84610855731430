import { DetailListItem } from "./DetailListItem";

export const DetailList = ({ children }) => (
  <dl className="flex flex-col w-full gap-4">{children}</dl>
);

export const Preview = () => {
  return (
    <DetailList>
      <DetailListItem description="Description" title="Title" />
    </DetailList>
  );
};
