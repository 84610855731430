import React, { useCallback, useState } from "react";

import { Permissions } from "bp-ui";
import { Button } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import addNotification from "components/notifications/Notifications";
import ProfileFormModal from "components/partner_management/profile_form/profile_form_modal/ProfileFormModal";
import VisibleWith from "components/visibleWith/VisibleWith";

const CreatePartner = ({ selectedMarket, setRefreshPartnerList }) => {
  const { t } = useTranslation();
  const [openProfileFormModal, setOpenProfileFormModal] = useState(false);
  const toggleProfileFormModal = () => setOpenProfileFormModal((value) => !value);
  const { createPartner } = Permissions;
  const initialFormState = {
    fields: {
      businessName: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      mobileCountryCode: "",
      jobTitle: "",
      industrySector: "",
      locale: "",
      businessCountry: "",
      website: "",
      twitter: "",
      instagram: "",
      facebook: "",
      linkedin: "",
      youtube: "",
      snapchat: "",
      signupReason: "",
      audienceSize: 0,
      audienceLocation: [],
    },
    formErrors: {
      creation: [],
      business_name: [],
      first_name: [],
      last_name: [],
      email: [],
      mobile: [],
      mobile_country_code: [],
      job_title: [],
      industry_sector: [],
      locale: [],
      business_country: [],
      website: [],
      twitter_handle: [],
      instagram_handle: [],
      facebook_handle: [],
      linkedin_handle: [],
      youtube_handle: [],
      snapchat_handle: [],
      signup_reason: [],
      audience_size: [],
      audience_location: [],
    },
  };

  const [form, setForm] = useState(initialFormState);

  const setFormFields = useCallback((key, value) => {
    setForm((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [key]: value,
      },
    }));
  }, []);

  const setFormErrors = (errors) => {
    setForm((prevState) => ({
      ...prevState,
      formErrors: errors,
    }));
  };

  const resetForm = () => {
    setForm(initialFormState);
  };

  const validateEmail = async (e) => {
    const email = e.target.value;
    if (!email) {
      setFormErrors({ email: [{ type: "filled" }] });
      return;
    }

    // TODO: sc-30965 Add frontend email format validation for speed
    try {
      await selectedMarket.users.validateEmail(email, null);
      setFormErrors({ email: [] });
    } catch (exception) {
      setFormErrors(exception.errors);
    }
  };

  const handleProfileFormSubmit = async () => {
    await selectedMarket.users.createNewPartner(
      form.fields.campaignId,
      form.fields.firstName,
      form.fields.lastName,
      form.fields.email,
      form.fields.mobile,
      form.fields.mobileCountryCode,
      form.fields.locale,
      form.fields.signupReason,
      form.fields.businessName,
      form.fields.jobTitle,
      form.fields.industrySector,
      form.fields.businessCountry,
      form.fields.website,
      form.fields.twitter,
      form.fields.instagram,
      form.fields.facebook,
      form.fields.linkedin,
      form.fields.youtube,
      form.fields.snapchat,
      form.fields.audienceSize,
      form.fields.audienceLocation
    );

    addNotification.success(t("global.success"), t("addPartner.modal.successMessage"));
    setRefreshPartnerList((value) => !value);
  };

  return (
    <>
      <ProfileFormModal
        selectedMarket={selectedMarket}
        action="Create"
        form={form}
        setFormFields={setFormFields}
        setFormErrors={setFormErrors}
        resetForm={resetForm}
        openModal={openProfileFormModal}
        toggleModal={toggleProfileFormModal}
        handleFormSubmit={handleProfileFormSubmit}
        validateEmail={validateEmail}
      />

      <VisibleWith actions={[createPartner]}>
        <div>
          <Button size="medium" appearance="primary" onClick={toggleProfileFormModal}>
            {t("addPartner.prompt")}
          </Button>
        </div>
      </VisibleWith>
    </>
  );
};

CreatePartner.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  setRefreshPartnerList: PropTypes.func.isRequired,
};

export default CreatePartner;
