import React, { useReducer, useState } from "react";

import { useGetPartners } from "hooks/useGetPartners";
import { useFlags } from "launchdarkly-react-client-sdk";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Partner from "components/partner_management/Partner";
import CreatePartner from "components/partner_management/profile_form/create_partner/CreatePartner";
import Spinner from "components/spinner/Spinner";

import BulkEditContent from "./bulk_edit_content/BulkEditContent";

const Partners = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const { editMultiplePartners } = useFlags();

  const [refreshPartnerList, setRefreshPartnerList] = useState(false);
  const tableColumns = [
    t("partners.tableHeader.businessName"),
    t("partners.tableHeader.email"),
    t("partners.tableHeader.contactName"),
  ];

  const initialPartnerResponseState = {
    partners: [],
    isLoading: false,
  };

  // TODO split this out with div in render into its own component
  const partnerResponseReducer = (state, action) => {
    switch (action.type) {
      case "loading":
        return { ...state, isLoading: true };
      case "loadedSuccessfully":
        return { ...state, partners: action.partners, isLoading: false };
      case "errorsOccurred":
        throw action.errors;
      default:
        throw new Error("Invalid Action Type for partnerResponseReducer");
    }
  };

  const [{ partners, isLoading }, partnerDispatch] = useReducer(
    partnerResponseReducer,
    initialPartnerResponseState
  );

  useGetPartners(selectedMarket, partnerDispatch, [refreshPartnerList]);

  return (
    <>
      <div className="mx-auto w-[75rem]">
        <div className="flex justify-end gap-4 py-4">
          <CreatePartner
            selectedMarket={selectedMarket}
            setRefreshPartnerList={setRefreshPartnerList}
          />

          {editMultiplePartners && <BulkEditContent partners={partners} />}
        </div>
      </div>

      <div className="card">
        <h2>{t("partners.header")}</h2>
        {isLoading ? (
          <Spinner showSpinner loadingText="" fullHeight={false} />
        ) : (
          <>
            <div className="table-container">
              <table className="portal-table hover-highlight search-results">
                <thead>
                  <tr>
                    {tableColumns.map((name) => (
                      <th key={name} scope="col">
                        {name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {partners.map((partner) => (
                    <Partner
                      key={partner.user_id}
                      partner={partner}
                      subdomain={selectedMarket.subdomain}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

Partners.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default Partners;
