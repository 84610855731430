import React, { useEffect, useRef, useState } from "react";

import { arrayOf, func, shape, string } from "prop-types";
import { FaChevronDown } from "react-icons/fa";

import "./dropdown_button.scss";

const DropdownButton = ({ dropdownList }) => {
  const [open, toggleList] = useState(false);
  const dropdownRef = useRef();

  const handleClick = () => {
    toggleList(!open);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      toggleList(!open);
    }
  };

  const handleClickOutside = ({ target }) =>
    dropdownRef.current && !dropdownRef.current.contains(target) && toggleList(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => document.removeEventListener("click", handleClickOutside, true);
  });

  return (
    <div
      className="c-dropdown-button"
      ref={dropdownRef}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex="0"
      role="button"
    >
      <FaChevronDown />

      {open && (
        <ul className="c-dropdown-button-list">
          {dropdownList.map(({ text, onClick }) => (
            <li key={text}>
              <button onClick={onClick} type="button">
                {text}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  dropdownList: arrayOf(
    shape({
      text: string.isRequired,
      onClick: func.isRequired,
    })
  ).isRequired,
};

export default DropdownButton;
