import React, { useEffect, useState } from "react";

import { Button, Modal } from "bp-ui";
import { useTranslation } from "react-i18next";

import SelectPartners from "./SelectPartners";

const BulkEditContent = ({ partners }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
  const [allPartnerIds, setAllPartnerIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setAllPartnerIds(partners.map((partner) => partner.id));
  }, [partners]);

  useEffect(() => {
    setAllSelected(selectedPartnerIds.length === allPartnerIds.length);
  }, [selectedPartnerIds, allPartnerIds]);

  const toggleProfileFormModal = () => setModalOpen((prevState) => !prevState);

  const cancelModal = () => {
    setSelectedPartnerIds([]);
    setModalOpen(false);
  };

  const selectAll = () => {
    setSelectedPartnerIds(allPartnerIds);
  };

  const deselectAll = () => {
    setSelectedPartnerIds([]);
  };

  return (
    <>
      <Modal
        className="top-24 max-w-1/2"
        open={modalOpen}
        onClose={cancelModal}
        title={t("editContent.title")}
      >
        <p className="font-normal text-xl">{t("editContent.description")}</p>

        <div className="flex justify-end my-8">
          <div>
            <Button
              size="medium"
              className="w-full"
              secondary
              onClick={allSelected ? deselectAll : selectAll}
            >
              {allSelected ? t("global.deselectAll") : t("global.selectAll")}
            </Button>
          </div>
        </div>

        <SelectPartners
          translation={t}
          partners={partners}
          selectedPartnerIds={selectedPartnerIds}
          setSelectedPartnerIds={setSelectedPartnerIds}
        />

        <div className="flex gap-4 mt-8">
          <Button size="medium" className="w-full" secondary onClick={cancelModal}>
            {t("global.cancel")}
          </Button>

          <Button size="medium" className="w-full" onClick={() => console.log(selectedPartnerIds)}>
            {t("global.confirm")}
          </Button>
        </div>
      </Modal>

      <Button size="medium" secondary onClick={toggleProfileFormModal}>
        {t("editContent.prompt")}
      </Button>
    </>
  );
};

export default BulkEditContent;
