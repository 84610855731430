import React, { useRef } from "react";

import PropTypes from "prop-types";

import Checkbox from "components/forms/checkbox/Checkbox";
import IndeterminateCheckbox from "components/forms/indeterminate_checkbox/IndeterminateCheckbox";
import "components/multi_select_filter/multi_select_filter.scss";

const MultiSelectFilterList = ({
  selectedOptions,
  options,
  selectAllLabel,
  handleSelectAll,
  handleOptionChange,
}) => {
  const listRef = useRef();
  const checkboxRefs = useRef([]);

  const allChecked = selectedOptions.length === options.length;
  const noneChecked = selectedOptions.length === 0;
  const someChecked = !allChecked && !noneChecked;

  const elementIsVisibleInContainer = (element, container) => {
    const outerShape = container.getBoundingClientRect();
    const innerShape = element.getBoundingClientRect();

    return (
      innerShape.top >= outerShape.top &&
      innerShape.left >= outerShape.left &&
      innerShape.bottom <= outerShape.bottom &&
      innerShape.right <= outerShape.right
    );
  };

  const handleCheckboxFocus = (e, focusedRef) => {
    e.preventDefault();

    if (focusedRef && !elementIsVisibleInContainer(focusedRef, listRef.current)) {
      listRef.current.scrollTo({ top: focusedRef.offsetTop, behaviour: "smooth" });
    }
  };

  return (
    <>
      <ul className="c-multi-select-filter-content-list">
        <li className="c-multi-select-filter-content-list__item-action">
          {someChecked ? (
            <IndeterminateCheckbox
              label={selectAllLabel}
              name={selectAllLabel}
              handleChange={handleSelectAll}
            />
          ) : (
            <Checkbox
              label={selectAllLabel}
              name={selectAllLabel}
              value="All"
              handleChange={handleSelectAll}
              checked={selectedOptions.length === options.length}
            />
          )}
        </li>
      </ul>

      <ul ref={listRef} className="c-multi-select-filter-content-list__inner c-scrollbar">
        {options.map((option) => (
          <li className="c-multi-select-filter-content-list__item-action" key={option.value}>
            <Checkbox
              ref={(el) => {
                checkboxRefs.current[option.value] = el;
              }}
              label={option.label}
              name={option.label}
              value={option.value}
              handleChange={handleOptionChange}
              checked={selectedOptions.includes(option.value)}
              onFocus={(e) => handleCheckboxFocus(e, checkboxRefs.current[option.value])}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

MultiSelectFilterList.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  selectAllLabel: PropTypes.string.isRequired,
};

export default MultiSelectFilterList;
