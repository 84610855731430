import { Badge } from "primereact/badge";

import { DotColours } from "../enums";
import { DotTypes } from "../enums";

export const Dot = ({ type = null }) => {
  const severityClass = () => {
    if (type in DotColours) {
      return `bg-${DotColours[type]}`;
    } else {
      return `bg-${DotColours[DotTypes.DEFAULT]}`;
    }
  };

  return (
    <Badge
      severity={type}
      pt={{
        root: {
          className: `p-0 text-center inline-block min-w-[0.5rem] h-[0.5rem] rounded-full ${severityClass()}`,
        },
      }}
    />
  );
};

export const Preview = () => (
  <div className="flex gap-4">
    <Dot type="success" />
    <Dot type="danger" />
    <Dot type="secondary" />
    <Dot type="info" />
    <Dot type="warning" />
    <Dot type="help" />
    <Dot type="default" />
  </div>
);
