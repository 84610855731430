import React, { useState } from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import CustomRangeSelector from "components/reports/date_picker/date_range_selector/custom_range/CustomRangeSelector";
import PresetsSelector from "components/reports/date_picker/date_range_selector/presets/PresetsSelector";

import "../date_range_selector/date_range_selector.scss";

const DateRangePicker = ({
  selectedStartDate,
  selectedEndDate,
  timezoneOffset,
  closeDateRangeSelector,
  action,
}) => {
  const { t } = useTranslation();
  const applyText = t("reporting.dashboard.topbar.dateRangeSelector.applyLinkText");
  const cancelText = t("global.cancel");
  const helpTextFirstLine = t("reporting.dashboard.topbar.dateRangeSelector.helpText.firstLine");
  const helpTextSecondLine = t("reporting.dashboard.topbar.dateRangeSelector.helpText.secondLine");

  const currentStartDate = DateTime.fromSeconds(selectedStartDate, { zone: "UTC" })
    .setZone("UTC" + timezoneOffset)
    .startOf("day");
  const currentEndDate = DateTime.fromSeconds(selectedEndDate, { zone: "UTC" })
    .setZone("UTC" + timezoneOffset)
    .endOf("day");

  const [newStartDate, setNewStartDate] = useState(currentStartDate);
  const [newEndDate, setNewEndDate] = useState(currentEndDate);

  const updateNewDateRange = (date) => {
    if (
      DateTime.fromISO(date) < DateTime.fromISO(newStartDate) ||
      !DateTime.fromISO(newStartDate).hasSame(DateTime.fromISO(newEndDate), "day", {
        useLocaleWeeks: true,
      })
    ) {
      setNewStartDate(date);
      setNewEndDate(date);
    } else if (DateTime.fromISO(date) > DateTime.fromISO(newStartDate)) {
      setNewEndDate(date);
    }
  };

  const setPresetRangeStartDate = (presetStartDate) => Math.floor(presetStartDate.toSeconds());

  const handleButtonClick = () => {
    action(
      Math.floor(newStartDate.startOf("day", { useLocaleWeeks: true }).toSeconds()),
      Math.floor(newEndDate.endOf("day", { useLocaleWeeks: true }).toSeconds())
    );
    closeDateRangeSelector();
  };

  return (
    <div className="date-range-selector--selector">
      <div className="date-range-selector--main">
        <CustomRangeSelector
          newStartDate={newStartDate}
          newEndDate={newEndDate}
          updateNewDateRange={(date) => updateNewDateRange(date)}
          timezoneOffset={timezoneOffset}
        />
        <PresetsSelector
          customStartDate={newStartDate}
          customEndDate={newEndDate}
          timezoneOffset={timezoneOffset}
          setPresetRangeStartDate={setPresetRangeStartDate}
          closeDateRangeSelector={closeDateRangeSelector}
          action={action}
        />
      </div>
      <div className="date-range-selector--footer">
        <Button appearance="primary" onClick={handleButtonClick}>
          {applyText}
        </Button>
        <button type="button" className="cancel" onClick={closeDateRangeSelector}>
          {cancelText}
        </button>
        <div className="date-range-selector__help-text">
          {helpTextFirstLine}
          <br />
          {helpTextSecondLine}
        </div>
      </div>
    </div>
  );
};

DateRangePicker.propTypes = {
  timezoneOffset: PropTypes.string.isRequired,
  selectedStartDate: PropTypes.number.isRequired,
  selectedEndDate: PropTypes.number.isRequired,
  closeDateRangeSelector: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default DateRangePicker;
