import React from "react";

import { Checkbox } from "bp-ui";
import { useTranslation } from "react-i18next";

const SelectPartners = ({ partners, setSelectedPartnerIds, selectedPartnerIds }) => {
  const { t } = useTranslation();

  const handleCheckboxChange = (id) => {
    setSelectedPartnerIds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((partnerId) => partnerId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const rowClass = "flex gap-8";
  const firstColClass = "w-12 break-all";
  const colClass = "w-60 break-all";
  const lastColClass = "w-fit max-w-96 break-all";

  const borderClass = "border border-solid border-grey rounded-lg";
  const dividerClass = "divide-y divide-x-0 divide-solid divide-grey";

  return (
    <div className="overflow-x-auto c-scrollbar">
      <div className={`min-w-max text-dark-blue ${borderClass} ${dividerClass}`}>
        <div className={`${rowClass} text-base text-grey-blue font-semibold uppercase`}>
          <div className={`${firstColClass} py-4 pl-4 gap-4`}></div>
          <div aria-hidden className={`${colClass} py-4 gap-4`}>
            {t("partners.tableHeader.contactName")}
          </div>
          <div aria-hidden className={`${lastColClass} py-4 pr-4 gap-4`}>
            {t("partners.tableHeader.email")}
          </div>
        </div>

        <ul className={`${dividerClass}`}>
          {partners.map((partner) => (
            <li key={partner.id} className={`${rowClass}`}>
              <div className={`${firstColClass} flex items-center py-4 pl-4 gap-4`}>
                <div className="relative leading-4 h-4">
                  <Checkbox
                    name={partner.id}
                    ariaLabel={`${t("partners.tableHeader.contactName")} ${partner.first_name} ${partner.last_name}, ${t("partners.tableHeader.email")} ${partner.email}`}
                    checked={selectedPartnerIds.includes(partner.id)}
                    onChange={() => handleCheckboxChange(partner.id)}
                    value={partner.id}
                  />
                </div>
              </div>

              <div
                aria-hidden
                className={`${colClass} py-4 gap-4`}
              >{`${partner.first_name} ${partner.last_name}`}</div>

              <div aria-hidden className={`${lastColClass} py-4 pr-4 gap-4`}>
                {partner.email}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectPartners;
