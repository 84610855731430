import React, { useCallback, useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const LocaleDropdown = ({
  localeErrorKey,
  handleLocaleChange,
  marketLocales,
  localeErrors = [],
  selectedLocale = undefined,
}) => {
  const { t } = useTranslation();
  // If there is a selectedLocale (e.g. via edit form) use this,
  // otherwise try to default to the locale of the browser. If no option
  // is found we display placeholder text and BE will validate presence.
  const setInitialLanguage = () => {
    const browserLanguage = navigator.language;
    // most of our locales are 2 characters and browser is 5 e.g. en-GB
    const browserLocale = browserLanguage?.slice(0, 2);
    return marketLocales.find((locale) => {
      if (selectedLocale) {
        return locale.value === selectedLocale;
      }

      return locale.value === browserLocale;
    });
  };

  const [language, setLanguage] = useState(setInitialLanguage());

  const handleLanguageChange = useCallback(
    (newLocale) => {
      setLanguage(newLocale);
      handleLocaleChange(newLocale.value);
    },
    [handleLocaleChange]
  );

  useEffect(() => {
    if (language) handleLocaleChange(language.value);
  }, [handleLocaleChange, language?.value]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--b-background-color)",
      boxShadow: "none",
      borderRadius: "10px",
      border: state.isFocused ? "2px solid var(--b-text-color)" : "2px solid transparent",
      transition: "none",
      "&:hover": {
        border: state.isFocused ? "2px solid var(--b-text-color)" : "2px solid transparent",
      },
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "15px 17px",
      width: "130px",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      marginTop: "5px",
      boxShadow: "2px 2px 10px  0 rgba(var(--b-black), 0.2)",
      padding: "20px 0",
    }),

    container: () => ({
      marginRight: "5px",
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: "400px",
      padding: "0em",
    }),

    placeholder: (provided) => ({
      ...provided,
      margin: "0",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "rgba(var(--b-dark-blue), 1)",
    }),

    input: (provided) => ({
      ...provided,
      padding: "0",
      margin: "0",
    }),

    option: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      padding: "0 20px",
      color: "rgba(var(--b-dark-blue), 1)",
      backgroundColor: state.isFocused ? "rgba(var(--b-blue), 0.08)" : "inherit",
      "&:hover": {
        backgroundColor: "rgba(var(--b-blue), 0.08)",
      },
      fontWeight: state.isSelected ? "600" : "400",
    }),
  };

  const Errors = () =>
    localeErrors.map((error) => (
      <div className="field-error-message" key={error.type}>
        {t(`forms.errors.${localeErrorKey}.${error.type}`)}
      </div>
    ));

  return (
    <div className="field-group">
      <div className="flex-between input-label__required">
        <label id="language_dropdown">{t("forms.labels.partner.language")}</label>
      </div>

      <Select
        aria-labelledby="language_dropdown"
        placeholder="Select language"
        options={marketLocales}
        value={language}
        onChange={handleLanguageChange}
        styles={customStyles}
        getOptionLabel={(e) => (
          <div>
            <span>{t(`languages.${e.value}`)}</span>
          </div>
        )}
      />

      <Errors />
    </div>
  );
};

export default LocaleDropdown;

LocaleDropdown.propTypes = {
  localeErrors: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    })
  ),
  localeErrorKey: PropTypes.string.isRequired,
  handleLocaleChange: PropTypes.func.isRequired,
  marketLocales: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  selectedLocale: PropTypes.string,
};
