import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({
  type,
  size,
  onClick,
  href,
  children,
  download,
  icon,
  loading,
  disabled,
  className,
  fullWidth,
  appearance,
  outline,
  buttonElement = "button",
  ...props
}) => {
  const buttonClasses = classnames(
    "c-button",
    {
      [`c-button--${appearance}`]: appearance,
      [`c-button--${size}`]: size,
      "c-button--disabled": disabled,
      "c-button--outline": outline,
      "c-button--is-loading": loading,
      "c-button--full-width": fullWidth,
    },
    className
  );

  const content = (
    <>
      {loading && <i className="c-button__loader" />}
      {icon && !loading && <i className="c-button__icon">{icon}</i>}
      {children && <span className="c-button__content">{children}</span>}
    </>
  );

  // TODO sc-33290: upgrade Link to react-router-dom v6 syntax.  API docs do NOT describe "`download`" as one of the attributes

  let component;
  if (buttonElement === "button") {
    component = (
      <button
        type="button"
        className={buttonClasses}
        onClick={onClick}
        disabled={loading || disabled}
        {...props}
        aria-busy={loading}
      >
        {content}
      </button>
    );
  } else if (buttonElement === "submit") {
    component = (
      <button
        type="submit"
        className={buttonClasses}
        onClick={onClick}
        disabled={loading || disabled}
        {...props}
      >
        {content}
      </button>
    );
  } else if (buttonElement === "link") {
    component = (
      <Link to={href} className={buttonClasses} download={download} {...props}>
        {content}
      </Link>
    );
  }

  return component;
};

Button.propTypes = {
  appearance: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "link",
    "negative",
    "negative-outlined",
  ]),
  size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
  buttonElement: PropTypes.oneOf(["button", "submit", "link"]),
  download: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
