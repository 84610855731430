import React from "react";

import { Permissions } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import Navigation from "components/navigation/Navigation";
import SubNavigation from "components/sub_navigation/SubNavigation";

import "./bi_campaign_reports.scss";
import ReportingNavigation from "./reporting-navigation/ReportingNavigation";

const BiCampaignReports = ({ selectedMarket }) => {
  const location = useLocation();
  const { t } = useTranslation();
  let { path } = useRouteMatch();

  const { viewReports } = Permissions;

  const title = t("reporting.navigation.noMobileWarningTitle");
  const subtitle = t("reporting.navigation.noMobileWarningSubtitle");

  const availableLinks = [
    {
      text: t("reporting.navigation.referrerReports"),
      path: "/reports/referrer/dashboard",
      cisOnly: false,
      permissions: [viewReports],
      visible: !!selectedMarket
        .visibleCampaigns()
        .find((f) => f.programType === "referrer" || f.programType === null),
    },
    {
      text: t("reporting.navigation.rewardReports"),
      path: "/reports/reward/operational",
      cisOnly: false,
      permissions: [viewReports],
      visible: !!selectedMarket.visibleCampaigns().find((f) => f.programType === "reward"),
    },
    {
      text: t("reporting.navigation.partnerReports"),
      path: "/reports/partner/dashboard",
      cisOnly: false,
      permissions: [viewReports],
      visible: !!selectedMarket.visibleCampaigns().find((f) => f.programType === "partner"),
    },
  ];

  if (location.pathname.endsWith("/reports")) {
    const defaultReport = availableLinks.find((f) => f.visible);

    if (defaultReport) {
      return <Redirect to={`/${selectedMarket.subdomain}${defaultReport.path}`} />;
    }
  }

  return (
    <div className="bi-campaign-reports">
      <Navigation selectedMarket={selectedMarket} />
      <div className="large-screen-only">
        <SubNavigation
          subdomain={selectedMarket.subdomain}
          cisMarket={selectedMarket.cis}
          availableLinks={availableLinks}
        />
        <Switch>
          <Route path={`${path}/partner`}>
            <ReportingNavigation selectedMarket={selectedMarket} type="partner" />
          </Route>

          <Route path={`${path}/reward`}>
            <ReportingNavigation selectedMarket={selectedMarket} type="reward" />
          </Route>

          <Route path={`${path}/referrer`}>
            <ReportingNavigation selectedMarket={selectedMarket} type="referrer" />
          </Route>
        </Switch>
      </div>
      <div className="small-screen-only">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  );
};

BiCampaignReports.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default BiCampaignReports;
