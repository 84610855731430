import "bp-ui/dist/index.css";
import { createRoot } from "react-dom/client";

import App from "./App.jsx";
import { AuthWrapper } from "./AuthWrapper";
import initLocales from "./locales";

initLocales(import.meta.env.VITE_I18N_DEBUG === "true");

const app = (
  <AuthWrapper>
    <App />
  </AuthWrapper>
);

createRoot(document.getElementById("root")).render(app);
