import React from "react";
import { useState } from "react";

import { FaCheck } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";

export const Checkbox = ({
  name,
  ariaLabel,
  checked,
  onChange,
  value,
  className,
  label,
}) => {
  const Input = () => (
    <div className="relative w-4 h-4">
      <input
        id={name}
        type="checkbox"
        className={`${twMerge(
          `w-4 h-4 m-0
            peer shrink-0
            appearance-none
            border border-solid border-mid-grey rounded
            bg-light-grey hover:bg-grey-blue/10
            checked:bg-mid-blue checked:border-mid-blue
            checked:hover:bg-dark-blue checked:hover:border-dark-blue
            checked:active:bg-dark-blue/80 checked:active:border-dark-blue/80`,
          className
        )}
          `}
        name={name}
        aria-label={ariaLabel}
        aria-checked={checked}
        checked={checked}
        onChange={onChange}
        value={value}
      />

      <i
        aria-hidden
        className={`
            w-4 h-4
            absolute inset-0
            hidden peer-checked:flex
            justify-center items-center
            text-white
            pointer-events-none
          `}
      >
        <FaCheck className="w-3 h-3" />
      </i>
    </div>
  );

  return (
    <>
      {label ? (
        <div className="flex items-center gap-4">
          <Input />

          <label className="cursor-pointer" htmlFor={name}>
            {label}
          </label>
        </div>
      ) : (
        <Input />
      )}
    </>
  );
};

export const Preview = () => {
  const [testOne, setTestOne] = useState(true);
  const [testTwo, setTestTwo] = useState(false);
  const [testThree, setTestThree] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <Checkbox
        name="test 1"
        ariaLabel="checkbox 1"
        value={1}
        checked={testOne}
        onChange={() => setTestOne(!testOne)}
      />
      <Checkbox
        name="test 2"
        ariaLabel="checkbox 2"
        value={2}
        checked={testTwo}
        onChange={() => setTestTwo(!testTwo)}
      />
      <Checkbox
        name="test 3"
        ariaLabel="checkbox 3"
        value={3}
        checked={testThree}
        onChange={() => setTestThree(!testThree)}
        label="Checkbox with label"
      />
    </div>
  );
};
