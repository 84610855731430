import React, { useState } from "react";

import { Button } from "bp-ui";
import { fullName } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import Select from "components/forms/select/Select";
import addNotification from "components/notifications/Notifications";

export const IssueReward = ({ selectedMarket, user, onSuccessfulRewardCreation, onCancel }) => {
  const { t } = useTranslation();
  const [selectedRewardType, setSelectedRewardType] = useState(null);
  const [selectedCampaignName, setSelectedCampaignName] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const inactiveRewardTypeText = t("userProfile.issueNewRewardModal.rewardTypes.inactive");

  const availableRewardTypes = selectedMarket.campaigns.map((campaign) => {
    const campaignName = `${campaign.name}${campaign.live ? "" : ` (${inactiveRewardTypeText})`}`;

    if (!campaign.rewardTypes) {
      return {
        name: campaignName,
        subOptions: [],
      };
    }

    const subOptions = campaign.rewardTypes
      .filter((rewardType) => rewardType.canBeIssuedTo(user))
      .filter((f) => !f.archived)
      .map((rewardType) => ({
        value: `${rewardType.id}|${campaign.name}`,
        name: `${rewardType.name}`,
      }));

    return {
      name: campaignName,
      subOptions,
    };
  });

  const handleRewardSelect = (e) => {
    const [rewardTypeId, campaignName] = e.value.split("|", 2);
    setSelectedRewardType({
      name: e.name,
      id: rewardTypeId,
    });
    setSelectedCampaignName(campaignName);
  };

  const handleIssueReward = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await selectedMarket.users.createFulfillment(selectedRewardType.id, user.id);
      addNotification.success(
        t("global.success"),
        `${t("userProfile.issueNewRewardModal.successMessage")} ${fullName(user.firstName, user.lastName)}`
      );
      onSuccessfulRewardCreation();
    } catch (e) {
      const message = e.errors?.limit
        ? t("userProfile.issueNewRewardModal.errorLimitMessage")
        : t("userProfile.issueNewRewardModal.errorMessage");

      addNotification.warning(t("global.error"), message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="c-issue-reward">
      <form onSubmit={handleIssueReward}>
        {!selectedRewardType ? (
          <Select
            options={availableRewardTypes}
            handleOnChange={handleRewardSelect}
            selectedValue={selectedRewardType}
            title={t("userProfile.issueNewRewardModal.rewardText")}
            buttonAppearance="secondary"
            id="campaign_id"
          />
        ) : (
          <div className="c-issue-reward__confirmation">
            <p>
              <strong>{t("userProfile.issueNewRewardModal.prompt")}</strong>
            </p>
            <ul className="c-issue-reward__details">
              <li>
                {`${t("userProfile.issueNewRewardModal.rewardText")}:`}
                <span>{selectedRewardType.name}</span>
              </li>
              <li>
                {`${t("userProfile.issueNewRewardModal.recipientText")}:`}
                <span>{fullName(user.firstName, user.lastName)}</span>
              </li>
              {user.customFields &&
                user.customFields.length > 0 &&
                user.customFields.map((customField) => (
                  <li key={`${customField.label}${customField.value}`}>
                    {customField.label}: <span>{customField.value}</span>
                  </li>
                ))}
              <li>
                {`${t("userProfile.issueNewRewardModal.campaignText")}:`}
                <span>{selectedCampaignName}</span>
              </li>
            </ul>

            {user.isCustomer() && (
              <p className="c-issue-reward__info">
                {t("userProfile.issueNewRewardModal.rewardInfo.isCustomer")}
              </p>
            )}
            {user.isExternalUser() && (
              <p className="c-issue-reward__info">
                {t("userProfile.issueNewRewardModal.rewardInfo.isExternalUser")}
              </p>
            )}
          </div>
        )}

        <div className="flex gap-3 mt-10">
          <Button className="flex-1" secondary={true} onClick={onCancel}>
            {t("global.cancel")}
          </Button>
          <Button
            className="flex-1"
            submit={true}
            disabled={!selectedRewardType}
            loading={isLoading}
          >
            {t("global.confirm")}
          </Button>
        </div>
      </form>
    </div>
  );
};
