export default class SearchResult {
  constructor(data) {
    this.id = data.id;
    this.type = data.user_type;

    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.customFields = data.custom_fields;

    this.totalCompletedReferrals = data.referral_counts.complete;
    this.totalPendingReferrals = data.referral_counts.pending;
    this.totalCancelledReferrals = data.referral_counts.cancelled;
    this.totalReferrals =
      this.totalCompletedReferrals + this.totalPendingReferrals + this.totalCancelledReferrals;
  }

  displayType() {
    if (this.type === "customer") {
      return "referrer";
    }

    return "friend";
  }
}
