import Fulfilment from "models/Fulfilment";

export default class BaseUser {
  constructor(market, data) {
    this.id = data.id;
    this.type = data.user_type;
    this.cisId = data.cis_id;

    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.subscribed = data.subscribed;

    this.firstSeen = data.first_seen_at;
    this.lastSeen = data.last_seen_at;

    this.locale = data.locale;

    this.campaignParticipations = this._loadCampaignParticipations(data.campaigns, market);
    this.customFields = this._loadCustomFields(data.custom_fields);

    this.campaignReferrals = this._loadCampaignReferrals(data.campaigns, data.referrals);

    this.fulfilments = data.fulfilments.map((fulfilmentData) => new Fulfilment(fulfilmentData));
  }

  isCIS() {
    return !!this.cisId;
  }

  _loadCampaignReferrals(rawCampaigns, rawReferrals) {
    return this.campaignParticipations
      .map((c) => {
        const referral = rawReferrals.find((r) => r.campaign_id === c.campaign.id);
        const customField = this.customFields.find((cf) => cf.campaignId === c.campaign.id);
        if (!referral) {
          return null;
        }

        return {
          referringFullName: referral.referring_full_name || null,
          referringId: referral.referring_id,
          referringType: referral.referring_type,
          campaignId: c.campaign.id,
          campaignName: c.campaign.name,
          completedAt: referral.completed_at || null,
          customField: customField || {},
        };
      })
      .filter((cf) => cf != null);
  }

  _loadCampaignParticipations(rawCampaigns, market) {
    return rawCampaigns
      .map((c) => {
        const campaign = market.campaigns.find((mc) => mc.id === c.id);
        const campaignParticipationData = { campaign, joinedAt: c.joined_at };
        const referrerCampaignParticipationData = {
          shareUrl: c.share_url,
          dashboardUrl: c.dashboard_url,
          rewardCapping: c.reward_capping,
        };
        if (this.type === "customer") {
          return { ...campaignParticipationData, ...referrerCampaignParticipationData };
        }

        return campaignParticipationData;
      })
      .filter((c) => c != null);
  }

  _loadCustomFields(rawCustomFields) {
    return this.campaignParticipations
      .flatMap((cp) => {
        const customFields = cp.campaign.customFieldsFor(this);

        if (customFields.length < 1) return [];

        const userCustomFields = customFields.map((customField) => {
          const userCustomField = rawCustomFields.find(
            (rawCustomField) => rawCustomField.label === customField.label
          );
          if (!userCustomField) return null;

          return { ...customField, value: userCustomField.value, campaignId: cp.campaign.id };
        });

        return userCustomFields;
      })
      .filter((cf) => cf != null);
  }

  customFieldFor(campaign, label) {
    return (
      this.customFields.find(
        (customField) => customField.campaignId === campaign.id && customField.label === label
      ) || null
    );
  }
}
