import { Status } from "bp-ui";

import {
  getReferralStatusIconFromValue,
  getReferralStatusLabelFromValue,
  getReferralStatusTypeFromValue,
} from "../helpers";

export const ReferralStatus = ({ status, tooltip = null }) => {
  return (
    <Status
      icon={getReferralStatusIconFromValue(status)}
      label={getReferralStatusLabelFromValue(status)}
      type={getReferralStatusTypeFromValue(status)}
      tooltip={tooltip}
    />
  );
};
