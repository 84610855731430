import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";

import { Spinner } from "../components/index.js";

export const Auth0Consumer = ({ onAuth, children }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    error,
  } = useAuth0();

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const authHeadersFetcher = async () => ({
    Authorization: `Bearer ${await getAccessTokenSilently()}`,
  });

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });

      return;
    }

    if (!isLoading && isAuthenticated) {
      onAuth({
        logout,
        getAuthHeaders: authHeadersFetcher,
      });

      setLoading(false);

      return;
    }

    if (error) {
      setIsError(true);
    }
  }, [isAuthenticated, isLoading, error]);

  if (isError) console.error(error);

  return (
    <>
      {loading && !isError && (
        <div className="w-full flex justify-center items-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      )}
      {!loading && !isError && children}
      {isError && (
        <div className="w-full flex justify-center items-center p-10">
          An error occurred
        </div>
      )}
    </>
  );
};

export const BpAuth0Provider = ({
  children,
  onRedirectCallback,
  domain,
  client_id,
  redirect_uri,
  audience,
  onAuth,
}) => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={client_id}
      authorizationParams={{
        redirect_uri,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Consumer onAuth={onAuth}>{children}</Auth0Consumer>
    </Auth0Provider>
  );
};
